import React, { Fragment } from 'react';
import DatePicker2 from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CargoDatepicker = ({ date, ChangeDate }) => {
  return (
    <Fragment>
      <DatePicker2
        selected={date}
        onChange={ChangeDate}
        showTimeSelect
        inline
        // customInput={<ExampleCustomInput />}
        timeFormat="HH:mm"
        timeIntervals={5}
        timeCaption="time"
        dateFormat="MM/dd/yyyy HH:mm:ss"
      />
    </Fragment>
  );
};

export default CargoDatepicker;
