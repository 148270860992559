import { Cell, DatePicker, Button, FontIcon } from 'react-md';
import { CircularProgress } from 'react-md';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import Grid2 from 'react-md/lib/Grids/Grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import Button2 from '@material-ui/core/Button';

import CargoImp from '../AWBHistory/Table/CargoImp';
import React, { Fragment, useEffect, useState } from 'react';
import 'chart.js';
import moment from 'moment';
import Axios from 'axios';
import edifeathers from '../../services/edifeathers';
import { toast } from 'react-toastify';
import { useApi } from '../../services/api';
import PagingContainer from '../../components/GridComponents/PagingPanel';
const env = process.env;

const Cell2 = (props) => {
  const { column } = props;

  if (column.name === 'date') {
    return <DateRequestCell {...props} />;
  }
  if (column.name === 'inQueue') {
    return <InQueueCell {...props} />;
  }

  return <Table.Cell {...props} />;
};

const DateRequestCell = ({ value, ...restProps }) => {
  let splitDate = value.toString().split(' ');
  let fullDate = '';
  let fullHour = '';

  if (splitDate.length > 0) {
    fullDate = splitDate[0];
    fullHour = splitDate[1];
  }
  return (
    <Table.Cell
      {...restProps}
      style={{
        overflow: 'visible',
      }}
    >
      <div>
        {fullDate} <br /> {fullHour}
      </div>
    </Table.Cell>
  );
};

const InQueueCell = ({ value, ...restProps }) => {
  if (value.toString() == 'true') {
    return (
      <Table.Cell
        {...restProps}
        // style={{
        //   overflow: 'visible'
        // }}
      >
        <div className="custom-success-text md-font-bold">{value}</div>
      </Table.Cell>
    );
  } else {
    return (
      <Table.Cell
        {...restProps}
        // style={{
        //   overflow: 'visible'
        // }}
      >
        <div className="custom-error-text md-font-bold">{value}</div>
      </Table.Cell>
    );
  }
};

export default (props) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [name, setName] = React.useState('');

  const [range, setRange] = useState({
    from: new Date().setDate(new Date().getDate() - 1),
    to: Date.now(),
  });

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const siteType =
    env.NODE_ENV == 'development' || env.NODE_ENV == 'staging'
      ? 'staging'
      : 'production';
  const [, , fetchFile, isError] = useApi(
    'ccapi',
    'documents/resendfile',
    undefined,
    'post'
  );

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        let day = new Date().getDate();
        let date = new Date();
        date.setDate(day - 1);
        let from = date.setHours(0, 0, 0, 0);

        getDocuments({
          site: 'CCAIR',
          from: from,
          to: Date.now(),
          siteType: siteType,
        });
      }
    }
    tttt();
  }, []);

  const getDocuments = async (query) => {
    setLoading(true);
    // console.log('query: ', query);
    const searchResult = await edifeathers.service('files-log').find({
      query: {
        $agregation: query,
      },
    });
    //if (err) return;
    setFilter(searchResult);
    setLoading(false);
    setData(searchResult);
  };

  const applyFilter = () => {
    let query = {};
    let from = new Date(range.from);
    let to = new Date(range.to);
    to.setHours(23, 54, 59, 999);

    query.site = 'CCAIR';
    query.to = to.setDate(to.getDate());
    query.from = from.setDate(from.getDate());
    query.siteType = siteType;

    getDocuments(query);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resendFile = async (id) => {
    const [result, err] = await fetchFile({
      params: [id],
    });
    if (!err) {
      //console.log('response ', result);
      if (result.message == 'File does not exist') {
        toast.info(result.message);
      } else if (result.status && result.status == 'error') {
        toast.error(result.message + '-> ' + result.error.message);
      } else {
        toast.success(result.message);
      }
    } else console.log('response  e', err);

    applyFilter();
  };

  return (
    <Fragment>
      <CargoImp
        open={open}
        name={name}
        onClose={handleClose}
        value={value}
      ></CargoImp>
      <Grid2 style={{ paddingTop: 0 }}>
        <Cell size={3} phoneSize={12} tabletSize={12}>
          <span style={{ fontSize: 35, color: 'gray' }}>
            Incoming Files Log
          </span>
        </Cell>
        <DatePicker
          id="inline-date-picker-portait"
          label="from"
          value={moment(range.from).format('L')}
          defaultValue={moment(range.from).format('L')}
          maxDate={new Date(range.to)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={(e) => {
            setRange({ ...range, from: e });
          }}
        />
        <DatePicker
          id="inline-date-picker-portait"
          label="to"
          value={moment(range.to).format('L')}
          defaultValue={moment(range.to).format('L')}
          minDate={new Date(range.from)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={(e) => setRange({ ...range, to: e })}
        />

        <Button
          className="md-cell"
          style={{ marginTop: 25 }}
          icon
          iconEl={
            <FontIcon
              secondary
              className="fas fa-search"
              onClick={applyFilter}
            />
          }
        />
        {/* <Button2
          variant="outlined"
          color="primary"
          style={{ marginTop: 25 }}
          onClick={applyFilter}
        >
          Refresh
        </Button2> */}
      </Grid2>

      {loading ? (
        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
          <CircularProgress
            id="loading"
            centered={true}
            className="loading-blue"
            scale={2}
          ></CircularProgress>
        </div>
      ) : (
        <Grid
          rows={filter}
          style={{ backgroundColor: 'red' }}
          columns={[
            {
              name: 'fileName',
              title: 'File Name',
              getCellValue: (row) => {
                return `${row.fileName}`;
              },
            },
            {
              name: 'awb',
              title: 'AWB/Content',
              getCellValue: (row) => {
                if (row.fileContent) {
                  let line = row.fileContent.split('\r');
                  if (line.length > 5) {
                    return line[5];
                  }
                  return row.fileContent;
                }
                return row.fileContent;
              },
            },
            {
              name: 'date',
              title: 'Date',
              getCellValue: (row) => {
                return moment(row.date).format('MM/DD/YYYY HH:mm:ss');
              },
            },
            {
              name: 'siteType',
              title: 'Environment',
              getCellValue: (row) => {
                return row.siteType;
              },
            },
            {
              name: 'inQueue',
              title: 'In Queue',
              getCellValue: (row) => `${row.inQueue}`,
            },
            {
              name: 'view',
              title: 'View EDI',
              getCellValue: (row) => {
                return (
                  <Button2
                    onClick={(c) => {
                      setValue(row.fileContent);
                      setName(`${row.fileName}
                      - ${row.date}`);
                      setTimeout(() => {
                        handleClickListItem();
                      }, 10);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    View EDI
                  </Button2>
                );
              },
            },
            {
              name: 'resend',
              title: 'Resend',
              getCellValue: (row) => {
                return (
                  <Button2
                    onClick={(c) => {
                      resendFile(row._id);
                    }}
                    variant="contained"
                    color="primary"
                    disabled={row.inQueue}
                  >
                    Resend
                  </Button2>
                );
              },
            },
          ]}
        >
          <SortingState
          // defaultSorting={[{ columnName: 'product', direction: 'asc' }]}
          />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={[
              { columnName: 'view', filteringEnabled: false },
              { columnName: 'resend', filteringEnabled: false },
            ]}
          />
          <IntegratedFiltering />
          <PagingState
            defaultCurrentPage={0}
            pageSize={50}
            // onCurrentPageChange={e => {
            //   setLoading(true);
            //   setCurrentPage(e);
            //   setTimeout(() => {
            //     setLoading(false);
            //   }, 100);
            // }}
          />
          <IntegratedPaging />
          <Table
            columnExtensions={[
              //{ columnName: 'additionalInfo', wordWrapEnabled: true },
              { columnName: 'inQueue', width: '100' },
            ]}
            // containerComponent={ContainerTable}
            cellComponent={Cell2}
          />
          <TableFilterRow />
          <TableHeaderRow showSortingControls />
          <PagingPanel containerComponent={PagingContainer} />
        </Grid>
      )}
    </Fragment>
  );
};
