import { Card, CardText, Cell, Grid, Switch, Button, FontIcon, CardTitle, List, ListItem, DialogContainer } from "react-md";
import { CargoDetails, Consignee, FlightDetails, PermitProceed, Shipper, SplitIndicator, TransferDetails } from "../../components/documents/manifest";
import React, { useState, Fragment, useEffect } from "react";
import { useApi } from "../../services/api";
import { toast } from "react-toastify";
import '../style.css'

export default () => {
    const [express, setExpress] = useState(false)
    const [, , fetchDoc,] = useApi('ccapi', 'documents', undefined, 'post')
    const [, , fetchDelete] = useApi('ccapi', 'documents', {}, 'delete')
    const [permit, setPermit] = useState(false)
    const [split, setSplit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [transfer, setTransfer] = useState(false)
    const [documents, setDocuments] = useState([])
    const [document, setDocument] = useState({})
    const [showModal, setShowModal] = useState(false)
    const [simple, setSimple] = useState(false)
    const [house, setHouse] = useState(false)
    const [ccl, setCcl] = useState({ arrivalAirport: '', terminalOp: '' })
    const [awb, setAwb] = useState({ consolidationId: 'M', prefix: '', serialNumber: '', hawbNumber: '', pkTrackingId: '' })
    const [wbl, setWbl] = useState({ airportOrigin: '', numPieces: '', weightCode: '', weight: '', cargoDesc: '', destinationAirport: '', arrivalDatePTPAirport: '' })
    const [arr, setArr] = useState({ impCarrier: '', flightNumber: '', arrivalDate: '', brdPieceCnt: '', weightCode: '', weight: '', arrivalRef: '' })
    const [ced, setCed] = useState({ entryType: '', entryNumber: '' })
    const [shp, setShp] = useState({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
    const [cne, setCne] = useState({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
    const [trn, setTrn] = useState({ destinationAirport: '', transferstatusId: '', bondedCarrierId: '', onwardCarrier: '', premisesId: '', inbondControlNum: '' })
    const [csd, setCsd] = useState({ originGoods: '', declaredValue: '', currencyCode: '', hazmatCode: '' })
    const [fda, setFda] = useState({ lineId: '' })
    const [aws, setAws] = useState('')

    useEffect(() => {
        setLoading(false)
    }, [loading])

    const validateInputs = () => {
        if (awb.prefix.length === 3 && awb.serialNumber.length === 8 && wbl.numPieces.length !== 0 && wbl.weight.length !== 0 && wbl.weightCode.length !== 0 && Object.values(shp).slice(0, -2).every(v => v !== '') && Object.values(cne).slice(0, -2).every(v => v !== '')) {
            return { valid: true }
        } else {
            let errors = handleErrors({ ...awb, ...ccl, ...arr, ...wbl, ...shp, ...cne, ...ced })
            return { valid: documents.length && !errors.length ? true : false, message: errors }
        }
    }

    const handleErrors = (values) => {
        let messages = Object.keys(values).filter(m => { return errorMessages[m] !== undefined })
        let ms = messages.reduce((a, b) => (values[b] === "" ? [...a, errorMessages[b]] : a), [])
        return ms
    }

    const send = async (add) => {
        let { valid, message } = validateInputs()
        if (!valid) {
            return message.filter(f => toast.error(f))
        }

        if (!house && !simple) {
            awb.consolidationId = 'M'
        }

        let body = { SMI: { componentId: 'FRI' }, AWB: awb, WBL: wbl, CED: ced }
        if (Object.values(ccl).some(v => v !== '')) body.CCL = ccl
        if (Object.values(arr).some(v => v !== '')) body.ARR = arr
        if (Object.values(shp).some(v => v !== '')) body.SHP = shp
        if (Object.values(cne).some(v => v !== '')) body.CNE = cne
        if (Object.values(trn).some(v => v !== '')) body.TRN = trn
        if (Object.values(csd).some(v => v !== '')) body.CSD = csd
        if (Object.values(fda).some(v => v !== '')) body.FDA = fda

        let { impCarrier, flightNumber, arrivalDate } = arr
        let { arrivalAirport, terminalOp } = ccl
        let { serialNumber, prefix } = awb
        let airportOrigin = wbl.airportOrigin
        if (fda.lineId === 'FDA') body.FDA = fda

        let [res, err] = await fetchDoc({ body })
        if (err.length) {
            toast.error(err[0].doc.meta.errors[0].message.replace('\\', ''))
        } else {
            toast.info(res.message)
            setCcl({ arrivalAirport: '', terminalOp: '' })
            setAwb({ consolidationId: 'M', prefix: '', serialNumber: '', hawbNumber: '', pkTrackingId: '' })
            setWbl({ airportOrigin: '', numPieces: '', weightCode: '', weight: '', cargoDesc: '', destinationAirport: '', arrivalDatePTPAirport: '' })
            setArr({ impCarrier: '', flightNumber: '', arrivalDate: '', brdPieceCnt: '', weightCode: '', weight: '', arrivalRef: '' })
            setCed({ entryType: '', entryNumber: '' })
            setShp({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
            setCne({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
            setTrn({ destinationAirport: '', transferstatusId: '', bondedCarrierId: '', onwardCarrier: '', premisesId: '', inbondControlNum: '' })
            setCsd({ originGoods: '', declaredValue: '', currencyCode: '', hazmatCode: '' })
            setFda({ lineId: '' })
        }

        if (add) {
            let houses = documents
            houses.push({
                id: res.id,
                doc: res.sn,
                status: res.status
            })
            setDocuments(houses)
            setAwb({ ...awb, serialNumber, prefix, hawbNumber: '', pkTrackingId: '' })
            setArr({ ...arr, impCarrier, flightNumber, arrivalDate, brdPieceCnt: '', weightCode: '', weight: '', arrivalRef: '' })
            setWbl({ ...wbl, airportOrigin, numPieces: '', weightCode: '', weight: '', cargoDesc: '', destinationAirport: '', arrivalDatePTPAirport: '' })
            setCcl({ ...ccl, arrivalAirport, terminalOp })
            setCed({ entryType: '', entryNumber: '' })
            setShp({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
            setCne({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
            setTrn({ destinationAirport: '', transferstatusId: '', bondedCarrierId: '', onwardCarrier: '', premisesId: '', inbondControlNum: '' })
            setCsd({ originGoods: '', declaredValue: '', currencyCode: '', hazmatCode: '' })
            setFda({ lineId: '' })
        }
        setLoading(true)
        window.scrollTo(0, 0)
    }

    return (
        <Fragment>
            <Grid>
                <Cell size={3} phoneSize={12} tabletSize={12}>
                    <span style={{ fontSize: 35, color: 'gray' }}>ACE Air Express eManifest</span>
                </Cell>
            </Grid>
            <Grid>
                <Cell size={8}>
                    <Grid noSpacing style={{ marginBottom: 10 }}>
                        <Card raise tableCard>
                            <CardText>
                                <FlightDetails create={true} expr={true} aws={aws} setAws={setAws} house={house} setHouse={setHouse} simple={simple} setSimple={setSimple} ced={ced} setCed={setCed} awb={awb} setAwb={setAwb} arr={arr} setArr={setArr} wbl={wbl} setWbl={setWbl} ccl={ccl} setCcl={setCcl} />
                            </CardText>
                        </Card>
                    </Grid>
                    <Grid noSpacing style={{ marginBottom: 10 }}>
                        <Cell size={6}>
                            <Card raise tableCard>
                                <CardText>
                                    <Shipper shp={shp} setShp={setShp} />
                                </CardText>
                            </Card>
                        </Cell>
                        <Cell size={6}>
                            <Card raise tableCard>
                                <CardText>
                                    <Consignee cne={cne} setCne={setCne} />
                                </CardText>
                            </Card>
                        </Cell>
                    </Grid>
                    <Grid noSpacing>
                        <Cell size={12}>
                            <Card raise tableCard>
                                <CardText>
                                    <CargoDetails awb={awb} setAwb={setAwb} csd={csd} setCsd={setCsd} wbl={wbl} setWbl={setWbl} fda={fda} setFda={setFda} />
                                </CardText>
                            </Card>
                        </Cell>
                    </Grid>
                    <Grid>
                        <Switch id="switch-permit" type="switch" label="Flight is moving under PTP" name="permit" onChange={e => setPermit(!permit)} />
                        <Switch id="switch-split" type="switch" label="Split Shipment?" name="permit" onChange={e => setSplit(!split)} />
                        <Switch id="switch-transfer" type="switch" label="In bond shipment or local transfer to a bonded facility?" name="permit" onChange={e => setTransfer(!transfer)} />
                    </Grid>
                    {
                        permit ? (
                            <Grid noSpacing style={{ marginBottom: 10 }}>
                                <Cell size={12}>
                                    <Card raise tableCard>
                                        <CardText>
                                            <PermitProceed wbl={wbl} setWbl={setWbl} />
                                        </CardText>
                                    </Card>
                                </Cell>
                            </Grid>
                        ) : null
                    }
                    {
                        split ? (
                            <Grid noSpacing style={{ marginBottom: 10 }}>
                                <Cell size={12}>
                                    <Card raise tableCard>
                                        <CardText>
                                            <SplitIndicator arr={arr} setArr={setArr} />
                                        </CardText>
                                    </Card>
                                </Cell>
                            </Grid>
                        ) : null
                    }
                    {
                        transfer ? (
                            <Grid noSpacing style={{ marginBottom: 10 }}>
                                <Cell size={12}>
                                    <Card raise tableCard>
                                        <CardText>
                                            <TransferDetails trn={trn} setTrn={setTrn} />
                                        </CardText>
                                    </Card>
                                </Cell>
                            </Grid>
                        ) : null
                    }
                    <Grid>
                        <Cell size={12} className="md-text-center">
                            <Button flat secondary iconBefore={false} disabled={simple} onClick={e => send(true)} iconEl={<FontIcon iconClassName='fas fa-plus' />}>Add House</Button>
                            <Button flat secondary iconBefore={false} onClick={e => send(false)} iconEl={<FontIcon iconClassName='fas fa-check' />}>Done</Button>
                        </Cell>
                    </Grid>
                </Cell>
                <Cell size={4} tabletSize={12} phoneSize={12} style={{ marginTop: 25 }}>
                    <Card raise tableCard>
                        <CardTitle title="Document created" subtitle={documents.length} />
                        <CardText>
                            {
                                documents.length ? (
                                    <List>
                                        {
                                            documents.map((d, i) => (
                                                <ListItem key={i} primaryText={d.doc} secondaryText={d.status} renderChildrenOutside>
                                                    <Button icon onClick={() => {
                                                        setDocument(d)
                                                        setShowModal(true)
                                                    }}>delete</Button>
                                                </ListItem>
                                            ))
                                        }
                                    </List>
                                ) : (<span>There are no documents yet</span>)
                            }
                        </CardText>
                    </Card>
                </Cell>
            </Grid>
            <DialogContainer
                id="speed-boost"
                visible={showModal}
                title="Do you want to delete document?"
                actions={[
                    {
                        secondary: true, children: 'Delete', onClick: async () => {
                            await fetchDelete({ params: [document.id] })
                            documents.slice(documents.indexOf(document), 1)
                            setShowModal(false)
                            toast.success('Document has been Deleted.')
                        }
                    },
                    { primary: true, children: 'Cancel', onClick: () => { setShowModal(false) } }
                ]}
                onHide={() => { }}
            >
            </DialogContainer>
        </Fragment>
    )
}

const errorMessages = {
    entryType: 'Entry Type is required',
    description: 'You must add a cargo description',
    piece: 'You must add piece count in cargo details',
    weight: 'You must add cargo weight',
    weightCode: 'You must specify weight code',
    name: 'You must add name of shipper and consignee',
    address: 'You must specify shipper and consignee address',
    city: 'You must specify shipper and consignee city',
    state: 'You must specify shipper and consignee state or procinve',
    country: 'You must specify shipper and consignee country',
    terminalOp: 'Terminal operator is required',
    prefix: 'AWB prefix is required',
    serialNumber: 'AWB # is required',
    impCarrier: 'Airline is required',
    flightNumber: 'Flight # is required',
    origin: 'Origin is required',
    arrivalAirport: '1st US Arrival Airport is required',
    arrivalDate: 'ETA (Day & Month) is required'
}
