import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';
import {
  FSNMessage,
  FSCMessage,
  FSNReasonCode,
  docType,
} from '../../../components/awb-history/Codes';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import moment from 'moment';
import feathers from '../../../services/feathers';
import CargoImp from './CargoImp';
import Button from '@material-ui/core/Button';

export default function Document(props) {
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [name, setName] = React.useState('');
  const [row, setRow] = useState([]);
  const [hideHAWB, setHideHAWB] = useState([]);

  const getManifests = async () => {
    let house = props.isHouse ? true : false;
    let impCarrier = props.impCarrier || '',
      arrivalDate = props.arrivalDate || '',
      flightNumber = [props.row.flightNumber],
      isSplit = props.row.isSplit ? props.row.isSplit : false;
    let result = await feathers.service('documents-by-awb').find({
      query: {
        prefix: props.prefix,
        serialNumber: props.serialNumber,
        hawbNumber: props.hawbNumber,
        isHouse: house,
        impCarrier: impCarrier,
        arrivalDate: arrivalDate,
        flightNumber: flightNumber,
        date: props.date,
        isSplit: isSplit,
        company: props.row.company,
      },
    });
    setRow(result);
    setLoading(false);
    let hide = [];
    if (!house) {
      hide.push('hawb');
    }
    await setHideHAWB(hide);
  };

  useEffect(() => {
    setLoading(true);
    if (props.currentTab === props.index) {
      getManifests();
    }
  }, [props.currentTab]);

  if (loading) return <div>Loading...</div>;

  let statusMessage = (row) => {
    let result = [];

    // if (typeof row.docTranslated == 'object') {
    switch (row.data.name) {
      case 'FSC':
        if (row.data.TXT && row.data.TXT.length) {
          if (
            row.data.FSC.answerCode == '08' ||
            row.data.FSC.answerCode == '09'
          ) {
            row.data.TXT.map((txt) => {
              result.push({
                code: row.data.FSC.answerCode,
                message: `${txt.information}-${
                  row.data.ARR2 ? row.data.ARR2.impCarrier : '' + row.data.ARR2
                }${row.data.ARR2 ? row.data.ARR2.flightNumber : ''}`,
              });
            });
          } else {
            row.data.TXT.map((txt) => {
              result.push({
                code: row.data.FSC.answerCode,
                message: txt.information,
              });
            });
          }
        } else {
          let info = FSCMessage(row.data.FSC.answerCode, false);
          info.additionalInfo = [];
          console.log({ row });
          if (row.data.FSC.answerCode == '10') {
            if (row.data.CCL && row.data.CCL.terminalOp)
              info.additionalInfo.push(
                `Terminal Op: ${row.data.CCL.terminalOp}`
              );

            if (row.data.CCL && row.data.CCL.arrivalAirport)
              info.additionalInfo.push(
                `1st US Port of Arrival: ${row.data.CCL.arrivalAirport}, ${
                  row.data.TRN ? row.data.TRN[0].transferstatusId : ''
                }, ${row.data.TRN ? row.data.TRN[0].premisesId : ''}`
              );

            if (
              (row.data.ARR2 && row.data.ARR2.impCarrier) ||
              (row.data.ARR2 && row.data.ARR2.flightNumber)
            )
              info.additionalInfo.push(
                `Flight #:${row.data.ARR2 && row.data.ARR2.impCarrier}${
                  row.data.ARR2 && row.data.ARR2.flightNumber
                }`
              );
            if (row.data.ARR2 && row.data.ARR2.arrivalDate)
              info.additionalInfo.push(
                `Arrival Date: ${row.data.ARR2.arrivalDate}`
              );

            if (row.data.WBL && row.data.WBL.numPieces)
              info.additionalInfo.push(`PCS: ${row.data.WBL.numPieces}`);
            if (
              row.data.WBL &&
              (row.data.WBL.weight || row.data.WBL.weightCode)
            )
              info.additionalInfo.push(
                `Weight: ${row.data.WBL.weight} ${row.data.WBL.weightCode}`
              );
          }
          result.push(info);
        }

        break;

      case 'FER':
        row.data.ERR.map((error) =>
          result.push({
            code: error.errorCode,
            message: error.errorText,
          })
        );
        break;

      case 'FSN':
        let message = FSNMessage(row.data.CSN.actionCode, false);
        message.additionalInfo = [];

        if (row.data.CSN.entryType)
          message.additionalInfo.push(`Entry Type: ${row.data.CSN.entryType}`);
        if (row.data.CSN.entryNumber)
          message.additionalInfo.push(`Entry #: ${row.data.CSN.entryNumber}`);
        if (row.data.CSN.numPieces)
          message.additionalInfo.push(`Quantity: ${row.data.CSN.numPieces}`);
        if (row.data.ARR2.arrivalRef)
          message.additionalInfo.push(
            `Split Shipment Part: ${row.data.ARR2.arrivalRef}`
          );
        if (row.data.CSN.remarks)
          message.additionalInfo.push(`Remarks: ${row.data.CSN.remarks}`);

        result.push(message);
        break;

      case 'FSI':
        result.push(FSNMessage(row.data.CSN.actionCode, false));
        break;
      case 'PSN':
        result.push({
          additionalInfo: [
            `CSN/${row.data.CSN.actionCode}-${row.data.CSN.numPieces}/${row.data.CSN.transactionDate}${row.data.CSN.transactionTime}`,
          ],
        });
        break;

      default:
        break;
    }
    // } else {
    //   result.push({
    //     code: '',
    //     message: ''
    //   });
    // }

    return result;
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginBottom: 80 }}>
      <CargoImp
        open={open}
        name={name}
        onClose={handleClose}
        value={value}
      ></CargoImp>
      <Grid
        rows={row}
        columns={[
          {
            name: 'awb',
            title: 'AWB',
            getCellValue: (row) => {
              let AWB = row.data.AWB2 || row.data.AWB;
              if (AWB) {
                return `${AWB.prefix}-${AWB.serialNumber}${
                  AWB.hawbNumber
                    ? ''
                    : row.data.ARR && row.data.ARR.arrivalRef
                    ? '-' + row.data.ARR.arrivalRef
                    : ''
                }`;
              } else {
                return ``;
              }
            },
          },
          {
            name: 'hawb',
            title: 'HAWB',
            getCellValue: (row) => {
              let AWB = row.data.AWB2 || row.data.AWB;
              return AWB
                ? `${AWB.hawbNumber || ''}${
                    row.data.ARR && row.data.ARR.arrivalRef
                      ? '-' + row.data.ARR.arrivalRef
                      : ''
                  }`
                : '';
            },
          },
          {
            name: 'awbtype',
            title: 'AWB Type',
            getCellValue: (row) => {
              return props.type;
            },
          },
          {
            name: 'status',
            title: 'Doc Type',
            getCellValue: (row) => {
              return row.data.SMI && row.data.SMI.componentId
                ? `${docType(row.data.SMI.componentId)}${
                    row.data.SMI.componentId == 'FSQ'
                      ? ' / ' + row.data.FSQ.requestCode
                      : ''
                  }`
                : `${docType(row.data.name)}`;
            },
          },
          {
            name: 'statusMessage',
            title: 'Status Message',
            getCellValue: (row) => {
              return row.type == 'response'
                ? statusMessage(row).map((item, index) => {
                    return (
                      <>
                        {item.code ? (
                          <div
                            key={index}
                          >{`${item.code} - ${item.message}`}</div>
                        ) : (
                          ''
                        )}

                        {item.additionalInfo
                          ? item.additionalInfo.map((item2, index2) => (
                              <div key={index2}>{item2}</div>
                            ))
                          : ''}
                      </>
                    );
                  })
                : row.data.ASN && row.data.ASN.statusCode
                ? FSNReasonCode(row.data.ASN.statusCode, true)
                : '';
            },
          },
          {
            name: 'type',
            title: 'Type',
            getCellValue: (row) =>
              row.type == 'request' || row.type == 'FDM' ? (
                <div>
                  <CallMadeIcon style={{ fontSize: 20 }} /> Request
                </div>
              ) : (
                <div>
                  <CallReceivedIcon style={{ fontSize: 20 }} /> Response
                </div>
              ),
          },
          {
            name: 'date',
            title: 'Date',
            getCellValue: (row) =>
              row.meta
                ? `${moment(row.meta.date).format('MM/DD/YYYY HH:mm:ss') || ''}`
                : '',
          },
          {
            name: 'view',
            title: 'View',
            getCellValue: (row) => {
              let AWB = row.data.AWB2 || row.data.AWB;
              if (AWB) {
                return (
                  <Button
                    onClick={(c) => {
                      setValue(row.cargo);
                      setName(AWB.prefix + '-' + AWB.serialNumber);
                      setTimeout(() => {
                        handleClickListItem();
                      }, 10);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    View
                  </Button>
                );
              } else {
                return (
                  <Button
                    onClick={(c) => {
                      setValue(row.cargo);
                      setName(
                        row.data.DEP.flightNumber +
                          '-' +
                          row.data.DEP.arrivalDate
                      );
                      setTimeout(() => {
                        handleClickListItem();
                      }, 10);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    View
                  </Button>
                );
              }
            },
          },
        ]}
      >
        <Table
          columnExtensions={[
            { columnName: 'statusMessage', wordWrapEnabled: true },
            { columnName: 'status', wordWrapEnabled: true },
            { columnName: 'date', wordWrapEnabled: true },
          ]}
        />
        <TableColumnVisibility hiddenColumnNames={hideHAWB} />
        <TableHeaderRow />
      </Grid>
    </div>
  );
}
