import React, { useState, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Grid,
  Cell,
  TextField,
  Button,
  Card,
  CardActions,
  Toolbar,
  Checkbox,
  SelectField,
} from 'react-md';
import Axios from 'axios';
import _ from 'lodash';
const env = process.env;
export default (props) => {
  const [, data, doFetch] = useApi('auth', 'user', {}, 'post');
  const [user, setuser] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    company: JSON.parse(localStorage.profile).company,
  });
  const [, userData, getuser] = useApi('auth', 'user', user);
  const [, , fetchClient] = useApi('client', 'client', undefined);
  const [partnership, setpartner] = useState({
    sourceCode: '',
    sourceName: '',
    sourceAddress: '',
  });
  const userLogRole = JSON.parse(localStorage.profile).role;
  const [clientData, setClient] = useState([]);
  const [company, setcompanySelected] = useState();
  const [menuItemsRole, setMenuItems] = useState([
    'support',
    'admin',
    'corporate',
    'agent',
    'user',
    'airline',
  ]);

  useEffect(() => {
    delete userData._id;
    setuser(userData);
    setcompanySelected(userData.company);
  }, [userData]);

  useEffect(() => {
    if (props.match.params.id && props.match.params.id != 'null') {
      getuser({ params: [props.match.params.id] });
    }
  }, [data]);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        getClients();
        if (userLogRole == 'support') {
          setMenuItems(['support', 'admin', 'user', 'fsn', 'shipper']);
        } else {
          setMenuItems(['admin', 'user', 'fsn', 'shipper']);
        }
      }
    }
    tttt();
  }, []);

  const getClients = async () => {
    //get clients
    let [clientsResponse, err] = await fetchClient();
    if (clientsResponse) {
      clientsResponse = await clientsResponse.filter(
        (item) => !(!item.companyCode || item.companyCode.length == 0)
      );

      let CompanyItemArray = [];
      await clientsResponse.forEach((element) => {
        CompanyItemArray.push({
          label: `${element.name}`,
          value: `${element._id}`,
        });
      });

      //Fill combo Client
      await setClient(CompanyItemArray);
      if (userLogRole != 'support' && !props.match.params.id) {
        setcompanySelected(JSON.parse(localStorage.profile).company);
      } else if (
        props.match.params.id == 'null' &&
        props.match.params.company
      ) {
        await getOneClient(props.match.params.company, CompanyItemArray);
      }
    }
  };

  const setValue = (val) => setuser({ ...user, ...val });

  const getOneClient = async (company, companyItemArray) => {
    let companyFind = await _.find([...companyItemArray], { value: company });

    if (companyFind) {
      setcompanySelected(companyFind.value);
      setValue({ company: companyFind.value });
    }
  };

  const setOcenTokenAndOrganization = async (user) => {
    console.log('Hay Ocean token ');
    let userProfile = localStorage.profile
      ? JSON.parse(localStorage.profile)
      : {};
    try {
      let resultToken = await Axios.get(
        `${env['REACT_APP_CCOCEAN_FEATHERS_URL']}/users/1?$getAuthenticatedUser=true`,
        {
          headers: {
            Authorization: user.oceanToken,
          },
        }
      );

      if (resultToken.data && resultToken.data.organizations) {
        setValue({ oceanOrganizationId: resultToken.data.organizations[0].id });
        user.oceanOrganizationId = resultToken.data.organizations[0].id;
      }

      if (userProfile.id == props.match.params.id) {
        // Actualizar el local storage si el usuario que se edito es el mismo que se logueo
        console.log('Same ID');
        userProfile.oceanToken = user.oceanToken;
        userProfile.oceanOrganizationId = user.oceanOrganizationId;
        await localStorage.setItem('profile', JSON.stringify(userProfile));
      }

      console.log('resultToken: ', resultToken);
      return true;
    } catch (error) {
      toast.error('Invalid Customs City Ocean Token.');
      console.log('Error->', error.response);
      return false;
    }
  };

  return (
    <Grid>
      <Cell offset={4} size={4}>
        <Card>
          <form>
            <Toolbar colored title="User" />
            <Grid>
              <Cell size={6}>
                <TextField
                  id="user_name"
                  required
                  label="name"
                  value={user.name}
                  onChange={(v) => setValue({ name: v })}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  id="user_last"
                  required
                  label="Last Name"
                  value={user.lastName}
                  onChange={(v) => setValue({ lastName: v })}
                />
              </Cell>
            </Grid>
            <Grid>
              <Cell size={6}>
                <TextField
                  id="user_email"
                  required
                  label="Email"
                  value={user.email}
                  onChange={(v) => setValue({ email: v })}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  id="user_phone"
                  required
                  label="Phone"
                  value={user.phone}
                  onChange={(v) => setValue({ phone: v })}
                />
              </Cell>

              <SelectField
                className="md-cell md-cell--6"
                label="Role"
                placeholder="Role"
                menuItems={menuItemsRole}
                value={user.role}
                onChange={(v) => setValue({ role: v })}
              />

              <SelectField
                className="md-cell md-cell--6"
                label="Client"
                placeholder="Client"
                menuItems={clientData}
                value={user.company}
                onChange={(v) => {
                  setValue({ company: v });
                  setcompanySelected(v);
                }}
                disabled={userLogRole == 'support' ? false : true}
              />
              <Cell size={12}>
                <TextField
                  id="user_oceanToken"
                  label="Customs City Ocean Token "
                  rows={2}
                  value={user.oceanToken}
                  onChange={(v) => setValue({ oceanToken: v })}
                />
              </Cell>
            </Grid>
            <CardActions centered>
              <Button
                onClick={async () => {
                  let saveUser = true;
                  if (user.oceanToken) {
                    saveUser = false;
                    saveUser = await setOcenTokenAndOrganization(user);
                    //console.log('tokenFound: ', saveUser);
                  } else {
                    user.oceanToken = '';
                    user.oceanOrganizationId = '';

                    //Remove token and organization from Profile
                    let userProfile = localStorage.profile
                      ? JSON.parse(localStorage.profile)
                      : {};
                    if (userProfile.id == props.match.params.id) {
                      userProfile.oceanToken = '';
                      userProfile.oceanOrganizationId = '';
                      await localStorage.setItem(
                        'profile',
                        JSON.stringify(userProfile)
                      );
                    }
                  }
                  if (saveUser) {
                    let [result, error] = await doFetch({
                      body: user,
                      params:
                        props.match.params.id && props.match.params.id != 'null'
                          ? [props.match.params.id] || []
                          : [],
                    });

                    if (!error) {
                      props.history.goBack();
                      toast.success('User successfully created.');
                    } else {
                      if (result && result.message) {
                        toast.error(result.message);
                      }
                      if (result && result.error) {
                        toast.error(result.error);
                      }
                      // else {
                      //   toast.error('Please check data before save user.');
                      // }
                      console.log('error saving user', error, result);
                    }
                  }
                }}
                flat
              >
                Save
              </Button>
              <Button
                secondary
                flat
                onClick={() => {
                  props.history.goBack();
                  // if (props.match.params.company) {
                  //   props.history.push(`/client/${props.match.params.company}`);
                  // } else {
                  //   props.history.push('/users/list');
                  // }
                }}
              >
                Cancel
              </Button>
            </CardActions>
          </form>
        </Card>
      </Cell>
      <Cell size={4}></Cell>
    </Grid>
  );
};
