import {
  Card,
  CardText,
  Cell,
  Grid,
  Button,
  FontIcon,
  DataTable,
  TableHeader,
  TableCardHeader,
  TableColumn,
  TableRow,
  TableBody,
  TablePagination,
  EditDialogColumn,
  SelectField
} from 'react-md';
import React, { useState, useEffect, Fragment } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import _ from 'lodash';
import '../style.css';
import Axios from 'axios';
const env = process.env;

export default props => {
  const [value, setValue] = useState({
    serialNumber: '',
    hawbNumber: '',
    requestCode: ''
  });
  const [, , getPending] = useApi('ccapi', 'documents', undefined, 'get');
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(50);
  const [toSend, setToSend] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        getPendings({ types: ['FDM'] });
      }
    }
    tttt();
  }, []);

  useEffect(() => {
    setSelected(false);
    setLoading(false);
  }, [loading]);

  const getPendings = async () => {
    let [pending, err] = await getPending({ query: { types: ['FSQ'] } });
    if (err) return;
    pending.map(p => (p.checked = false));
    setFilter(pending.slice(0, 50));
    setData(pending);
  };

  const handleEvent = (e, path, segment) => {
    setValue({ [path]: e });
    setFilter(_.filter(data, [`docOrigin.${segment}.${path}`, e]));
  };

  const handlePagination = (start, rowsPerPage) => {
    setRows(rowsPerPage);
    setFilter(data.slice(start, start + rowsPerPage));
  };

  const sendDocs = async () => {
    let [response, err] = await doSend({ body: toSend });
    if (err) return;
    toast.success(response.message);
    getPendings();
    setLoading(true);
  };

  const checked = (e, action) => {
    let ids = toSend;
    let fl = filter;

    if (action === 'all') {
      ids.length === data.length
        ? ids.splice(0, ids.length)
        : e.map(d => ids.indexOf(d) !== -1 || ids.push(d));
      fl.map(f => (f.checked = !f.checked));
      setLoading(true);
    } else {
      fl.map(f => (f._id === e ? (f.checked = !f.checked) : ''));
      ids.indexOf(e) !== -1 ? ids.splice(ids.indexOf(e), 1) : ids.push(e);
      setLoading(true);
    }
    setFilter(fl);
    setToSend(ids);
  };

  return (
    <Grid>
      <Cell size={12} style={{ marginBottom: '30px' }}>
        <Card raise tableCard>
          <Button
            style={{ marginTop: '80px' }}
            floating
            secondary
            fixed
            onClick={sendDocs}
            fixedPosition="tr"
          >
            send
          </Button>
          <CardText>
            <TableCardHeader title="Send Status Query" visible={false} />
            <DataTable
              baseId="simple-pagination"
              fixedDividers
              selectableRows={true}
            >
              <TableHeader>
                <TableRow
                  onCheckboxClick={e =>
                    checked(
                      data.map(d => d._id),
                      'all'
                    )
                  }
                >
                  <EditDialogColumn
                    className="header-style"
                    placeholder="AWB #"
                    onChange={e => handleEvent(e, 'serialNumber', 'AWB')}
                    value={value.serialNumber}
                    inline
                    leftIcon={<FontIcon>filter_list</FontIcon>}
                    inlineIcon={null}
                  />
                  <EditDialogColumn
                    className="header-style"
                    placeholder="HAWB #"
                    onChange={e => handleEvent(e, 'hawbNumber', 'AWB')}
                    value={value.hawbNumber}
                    inline
                    leftIcon={<FontIcon>filter_list</FontIcon>}
                    inlineIcon={null}
                  />
                  <EditDialogColumn
                    className="header-style"
                    placeholder="Rquest Code"
                    onChange={e => handleEvent(e, 'requestCode', 'FSQ')}
                    value={value.requestCode}
                    inline
                    leftIcon={<FontIcon>filter_list</FontIcon>}
                    inlineIcon={null}
                  />
                </TableRow>
              </TableHeader>
              <TableBody>
                {filter.length
                  ? filter.map((c, i) => (
                      <TableRow
                        key={i}
                        selected={c.checked}
                        onCheckboxClick={e => checked(c._id)}
                      >
                        {
                          <Fragment>
                            <TableColumn className="column-style">
                              {c.docOrigin.AWB.prefix}-
                              {c.docOrigin.AWB.serialNumber}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {c.docOrigin.AWB.hawbNumber || ''}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {c.docOrigin.FSQ.requestCode || ''}
                            </TableColumn>
                          </Fragment>
                        }
                      </TableRow>
                    ))
                  : null}
              </TableBody>
              <TablePagination
                rows={data.length}
                rowsPerPageLabel="rows"
                rowsPerPage={rows}
                onPagination={handlePagination}
              />
            </DataTable>
          </CardText>
        </Card>
      </Cell>
    </Grid>
  );
};
