import moment from 'moment';
import React from 'react';
import { DataTable, TableBody, TableColumn, TableRow } from 'react-md';
import '../style.css';

export default props => {
  const dateFormat = 'MM/DD/YYYY HH:mm:ss';

  return (
    <DataTable plain>
      <TableBody id="data-table">
        <TableRow>
          <TableColumn>
            <b>{`AWB # ${props.awb.prefix ? props.awb.prefix : ''}-${
              props.awb.serial
                ? props.awb.serial
                : props.awb.serialNumber
                ? props.awb.serialNumber
                : ''
            }`}</b>
          </TableColumn>
          <TableColumn></TableColumn>
          <TableColumn>
            <b>Status </b>
          </TableColumn>

          <TableColumn>
            {` ${props.status ? props.status : 'DRAFT'}`}
          </TableColumn>
        </TableRow>

        {props.aws === 'House' ? (
          <TableRow>
            <TableColumn>
              <b>{`HAWB# ${
                props.awb.hawbNumber ? props.awb.hawbNumber : ''
              }`}</b>
            </TableColumn>
            <TableColumn></TableColumn>
            <TableColumn></TableColumn>
            <TableColumn></TableColumn>
          </TableRow>
        ) : null}

        <TableRow>
          <TableColumn>
            <b>Creation Date/time </b>
          </TableColumn>
          <TableColumn style={{ textAlign: 'left' }}>
            {moment(props.manifestCreated).format(dateFormat)}
          </TableColumn>
          <TableColumn>
            <b>User ID </b>
          </TableColumn>
          <TableColumn>{`${props.userCreated || props.email}`}</TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>
            <b>Latest Submission Date/time </b>
          </TableColumn>
          {props.lastRequest ? (
            <TableColumn>
              {moment(props.lastRequest).format(dateFormat)}
            </TableColumn>
          ) : (
            <TableColumn></TableColumn>
          )}

          <TableColumn>
            <b>User ID </b>
          </TableColumn>
          <TableColumn>{` ${props.userSubmission || ''}`}</TableColumn>
        </TableRow>
        <TableRow>
          <TableColumn>
            <b>Latest Response Date/time </b>
          </TableColumn>
          {props.lastResponse ? (
            <TableColumn>
              {moment(props.lastResponse).format(dateFormat)}
            </TableColumn>
          ) : (
            <TableColumn></TableColumn>
          )}

          <TableColumn></TableColumn>
          <TableColumn></TableColumn>
        </TableRow>
      </TableBody>
    </DataTable>
  );
};
