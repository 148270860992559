import React, { Fragment } from 'react';
import {
  Dialog,
  DialogTitle as Title,
  DialogContent as Content,
  DialogContentText as Text,
  DialogActions as Actions,
  Button,
} from '@material-ui/core';

const Modal = ({ open, toggle, data }) => {
  const text = data.edi || '';
  const name = `${data.prefix}-${data.serialNumber}`;
  const handleClose = () => toggle && toggle();

  const handleDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([text], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = name + '.txt';
    document.body.appendChild(element);
    element.click();
  };

  const mapRow = (line, key) => {
    line = line.replace(/\n/g, '');
    if (line.trim() !== '') {
      return (
        <Fragment key={key}>
          {line}
          <br />
        </Fragment>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <Title id="alert-dialog-slide-title">Cargo Imp</Title>
      <Content>
        <Text id="alert-dialog-slide-description">
          {text.split('\r\n').map(mapRow)}
        </Text>
      </Content>
      <Actions>
        <Button onClick={handleDownload} color="primary">
          Download
        </Button>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </Actions>
    </Dialog>
  );
};

export default Modal;
