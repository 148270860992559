import { FlightDetails, PermitProceed, Shipper, Consignee, CargoDetails, SplitIndicator, TransferDetails } from "./manifest";
import { Cell, Grid, Button, FontIcon, Switch } from "react-md";
import React, { Fragment, useState } from "react";
import { useApi } from "../../services/api";
import { toast } from "react-toastify";

export default (props) => {
    const [, , fetchDoc,] = useApi('ccapi', 'save/documents', undefined, 'post')
    const [permit, setPermit] = useState(false)
    const [split, setSplit] = useState(false)
    const [transfer, setTransfer] = useState(false)
    const [ccl, setCcl] = useState({ arrivalAirport: '', terminalOp: '' })
    const [awb, setAwb] = useState({ consolidationId: '', prefix: '', serialNumber: '', hawbNumber: '', pkTrackingId: '' })
    const [wbl, setWbl] = useState({ airportOrigin: '', numPieces: '', weightCode: '', weight: '', cargoDesc: '', destinationAirport: '', arrivalDatePTPAirport: '' })
    const [arr, setArr] = useState({ impCarrier: '', flightNumber: '', arrivalDate: '', brdPieceCnt: '', weightCode: '', weight: '', arrivalRef: '' })
    const [agt, setAgt] = useState({ participantCode: '' })
    const [shp, setShp] = useState({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
    const [cne, setCne] = useState({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
    const [trn, setTrn] = useState({ destinationAirport: '', transferstatusId: '', bondedCarrierId: '', onwardCarrier: '', premisesId: '', inbondControlNum: '' })
    const [csd, setCsd] = useState({ originGoods: '', declaredValue: '', currencyCode: '', hazmatCode: '' })
    const [fda, setFda] = useState({ lineId: '' })

    const send = async (add) => {
        let body = { SMI: { componentId: 'FRI' }, CCL: ccl, AWB: awb, WBL: wbl, ARR: arr, AGT: agt, SHP: shp, CNE: cne, TRN: trn, CSD: csd }
        let { impCarrier, flightNumber, arrivalDate } = arr
        let { arrivalAirport, terminalOp } = ccl
        let airportOrigin = wbl.airportOrigin
        if (fda.lineId === 'FDA') body.FDA = fda

        fetchDoc({ body }).then(response => {
            if (response.length) {
                toast.error(response[0].doc.meta.errors[0].message.replace('\\', ''))
            } else {
                toast.success(`FRI has succesfully created`)
                setCcl({ arrivalAirport: '', terminalOp: '' })
                setAwb({ consolidationId: '', prefix: '', serialNumber: '', hawbNumber: '', pkTrackingId: '' })
                setWbl({ airportOrigin: '', numPieces: '', weightCode: '', weight: '', cargoDesc: '', destinationAirport: '', arrivalDatePTPAirport: '' })
                setArr({ impCarrier: '', flightNumber: '', arrivalDate: '', brdPieceCnt: '', weightCode: '', weight: '', arrivalRef: '' })
                setAgt({ participantCode: '' })
                setShp({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
                setCne({ name: '', phoneNumber: '', address: '', city: '', state: '', postalCode: '', countryCode: '' })
                setTrn({ destinationAirport: '', transferstatusId: '', bondedCarrierId: '', onwardCarrier: '', premisesId: '', inbondControlNum: '' })
                setCsd({ originGoods: '', declaredValue: '', currencyCode: '', hazmatCode: '' })
                setFda({ lineId: '' })
            }
        })

        if (add) {
            setArr({ ...arr, impCarrier, flightNumber, arrivalDate })
            setWbl({ ...wbl, airportOrigin })
            setCcl({ ...ccl, arrivalAirport, terminalOp })
        }
        window.scrollTo(0, 0)
    }

    return (
        <Fragment>
            <Grid>
                <Cell size={12}>
                    <FlightDetails create={true} agt={agt} setAgt={setAgt} awb={awb} setAwb={setAwb} arr={arr} setArr={setArr} wbl={wbl} setWbl={setWbl} ccl={ccl} setCcl={setCcl} />
                </Cell>
            </Grid>
            <Grid>
                <Switch id="switch-permit" type="switch" label="Flight is moving under PTP" name="permit" onChange={e => setPermit(!permit)} />
            </Grid>
            {
                permit ? (
                    <Grid>
                        <Cell size={12} >
                            <PermitProceed wbl={wbl} setWbl={setWbl} />
                        </Cell>
                    </Grid>
                ) : null
            }
            <Grid>
                <Switch id="switch-split" type="switch" label="Split Shipment?" name="permit" onChange={e => setSplit(!split)} />
            </Grid>
            {
                split ? (
                    <Grid>
                        <Cell size={12}>
                            <SplitIndicator arr={arr} setArr={setArr} />
                        </Cell>
                    </Grid>
                ) : null
            }
            <Grid>
                <Switch id="switch-transfer" type="switch" label="In bond shipment or local transfer to a bonded facility?" name="permit" onChange={e => setTransfer(!transfer)} />
            </Grid>
            {
                transfer ? (
                    <Grid>
                        <Cell size={12}>
                            <TransferDetails trn={trn} setTrn={setTrn} />
                        </Cell>
                    </Grid>
                ) : null
            }
            <Grid noSpacing>
                <Cell size={6}>
                    <Shipper shp={shp} setShp={setShp} />
                </Cell>
                <Cell size={6}>
                    <Consignee cne={cne} setCne={setCne} />
                </Cell>
            </Grid>
            <Grid>
                <Cell size={12}>
                    <CargoDetails awb={awb} setAwb={setAwb} csd={csd} setCsd={setCsd} wbl={wbl} setWbl={setWbl} fda={fda} setFda={setFda} />
                </Cell>
            </Grid>
            <Grid>
                <Cell size={12} className="md-text-center">
                    <Button flat secondary iconBefore={false} onClick={e => send(true)} iconEl={<FontIcon iconClassName='fas fa-plus' />}>Add and continue</Button>
                    <Button flat secondary iconBefore={false} onClick={e => send(false)} iconEl={<FontIcon iconClassName='fas fa-check' />}>Done</Button>
                </Cell>
            </Grid>
        </Fragment>
    )
}
