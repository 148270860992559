import React, { useState, useEffect } from 'react';
import { Chip } from 'react-md';
import {
  FormControlLabel,
  FormLabel,
  FormGroup,
  Grid as GridMUI,
  Button as Button2
} from '@material-ui/core';
export default props => {
  const chipStyle = { backgroundColor: '#019ee1A8' };
  const divStyle = {
    width: '100%',
    textAlign: 'center',
    marginTop: '5px',
    marginBottom: '5px'
  };

  return (
    <GridMUI container style={{ marginTop: '10px' }}>
      <GridMUI item style={{ marginRight: '10px' }}>
        <FormLabel component="legend">Total Bills</FormLabel>
        <div style={divStyle}>
          <Chip label={props.totals.total} style={chipStyle} />
        </div>
      </GridMUI>

      <GridMUI item style={{ marginRight: '10px' }}>
        <FormLabel component="legend">Accepted</FormLabel>
        <div style={divStyle}>
          <Chip label={props.totals.accepted} style={chipStyle} />
        </div>
      </GridMUI>

      <GridMUI item style={{ marginRight: '10px' }}>
        <FormLabel component="legend">Draft</FormLabel>
        <div style={divStyle}>
          <Chip label={props.totals.draft} style={chipStyle} />
        </div>
      </GridMUI>

      <GridMUI item style={{ marginRight: '10px' }}>
        <FormLabel component="legend">Sent</FormLabel>
        <div style={divStyle}>
          <Chip label={props.totals.sent} style={chipStyle} />
        </div>
      </GridMUI>

      <GridMUI item style={{ marginRight: '10px' }}>
        <FormLabel component="legend">Rejected</FormLabel>
        <div style={divStyle}>
          <Chip label={props.totals.rejected} style={chipStyle} />
        </div>
      </GridMUI>

      <GridMUI item>
        <FormLabel component="legend" style={{ marginRight: '10px' }}>
          Cancelled
        </FormLabel>
        <div style={divStyle}>
          <Chip label={props.totals.cancelled} style={chipStyle} />
        </div>
      </GridMUI>
    </GridMUI>
  );
};
