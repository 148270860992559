export const groupedCheckbox = [
  {
    title: 'Type',
    checkbox: [
      { id: 'master', label: 'Master' },
      { id: 'consolidation', label: 'Consolidation' },
      { id: 'simple', label: 'Simple' }
    ]
  },
  {
    title: 'Status',
    checkbox: [
      { id: 'draft', label: 'Draft' },
      { id: 'send', label: 'Sent' },
      { id: 'accepted', label: 'Accepted' },
      { id: 'rejected', label: 'Rejected' },
      { id: 'cancelled', label: 'Cancelled' }
    ]
  },
  {
    title: 'File',
    checkbox: [
      { id: 'hasFSN', label: 'FSN' },
      { id: 'hasFDM', label: 'FDM' },
      { id: 'has1C', label: '1C' },
      { id: 'hasAmsHold', label: 'AMS Hold' }
    ]
  }
];

export const filterDefinitions = [
  {
    id: 'client',
    label: 'Client',
    data: 'clients',
    fuzzyFilter: true,
    role: ['support']
  },
  {
    id: 'clientName',
    label: 'Client',
    data: 'clientName',
    disabled: true,
    role: ['admin', 'user', 'fsn']
  },
  {
    id: 'HAWB',
    label: 'HAWB #',
    data: 'hawbs',
    fuzzyFilter: true,
    disabled: true
  },
  { id: 'airline', label: 'Airline', data: 'airline' },
  { id: 'flightNumber', label: 'Flight Number', data: 'flightNumber' },
  {
    id: 'usPortOfArrival',
    label: '1st US Airport Of Arrival',
    data: 'usPortOfArrival'
  },
  { title: '', checkbox: [{ check: 'split', label: 'Split Shipment' }] }
];
