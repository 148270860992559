import {
  Cell,
  DatePicker,
  Button,
  FontIcon,
  TextField,
  CircularProgress,
} from 'react-md';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid';
import Grid2 from 'react-md/lib/Grids/Grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import Button2 from '@material-ui/core/Button';
import CargoImp from '../AWBHistory/Table/CargoImp';
import React, { Fragment, useEffect, useState } from 'react';
import { useApi } from '../../services/api';
import 'chart.js';
import { FSNMessage, entryType } from '../../components/awb-history/Codes';
import moment from 'moment';
import Axios from 'axios';
import PagingContainer from '../../components/GridComponents/PagingPanel';
import Dialog from './Components/Dialog';
import CargoDatepicker from './Components/CargoDatepicker';
import ButtonCargoIn from './Components/ButtonCargoIn';
import ButtonCargoOut from './Components/ButtonCargoOut';
import StatusNotificationFSN from './Components/StatusNotificationFSN';
import ArrivalFSN from './Components/ArrivalFSN';
import ButtonFSN from './Components/ButtonFSN';
const env = process.env;

const Cell2 = (props) => {
  const { column } = props;

  if (column.name === 'date' || column.name === 'CBPDateTime') {
    return <DateRequestCell {...props} />;
  }
  if (column.name === 'cargoIn') {
    return <ButtonCargoIn {...props} key={'CHECK IN'} otherName={'CHECK IN'} />;
  }
  if (column.name === 'cargoOut') {
    return (
      <ButtonCargoOut {...props} key={'CHECK OUT'} otherName={'CHECK OUT'} />
    );
  }
  if (column.name === 'statusNotification') {
    return <StatusNotificationFSN {...props} />;
  }
  if (column.name === 'Arrival') {
    return <ArrivalFSN {...props} />;
  }
  if (column.name === 'createFSN') {
    return <ButtonFSN {...props} />;
  }
  return <Table.Cell {...props} />;
};

const DateRequestCell = ({ value, ...restProps }) => {
  let splitDate = value.toString().split(' ');
  let fullDate = '';
  let fullHour = '';

  if (splitDate.length > 0) {
    fullDate = splitDate[0];
    fullHour = splitDate[1];
  }
  return (
    <Table.Cell
      {...restProps}
      style={{
        overflow: 'visible',
      }}
    >
      <div>
        {fullDate} <br /> {fullHour}
      </div>
    </Table.Cell>
  );
};

export default (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [name, setName] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [date, setDate] = useState(new Date());
  const [titleDialog, setTitleDialog] = useState('');
  const [rowDialog, setRowDialog] = useState({});
  const [commentDialog, setCommentDialog] = useState('');
  const [, , fetchDocuments] = useApi(
    'ccapi',
    'documents/search',
    undefined,
    'get'
  );
  const [range, setRange] = useState({
    from: new Date(),
    to: Date.now(),
  });
  const [awb, setAwb] = useState([]);
  const [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  const updateFSN = async (update) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    await Axios.post(env['REACT_APP_CCAPI'] + '/update-fsn/', update);
  };

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function start() {
      if (await checkToken()) {
        let day = new Date().getDate();
        let date = new Date();
        date.setDate(day);

        var fromDate = new Date();
        fromDate.setMonth(new Date().getMonth());

        getDocuments({
          from: fromDate.setHours(0, 0, 0, 0),
          to: Date.now(),
          types: ['FSN_CC'],
        });
      }
    }
    start();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDocuments = async (query) => {
    setLoading(true);
    let [searchResult, err] = await fetchDocuments({ query: query });
    if (err) return;
    for (let r of searchResult) {
      if (!r.cargoIn) {
        r.cargoIn = '';
      }
      if (!r.commentIn) {
        r.commentIn = '';
      }
      if (!r.cargoOut) {
        r.cargoOut = '';
      }
      if (!r.commentOut) {
        r.commentOut = '';
      }
    }
    // console.log('searchResult :', searchResult);
    setData(searchResult);
    setLoading(false);
  };

  const applyFilter = async () => {
    let query = {};
    let from = new Date(range.from);
    let to = new Date(range.to);
    to.setHours(23, 59, 59, 999);
    query.to = to.setDate(to.getDate());
    query.from = from.setDate(from.getDate());
    query.types = ['FSN_CC'];
    query.awb = awb;
    // Se añadió para no enviar manifiestos de en la opcion de busqueda, ya que de ahí se envían los documentos que están en DRAFT
    query.search = true;
    await getDocuments(query);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const CloseDialog = () => {
    setOpenDialog(false);
    setTitleDialog('');
    setCommentDialog('');
  };
  const ChangeDate = (date) => {
    setDate(date);
  };
  const openDialogCargo = (open, title, date, comment) => {
    setOpenDialog(open);
    setTitleDialog(title);
    setCommentDialog(comment);
    if (date) {
      setDate(new Date(date));
    } else {
      setDate(new Date());
    }
  };
  const SaveData = async () => {
    let update = {};
    if (titleDialog === 'CHECK IN') {
      update = { id: rowDialog._id, cargoIn: +date, commentIn: commentDialog };
    } else {
      update = {
        id: rowDialog._id,
        cargoOut: +date,
        commentOut: commentDialog,
      };
    }
    await updateFSN(update);
    setOpenDialog(false);
    setTitleDialog('');
    setCommentDialog('');
    await applyFilter();
  };

  return (
    <Fragment>
      <Dialog
        open={openDialog}
        CloseDialog={CloseDialog}
        Title={titleDialog}
        showSave={true}
        Save={SaveData}
      >
        <Fragment>
          <CargoDatepicker date={date} ChangeDate={ChangeDate} />
          <TextField
            value={commentDialog}
            onChange={(e) => setCommentDialog(e)}
            multiline
            id="outlined-basic"
            label="Comment"
            variant="outlined"
          />
        </Fragment>
      </Dialog>
      <CargoImp open={open} name={name} onClose={handleClose} value={value} />
      <Grid2 style={{ paddingTop: 0 }}>
        <Cell size={3} phoneSize={12} tabletSize={12}>
          <span style={{ fontSize: 35, color: 'gray' }}>Response FSN</span>
        </Cell>
        <DatePicker
          id="inline-date-picker-portait"
          label="from"
          value={moment(range.from).format('L')}
          defaultValue={moment(range.from).format('L')}
          maxDate={new Date(range.to)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={(e) => setRange({ ...range, from: e })}
        />
        <DatePicker
          id="inline-date-picker-portait"
          label="to"
          value={moment(range.to).format('L')}
          defaultValue={moment(range.to).format('L')}
          minDate={new Date(range.from)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={(e) => setRange({ ...range, to: e })}
        />

        <TextField
          id="floating-center-title"
          label="AirWay Bill"
          lineDirection="center"
          className="md-cell md-cell--2"
          onChange={(e) => setAwb(e)}
        />
        <Button
          className="md-cell"
          style={{ marginTop: 25 }}
          icon
          iconEl={
            <FontIcon
              secondary
              className="fas fa-search"
              onClick={applyFilter}
            />
          }
        />
        <Button2
          variant="outlined"
          color="primary"
          style={{ marginTop: 25 }}
          onClick={applyFilter}
        >
          Refresh
        </Button2>
      </Grid2>

      {loading ? (
        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
          <CircularProgress
            id="loading"
            centered={true}
            className="loading-blue"
            scale={2}
          ></CircularProgress>
        </div>
      ) : (
        <Grid
          rows={data}
          style={{ backgroundColor: 'red' }}
          columns={[
            {
              name: 'awb',
              title: 'AWB',
              getCellValue: (row) => {
                return `${
                  row.docTranslated.AWB
                    ? row.docTranslated.AWB.prefix
                    : row.docTranslated.AWB2.prefix
                }-${
                  row.docTranslated.AWB
                    ? row.docTranslated.AWB.serialNumber
                    : row.docTranslated.AWB2.serialNumber
                }`;
              },
            },
            {
              name: 'houseBill',
              title: 'House Bill',
              getCellValue: (row) =>
                row.docTranslated.AWB
                  ? row.docTranslated.AWB.hawbNumber
                  : row.docTranslated.AWB2.hawbNumber,
            },
            {
              name: 'docType',
              title: 'Doc Type',
              getCellValue: (row) => {
                return row.docTranslated.name === 'FSN'
                  ? `Freight Status Notification (FSN)`
                  : `Freight Error Report (FER)`;
              },
            },
            {
              name: 'statusNotification',
              title: 'Status Notification',
              getCellValue: (row) => {
                if (row.docTranslated.name === 'FSN') {
                  return row.docTranslated.CSN
                    ? `${FSNMessage(row.docTranslated.CSN.actionCode, true)}${
                        row.docTranslated.CSN.entryType
                          ? ' Entry Type: ' +
                            entryType(row.docTranslated.CSN.entryType)
                          : ''
                      }${
                        row.docTranslated.CSN &&
                        row.docTranslated.CSN.entryNumber
                          ? ' Entry #: ' + row.docTranslated.CSN.entryNumber
                          : ''
                      }${
                        row.docTranslated.CSN && row.docTranslated.CSN.numPieces
                          ? ' Quantity: ' + row.docTranslated.CSN.numPieces
                          : ''
                      }${
                        row.docTranslated.ARR2 &&
                        row.docTranslated.ARR2.arrivalRef
                          ? ' Split Shipment Part: ' +
                            row.docTranslated.ARR2.arrivalRef
                          : ''
                      }${
                        row.docTranslated.CSN && row.docTranslated.CSN.remarks
                          ? ' Remarks: ' + row.docTranslated.CSN.remarks
                          : ''
                      }`
                    : '';
                } else {
                  let error = row.docTranslated.ERR
                    ? row.docTranslated.ERR.map(function (value) {
                        return `${value.errorCode} - ${value.errorText} `;
                      })
                    : '';
                  return error;
                }
              },
            },
            {
              name: 'Arrival',
              title: 'Flight Arrival',
              getCellValue: (row) => {
                return `${
                  row.docTranslated.ARR2
                    ? 'Flight #: ' +
                      (row.docTranslated.ARR2.impCarrier || '') +
                      (row.docTranslated.ARR2.flightNumber || '')
                    : ''
                }${
                  row.docTranslated.ARR2 && row.docTranslated.ARR2.arrivalDate
                    ? ' Arrival Date: ' + row.docTranslated.ARR2.arrivalDate
                    : ''
                }${
                  row.docTranslated.CCL && row.docTranslated.CCL.arrivalAirport
                    ? ' Arrival Airport: ' +
                      row.docTranslated.CCL.arrivalAirport
                    : ''
                }${
                  row.docTranslated.CCL && row.docTranslated.CCL.terminalOp
                    ? ' Terminal Operator: ' + row.docTranslated.CCL.terminalOp
                    : ''
                }${
                  row.docTranslated.CSN &&
                  row.docTranslated.CSN.actionCode &&
                  row.docTranslated.CSN.actionCode === '1F'
                    ? 'Firms Code: ' + row.docTranslated.CSN.remarks
                    : ''
                }`;
              },
            },
            {
              name: 'CBPDateTime',
              title: 'CBP Processing date/time',
              getCellValue: (row) =>
                row.meta.cbpDate
                  ? moment(row.meta.cbpDate).format('MM/DD/YYYY HH:mm:ss')
                  : moment(row.meta.date).format('MM/DD/YYYY HH:mm:ss'),
            },
            {
              name: 'date',
              title: 'Date',
              getCellValue: (row) =>
                moment(row.meta.date).format('MM/DD/YYYY HH:mm:ss'),
            },
            {
              name: 'createFSN',
              title: 'Create ASN',
              getCellValue: (row) => row._id,
            },
            {
              name: 'cargoIn',
              title: 'Cargo In',
              getCellValue: (row) => {
                return row.docTranslated.name === 'FSN' && row.cargoIn !== ''
                  ? `${moment(row.cargoIn).format('MM/DD/YYYY HH:mm:ss')} ${
                      row.commentIn
                    }`
                  : row.docTranslated.name === 'FSN'
                  ? ''
                  : 'N/A';
              },
              updateFSN: updateFSN,
              openDialogCargo: openDialogCargo,
              setRowDialog: setRowDialog,
            },
            {
              name: 'cargoOut',
              title: 'Cargo Out',
              getCellValue: (row) => {
                return row.docTranslated.name === 'FSN' && row.cargoOut !== ''
                  ? `${moment(row.cargoOut).format('MM/DD/YYYY HH:mm:ss')} ${
                      row.commentOut
                    }`
                  : row.docTranslated.name === 'FSN'
                  ? ''
                  : 'N/A';
              },
              updateFSN: updateFSN,
              openDialogCargo: openDialogCargo,
              setRowDialog: setRowDialog,
            },
            {
              name: 'view',
              title: 'View EDI',
              getCellValue: (row) => {
                return (
                  <Button2
                    onClick={(c) => {
                      setValue(row.docOrigin);
                      setName(`${
                        row.docTranslated.AWB
                          ? row.docTranslated.AWB.prefix
                          : row.docTranslated.AWB2.prefix
                      }
                      -
                      ${
                        row.docTranslated.AWB
                          ? row.docTranslated.AWB.serialNumber
                          : row.docTranslated.AWB2.serialNumber
                      }`);
                      setTimeout(() => {
                        handleClickListItem();
                      }, 10);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    View EDI
                  </Button2>
                );
              },
            },
          ]}
        >
          <SortingState />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={[{ columnName: 'view', filteringEnabled: false }]}
          />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} pageSize={50} />
          <IntegratedPaging />
          <Table
            columnExtensions={[
              { columnName: 'statusNotification', wordWrapEnabled: true },
              { columnName: 'docType', wordWrapEnabled: true },
              { columnName: 'CBPDateTime', wordWrapEnabled: true },
              { columnName: 'Arrival', wordWrapEnabled: true, width: '150' },
              { columnName: 'cargoIn', width: '200', wordWrapEnabled: true },
              { columnName: 'cargoOut', width: '200', wordWrapEnabled: true },
              { columnName: 'awb', width: '120' },
            ]}
            cellComponent={Cell2}
          />
          <TableColumnVisibility defaultHiddenColumnNames={[]} />
          <TableFilterRow />
          <TableHeaderRow showSortingControls />
          <PagingPanel containerComponent={PagingContainer} />
        </Grid>
      )}
    </Fragment>
  );
};
