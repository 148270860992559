import Grid2 from 'react-md/lib/Grids/Grid';
import {
  Cell,
  Card,
  CardText,
  Button,
  CircularProgress,
  SelectField,
  FontIcon,
  TextField,
  Autocomplete
} from 'react-md';
import { DatePicker } from 'react-md';
import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';
import { toast } from 'react-toastify';
import PagingContainer from '../../components/GridComponents/PagingPanel';
import Axios from 'axios';
import moment from 'moment';
import Papa from 'papaparse';
import { useApi } from '../../services/api';
const env = process.env;

const Cell2 = props => {
  const { column } = props;
  if (column.name === 'Date') {
    return <DateRequestCell {...props} />;
  }
  return <Table.Cell {...props} />;
};

const DateRequestCell = ({ value, ...restProps }) => {
  let splitDate = value.toString().split(' ');
  let fullDate = '';
  let fullHour = '';

  if (splitDate.length > 0) {
    fullDate = splitDate[0];
    fullHour = splitDate[1];
  }
  return (
    <Table.Cell
      {...restProps}
      style={{
        overflow: 'visible'
      }}
    >
      <div>
        {fullDate} <br /> {fullHour}
      </div>
    </Table.Cell>
  );
};

export default props => {
  let form;
  let [toDate, setToDate] = useState();
  let [fromDate, setFromDate] = useState();
  const [range, setRange] = useState({
    from: null,
    to: null
  });
  let [awb, setAwb] = useState({
    prefix: '',
    serialNumber: ''
  });
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [clientsAutoComplete, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({
    value: ''
  });
  const [, , fetchDocuments] = useApi(
    'ccapi',
    'documents/searchResponses',
    undefined,
    'get'
  );
  const [userRole, setUserRole] = useState('');
  const [companyName, setCompanyName] = useState('');

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    } else {
      let profile = JSON.parse(localStorage.profile);
      setUserRole(profile.role);
      setCompanyName(profile.companyName);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      await checkToken();
    }
    tttt();
  }, []);

  const getReportData = async () => {
    if (awb.prefix || awb.prefix || (range.from && range.to)) {
      console.log('ReportData');
      setLoading(true);

      let from = '';
      let to = '';
      if (range.from && range.to) {
        from = moment(range.from)
          .startOf('day')
          .valueOf();
        to = moment(range.to)
          .endOf('day')
          .valueOf();
      }

      let profile = JSON.parse(localStorage.profile);

      let query = {
        prefix: awb.prefix,
        serialNumber: awb.serialNumber,
        types: 'FSN,FER',
        from: from,
        to: to,
        sort: -1,
        company: profile.role == 'support' ? selectedClient.value : ''
      };

      let [result, err] = await fetchDocuments({ query: query });
      if (err) return;
      if (result && result.length > 0) {
        for (const row of result) {
          let msg = '';
          for (const err of row.Errors) {
            msg = msg
              ? msg + `\r\n${err.errorCode} -${err.errorText}`
              : msg + `${err.errorCode} -${err.errorText}`;
          }
          row['ErrorMsg'] = msg;

          row.Date = await moment(row.Date).format('MM/DD/YYYY HH:mm:ss');
        }

        setReportData(result);
      }
      result && result.length == 0 && setReportData(result);

      // await setReportData(result);
      setLoading(false);
    } else {
      if (!range.from && !range.to) {
        toast.error('Please fill at less an option of the  search filter.');
      } else {
        toast.error(
          'For use range of date filter please fill the both dates please.'
        );
      }
    }
  };

  const xlsData = (report = []) => {
    let xlsHeaders = {};
    return [
      xlsHeaders,
      {
        columns: [
          'AWB',
          'HAWB',
          'Doc Type',
          'FSN Code',
          'Entry Number',
          'Terminal Op',
          'Arrival Airport',
          'Arrival Date',
          'Flight #',
          'Errors',
          'Date'
        ],
        data: report.map(r => {
          let row = [
            r.AWB,
            r.HAWB ? "'" + r.HAWB : '',
            r.DocType,
            r.FSNCode || '',
            r.EntryNumber || '',
            r.TerminalOp || '',
            r.ArrivalAirport || '',
            r.ArrivalDate || '',
            r.FullFlightNumber || '',
            r.ErrorMsg || '',
            "'" + r.Date || ''
          ];

          return row;
        })
      }
    ];
  };

  const exportCSV = async () => {
    let output = await xlsData(reportData);
    //console.log('output: ', output);

    let papa = Papa.unparse(
      { fields: output[1].columns, data: output[1].data },
      {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        newline: '\r\n',
        skipEmptyLines: false, //or 'greedy',
        encoding: 'utf-8',
        download: true,
        delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
      }
    );

    let CsvString = 'data:application/csv,' + encodeURIComponent(papa);
    var x = document.createElement('A');
    x.setAttribute('href', CsvString);
    x.setAttribute(
      'download',
      `CCAIR-Report-ASNResponses-${moment().format('YYYYMMDDhmmss')}.csv`
    );
    document.body.appendChild(x);
    x.click();
    //return encodedUri;
    //setExportData(encodedUri);
  };

  const getClients = async name => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_CLIENT'] + '/client/', {
      params: { $autoComplete: name }
    });

    if (response && response.data) {
      let clientList = response.data.map(c => {
        return { value: c._id, label: c.name };
      });
      setClients(clientList);
    }
  };

  return (
    <Fragment>
      <Grid2>
        <Cell size={12}>
          <Card tableCard>
            <CardText>
              <form
                ref={ref => {
                  form = ref;
                }}
              >
                <Grid2>
                  <Cell size={3} tabletSize={3}>
                    <div
                      className="md-cell md-cell--5"
                      style={{ display: 'inline' }}
                    >
                      <span style={{ fontSize: 35, color: 'gray' }}>
                        Responses ASN
                      </span>
                    </div>
                  </Cell>
                  {userRole === 'support' ? (
                    <Cell size={2}>
                      <Autocomplete
                        id="clientAuto"
                        label="CLIENT"
                        data={clientsAutoComplete}
                        dataLabel={'label'}
                        dataValue={'value'}
                        filter={null}
                        findInlineSuggestion={Autocomplete.findIgnoreCase}
                        value={selectedClient.selected}
                        onChange={e => {
                          if (e.length > 2) {
                            getClients(e);
                          }
                          if (e.length === 0) {
                            setSelectedClient({ ...selectedClient, value: e });
                          }
                        }}
                        onAutocomplete={v => {
                          //console.log('v', v);
                          setSelectedClient({ ...selectedClient, value: v });
                        }}
                      />
                    </Cell>
                  ) : (
                    <Cell size={2}>
                      <TextField
                        id="client"
                        label="CLIENT"
                        value={companyName}
                        lineDirection="center"
                        autoComplete="off"
                        disabled="true"
                      />
                    </Cell>
                  )}
                  <Cell size={4} tabletSize={3}>
                    <DatePicker
                      id="inline-date-picker-portait-from"
                      label="from"
                      value={fromDate}
                      //defaultValue={moment(range.from).format('L')}
                      maxDate={range.to ? new Date(range.to) : new Date()}
                      className="md-cell md-cell--2"
                      locales="en-US"
                      inline
                      displayMode="portrait"
                      fullWidth={false}
                      autoOk
                      style={{ display: 'inline' }}
                      onChange={e => {
                        setRange({ ...range, from: e });
                        setFromDate(moment(e).format('L'));
                      }}
                    />

                    <DatePicker
                      id="inline-date-picker-portait-to"
                      label="to"
                      value={toDate}
                      //defaultValue={moment(range.to).format('L')}
                      minDate={range.from ? new Date(range.from) : new Date()}
                      className="md-cell md-cell--2"
                      locales="en-US"
                      inline
                      displayMode="portrait"
                      fullWidth={false}
                      autoOk
                      style={{ display: 'inline' }}
                      onChange={e => {
                        setRange({ ...range, to: e });
                        setToDate(moment(e).format('L'));
                      }}
                    />
                  </Cell>

                  <Cell size={2} tabletSize={3}>
                    <TextField
                      id="AWBPSN"
                      onChange={e => {
                        const re = /^[a-zA-Z0-9\b]*$/;
                        if (e === '' || re.test(e)) {
                          if (e.length <= 3) {
                            setAwb({ ...awb, prefix: e.toUpperCase() });
                          }
                        }
                      }}
                      label="AWB Prefix#"
                      value={awb.prefix}
                      lineDirection="center"
                      autoComplete="off"
                    />

                    <TextField
                      id="AWBSerial"
                      onChange={e => {
                        const re = /^[0-9\b]*$/;
                        if (e === '' || re.test(e)) {
                          if (e.length <= 8) {
                            setAwb({ ...awb, serialNumber: e.toUpperCase() });
                          }
                        }
                      }}
                      label="AWB #"
                      value={awb.serialNumber}
                      lineDirection="center"
                      autoComplete="off"
                    />
                  </Cell>
                  {/* <Cell size={1}></Cell> */}
                  <Cell size={1}>
                    <Button
                      icon
                      secondary
                      iconEl={<FontIcon secondary className="fas fa-search" />}
                      onClick={async e => {
                        getReportData();
                      }}
                      className="md-cell"
                      style={{ marginTop: 25 }}
                    />
                  </Cell>
                </Grid2>
                <Grid2>
                  <Cell
                    size={12}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Button
                      raised
                      secondary
                      onClick={() => {
                        exportCSV();
                      }}
                      iconClassName="fa fa-file-export"
                      style={{ marginLeft: '5px' }}
                    >
                      Export CSV
                    </Button>
                  </Cell>
                </Grid2>
              </form>
              {loading ? (
                <div style={{ marginTop: '40px', marginBottom: '40px' }}>
                  <CircularProgress
                    id="loading"
                    centered={true}
                    className="loading-blue"
                    scale={2}
                  ></CircularProgress>
                </div>
              ) : (
                <Grid
                  rows={reportData}
                  style={{ backgroundColor: 'red' }}
                  columns={[
                    {
                      name: 'AWB',
                      title: 'AWB',
                      getCellValue: row => {
                        return row.AWB ? row.AWB : '';
                      }
                    },
                    {
                      name: 'HAWB',
                      title: 'House Bill',
                      getCellValue: row => {
                        return row.HAWB ? row.HAWB : '';
                      }
                    },
                    {
                      name: 'DocType',
                      title: 'Doc Type',
                      getCellValue: row => (row.DocType ? row.DocType : '')
                    },
                    {
                      name: 'FSNCode',
                      title: 'FSN Code',
                      getCellValue: row => {
                        return row.FSNCode ? row.FSNCode : '';
                      }
                    },
                    {
                      name: 'EntryNumber',
                      title: 'Entry Number',
                      getCellValue: row => {
                        return row.EntryNumber;
                      }
                    },
                    {
                      name: 'TerminalOp',
                      title: 'Terminal Op',
                      getCellValue: row => {
                        return row.TerminalOp ? row.TerminalOp : '';
                      }
                    },
                    {
                      name: 'ArrivalAirport',
                      title: 'Arrival Airport',
                      getCellValue: row => {
                        return row.ArrivalAirport ? row.ArrivalAirport : '';
                      }
                    },
                    {
                      name: 'ArrivalDate',
                      title: 'Arrival Date',
                      getCellValue: row => {
                        return row.ArrivalDate ? row.ArrivalDate : '';
                      }
                    },
                    {
                      name: 'FlightNumber',
                      title: 'Flight #',
                      getCellValue: row => {
                        return row.FullFlightNumber ? row.FullFlightNumber : '';
                      }
                    },
                    {
                      name: 'Errors',
                      title: 'Errors',
                      getCellValue: row => {
                        let msg = '';
                        if (row.Errors) {
                          for (const err of row.Errors) {
                            msg = msg
                              ? msg + `\n${err.errorCode} - ${err.errorText}`
                              : msg + `${err.errorCode} - ${err.errorText}`;
                          }
                        }

                        return msg ? msg : '';
                      }
                    },
                    {
                      name: 'Date',
                      title: 'Date',
                      getCellValue: row => {
                        return row.Date;
                      }
                    }
                  ]}
                >
                  <SortingState
                  // defaultSorting={[{ columnName: 'product', direction: 'asc' }]}
                  />
                  <IntegratedSorting />
                  <FilteringState defaultFilters={[]} />
                  <IntegratedFiltering />
                  <PagingState defaultCurrentPage={0} pageSize={50} />
                  <IntegratedPaging />
                  <Table
                    columnExtensions={[
                      { columnName: 'DocType', width: '100' },
                      { columnName: 'FSNCode', width: '100' },
                      { columnName: 'FlightNumber', width: '100' },
                      {
                        columnName: 'Errors',
                        wordWrapEnabled: true,
                        width: '200'
                      }
                    ]}
                    cellComponent={Cell2}
                    // containerComponent={ContainerTable}
                  />
                  <TableFilterRow />
                  <TableHeaderRow showSortingControls />
                  <PagingPanel containerComponent={PagingContainer} />
                </Grid>
              )}
            </CardText>
          </Card>
        </Cell>
      </Grid2>
    </Fragment>
  );
};
