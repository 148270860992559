import {
  Cell,
  DatePicker,
  Button,
  FontIcon,
  TextField,
  CircularProgress,
} from 'react-md';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import Grid2 from 'react-md/lib/Grids/Grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import Button2 from '@material-ui/core/Button';

import CargoImp from '../AWBHistory/Table/CargoImp';
import React, { Fragment, useEffect, useState } from 'react';
import { useApi } from '../../services/api';
import 'chart.js';
import { FSNReasonCode } from '../../components/awb-history/Codes';
import moment from 'moment';
import Axios from 'axios';
import PagingContainer from '../../components/GridComponents/PagingPanel';
import FlightDetails from './Components/FlightDetails';

const env = process.env;

const Cell2 = (props) => {
  const { column } = props;

  if (column.name === 'date') {
    return <DateRequestCell {...props} />;
  }
  if (column.name === 'Flight') {
    return <FlightDetails {...props} />;
  }
  return <Table.Cell {...props} />;
};

const DateRequestCell = ({ value, ...restProps }) => {
  let splitDate = value.toString().split(' ');
  let fullDate = '';
  let fullHour = '';

  if (splitDate.length > 0) {
    fullDate = splitDate[0];
    fullHour = splitDate[1];
  }
  return (
    <Table.Cell
      {...restProps}
      style={{
        overflow: 'visible',
      }}
    >
      <div>
        {fullDate} <br /> {fullHour}
      </div>
    </Table.Cell>
  );
};
export default (props) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [name, setName] = React.useState('');

  const [, , fetchDocuments] = useApi(
    'ccapi',
    'documents/search',
    undefined,
    'get'
  );
  const [range, setRange] = useState({
    from: new Date(),
    to: Date.now(),
  });
  const [awb, setAwb] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState([]);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        let day = new Date().getDate();
        let date = new Date();
        date.setDate(day);
        getDocuments({
          from: date.setHours(0, 0, 0, 0),
          to: Date.now(),
          types: ['FSN'],
        });
      }
    }
    tttt();
  }, []);

  const getDocuments = async (query) => {
    setLoading(true);
    const [searchResult, err] = await fetchDocuments({ query: query });
    if (err) return;
    setFilter(searchResult);
    setLoading(false);
  };

  const applyFilter = () => {
    let query = {};
    let from = new Date(range.from);
    let to = new Date(range.to);
    to.setHours(23, 59, 59, 999);
    query.to = to.setDate(to.getDate());
    query.from = from.setDate(from.getDate());
    query.types = ['FSN'];
    query.awb = awb;
    // Se añadió para no enviar manifiestos de en la opcion de busqueda, ya que de ahí se envían los documentos que están en DRAFT
    query.search = true;
    getDocuments(query);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <CargoImp
        open={open}
        name={name}
        onClose={handleClose}
        value={value}
      ></CargoImp>
      <Grid2 style={{ paddingTop: 0 }}>
        <Cell size={3} phoneSize={12} tabletSize={12}>
          <span style={{ fontSize: 35, color: 'gray' }}>Request FSN</span>
        </Cell>
        <DatePicker
          id="inline-date-picker-portait"
          label="from"
          value={moment(range.from).format('L')}
          defaultValue={moment(range.from).format('L')}
          maxDate={new Date(range.to)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={(e) => setRange({ ...range, from: e })}
        />
        <DatePicker
          id="inline-date-picker-portait"
          label="to"
          value={moment(range.to).format('L')}
          defaultValue={moment(range.to).format('L')}
          minDate={new Date(range.from)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={(e) => setRange({ ...range, to: e })}
        />

        <TextField
          id="floating-center-title"
          label="AirWay Bill"
          lineDirection="center"
          className="md-cell md-cell--2"
          onChange={(e) => setAwb(e)}
        />
        <Button
          className="md-cell  md-cell--1"
          style={{ marginTop: 25 }}
          icon
          iconEl={
            <FontIcon
              secondary
              className="fas fa-search"
              onClick={applyFilter}
            />
          }
        />
        <Button2
          variant="outlined"
          color="primary"
          style={{ marginTop: 25 }}
          onClick={applyFilter}
        >
          Refresh
        </Button2>
      </Grid2>

      {loading ? (
        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
          <CircularProgress
            id="loading"
            centered={true}
            className="loading-blue"
            scale={2}
          ></CircularProgress>
        </div>
      ) : (
        <Grid
          rows={filter}
          style={{ backgroundColor: 'red' }}
          columns={[
            {
              name: 'awb',
              title: 'AWB',
              getCellValue: (row) => {
                return `${row.docOrigin.AWB ? row.docOrigin.AWB.prefix : ''}-${
                  row.docOrigin.AWB ? row.docOrigin.AWB.serialNumber : ''
                }`;
              },
            },
            {
              name: 'houseBill',
              title: 'House Bill',
              getCellValue: (row) =>
                row.docOrigin.AWB ? row.docOrigin.AWB.hawbNumber : '',
            },
            {
              name: 'docType',
              title: 'Doc Type',
              getCellValue: (row) => {
                return `
                Freight Status Notification (FSN)
            `;
              },
            },
            {
              name: 'FSNReasonCode',
              title: 'FSN Reason Code',
              getCellValue: (row) =>
                row.docOrigin.ASN
                  ? FSNReasonCode(row.docOrigin.ASN.statusCode, true)
                  : '',
            },
            {
              name: 'Flight',
              title: 'Flight Details',
              getCellValue: (row) =>
                `${
                  row.docOrigin.ARR
                    ? 'Flight #: ' +
                      row.docOrigin.ARR.impCarrier +
                      row.docOrigin.ARR.flightNumber
                    : ''
                }${
                  row.docOrigin.ARR && row.docOrigin.ARR.arrivalDate
                    ? ' Arrival Date: ' + row.docOrigin.ARR.arrivalDate
                    : ''
                }${
                  row.docOrigin.ARR && row.docOrigin.ARR.arrivalRef
                    ? ' Split Shipment Part: ' + row.docOrigin.ARR.arrivalRef
                    : ''
                }${
                  row.docOrigin.CCL && row.docOrigin.CCL.arrivalAirport
                    ? ' Arrival Airport: ' + row.docOrigin.CCL.arrivalAirport
                    : ''
                }${
                  row.docOrigin.CCL && row.docOrigin.CCL.terminalOp
                    ? ' Terminal Operator: ' + row.docOrigin.CCL.terminalOp
                    : ''
                }`,
            },
            {
              name: 'date',
              title: 'Date',
              getCellValue: (row) =>
                moment(row.meta.date).format('MM/DD/YYYY HH:mm:ss'),
            },
            {
              name: 'view',
              title: 'View EDI',
              getCellValue: (row) => {
                return (
                  <Button2
                    onClick={(c) => {
                      setValue(row.docTranslated);
                      setName(`${
                        row.docOrigin.AWB ? row.docOrigin.AWB.prefix : ''
                      }
                      -
                      ${
                        row.docOrigin.AWB ? row.docOrigin.AWB.serialNumber : ''
                      }`);
                      setTimeout(() => {
                        handleClickListItem();
                      }, 10);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    View EDI
                  </Button2>
                );
              },
            },
          ]}
        >
          <SortingState />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={[{ columnName: 'view', filteringEnabled: false }]}
          />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} pageSize={50} />
          <IntegratedPaging />
          <Table
            columnExtensions={[
              { columnName: 'FSNReasonCode', wordWrapEnabled: true },
              { columnName: 'Flight', wordWrapEnabled: true },
              { columnName: 'awb', width: '120' },
              { columnName: 'FSNReasonCode', width: '300' },
              { columnName: 'c1', width: '80' },
              { columnName: 'amsHold', width: '120' },
            ]}
            cellComponent={Cell2}
          />
          <TableFilterRow />
          <TableHeaderRow showSortingControls />
          <PagingPanel containerComponent={PagingContainer} />
        </Grid>
      )}
    </Fragment>
  );
};
