import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Cell,
  TextField,
  Button,
  Subheader,
  SelectField,
  CardText,
  Switch,
  Card,
  Autocomplete,
} from 'react-md';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import { throttle } from 'lodash/function';
import Button2 from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DatePicker from 'react-datepicker';
import feathers from '../../services/feathers';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import FlatFileImporterOnlyButtonComponent from '../../components/flatfile/FlatFileImporterOnlyButtonComponent';
import DataTableFSN from '../../views/documents/Components/DataTableFSN';
import { airSchemasOnly } from '../../constants/SchemaTypes';
import Axios from 'axios';
const env = process.env;

export default (props) => {
  const [open, setOpen] = React.useState(false);
  const [master, setMaster] = useState(false);
  const { close, AWB, HAWB } = props;
  const [dataFlatfile, setDataFlatfile] = useState([]);
  const [schemas, setSchemas] = useState([]);

  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');
  const [startDate, setStartDate] = useState(new Date());
  const [asn, setAsn] = useState({ statusCode: '' });
  const [ccl, setCcl] = useState({ arrivalAirport: '', terminalOp: '' });
  const [awb, setAwb] = useState({
    prefix: props.prefix || '',
    serialNumber: props.serialNumber || '',
    hawbNumber: props.hawbNumber || '',
    consolidationId: props.consolidationId || '',
    pkTrackingId: '',
  });
  const [arr, setArr] = useState({
    impCarrier: '',
    flightNumber: '',
    arrivalDate: '',
    arrivalRef: '',
  });
  const [airlines, setAirlines] = useState([]);
  const [airlineName, setAirlineName] = useState('');
  const [usCodes, setUsCodes] = useState([]);
  const [arrivalAirportName, setArrivalAirportName] = useState('');
  const [terminal, setTerminal] = useState([]);
  const [terminalOpName, setTerminalOpName] = useState('');

  const [, , fetchDocuments] = useApi(
    'ccapi',
    'documents/searchResponses',
    undefined,
    'get'
  );

  const getSchemaFSN = async () => {
    try {
      let response = [];
      for (const { type } of airSchemasOnly) {
        let res = await Axios.get(
          env['REACT_APP_CCOCEAN_FEATHERS_URL'] + `/schemas/${type}`,
          {
            params: {
              dereference: true,
              $populate: 'includes',
            },
          }
        ).then(function (item) {
          if (item && item.data)
            response.push({
              ...item.data,
              title: 'FSN',
              type: type,
            });
        });
      }
      setSchemas(response);
    } catch (error) {
      console.error('error :>> ', error);
    }
  };

  useEffect(() => {
    getSchemaFSN();
  }, [dataFlatfile]);

  const CustomInput = ({ value, onClick }) => (
    <Button2 variant="outlined" onClick={onClick}>
      {value}
    </Button2>
  );
  useEffect(() => {
    setDateFirstTime();
    if (props.match.params.id) {
      get1F(props.match.params.id);
    }
  }, []);

  const setDateFirstTime = async () => {
    let date = moment(new Date()).format('DDMMM').toUpperCase();
    setArr({ ...arr, arrivalDate: date });
  };

  const get1F = async (id) => {
    try {
      let document = await feathers.service('responses').get(id);
      setAwb({
        ...awb,
        prefix: document.docTranslated.AWB.prefix,
        serialNumber: document.docTranslated.AWB.serialNumber,
        hawbNumber: document.docTranslated.AWB.hawbNumber || '',
      });
      let correct = await searchAIR(
        document.docTranslated.ARR2.impCarrier,
        true
      );
      if (!correct && document.docTranslated.ARR2.impCarrier.length === 3) {
        let oldImpCarrier = document.docTranslated.ARR2.impCarrier;
        let oldFlightNumber = document.docTranslated.ARR2.flightNumber;
        document.docTranslated.ARR2.impCarrier = oldImpCarrier.substring(0, 2);
        document.docTranslated.ARR2.flightNumber =
          oldImpCarrier.substring(2) + oldFlightNumber;
        searchAIR(document.docTranslated.ARR2.impCarrier, true);
      }
      setArr({
        ...arr,
        impCarrier: document.docTranslated.ARR2.impCarrier,
        flightNumber: document.docTranslated.ARR2.flightNumber,
        arrivalDate: document.docTranslated.ARR2.arrivalDate,
        arrivalRef: document.docTranslated.ARR2.arrivalRef || '',
      });

      searchCCL(document.docTranslated.CCL.arrivalAirport, true);
      searchTerminal(document.docTranslated.CCL.terminalOp, true);
      setCcl({
        ...ccl,
        arrivalAirport: document.docTranslated.CCL.arrivalAirport,
        terminalOp: document.docTranslated.CCL.terminalOp,
      });

      let year = new Date().getFullYear();
      let date = moment(
        document.docTranslated.ARR2.arrivalDate + year,
        'DDMMMYYYY'
      ).toDate();

      setStartDate(date);
    } catch (error) {
      console.log('error :>> ', error);
      toast.warn(error.message);
    }
  };

  ////  AutoComplete ////
  const searchAIR = throttle(async (query, setInputValue = false) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });
    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt:
              value.iata === ''
                ? value.iata + '-' + value.icao
                : value.iata + '-' + value.icao,
          }))
        : null;

      setAirlines(air);

      if (setInputValue == true) setAirlineName(air[0].find);
      return true;
    }
    return false;
  }, 250);

  const searchCCL = throttle(async (query, setInputValue = false) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $ccl: query,
      },
    });
    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt:
              value.iata === 'N'
                ? value.icao + '-' + value.iata
                : value.icao + '-' + value.iata,
          }))
        : null;
      setUsCodes(ccl);

      if (setInputValue == true) setArrivalAirportName(ccl[0].find);
    }
  }, 250);

  const searchTerminal = throttle(async (query, setInputValue = false) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });

    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt:
              value.iata === ''
                ? value.iata + '-' + value.icao
                : value.iata + '-' + value.icao,
          }))
        : null;
      setTerminal(air);

      if (setInputValue == true) setTerminalOpName(air[0].find);
    }
  }, 250);

  const [, , fetchDoc] = useApi('ccapi', 'documents', undefined, 'post');

  const send = async (id) => {
    let [response, err] = await doSend({ body: id });
    if (err) return;
    toast.success(`${id.length} ${response.message}`);
  };
  const create = async (willSend) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response2 = await Axios.get(
      env['REACT_APP_AUTH'] + '/validPartnership'
    );
    if (response2.data.success) {
      asn.componentId = 'ASN';
      arr.componentId = 'ARR';
      let [apifsn, err] = await fetchDoc({
        body: {
          SMI: { componentId: 'FSN' },
          CCL: ccl,
          AWB: awb,
          ARR: arr,
          ASN: asn,
        },
      });
      if (err) return;
      if (apifsn) {
        if (apifsn.errors) {
          let detail = '';
          for (let i = 0; i < apifsn.errors.length; i++) {
            if (apifsn.errors.length - 1 === i)
              detail += ` ${apifsn.errors[i].message}`;
            else detail += ` ${apifsn.errors[i].message},`;
          }

          toast.warn(`${apifsn.message}, ${detail}`);
        } else {
          toast.success(
            `FSN for ${apifsn.message.docOrigin.AWB.prefix}-${apifsn.message.docOrigin.AWB.serialNumber} successfully created.`
          );
          if (willSend) {
            await send([apifsn.message._id]);
            props.history && props.history.push('/request-notification');
          }
        }
      } else {
        toast.error(`Error`);
      }
    } else {
      toast.warn(response2.data.message);
    }
  };
  const createCsv = async (willSend2) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response2 = await Axios.get(
      env['REACT_APP_AUTH'] + '/validPartnership'
    );
    if (response2.data.success) {
      const succ = [];
      const msgs = [];
      const msg = [];
      const toSend = [];

      const promises = [];
      dataFlatfile.forEach(async (element) => {
        let arrivalDate = moment(element.dateOfArrival)
          .format('DDMMM')
          .toUpperCase();
        let ccl2 = {
          arrivalAirport: element.arrivalAirport
            ? element.arrivalAirport
            : ccl.arrivalAirport,
          terminalOp: element.terminalOperator
            ? element.terminalOperator
            : ccl.terminalOp,
        };
        let awb2 = {
          prefix: element.AWBPrefix,
          serialNumber: element.AWBNumber,
          hawbNumber: element.HAWBNumber != null ? element.HAWBNumber : '',
          consolidationId:
            element.MAWBIndicator != null ? element.MAWBIndicator : '',
        };
        let arr2 = {
          arrivalDate: arrivalDate ? arrivalDate : arr.arrivalDate,
          flightNumber: element.flightNumber
            ? element.flightNumber
            : arr.flightNumber,
          arrivalRef: element.partArrivalReference,
          impCarrier: element.airLine ? element.airLine : arr.impCarrier,
        };
        let asn2 = {
          statusCode: element.FSNReasonCode
            ? element.FSNReasonCode
            : asn.statusCode,
        };
        asn2.componentId = 'ASN';
        arr2.componentId = 'ARR';
        promises.push(
          fetchDoc({
            body: {
              SMI: { componentId: 'FSN' },
              CCL: ccl2,
              AWB: awb2,
              ARR: arr2,
              ASN: asn2,
            },
          })
        );
      });
      await Promise.all(promises)
        .then((responses) => {
          responses.forEach(async ([res, err]) => {
            if (err) return;
            if (res) {
              const { errors, message, doc } = res;
              if (errors) {
                const {
                  AWB: { prefix, serialNumber },
                } = doc;
                errors.forEach(({ message: msg }) => {
                  msgs.push({ msg, doc: `${prefix}-${serialNumber}` });
                });
                msg.push(`${message}: ${prefix}-${serialNumber}`);
                // console.log('msg :>> ', msg);
              } else {
                const {
                  docOrigin: {
                    AWB: { prefix, serialNumber },
                  },
                  _id,
                } = message;
                succ.push(`${prefix}-${serialNumber}`);
                toSend.push(_id);
              }
            }
          });
        })
        .catch((e) => {
          console.log({ e });
        });
      if (succ.length) {
        let details = '';
        succ.forEach((s) => {
          details +=
            details === ''
              ? `${succ.length} FSN successfully created for: ${s}`
              : `, ${s}`;
        });
        toast.success(details);
        //limpiar tabla
        setDataFlatfile([]);
      }
      if (msgs.length) {
        let detail = '';
        msgs.forEach(({ msg, doc }) => {
          detail +=
            detail === ''
              ? `${msg} at: ${doc}`
              : detail.includes(msg)
              ? `, ${doc}`
              : `, ${msg} at: ${doc}`;
        });
        toast.warn(`${msg}, ${detail}`);
      }
      if (toSend.length) {
        await send(toSend);
        props.history && props.history.push('/request-notification');
      }
    } else {
      toast.warn(response2.data.message);
    }
  };

  const dateInput = (e) => {
    let date = moment(e).format('DDMMM').toUpperCase();
    setArr({ ...arr, arrivalDate: date });
  };
  const setPrefix = (e) => {
    setAwb({ ...awb, prefix: e });
  };
  const setSerialNumber = (e) => {
    setAwb({ ...awb, serialNumber: e });
    if (e.length === 8) {
      search1FInfo(e);
    }
  };

  const search1FInfo = async (serialNumber) => {
    let query = {
      prefix: awb.prefix,
      serialNumber: serialNumber,
      types: 'FSN',
      actionCode: '1F',
      sort: -1,
      limit: 1,
    };

    const [searchResult, err] = await fetchDocuments({ query: query });
    if (err) return;
    if (searchResult && searchResult.length > 0) {
      searchAIR(searchResult[0].ImpCarrier, true);
      setArr({
        ...arr,
        impCarrier: searchResult[0].ImpCarrier.toUpperCase(),
        flightNumber: searchResult[0].FlightNumber,
        arrivalDate: searchResult[0].ArrivalDate,
      });

      searchCCL(searchResult[0].ArrivalAirport, true);
      searchTerminal(searchResult[0].TerminalOp, true);
      setCcl({
        ...ccl,
        arrivalAirport: searchResult[0].ArrivalAirport,
        terminalOp: searchResult[0].TerminalOp,
      });

      let year = new Date().getFullYear();
      let date = moment(
        searchResult[0].ArrivalDate + year,
        'DDMMMYYYY'
      ).toDate();

      setStartDate(date);
    }
  };

  return (
    <Fragment>
      <Grid>
        <Cell size={12} tabletSize={8} phoneSize={12}>
          <Card>
            <CardText>
              <Grid>
                <Cell size={2}>
                  <TextField
                    id="AWBPSN"
                    onChange={(e) => {
                      const re = /^[a-zA-Z0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 3) {
                          setPrefix(e.toUpperCase());
                        }
                      }
                    }}
                    label="AWB Prefix#"
                    value={awb.prefix}
                    lineDirection="center"
                    required
                  />
                </Cell>
                <Cell size={3}>
                  <TextField
                    id="AWBPSN"
                    onChange={(e) => {
                      const re = /^[0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 8) {
                          setSerialNumber(e);
                        }
                      }
                    }}
                    label="AWB #"
                    value={awb.serialNumber}
                    lineDirection="center"
                    required
                  />
                </Cell>
                <Cell size={2}>
                  <Switch
                    style={{ marginTop: 20 }}
                    id="checkbox-read-material-design-spec"
                    name="simple-checkboxes[]"
                    label="MAWB Indicator"
                    value="material-design"
                    checked={master}
                    onChange={(e) => {
                      setMaster(e);
                      if (e) {
                        setAwb({
                          ...awb,
                          consolidationId: 'M',
                          hawbNumber: '',
                        });
                      } else {
                        setAwb({ ...awb, consolidationId: '' });
                      }
                    }}
                  />
                </Cell>
                <Cell size={3}>
                  <TextField
                    id="HAWB"
                    disabled={master}
                    defaultValue={HAWB}
                    onChange={(e) => {
                      const re = /^[a-zA-Z0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 12) {
                          setAwb({ ...awb, hawbNumber: e.toUpperCase() });
                        }
                      }
                    }}
                    value={awb.hawbNumber}
                    label="House AWB #"
                    lineDirection="center"
                  />
                </Cell>
                <SelectField
                  id="floating-center-title"
                  className="md-cell md-cell--2"
                  onChange={(arrivalRef) => setArr({ ...arr, arrivalRef })}
                  label="PARN #"
                  style={{ maxHeight: '120px' }}
                  menuItems={PARNs}
                  value={arr.arrivalRef}
                  lineDirection="center"
                />
              </Grid>
            </CardText>
          </Card>
        </Cell>
      </Grid>
      <Grid>
        <Cell size={12} tabletSize={8} phoneSize={12}>
          <Card>
            <CardText>
              <Subheader
                className="md-grid"
                primary
                primaryText="Flight Details"
              />
              <Grid>
                <Cell size={3} tabletSize={2}>
                  {airlines !== undefined ? (
                    <Autocomplete
                      id="codes-airline"
                      required
                      label="Airline"
                      data={airlines.slice(0, 30)}
                      dataLabel={'find'}
                      dataValue={'txt'}
                      filter={null}
                      findInlineSuggestion={Autocomplete.findIgnoreCase}
                      value={airlineName ? airlineName : arr.impCarrier}
                      onChange={(e) => {
                        searchAIR(e);

                        setArr({
                          ...arr,
                          impCarrier: e.toUpperCase(),
                        });
                        setAirlineName(e.toUpperCase());
                      }}
                      onAutocomplete={(v) => {
                        let find = v.split('-');
                        let c = airlines;

                        let f = airlines.filter((x) => x.txt === v)[0];
                        setAirlineName(f.find);

                        setArr({
                          ...arr,
                          impCarrier: v === '' ? c[0].icao : find[0] || find[1],
                        });
                      }}
                    />
                  ) : null}
                </Cell>
                <Cell size={3} tabletSize={3}>
                  <TextField
                    id="floating-center-title"
                    value={arr.flightNumber}
                    onChange={(e) => {
                      const re = /^[0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 8) {
                          setArr({ ...arr, flightNumber: e });
                        }
                      }
                    }}
                    label="Flight #"
                    required
                    lineDirection="center"
                  />
                </Cell>
                <Cell size={3} tabletSize={3}>
                  {usCodes !== undefined ? (
                    <Autocomplete
                      id="all-codes-arrival"
                      required
                      label="Arrival Airport"
                      arrivalAirportName
                      value={
                        arrivalAirportName
                          ? arrivalAirportName
                          : ccl.arrivalAirport
                      }
                      data={usCodes.slice(0, 30)}
                      dataLabel={'find'}
                      dataValue={'txt'}
                      filter={null}
                      findInlineSuggestion={Autocomplete.findIgnoreCase}
                      onChange={(e) => {
                        searchCCL(e);
                        setCcl({
                          ...ccl,
                          arrivalAirport: e.toUpperCase(),
                        });
                        setArrivalAirportName(e.toUpperCase());
                      }}
                      onAutocomplete={(v) => {
                        let find = v.split('-');

                        let c = usCodes;

                        let f = usCodes.filter((x) => x.txt === v)[0];
                        setArrivalAirportName(f.find);

                        setCcl({
                          ...ccl,
                          arrivalAirport: v === 'N' ? c[0].icao : find[1],
                        });
                      }}
                    />
                  ) : null}
                </Cell>
                <Cell size={3} tabletSize={3}>
                  {terminal !== undefined ? (
                    <Autocomplete
                      id="codes-terminal-operator"
                      required
                      label="Terminal Operator (Airline)"
                      data={terminal.slice(0, 30)}
                      dataLabel={'find'}
                      dataValue={'txt'}
                      filter={null}
                      findInlineSuggestion={Autocomplete.findIgnoreCase}
                      value={terminalOpName ? terminalOpName : ccl.terminalOp}
                      onChange={(e) => {
                        if (props.setChange) {
                          props.setChange(false);
                        }
                        if (e.length >= 1) {
                          searchTerminal(e);
                        }
                        setCcl({
                          ...ccl,
                          terminalOp: e.toUpperCase(),
                        });
                        setTerminalOpName(e.toUpperCase());
                      }}
                      onAutocomplete={(v) => {
                        let find = v.split('-');
                        let c = terminal;

                        let f = terminal.filter((x) => x.txt === v)[0];
                        setTerminalOpName(f.find);

                        setCcl({
                          ...ccl,
                          terminalOp: v === '' ? c[0].icao : find[0] || find[1],
                        });
                      }}
                    />
                  ) : null}
                </Cell>
              </Grid>
              <Grid>
                <Cell size={3}>
                  <Typography>Select a Date</Typography>
                  <br></br>
                  <DatePicker
                    selected={startDate}
                    dateFormat="ddMMM"
                    customInput={<CustomInput />}
                    onChange={(e) => {
                      setStartDate(e);
                      dateInput(e);
                    }}
                    withPortal
                  />
                </Cell>
                <SelectField
                  id="floating-center-title"
                  className="md-cell md-cell--9"
                  onChange={(e) =>
                    setAsn({ ...asn, statusCode: e.match(/[0-9]{1}/g)[0] })
                  }
                  label="FSN Reason Code"
                  menuItems={code}
                  lineDirection="center"
                  required
                />
              </Grid>
              <Grid>
                <Cell size={2}>
                  <Button
                    raised
                    secondary
                    iconBefore={false}
                    onClick={async (e) => {
                      if (dataFlatfile.length) {
                        toast.warn(
                          `Se tomara en cuenta la informacion del grid`
                        );
                        await createCsv(true);
                      } else {
                        await create(true);
                      }
                    }}
                    disabled={
                      dataFlatfile.length > 0
                        ? false
                        : awb.prefix.length < 3 ||
                          awb.serialNumber.length < 8 ||
                          arr.impCarrier.length < 2 ||
                          arr.flightNumber === '' ||
                          ccl.arrivalAirport.length < 2 ||
                          ccl.terminalOp.length < 2 ||
                          asn.statusCode === ''
                        ? true
                        : false
                    }
                  >
                    Create & Send
                  </Button>
                </Cell>
                <Cell>
                  {schemas.map((schema, i) => (
                    <FlatFileImporterOnlyButtonComponent
                      schema={schema}
                      text="UPLOAD ASN"
                      setDataFlatfile={setDataFlatfile}
                    />
                  ))}
                </Cell>
                <Cell style={{ display: AWB ? 'block' : 'none' }} size={2}>
                  <Button raised secondary iconBefore={false} onClick={close}>
                    Cancel
                  </Button>
                </Cell>
              </Grid>
            </CardText>
          </Card>
        </Cell>
        <DataTableFSN data={dataFlatfile} />
      </Grid>
    </Fragment>
  );
};

const code = [
  '3 In-bond arrived at destination',
  //Sent by the bonded custodian at the CBP Control Destination
  '4 Local transfer of consolidated cargo',
  //to a Deconsolidator has been accomplished
  '7 In-bond exported at destination',
  //Sent by the bonded custodian at the CBP Control Destination
];
const PARNs = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
