import React, { useState } from 'react';
import { Grid, Cell, TextField, SelectionControlGroup, Button } from 'react-md';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useApi } from '../../../services/api';

const env = process.env;

const FSQ = (props) => {
  const { data, getData, onClose, AWB } = props;
  const [formData, setFormData] = useState({
    AWB: {
      prefix: AWB.split('-')[0] || '',
      serialNumber: AWB.split('-')[1] || '',
    },
    FSQ: { requestCode: '', componentId: 'FSQ' },
  });

  const ready =
    formData.AWB.prefix.length === 3 &&
    formData.AWB.serialNumber.length === 8 &&
    formData.FSQ.requestCode &&
    formData.FSQ.requestCode.length !== 0;

  const items = [
    {
      label: '01 - Request for routing information',
      value: '01',
    },
    {
      label: '02 - Request for current record status',
      value: '02',
    },
    {
      label: '03 - Request for nominated agent',
      value: '03',
    },
    {
      label: '04 - Request for retransmission of FSN messages',
      value: '04',
    },
    {
      label:
        '05 - Request for: routing information, current record status, nominated agent, current AWB information on file returned in FSC/10 message',
      value: '05',
    },
    {
      label: '06 - Request for HAWB information for supplied MAWB.',
      value: '06',
      disabled: data.isHouse === 'Consolidation',
    },
  ];

  const [, , fetchDoc] = useApi('ccapi', 'documents', undefined, 'post');

  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');

  const handleChange = (_, { target }) => {
    let data = JSON.parse(JSON.stringify(formData));
    if (target) {
      const { value, id } = target;
      const upperValue = value.toUpperCase();
      const regex = { text: /^[a-zA-Z0-9\b]*$/, number: /^[0-9\b]*$/ };
      switch (id) {
        case 'prefix':
          if ((value === '' || regex.text.test(value)) && value.length <= 3) {
            data = { ...data, AWB: { ...data.AWB, [id]: upperValue } };
          }
          break;
        case 'serialNumber':
          if ((value === '' || regex.number.test(value)) && value.length <= 8)
            data = { ...data, AWB: { ...data.AWB, [id]: upperValue } };
          break;
        default:
          if (id.includes('requestCode')) {
            data = { ...data, FSQ: { ...data.FSQ, requestCode: upperValue } };
          }
          break;
      }
      setFormData(data);
    }
  };

  const send = async (id) => {
    const [result, err] = await doSend({ body: [id] });
    if (err) return;
    toast.success(result.message);
    onClose();
    await getData();
  };

  const create = async (willSend) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const result_axios = await Axios.get(
      `${env['REACT_APP_AUTH']}/validPartnership`
    );
    if (result_axios.data.success) {
      let data = JSON.parse(JSON.stringify(formData));
      let { AWB, FSQ } = data;
      const body = { SMI: { componentId: 'FSQ' }, AWB, FSQ };

      const [apiFSQ, error] = await fetchDoc({ body });

      if (error) return;

      if (apiFSQ) {
        if (apiFSQ.errors) {
          let detail = '';
          Array.isArray(apiFSQ.errors) &&
            apiFSQ.errors.forEach(({ message }) => {
              detail += detail === '' ? `${message}` : `, ${message}`;
            });
          toast.warn(`${apiFSQ.message}, ${detail}`);
        } else {
          const {
            _id,
            docOrigin: {
              AWB: { prefix, serialNumber },
            },
          } = apiFSQ.message;
          toast.success(
            `FSQ for ${prefix}-${serialNumber} successfully created.`
          );
          if (willSend) await send(_id);
        }
      } else toast.error('Something went wrong, try again.');
    } else toast.warn(result_axios.data.message);
  };

  return (
    <div>
      <Grid>
        <Cell size={2}>
          <TextField
            id="prefix"
            value={formData.AWB.prefix}
            helpText="Example: 999"
            onChange={handleChange}
            label="AWB Prefix"
            lineDirection="center"
          />
        </Cell>
        <Cell size={5}>
          <TextField
            id="serialNumber"
            value={formData.AWB.serialNumber}
            helpText="Example: 12345678"
            onChange={handleChange}
            label="AWB #"
            lineDirection="center"
          />
        </Cell>
        <Cell size={12}>
          <SelectionControlGroup
            id="requestCode"
            name="requestCode"
            type="radio"
            label="Status Request Code"
            defaultValue={false}
            controls={items}
            onChange={handleChange}
          />
        </Cell>
        <Cell size={2}>
          <Button
            style={{ display: AWB ? 'none' : 'block' }}
            raised
            secondary
            iconBefore={false}
            onClick={() => create(false)}
            disabled={!ready}
          >
            Create
          </Button>
          <Button
            style={{ display: AWB ? 'block' : 'none' }}
            raised
            secondary
            iconBefore={false}
            onClick={() => create(true)}
            disabled={!ready}
          >
            Create & Send
          </Button>
        </Cell>
        <Cell style={{ display: AWB ? 'block' : 'none' }} size={2}>
          <Button raised secondary iconBefore={false} onClick={onClose}>
            Cancel
          </Button>
        </Cell>
      </Grid>
    </div>
  );
};

export default FSQ;
