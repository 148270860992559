import {
  Cell,
  Subheader,
  TextField,
  SelectField,
  Grid,
  Autocomplete,
} from 'react-md';
import React, { Fragment, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { throttle } from 'lodash/function';
import feathers from '../../../services/feathers';
import 'react-datepicker/dist/react-datepicker.css';

export default (props) => {
  const { role, status } = props;
  const [startDate, setStartDate] = useState(Date.now());
  const [allCodes, setAllCodes] = useState([]);
  const [find, setFind] = useState('code');
  const searchPermit = throttle(async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $params: query,
      },
    });
    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city +
              ' - ' +
              value.alias,
            txt:
              value.iata === 'N'
                ? value.icao + '-' + value.iata
                : value.icao + '-' + value.iata,
            // value.iata === 'N' ? value.icao : value.iata
          }))
        : null;
      setAllCodes(ccl);
    }
  }, 250);
  let [errorArrival, setErrorArrival] = useState(false);
  const ref = React.createRef();
  const dateInput = (e) => {
    props.setWbl({ ...props.wbl, arrivalDatePTPAirport: e });
    if (e.match(/[0-9]{2}JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC/)) {
      setErrorArrival(false);
      if (e.length > 5) {
        setErrorArrival('Length must be 5, DDMMM');
      }
    } else if (e.length === 0) {
      setErrorArrival(false);
    } else if (e.length > 5) {
      setErrorArrival('Length must be 5, DDMMM');
    } else {
      setErrorArrival(
        'Date Format must be DDMMM and must be valid Month: JAN, FEB, MAR, APR, MAY, JUN, JUL, AUG, SEP, OCT, NOV, DEC'
      );
    }
  };

  // const usAir = (e) => {
  //     for (const key in codes) {
  //         if (codes.hasOwnProperty(key)) {
  //             if (codes[key] === e) {
  //                 props.setWbl({ ...props.wbl, destinationAirport: key })
  //             }
  //         }
  //     }
  // }

  return (
    <Fragment>
      <Cell size={12}>
        <Grid>
          <Cell size={4}>
            <Autocomplete
              id="all-codes-origin"
              required
              label="Destination Airport"
              data={allCodes.slice(0, 30)}
              value={
                props.autocompletesAirPermit
                  ? props.autocompletesAirPermit.destinationAirport
                  : allCodes.name
              }
              dataLabel={'find'}
              dataValue={'txt'}
              findInlineSuggestion={Autocomplete.findIgnoreCase}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }

                searchPermit(e);
                props.setWbl({
                  ...props.wbl,
                  destinationAirport: e.toUpperCase(),
                });
                if (props.autocompletesAirPermit) {
                  props.setAutocompletesAirPermit({
                    ...props.autocompletesAirPermit,
                    destinationAirport: e.toUpperCase(),
                  });
                }
              }}
              onAutocomplete={(v) => {
                let find = v.split('-');
                let c = allCodes;
                props.setWbl({
                  ...props.wbl,
                  destinationAirport: v === 'N' ? c[0].icao : find[1],
                });

                if (props.autocompletesAirPermit) {
                  let f = allCodes.filter((x) => x.txt === v)[0];
                  props.setAutocompletesAirPermit({
                    ...props.autocompletesAirPermit,
                    destinationAirport: f.find,
                  });
                }
              }}
              disabled={
                (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
              }
            />
            <input
              ref={props.destRef}
              type="text"
              value={
                props.wbl.destinationAirport ? props.wbl.destinationAirport : ''
              }
              required
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
          <Cell fontSize={16} size={2} style={{ marginLeft: '150px' }}>
            <br></br>
            <br></br>
            <DatePicker
              selected={startDate}
              value={
                props.wbl.arrivalDatePTPAirport
                  ? props.wbl.arrivalDatePTPAirport
                  : startDate
              }
              dateFormat="ddMMM"
              placeholderText="Select an Arrival Date"
              // ref={ref}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                setStartDate(e);
                let date = moment(e).format('DDMMM').toUpperCase();

                return (props.wbl.arrivalDatePTPAirport = date);
                // dateInput(e);
              }}
              withPortal
              disabled={
                (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
              }
            />
          </Cell>
        </Grid>
      </Cell>
    </Fragment>
  );
};
