import { Table } from '@devexpress/dx-react-grid-material-ui';
import React, { useEffect } from 'react';
import feathers from '../../../../services/feathers';

const AWBCell = ({ value, restProps }) => {
  useEffect(() => {
    feathers.service('manifests').on('cached', listenerCached);
    //console.log('listener added');
    return () => {
      //console.log('removing listner');
      feathers.service('manifests').removeListener('cached', listenerCached);
    };
  }, []);

  const listenerCached = (data) => {
    if (
      data.prefix === restProps.row.prefix &&
      data.serialNumber === restProps.row.serialNumber &&
      data.flightNumber === restProps.row.flightNumber &&
      data.type === restProps.row.type
    ) {
      restProps.row.hawb = data.hawb;
      restProps.row.status = data.status;
      restProps.row.docType = data.docType;
      restProps.row.code = data.code;
      restProps.row.message = data.message;
      restProps.row.message2 = data.message2;
    }
  };

  if (restProps.row.type === 'Consolidation') {
    return (
      <Table.Cell {...restProps}>
        <div>
          {/* <Checkbox
            color="primary"
            checked={restProps.row.checked}
            onClick={async (e) => {
              restProps.row.checked = !restProps.row.checked;
              restProps.column.setSelection(
                restProps.row,
                restProps.row.checked
              );
            }}
            disabled={
              restProps.row.status === 'SENT' ||
              //restProps.row.status === 'SENDING' ||
              !restProps.row.hasOwnProperty('hawb')
            }
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          /> */}
          {value}
        </div>
      </Table.Cell>
    );
  } else {
    return (
      <Table.Cell {...restProps}>
        <div>
          {/* <Checkbox
            color="primary"
            checked={restProps.row.checked}
            onClick={async (e) => {
              restProps.row.checked = !restProps.row.checked;
              restProps.column.setSelection(
                restProps.row,
                restProps.row.checked
              );
            }}
            disabled={restProps.row.status === 'SENT'}
            inputProps={{
              'aria-label': 'primary checkbox',
            }}
          /> */}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={async (e) => {
              await restProps.column.setRow({
                currentTab: 0,
                index: 0,
                row: restProps.row,
                prefix: restProps.row.prefix,
                serialNumber: restProps.row.serialNumber,
                impCarrier: restProps.row.impCarrier,
                arrivalDate: restProps.row.arrivalDate,
                flightNumber: restProps.row.flightNumber,
                type: restProps.row.type,
                date: restProps.row.manifestCreated,
              });
              await restProps.column.openDetails(
                true,

                restProps.row.type === 'Master' ? restProps.row.prefix : null,

                restProps.row.type === 'Master'
                  ? restProps.row.serialNumber
                  : null,
                restProps.row.lastId,
                restProps.row.status,
                restProps.row.userCreated,
                restProps.row.userSubmission,
                restProps.row.manifestCreated,
                restProps.row.requestDate,
                restProps.row.responseDate,
                restProps.row,
                undefined,
                0
              );
            }}
          >
            {value}
          </span>
        </div>
      </Table.Cell>
    );
  }
};

export default AWBCell;
