import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import React, { useState, useEffect, Fragment } from 'react';
import {
  Grid,
  Cell,
  Card,
  Toolbar,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TextField,
  TableColumn,
  ListItem,
  MenuButton,
  FontIcon,
  Button,
  DialogContainer,
  TablePagination,
} from 'react-md';
import Axios from 'axios';
const env = process.env;
export default (props) => {
  const [client, setClient] = useState({});
  const [clientsSliced, setClientSliced] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [, clients, fetchClient] = useApi('client', 'client', []);
  const [, , fetchDelete] = useApi('client', 'client', {}, 'delete');
  const [asending, setAsending] = useState({
    name: false,
    email: false,
    'address.city': false,
    'address.state': false,
  });

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        fetchClient();
      }
    }
    tttt();
  }, []);

  useEffect(() => {
    if (clients.length > 0) {
      setClientSliced(clients.slice(0, 10));
    }
  }, [clients]);

  const sort = (culumn) => {
    let sort = _.orderBy(
      [...clients],
      [culumn],
      [!asending[culumn] ? 'asc' : 'desc']
    );
    setClientSliced([...sort]);
    setAsending({ ...asending, [culumn]: !asending[culumn] });
  };

  const filter = (v, filterBy) => {
    if (!v.length) {
      setClientSliced(clients);
    } else {
      setClientSliced(
        clients.filter((u) => {
          if (u.contact) {
            return u.contact[filterBy].toLowerCase().includes(v.toLowerCase());
          } else {
            return false;
          }
        })
      );
    }
  };
  return (
    <Fragment>
      <Grid className="grid-example">
        <Cell desktopOffset={2} size={8}>
          <Card>
            <Toolbar
              colored
              title="Clients"
              actions={
                <Button
                  component={Link}
                  to="/client"
                  tooltipLabel="Add Client"
                  style={{ marginTop: '30px' }}
                  secondary
                  floating
                >
                  add
                </Button>
              }
            ></Toolbar>
            <DataTable baseId="123" plain>
              <TableHeader>
                <TableRow>
                  <TableColumn
                    sorted={asending['name']}
                    onClick={() => sort('name')}
                  >
                    Name
                  </TableColumn>
                  <TableColumn
                    sorted={asending['email']}
                    onClick={() => sort('email')}
                  >
                    Email
                  </TableColumn>
                  <TableColumn>Phone</TableColumn>
                  <TableColumn>Company Code</TableColumn>
                  <TableColumn />
                </TableRow>
              </TableHeader>
              <TableHeader>
                <TableRow key={0}>
                  <TableColumn>
                    {' '}
                    <TextField
                      id="name"
                      onChange={(v) => filter(v, 'name')}
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                    />
                  </TableColumn>
                  <TableColumn>
                    {' '}
                    <TextField
                      id="email"
                      onChange={(v) => filter(v, 'email')}
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                    />
                  </TableColumn>
                  <TableColumn> </TableColumn>
                  <TableColumn></TableColumn>
                  <TableColumn></TableColumn>
                </TableRow>
              </TableHeader>
              <TableBody>
                {clientsSliced.map((s, i) => (
                  <TableRow key={i}>
                    <TableColumn>
                      {s.contact
                        ? `${s.contact.name} ${s.contact.lastName}`
                        : ''}
                    </TableColumn>
                    <TableColumn>
                      {s.contact ? s.contact.email : ''}
                    </TableColumn>
                    <TableColumn>
                      {s.contact ? s.contact.phone : ''}
                    </TableColumn>
                    <TableColumn>{s.companyCode}</TableColumn>
                    <TableColumn>
                      <MenuButton
                        id="menu"
                        icon
                        menuItems={[
                          <ListItem
                            key={1}
                            primaryText="Edit"
                            rightIcon={<FontIcon>edit</FontIcon>}
                            onClick={() => {
                              props.history.push(`/client/${s._id}`);
                            }}
                          />,
                          <ListItem
                            key={2}
                            primaryText="Delete"
                            rightIcon={<FontIcon>delete</FontIcon>}
                            onClick={() => {
                              setClient(s);
                              setShowModal(true);
                            }}
                          />,
                        ]}
                        centered
                      >
                        more_vert
                      </MenuButton>
                    </TableColumn>
                  </TableRow>
                ))}
              </TableBody>
              <TablePagination
                rows={clients.length}
                rowsPerPageLabel={'Rows'}
                onPagination={(s, rpp) =>
                  setClientSliced(clients.slice(s, s + rpp))
                }
              />
            </DataTable>
          </Card>
        </Cell>
      </Grid>
      <DialogContainer
        id="speed-boost"
        visible={showModal}
        title="Do you want to permanently delete this client?"
        actions={[
          {
            secondary: true,
            children: 'Delete',
            onClick: async () => {
              await fetchDelete({ params: [client._id] });
              await fetchClient({});
              setShowModal(false);
              toast.success('Client has been deleted.');
            },
          },
          {
            primary: true,
            children: 'Cancel',
            onClick: () => {
              setShowModal(false);
            },
          },
        ]}
        onHide={() => {}}
      ></DialogContainer>
    </Fragment>
  );
};
