import React, { useEffect, Fragment, useState } from 'react';
import feathers from '../../services/feathers';
import { Cell, Grid } from 'react-md';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
export default props => {
  const [notifications, SetNotifications] = useState([]);
  useEffect(() => {
    async function start() {
      await getNotifications();
    }
    start();
  }, []);
  const getNotifications = async () => {
    let notificationsActives = await feathers
      .service('system-notifications')
      .find({
        query: {
          $sort: {
            createdAt: -1
          },
          active: true
        }
      });
    SetNotifications(notificationsActives);
  };
  return (
    <Fragment>
      {notifications.map((notification, index) => (
        <Grid>
          <Cell size={12}>
            <Card style={{ backgroundColor: 'yellow' }}>
              <CardContent>
                <Typography
                  variant="h4"
                  style={{ color: 'black' }}
                  component="h1"
                >
                  Customer Announcement
                </Typography>
                <hr />
                <Typography variant="h5" component="p">
                  CSMS #{notification.CSMSNumber} - {notification.title}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {notification.description}
                </Typography>
              </CardContent>
            </Card>
          </Cell>
        </Grid>
      ))}
    </Fragment>
  );
};
