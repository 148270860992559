import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Drawer as SlidePanel,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Tabs, Tab, Toolbar, TabPanel } from 'react-md';
import Table from './Table';
import feathers from '../../../services/feathers';
import { getHeight } from '../functions/functions';
import Modal from '../Modal/';

const services = { fsn: feathers.service('requests') };

const Drawer = ({
  isOpenDrawer,
  setIsOpenDrawer,
  gridActions,
  AWB,
  setAWB,
  isOpenModal,
  toggleModal,
  data,
}) => {
  const [fsnData, setFsnData] = useState([]);
  const [busy, setBusy] = useState(false);
  const [tab] = useState('Response');

  useEffect(() => {
    const getData = async () => {
      if (AWB.serialNumber || AWB.prefix) {
        setBusy(true);
        let res;
        const { prefix, serialNumber } = AWB;
        const { company } = JSON.parse(localStorage.profile);
        var query = { $summaryDetailsFsn: { prefix, serialNumber, company } };
        try {
          res = await services.fsn.find({ query });
          res = res.map((it) => ({ ...it, height: getHeight(it) }));
        } catch (error) {
          console.log({ error });
        } finally {
          setFsnData(res || []);
          setBusy(false);
        }
      }
    };
    getData();
  }, [AWB]);

  const handleClose = () => {
    setAWB({});
    setFsnData([]);
    setIsOpenDrawer(false);
  };

  return (
    <SlidePanel open={isOpenDrawer} onClose={handleClose} anchor="right">
      <Main style={{ width: window.innerWidth * 0.9 }}>
        <Header>
          <Toolbar>
            <Tabs tabId={tab}>
              <Tab label={tab} wrapped tabId={tab} />
            </Tabs>
            <Icon
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="menu"
            >
              <Close />
            </Icon>
          </Toolbar>
        </Header>
        <Content value={tab} index={0} id={tab} controlledById={tab}>
          {busy ? (
            <Spinner />
          ) : (
            <Table fsnData={fsnData} gridActions={gridActions} />
          )}
        </Content>
      </Main>
      <Modal open={isOpenModal} toggle={toggleModal} data={data} />
    </SlidePanel>
  );
};

const Main = styled.div``;

const Header = styled.div`
  padding-top: 8px;
  background-color: rgb(89, 157, 222);
  width: 100%;
  height: 64px;
  color: #fff;
`;

const Content = styled(TabPanel)`
  height: calc(100vh - 64px);
`;

const Spinner = styled(CircularProgress)`
  display: flex !important;
  margin: auto;
  margin-top: calc(50vh - 64px);
`;

const Icon = styled(IconButton)`
  margin-right: 10px !important;
  position: absolute !important;
  right: 0 !important;
`;

export default Drawer;
