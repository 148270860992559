import { Grid, Cell, SelectField, DatePicker } from 'react-md';
import React, { Fragment, useEffect, useState } from 'react';
import { PieChartCard, TopTableCard } from '../components';
import { useApi } from '../services/api';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import _ from 'lodash';
import 'chart.js';
import moment from 'moment';
import feathers from '../services/feathers';
import DatePickerRange from '../components/common/DatePickerRange';
import Notifications from '../components/common/Notifications';
import Axios from 'axios';
const env = process.env;
export default (props) => {
  // const [, , fetchTelemetry] = useApi(
  //   'ccapi',
  //   'cbp/telemetry',
  //   undefined,
  //   'get'
  // );
  let toDate = Date.now();
  let fromDate = new Date();
  fromDate = fromDate.setDate(fromDate.getDate() - 6); //moment().subtract(6, 'months');

  const [total, setTotal] = useState({});
  const [master, setMaster] = useState({});
  const [simple, setSimple] = useState({});
  const [loadingSimple, setLoadingSimple] = useState(true);
  const [loadingTotal, setLoadingTotal] = useState(true);
  const [loadingMaster, setLoadingMaster] = useState(true);
  const [loadingHouse, setLoadingHouse] = useState(true);
  const [house, setHouse] = useState({});
  const [consegnees, setConsegnees] = useState([]);
  const [shippers, setShippers] = useState([]);
  const [origins, setOrigin] = useState([]);
  const [destinations, setDestination] = useState([]);
  const [loadingConsegnees, setLoadingConsegnees] = useState(true);
  const [loadingShippers, setLoadingShippers] = useState(true);
  const [loadingOrigins, setLoadingOrigins] = useState(true);
  const [loadingDestinations, setLoadingDestinations] = useState(true);
  const [range, setRange] = useState({
    from: new Date().setDate(new Date().getDate() - 6),
    to: Date.now(),
  });

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        getTelemetry({
          from: new Date().setDate(new Date().getDate() - 6),
          to: Date.now(),
        });
      }
    }
    tttt();
  }, []);
  const setDates = async (start, end) => {
    setRange({ ...range, from: start, to: end });
  };

  const getTelemetry = async (query) => {
    let tele = await feathers.service('awb-status').find({
      query: {
        $agregation: {
          startDate: query.from,
          endDate: query.to,
          company: JSON.parse(localStorage.profile).company,
          role: JSON.parse(localStorage.profile).role,
          isDashboard: true,
        },
      },
    });

    let totalSimple;
    let acceptedSimple = tele.filter(
      (f) => f.type === 'Simple' && f.status === 'ACCEPTED'
    ).length;

    let rejectedSimple = tele.filter(
      (f) => f.type === 'Simple' && f.status === 'REJECTED'
    ).length;
    let cancelSimple = tele.filter(
      (f) => f.type === 'Simple' && f.status === 'CANCELLED'
    ).length;
    let sentSimple = tele.filter(
      (f) => f.type === 'Simple' && f.status === 'SENT'
    ).length;
    totalSimple = acceptedSimple + rejectedSimple + cancelSimple + sentSimple;

    setSimple({
      total: totalSimple,
      accepted: acceptedSimple,
      rejected: rejectedSimple,
      cancel: cancelSimple,
      sent: sentSimple,
    });
    setLoadingSimple(false);

    let totalMaster;
    let acceptedMaster = tele.filter(
      (f) => f.type === 'Master' && f.status === 'ACCEPTED'
    ).length;
    let rejectedMaster = tele.filter(
      (f) => f.type === 'Master' && f.status === 'REJECTED'
    ).length;
    let cancelMaster = tele.filter(
      (f) => f.type === 'Master' && f.status === 'CANCELLED'
    ).length;
    let sentMaster = tele.filter(
      (f) => f.type === 'Master' && f.status === 'SENT'
    ).length;
    totalMaster = acceptedMaster + rejectedMaster + cancelMaster + sentMaster;

    setMaster({
      total: totalMaster,
      accepted: acceptedMaster,
      rejected: rejectedMaster,
      cancel: cancelMaster,
      sent: sentMaster,
    });
    setLoadingMaster(false);

    let totalH;
    let acceptedH = tele.filter(
      (f) => f.type === 'Consolidation' && f.status === 'ACCEPTED'
    ).length;
    let rejectedH = tele.filter(
      (f) => f.type === 'Consolidation' && f.status === 'REJECTED'
    ).length;
    let cancelH = tele.filter(
      (f) => f.type === 'Consolidation' && f.status === 'CANCELLED'
    ).length;
    let sentH = tele.filter(
      (f) => f.type === 'Consolidation' && f.status === 'SENT'
    ).length;
    totalH = acceptedH + rejectedH + cancelH + sentH;
    setHouse({
      total: totalH,
      accepted: acceptedH,
      rejected: rejectedH,
      cancel: cancelH,
      sent: sentH,
    });
    setLoadingHouse(false);
    setTotal({
      total: totalSimple + totalMaster + totalH,
      accepted: acceptedSimple + acceptedMaster + acceptedH,
      rejected: rejectedSimple + rejectedMaster + rejectedH,
      cancel: cancelSimple + cancelMaster + cancelH,
      sent: sentSimple + sentMaster + sentH,
    });
    setLoadingTotal(false);

    let telemetry = await feathers.service('telemetry').find({
      query: {
        $agregation: {
          startDate: query.from,
          endDate: query.to,
          company: JSON.parse(localStorage.profile).company,
          role: JSON.parse(localStorage.profile).role,
        },
      },
    });
    let data = [];
    if (telemetry.length > 0) {
      data = telemetry[0].telemetry;
    }

    topFive(data);
    return;
  };

  const topFive = (e) => {
    let shipperOcc = [];
    let consegneeOcc = [];
    let originOcc = [];
    let destinationOcc = [];
    let shipperTp = e
      .filter((f) => f.shipper)
      .reduce((a, b) => {
        a[b.shipper] = a[b.shipper] || 0;
        a[b.shipper]++;
        return a;
      }, {});
    let consegneeTp = e
      .filter((f) => f.shipper)
      .reduce((a, b) => {
        a[b.consegnee] = a[b.consegnee] || 0;
        a[b.consegnee]++;
        return a;
      }, {});
    let originTp = e
      .filter((f) => f.type === 'airport')
      .reduce((a, b) => {
        a[b.airOrigin] = a[b.airOrigin] || 0;
        a[b.airOrigin]++;
        return a;
      }, {});
    let destinationTp = e
      .filter((f) => f.type === 'airport')
      .reduce((a, b) => {
        a[b.airArrival] = a[b.airArrival] || 0;
        a[b.airArrival]++;
        return a;
      }, {});

    _.forIn(shipperTp, (v, t) => {
      shipperOcc.push({ name: t, transactions: parseInt(v) });
    });
    _.forIn(consegneeTp, (v, t) => {
      consegneeOcc.push({ name: t, transactions: v });
    });
    _.forIn(originTp, (v, t) => {
      originOcc.push({ name: t, transactions: v });
    });
    _.forIn(destinationTp, (v, t) => {
      destinationOcc.push({ name: t, transactions: v });
    });

    setShippers(
      shipperOcc.sort((a, b) => a.transactions < b.transactions).slice(0, 5)
    );
    setLoadingShippers(false);
    setConsegnees(
      consegneeOcc.sort((a, b) => a.transactions < b.transactions).slice(0, 5)
    );
    setLoadingConsegnees(false);
    setOrigin(
      originOcc.sort((a, b) => a.transactions < b.transactions).slice(0, 5)
    );
    setLoadingOrigins(false);
    setDestination(
      destinationOcc.sort((a, b) => a.transactions < b.transactions).slice(0, 5)
    );
    setLoadingDestinations(false);
  };

  const period = async (e) => {
    setTotal({});
    setMaster({});
    setSimple({});
    setHouse({});
    let query = {};
    query.to = Date.now();

    if (e === 'Last seven days') {
      query.from = new Date().setDate(new Date().getDate() - 7);
    } else if (e === 'Last month') {
      query.from = new Date().setDate(new Date().getDate() - 30);
    } else if (e === 'Last three months') {
      query.from = new Date().setDate(new Date().getDate() - 90);
    } else {
      query.from = new Date().setHours(
        new Date().getHours() - new Date().getHours()
      );
    }

    getTelemetry(query);
  };

  useEffect(() => {
    setTotal({});
    setLoadingTotal(true);
    setMaster({});
    setLoadingMaster(true);
    setSimple({});
    setLoadingSimple(true);
    setHouse({});
    setLoadingHouse(true);
    if (range.from === '' && range.to === '') {
      range.from = fromDate;
      range.to = toDate;
    } else {
      if (typeof range.from !== 'number') fromDate = Date.parse(range.from);
      if (typeof range.to !== 'number') {
        toDate = moment(range.to);
        toDate = Date.parse(toDate);
      }
      async function tttt() {
        if (await checkToken()) {
          getTelemetry({
            from: fromDate,
            to: toDate,
          });
        }
      }
      tttt();
    }
  }, [range]);

  return (
    <Fragment>
      {/* <Grid>
        <div className="md-cell md-cell--5">
          <span style={{ fontSize: 35, color: 'gray' }}>Dashboard</span>
        </div>

        {/* <DatePicker
          id="inline-date-picker-portait"
          label="from"
          selected={range.from}
          maxDate={new Date(range.to)}
          value={moment(range.from).format('L')}
          defaultValue={moment(range.from).format('L')}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={e => setRange({ ...range, from: e })}
          selectsStart
          startDate={range.from}
          endDate={range.to}
        />
        <DatePicker
          id="inline-date-picker-portait-1"
          label="to"
          selected={range.to}
          value={moment(range.to).format('L')}
          defaultValue={moment(range.to).format('L')}
          minDate={new Date(range.from)}
          maxDate={new Date(Date.now())}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={e => setRange({ ...range, to: e })}
          selectsEnd
          startDate={range.from}
          endDate={range.to}
        /> */}
      {/* <SelectField
            id="select-field-default-value"
            label="Period"
            defaultValue="Today"
            menuItems={[
              'Today',
              'Last seven days',
              'Last month',
              'Last three months'
            ]}
            className="md-cell md-cell--2"
            onChange={e => {
              period(e);
            }}
            position="br"
          /> 
      </Grid> */}
      <Grid style={{ alignItems: 'center' }}>
        <Cell size={6}>
          <span style={{ fontSize: 35, color: 'gray' }}>Dashboard</span>
        </Cell>
        <Cell
          size={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <UtilityButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              onClick={async (e) => {
                if (await checkToken()) {
                  props.history.push('/upload-flatfile');
                }
              }}
            >
              Upload CSV
            </Button>
          </UtilityButtonWrapper>

          <UtilityButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={async (e) => {
                if (await checkToken()) {
                  props.history.push('/create-manifest');
                }
              }}
            >
              New Airway Bill
            </Button>
          </UtilityButtonWrapper>
          <UtilityButtonWrapper>
            <DatePickerRange
              startDate={range.from}
              endDate={range.to}
              dates={setDates}
            />
          </UtilityButtonWrapper>
        </Cell>
        <Cell size={12}>
          <Notifications />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={3} phoneSize={12} tabletSize={4}>
          <PieChartCard
            loading={loadingTotal}
            value={total}
            title="Documents"
            id={1}
          />
        </Cell>

        <Cell size={3} phoneSize={12} tabletSize={4}>
          <PieChartCard
            loading={loadingMaster}
            value={master}
            title="Master"
            id={2}
          />
        </Cell>
        <Cell size={3} phoneSize={12} tabletSize={4}>
          <PieChartCard
            loading={loadingSimple}
            value={simple}
            title="Simple"
            id={3}
          />
        </Cell>
        <Cell size={3} phoneSize={12} tabletSize={4}>
          <PieChartCard
            loading={loadingHouse}
            value={house}
            title="House"
            id={4}
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={3} phoneSize={12} tabletSize={4}>
          <TopTableCard
            loading={loadingShippers}
            value={shippers}
            title="Shippers"
            id={1}
          />
        </Cell>
        <Cell size={3} phoneSize={12} tabletSize={4}>
          <TopTableCard
            loading={loadingConsegnees}
            value={consegnees}
            title="Consignees"
            id={2}
          />
        </Cell>
        <Cell size={3} phoneSize={12} tabletSize={4}>
          <TopTableCard
            loading={loadingOrigins}
            value={origins}
            title="Origin Airports"
            id={3}
          />
        </Cell>
        <Cell size={3} phoneSize={12} tabletSize={4}>
          <TopTableCard
            value={destinations}
            title="Destination Airports"
            loading={loadingDestinations}
            id={4}
          />
        </Cell>
      </Grid>
    </Fragment>
  );
};
const UtilityButtonWrapper = styled.div`
  margin-left: 16px;
`;
