import { Grid, Cell, SelectField, DatePicker, Button, FontIcon, Chip, Card, TextField, CardText, DataTable, TableBody, TableRow, TableColumn, TableHeader, TablePagination, EditDialogColumn } from "react-md";
import React, { Fragment, useEffect, useState } from "react";
import { useApi } from "../services/api";
import 'chart.js'
import moment from "moment";

export default (props) => {
    const [, , fetchDocuments,] = useApi('ccapi', 'documents/search', undefined, 'get')
    const [range, setRange] = useState({ from: new Date().setDate(new Date().getDate() - 1), to: Date.now() })
    const [chips, setType] = useState([])
    const [awb, setAwb] = useState([])
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [rows, setRows] = useState(50)
    const [filter, setFilter] = useState([])
    const [value, setValue] = useState({ serialNumber: '', hawbNumber: '', shipper: '', consignee: '', flight: '', eta: '', destination: '', origin: '' })

    useEffect(() => {
        getDocuments({ from: new Date().setHours(new Date().getHours() - new Date().getHours()), to: Date.now() })
    }, [])

    useEffect(() => {
        setLoading(false)
    }, [loading])

    const getDocuments = async (query) => {
        const [searchResult, err] = await fetchDocuments({ query: query })
        if (err) return
        setFilter(searchResult.slice(0, 50))
        setData(searchResult)
    }

    const applyFilter = () => {
        let query = {}
        let from = new Date(range.from)
        let to = new Date(range.to)
        query.to = to.setDate(to.getDate())
        query.from = from.setDate(from.getDate())
        query.types = chips
        query.awb = awb
        getDocuments(query)
    }

    const fillType = (e) => {
        e = e.match(/\(([^)]+)\)/)[1]
        let t = chips
        if (t.includes(e)) return
        t.push(e)
        setType(t)
        setLoading(true)
    }

    const removeType = (e) => {
        let t = chips
        t.splice(t.indexOf(e), 1)
        setType(t)
        setLoading(true)
    }

    const handlePagination = (start, rowsPerPage) => {
        setRows(rowsPerPage)
        setFilter(data.slice(start, start + rowsPerPage));
    };

    const handleEvent = (e, path, segment) => {
        setValue({ [path]: e })
        setFilter(data.filter(f => f.docOrigin[segment][path].toUpperCase().includes(e)))
    }

    return (
        <Fragment>
            <Grid>
                {
                    chips.length ? chips.map((f, i) => (
                        <div className="chips__list" key={i}>
                            <Chip label={f} removable onClick={e => removeType(f)} style={{ marginRight: 5 }} />
                        </div>
                    )) : null
                }
            </Grid>
            <Grid style={{ paddingTop: 0 }}>
                <Cell size={3} phoneSize={12} tabletSize={12}>
                    <span style={{ fontSize: 35, color: 'gray' }}>Global Search</span>

                </Cell>
                <DatePicker
                    id="inline-date-picker-portait"
                    label="from"
                    value={moment(range.from).format('L')}
                    defaultValue={moment(range.from).format('L')}
                    maxDate={new Date(range.to)}
                    className="md-cell md-cell--2"
                    locales="en-US"
                    inline
                    displayMode="portrait"
                    fullWidth={false}
                    autoOk
                    onChange={e => setRange({ ...range, from: e })}
                />
                <DatePicker
                    id="inline-date-picker-portait"
                    label="to"
                    value={moment(range.to).format('L')}
                    defaultValue={moment(range.to).format('L')}
                    minDate={new Date(range.from)}
                    className="md-cell md-cell--2"
                    locales="en-US"
                    inline
                    displayMode="portrait"
                    fullWidth={false}
                    autoOk
                    onChange={e => setRange({ ...range, to: e })}
                />

                <TextField
                    id="floating-center-title"
                    label="AirWay Bill"
                    lineDirection="center"
                    className="md-cell md-cell--2"
                    onChange={e => setAwb(e)}
                />
                <SelectField
                    id="select-field-default-value"
                    label="Type"
                    menuItems={['ACE Air eManifest (FRI)', 'Changes (FRC)', 'Cancellations (FRX)', 'ACE Air eManifest express (FXI)', 'Express Changes (FXC)', 'Express Cancellations (FXX)', 'Departure Messages (FDM)', 'Status Query (FSQ)', 'Notifications (FSN)']}
                    className="md-cell md-cell--2"
                    onChange={e => fillType(e)}
                    position="br"
                />
                <Button className="md-cell" style={{ marginTop: 25 }} icon iconEl={<FontIcon secondary className="fas fa-search" onClick={applyFilter} />} />
            </Grid>
            <Grid>
                {
                    filter ? (
                        <Cell size={12}>
                            <Card raise tableCard>
                                <CardText>
                                    <DataTable baseId="simple-pagination" fixedDividers selectableRows={false}>
                                        <TableHeader>
                                            <TableRow>
                                                <TableColumn style={{ width: 100 }}>Doc Type</TableColumn>
                                                <EditDialogColumn style={{ width: 100 }} textFieldStyle={{ width: 100 }} placeholder="AWB #" onChange={e => handleEvent(e, 'serialNumber', 'AWB')} value={value.serialNumber} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                                <EditDialogColumn style={{ width: 100 }} textFieldStyle={{ width: 100 }} placeholder="HAWB #" onChange={e => handleEvent(e, 'hawbNumber', 'AWB')} value={value.hawbNumber} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                                <EditDialogColumn style={{ width: 100 }} textFieldStyle={{ width: 100 }} placeholder="Shipper" onChange={e => handleEvent(e.toUpperCase(), 'name', 'SHP')} value={value.shipper} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                                <EditDialogColumn style={{ width: 100 }} textFieldStyle={{ width: 100 }} placeholder="Consignee" onChange={e => handleEvent(e.toUpperCase(), 'name', 'CNE')} value={value.consignee} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                                <TableColumn style={{ width: 100 }}>Piece Count</TableColumn>
                                                <TableColumn style={{ width: 100 }}>Weight</TableColumn>
                                                <EditDialogColumn style={{ width: 100 }} textFieldStyle={{ width: 100 }} placeholder="Origin" onChange={e => handleEvent(e.toUpperCase(), 'airportOrigin', 'WBL')} value={value.origin} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                                <EditDialogColumn style={{ width: 100 }} textFieldStyle={{ width: 100 }} placeholder="Destination" onChange={e => handleEvent(e.toUpperCase(), 'arrivalAirport', 'CCL')} value={value.destination} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {
                                                filter.map((content, i) => (
                                                    < TableRow key={i} >
                                                        {
                                                            < Fragment >
                                                                <TableColumn className="column-style">{content.docOrigin.SMI.componentId}</TableColumn>
                                                                <TableColumn className="column-style">{content.docOrigin.AWB ? content.docOrigin.AWB.prefix + '-' + content.docOrigin.AWB.serialNumber : ''}</TableColumn>
                                                                <TableColumn className="column-style">{content.docOrigin.AWB ? content.docOrigin.AWB.hawbNumber : ''}</TableColumn>
                                                                <TableColumn className="column-style">{content.docOrigin.SHP ? content.docOrigin.SHP.name && content.docOrigin.SHP.name.slice(0, 10) : ''}</TableColumn>
                                                                <TableColumn className="column-style">{content.docOrigin.CNE ? content.docOrigin.CNE.name && content.docOrigin.CNE.name.slice(0, 10) : ''}</TableColumn>
                                                                <TableColumn className="column-style">{content.docOrigin.WBL ? content.docOrigin.WBL.numPieces : ''}</TableColumn>
                                                                <TableColumn className="column-style">{content.docOrigin.WBL ? content.docOrigin.WBL.weight + '' + content.docOrigin.WBL.weightCode : ''}</TableColumn>
                                                                <TableColumn className="column-style">{content.docOrigin.WBL ? content.docOrigin.WBL.airportOrigin : ''}</TableColumn>
                                                                <TableColumn className="column-style">{content.docOrigin.CCL ? content.docOrigin.CCL.arrivalAirport : ''}</TableColumn>
                                                            </Fragment>
                                                        }
                                                    </TableRow>
                                                ))
                                            }
                                        </TableBody>
                                        <TablePagination rows={data.length} rowsPerPageLabel="rows" rowsPerPage={rows} onPagination={handlePagination} />
                                    </DataTable>
                                </CardText>
                            </Card>
                        </Cell>
                    ) : null
                }
            </Grid>
        </Fragment>
    )
}
