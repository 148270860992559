import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, LinearProgress } from '@material-ui/core';
import Lottie from 'react-lottie';
import Upload from '../../animations/upload.json';

const UploadModal = ({
  open,
  legend,
  progress,
  progressError,
  legendError,
}) => (
  <Modal
    maxWidth="xl"
    open={open}
    onEscapeKeyDown={false}
    id="upload-modal-progress"
  >
    <Body>
      <Animation>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: Upload,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          width={800}
          height={480}
          isClickToPauseDisabled={true}
        />
      </Animation>
      <Progress variant="determinate" value={progress} />
      <Text>{`${legend} - ${progress}%`}</Text>
      {legendError && progressError && (
        <>
          <Progress
            variant="determinate"
            color="secondary"
            value={progressError}
          />
          <Text>{`${legendError} - ${progressError}%`}</Text>
        </>
      )}
      <Help>Don't close this tab or progress will be missed!</Help>
    </Body>
  </Modal>
);

const Modal = styled(Dialog)``;

const Body = styled(DialogContent)``;

const Animation = styled.div`
  margin-bottom: 20px;
`;

const Progress = styled(LinearProgress)``;

const Text = styled.div`
  margin: 10px;
  text-align: center;
`;

const Help = styled.div`
  margin: 10px;
  text-align: center;
  color: lightgray;
`;

export default UploadModal;
