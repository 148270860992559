import React from 'react';
import { CallMade, CallReceived, Help } from '@material-ui/icons';

const capitalize = (str) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const Icon = (v) => {
  switch (v) {
    case 'response':
      return <CallReceived />;
    case 'request':
      return <CallMade />;
    default:
      return <Help />;
  }
};

const Type = ({ value }) => (
  <div>
    {Icon(value)}
    {capitalize(value)}
  </div>
);

export default Type;
