import io from 'socket.io-client';
import feathers from '@feathersjs/client';

const socket = io(process.env.REACT_APP_EDI_FEATHERS_URL);
const app = feathers();

app.configure(
  feathers.socketio(socket, {
    timeout: 90000
  })
);

export default app;
