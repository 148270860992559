import React from 'react';
import ReactDOM from 'react-dom';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import lightBlue from '@material-ui/core/colors/lightBlue';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#019EE1', // This is an orange looking color
    },
    secondary: {
      main: lightBlue[600], //Another orange-ish color
    },
  },
});

Sentry.init({
  dsn: 'https://e5ecc45abcdf43cf86e79a329e501cc7@o566704.ingest.sentry.io/5776287',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  environment: process.env.NODE_ENV,
  // debug: process.env.NODE_ENV === 'development' ? true : false,
  tracesSampleRate: 1.0,
});

// window.onerror = async function (msg, url, lineNo, columnNo, error) {
//   let userData = await localStorage.getItem('profile');
//   let user = (userData && JSON.parse(userData)) || null;

//   Sentry.captureException(error, async (scope) => {
//     if (user) {
//       scope.setUser(user);
//     }

//     return scope;
//   });
// };

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <App />
    </Router>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
