import {
  Grid,
  Cell,
  TextField,
  Subheader,
  SelectField,
  CardText,
  Autocomplete,
} from 'react-md';
import React, { Fragment, useEffect, useState } from 'react';
import { throttle } from 'lodash/function';
import Button2 from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DatePicker from 'react-datepicker';
import feathers from '../../services/feathers';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

export default ({
  arr,
  setArr,
  asn,
  setAsn,
  ccl,
  setCcl,
  startDate,
  setStartDate,
  airlines,
  setAirlines,
  usCodes,
  setUsCodes,
  terminal,
  setTerminal,
  disabledArrivalRef,
}) => {
  const CustomInput = ({ value, onClick }) => (
    <Button2 variant="outlined" onClick={onClick}>
      {arr.arrivalDate ? arr.arrivalDate : value}
    </Button2>
  );
  // let airlineText = ;
  const [airlineText, setAirlineText] = useState(null);
  const [terminalOpText, setTerminalOpText] = useState(null);
  const [arrivalAirportText, setArrivalAirportText] = useState(null);

  useEffect(() => {
    setDateFirstTime();
  }, [arr, ccl]);

  const setDateFirstTime = async () => {
    if (arr.impCarrier && arr.impCarrier !== '')
      await searchAIR(arr.impCarrier, true);
    if (ccl.terminalOp && ccl.terminalOp !== '')
      await searchTerminal(ccl.terminalOp, true);
    if (ccl.arrivalAirport && ccl.arrivalAirport !== '')
      await searchCCL(ccl.arrivalAirport, true);
    if (arr.arrivalDate === '') {
      let date = moment(new Date()).format('DDMMM').toUpperCase();
      setArr({ ...arr, arrivalDate: date });
    }
  };

  ////  AutoComplete ////
  const searchAIR = throttle(async (query, setValue = false) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });
    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt:
              value.iata === ''
                ? value.iata + '-' + value.icao
                : value.iata + '-' + value.icao,
          }))
        : null;

      setAirlines(air);
      if (setValue) {
        setAirlineText(air[0].find);
      }
    }
  }, 250);

  const searchCCL = throttle(async (query, setValue = false) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $ccl: query,
      },
    });
    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt:
              value.iata === 'N'
                ? value.icao + '-' + value.iata
                : value.icao + '-' + value.iata,
          }))
        : null;
      setUsCodes(ccl);
      if (setValue) {
        setArrivalAirportText(ccl[0].find);
      }
    }
  }, 250);

  const searchTerminal = throttle(async (query, setValue = false) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });

    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt:
              value.iata === ''
                ? value.iata + '-' + value.icao
                : value.iata + '-' + value.icao,
          }))
        : null;
      setTerminal(air);
      if (setValue) {
        setTerminalOpText(air[0].find);
      }
    }
  }, 250);

  const dateInput = (e) => {
    let date = moment(e).format('DDMMM').toUpperCase();
    setArr({ ...arr, arrivalDate: date });
  };

  return (
    <Fragment>
      <CardText>
        <Subheader className="md-grid" primary primaryText="Flight Details" />
        <Grid>
          <Cell size={3} tabletSize={2}>
            {airlines !== undefined ? (
              <Autocomplete
                id="codes-airline"
                required
                label="Airline"
                data={airlines.slice(0, 30)}
                dataLabel={'find'}
                dataValue={'txt'}
                filter={null}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                value={airlines.name ? airlines.name : airlineText}
                onChange={(e) => {
                  searchAIR(e);
                  setArr({
                    ...arr,
                    impCarrier: e.toUpperCase(),
                  });
                  setAirlineText(e.toUpperCase());
                }}
                onAutocomplete={(v) => {
                  let find = v.split('-');
                  let c = airlines;
                  setArr({
                    ...arr,
                    impCarrier: v === '' ? c[0].icao : find[0] || find[1],
                  });
                }}
              />
            ) : null}
          </Cell>
          <Cell size={3} tabletSize={3}>
            <TextField
              id="floating-center-title"
              value={arr.flightNumber}
              onChange={(e) => {
                const re = /^[0-9\b]*$/;
                if (e === '' || re.test(e)) {
                  if (e.length <= 8) {
                    setArr({ ...arr, flightNumber: e });
                  }
                }
              }}
              label="Flight #"
              required
              lineDirection="center"
            />
          </Cell>
          <Cell size={3} tabletSize={3}>
            {usCodes !== undefined ? (
              <Autocomplete
                id="all-codes-arrival"
                required
                label="Arrival Airport"
                data={usCodes.slice(0, 30)}
                dataLabel={'find'}
                dataValue={'txt'}
                filter={null}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                value={usCodes.name ? usCodes.name : arrivalAirportText}
                onChange={(e) => {
                  searchCCL(e);
                  setCcl({
                    ...ccl,
                    arrivalAirport: e.toUpperCase(),
                  });
                  setArrivalAirportText(e.toUpperCase());
                }}
                onAutocomplete={(v) => {
                  let find = v.split('-');
                  let c = usCodes;
                  setCcl({
                    ...ccl,
                    arrivalAirport: v === 'N' ? c[0].icao : find[1],
                  });
                }}
              />
            ) : null}
          </Cell>
          <Cell size={3} tabletSize={3}>
            {terminal !== undefined ? (
              <Autocomplete
                id="codes-terminal-operator"
                required
                label="Terminal Operator (Airline)"
                data={terminal.slice(0, 30)}
                dataLabel={'find'}
                dataValue={'txt'}
                filter={null}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                value={terminal.name ? terminal.name : terminalOpText}
                onChange={(e) => {
                  if (e.length >= 1) {
                    searchTerminal(e);
                  }
                  setCcl({
                    ...ccl,
                    terminalOp: e.toUpperCase(),
                  });
                  setTerminalOpText(e.toUpperCase());
                }}
                onAutocomplete={(v) => {
                  let find = v.split('-');
                  let c = terminal;
                  setCcl({
                    ...ccl,
                    terminalOp: v === '' ? c[0].icao : find[0] || find[1],
                  });
                }}
              />
            ) : null}
          </Cell>
        </Grid>
        <Grid>
          <Cell size={3}>
            <Typography>Select a Date</Typography>
            <br></br>
            <DatePicker
              selected={startDate}
              dateFormat="ddMMM"
              name="arrivalDate"
              customInput={<CustomInput />}
              onChange={(e) => {
                setStartDate(e);
                dateInput(e);
              }}
              withPortal
            />
          </Cell>
          <SelectField
            id="floating-center-title"
            className="md-cell md-cell--3"
            value={arr.arrivalRef}
            onChange={(arrivalRef) => setArr({ ...arr, arrivalRef })}
            label="PARN #"
            style={{ maxHeight: '120px' }}
            menuItems={PARNs}
            lineDirection="center"
            disabled={disabledArrivalRef}
            // required
          />
          <SelectField
            id="floating-center-title"
            className="md-cell md-cell--6"
            onChange={(e) =>
              setAsn({ ...asn, statusCode: e.match(/[0-9]{1}/g)[0] })
            }
            label="FSN Reason Code"
            menuItems={code}
            lineDirection="center"
            required
          />
        </Grid>
      </CardText>
    </Fragment>
  );
};

const code = [
  '3 In-bond arrived at destination',
  //Sent by the bonded custodian at the CBP Control Destination
  '4 Local transfer of consolidated cargo',
  //to a Deconsolidator has been accomplished
  '7 In-bond exported at destination',
  //Sent by the bonded custodian at the CBP Control Destination
];

const PARNs = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
