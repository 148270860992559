import React from 'react';

const Link = ({ text, onClick, id }) => (
  <div id={id}>
    <a onClick={onClick} id={`link-${id}`} className="ag-grid-custom-link">
      {text}
    </a>
  </div>
);

export default Link;
