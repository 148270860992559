import React from 'react';
import styled from 'styled-components';
import { Dialog, DialogContent, LinearProgress } from '@material-ui/core';
import Lottie from 'react-lottie';
import Upload from '../../animations/upload.json';

export default props => {
  let { open, text, progress } = props;

  const lottieProps = {
    width: 800,
    height: 480,
    isClickToPauseDisabled: true,
    options: {
      loop: true,
      autoplay: true,
      animationData: Upload,
      rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    }
  };

  const progressProps = {
    variant: 'determinate'
  };

  return (
    <Modal
      maxWidth="xl"
      open={open}
      onEscapeKeyDown={false}
      id="upload-modal-progress"
    >
      <Body>
        <Lottie {...lottieProps} />
        <Progress value={progress} {...progressProps} />
        <Legend>{text}</Legend>
        <Warning>Don't close this tab or progress will be missed!</Warning>
      </Body>
    </Modal>
  );
};

const Modal = styled(Dialog)``;

const Body = styled(DialogContent)``;

const Legend = styled.div`
  margin: 10px;
  text-align: center;
`;
const Warning = styled.div`
  margin: 10px;
  text-align: center;
  color: lightgray;
`;
// const Animation = styled.div`
//   margin-bottom: 20px;
// `;

const Progress = styled(LinearProgress)``;
