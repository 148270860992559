import React, { Fragment } from 'react';
import { FSCMessage } from '../../../components/awb-history/Codes';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const StatusMessage = ({ value, ...restProps }) => {
  const { row } = restProps;
  if (row.docTranslated.name === 'FSC') {
    return (
      <Table.Cell
        {...restProps}
        style={{
          overflow: 'visible',
        }}
      >
        <div>
          {row.docTranslated.FSC
            ? FSCMessage(row.docTranslated.FSC.answerCode, true)
            : ''}
        </div>
      </Table.Cell>
    );
  } else {
    let error = row.docTranslated.ERR
      ? row.docTranslated.ERR.map(function (value) {
          return `${value.errorCode} - ${value.errorText} `;
        })
      : '';
    return (
      <Table.Cell
        {...restProps}
        style={{
          overflow: 'visible',
        }}
      >
        <div>
          {error.map((content, i) => (
            <Fragment>
              {content}
              <br />
            </Fragment>
          ))}
        </div>
      </Table.Cell>
    );
  }
};

export default StatusMessage;
