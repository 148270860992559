export const typeSchemas = {
  AMSTYPE86: 'amsair-type86',
  FSN: 'fsn',
  AMSCOM: 'amsair-complementary'
};

const airSchemasAll = [
  { type: typeSchemas.AMSTYPE86, title: 'Air AMS + Type 86' },
  { type: typeSchemas.FSN }
];
const airSchemasComplementary = [
  {
    type: typeSchemas.AMSCOM,
    title: 'AIR AMS COMPLEMENTARY INFORMATION'
  }
];

export const airSchemas = airSchemasAll.filter(item => item.title);
export const airSchemasOnly = airSchemasAll.filter(item => !item.title);
export const airSchemasComp = airSchemasComplementary.filter(
  item => item.title
);
