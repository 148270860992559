import {
  Cell,
  TextField,
  Subheader,
  Grid,
  SelectField,
  Switch,
  Autocomplete,
} from 'react-md';
import React, { Fragment, useState, useEffect } from 'react';
import { throttle } from 'lodash/function';
import feathers from '../../../services/feathers';
export default (props) => {
  // console.log('props cargo:', props);
  const {
    role,
    status,
    cargoCountryRef,
    cargoCurrencyRef,
    marksAndNumbers,
    setMarksAndNumbers,
  } = props;
  const [fdaInd, setFdaIndicator] = useState(false);
  const [find, setFind] = useState('code');
  const [currencies, setCurrencies] = useState([]);

  const searchCSD = throttle(async (query) => {
    let countries = await feathers.service('countries').find({
      query: {
        $type: 'Country',
        $csd: query,
      },
    });
    if (countries.length > 0) {
      let csd = [];

      csd = countries.length
        ? countries.map((value) => ({
            ...value,
            find: value.code + ' - ' + value.name,
            txt: value.code,
          }))
        : null;
      props.setCountries(csd);
    }
    // if (countries.length) {
    //   let csd = [];
    //   for (const val of countries) {
    //     csd.push(val.country);
    //   }

    //   props.setCountries(csd);
    // }
  }, 250);

  const search = throttle(async (query) => {
    let currency = await feathers.service('currencies').find({
      query: {
        $agregation: {},
        $curr: query,
      },
    });
    if (currency.length > 0) {
      let curr = [];

      curr = currency.length
        ? currency.map((value) => ({
            ...value,
            find: value.code + ' - ' + value.name,
            txt: value.code,
          }))
        : null;
      props.setCurrency(curr);
    }
    // if (currency.length > 0) {
    //   let curr = [];

    //   curr = currency.length
    //     ? currency.map(value => ({
    //         ...value.currency,
    //         find:
    //           value.currencies[0].currencycode +
    //           ' - ' +
    //           value.currencies[0].currency,
    //         txt: value.currencies[0].currencycode
    //       }))
    //     : null;
    //   props.setCurrency(curr);
    // }
    // if (currency.length) {
    //   let curr = [];
    //   for (const val of currency) {
    //     curr.push(val.country);
    //   }

    //   props.setCurrency(curr);
    // }
  }, 250);

  useEffect(() => {
    if (fdaInd) {
      props.setFda({ ...props.fda, lineId: 'FDA' });
      return;
    }
    props.setFda({ ...props.fda, lineId: '' });
  }, [fdaInd]);
  // useEffect(() => {
  //   let currencies2 = [];
  //   if (props.countries.length > 0) {
  //     for (const x of props.countries[0].currencies) {
  //       currencies2.push({
  //         label: `${x.currencycode} - ${x.currency}`,
  //         value: `${x.currencycode}`
  //       });
  //     }
  //     let isUs = props.countries[0].code !== 'US' ? true : false;
  //     if (isUs) {
  //       currencies2.push({
  //         label: `USD - US DOLLAR`,
  //         value: `USD`
  //       });
  //     }
  //     setCurrencies(currencies2);
  //   }
  // }, [props.countries]);
  const searchHts = throttle(async (query) => {
    let htsnumber = await feathers.service('hts').find({
      query: {
        $agregation: {},
        $hts: query,
      },
    });
    if (htsnumber.length > 0) {
      let csd = [];

      csd = htsnumber.length
        ? htsnumber.map((value) => ({
            ...value,
            find: value.hts_number + ' - ' + value.description,
          }))
        : null;
      props.setHts(csd);
    }
  }, 250);

  return (
    <Fragment>
      <Grid>
        <Switch
          id="switch-fda"
          className="md-cell md-cell--3"
          style={{ marginTop: 28 }}
          type="switch"
          label="FDA Indicator"
          name="fda"
          onChange={(e) => {
            if (props.setChange) {
              props.setChange(false);
            }
            setFdaIndicator(!fdaInd);
          }}
          disabled={
            status === 'DRAFT' ||
            status === 'ACCEPTED' ||
            status === 'REJECTED' ||
            status === 'SENDING'
              ? false
              : true
          }
        />
        <Cell size={3}>
          <TextField
            id="floating-center-title-piece-count"
            value={props.wbl.numPieces ? props.wbl.numPieces : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 5) {
                props.setWbl({ ...props.wbl, numPieces: e.toUpperCase() });
              }
            }}
            label="Piece Count"
            lineDirection="center"
            required
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            type="number"
          />
        </Cell>
        <Cell size={3}>
          <TextField
            id="floating-center-title-weight"
            value={props.wbl.weight ? props.wbl.weight : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 7) {
                props.setWbl({ ...props.wbl, weight: e.toUpperCase() });
              }
            }}
            label="Weight"
            lineDirection="center"
            required
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            type="number"
          />
        </Cell>
        <Cell size={2}>
          <SelectField
            id="select-field-k-l"
            value={props.wbl.weightCode ? props.wbl.weightCode : ''}
            style={{ display: 'block' }}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              props.setWbl({ ...props.wbl, weightCode: e.toUpperCase() });
            }}
            label="LB or KGM"
            menuItems={['L', 'K']}
            simplifiedMenu={true}
            position={SelectField.Positions.BOTTOM_RIGHT}
            required
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
          <input
            ref={props.weightCodeCargoRef}
            type="text"
            value={props.wbl.weightCode ? props.wbl.weightCode : ''}
            required
            onChange={() => {}}
            style={{
              marginTop: '-20px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
      </Grid>
      <Grid>
        {props.delete ? (
          <Fragment>
            <Cell size={3}>
              {props.countries !== undefined ? (
                <Autocomplete
                  id="codes-origingoods"
                  label="Country of Origin"
                  data={props.countries}
                  dataLabel={'find'}
                  dataValue={'txt'}
                  findInlineSuggestion={Autocomplete.findIgnoreCase}
                  value={
                    props.autocompletes
                      ? props.autocompletes.originGoods
                      : props.countries.length > 0
                      ? props.countries.code
                      : ''
                  }
                  onChange={(e) => {
                    if (props.setChange) {
                      props.setChange(false);
                    }

                    searchCSD(e);
                    if (e.length < 1) {
                      props.setCsd({
                        ...props.csd,
                        originGoods: e.toUpperCase(),
                        currencyCode: '',
                      });
                      // props.setCsd({
                      //   ...props.csd,
                      //   currencyCode: ''
                      // });
                    } else {
                      props.setCsd({
                        ...props.csd,
                        originGoods: e.toUpperCase(),
                      });
                    }
                    if (props.autocompletes) {
                      props.setAutocompletes({
                        ...props.autocompletes,
                        originGoods: e.toUpperCase(),
                      });
                    }
                  }}
                  onAutocomplete={async (v) => {
                    // props.setCsd({ ...props.csd, originGoods: v });
                    props.csd.originGoods = v;
                    let f = props.countries.filter((x) => x.code === v)[0];
                    // props.setAutocompletes({
                    //   ...props.autocompletes,
                    //   originGoods: f.find
                    // });
                    if (props.autocompletes) {
                      props.setAutocompletes({
                        ...props.autocompletes,
                        originGoods: f.find,
                      });
                      // props.autocompletes.originGoods = f.find;
                    }
                    // let country = props.countries.filter(x => x.code == v)[0];
                    // let currencies2 = [];
                    // let isUs = true;
                    // for (const x of country.currencies) {
                    //   currencies2.push({
                    //     label: `${x.currencycode} - ${x.currency}`,
                    //     value: `${x.currencycode}`
                    //   });
                    //   if (x.currencycode === 'USD') {
                    //     isUs = false;
                    //   }
                    // }
                    // if (isUs) {
                    //   currencies2.push({
                    //     label: `USD - US DOLLAR`,
                    //     value: `USD`
                    //   });
                    // }
                    // setCurrencies(currencies2);
                    props.setCsd({
                      ...props.csd,
                      currencyCode: 'USD',
                    });
                    // console.log('object :', f.find, v, props);
                  }}
                  disabled={
                    (status !== 'DRAFT' && role === 'shipper') ||
                    status === 'SENT'
                  }
                />
              ) : null}
              <input
                ref={cargoCountryRef}
                type="text"
                value={props.csd.originGoods ? props.csd.originGoods : ''}
                required={props.csd.originGoods.length > 1 ? true : false}
                onChange={() => {}}
                style={{
                  marginTop: '-23px',
                  position: 'absolute',
                  zIndex: '-1',
                }}
              ></input>
              {/* <TextField
                id="floating-center-title"
                value={props.csd.originGoods}
                onChange={e =>
                  props.setCsd({ ...props.csd, originGoods: e.toUpperCase() })
                }
                label="Country of Origin"
                lineDirection="center"
              /> */}
            </Cell>
            <Cell size={3}>
              <TextField
                id="floating-center-title-csd-value"
                value={props.csd.declaredValue ? props.csd.declaredValue : ''}
                type="number"
                required={props.csd.originGoods.length > 0 ? true : false}
                onChange={(e) => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  if (e.length <= 12) {
                    props.setCsd({
                      ...props.csd,
                      declaredValue: e.toUpperCase(),
                    });
                  }
                }}
                label="Value"
                lineDirection="center"
                disabled={
                  (status !== 'DRAFT' && role === 'shipper') ||
                  (props.csd.originGoods != '' && status === 'DRAFT')
                    ? false
                    : props.csd.originGoods === '' && status === 'DRAFT'
                    ? true
                    : // status === 'DRAFT' ||
                    (status === 'ACCEPTED' && props.csd.originGoods != '') ||
                      (status === 'REJECTED' && props.csd.originGoods != '')
                    ? false
                    : (status === 'ACCEPTED' && props.csd.originGoods === '') ||
                      (status === 'REJECTED' && props.csd.originGoods === '')
                    ? true
                    : (props.csd.originGoods != '' && status === 'SENT') ||
                      (props.csd.originGoods === '' && status === 'SENT')
                    ? true
                    : false
                }
              />
            </Cell>
            <Cell size={3}>
              {props.currency !== undefined ? (
                <Autocomplete
                  id="currencyodes"
                  label="Currency"
                  data={props.currency.slice(0, 10)}
                  dataLabel={'find'}
                  dataValue={'txt'}
                  filter={Autocomplete.fuzzyFilter}
                  findInlineSuggestion={Autocomplete.findIgnoreCase}
                  value={
                    props.autocompletesCurr
                      ? props.autocompletesCurr.currencyCode
                      : props.currency.code
                  }
                  defaultValue={'USD - US DOLLAR'}
                  onChange={(e) => {
                    // setFind(e.length >= 4 ? 'currency' : 'currencycode');
                    if (props.setChange) {
                      props.setChange(false);
                    }
                    search(e);
                    if (e.length < 1) {
                      props.setCsd({
                        ...props.csd,
                        currencyCode: '',
                      });
                    } else {
                      props.setCsd({
                        ...props.csd,
                        currencyCode: e.toUpperCase(),
                      });
                    }
                    if (props.autocompletesCurr) {
                      props.setAutocompletesCurr({
                        ...props.autocompletesCurr,
                        currencyCode: e.toUpperCase(),
                      });
                    }
                  }}
                  onAutocomplete={async (v) => {
                    // setFind('currency');
                    // // console.log('props.countries :', props.countries);
                    // props.setCsd({ ...props.csd, currencyCode: v });
                    props.csd.currencyCode = v;
                    let f = props.currency.filter((x) => x.code === v)[0];
                    if (props.autocompletesCurr) {
                      props.setAutocompletesCurr({
                        ...props.autocompletesCurr,
                        currencyCode: f.find,
                      });
                      // props.autocompletesCurr.currencyCode = f.find;
                    }
                  }}
                  disabled={
                    props.csd.originGoods != '' && status === 'DRAFT'
                      ? false
                      : props.csd.originGoods === '' && status === 'DRAFT'
                      ? true
                      : (props.csd.originGoods != '' && status === 'SENT') ||
                        (props.csd.originGoods === '' && status === 'SENT')
                      ? true
                      : false || (status !== 'DRAFT' && role === 'shipper')
                  }
                />
              ) : null}
              <input
                ref={cargoCurrencyRef}
                type="text"
                value={props.csd.currencyCode ? props.csd.currencyCode : ''}
                required={props.csd.currencyCode.length > 1 ? true : false}
                onChange={() => {}}
                style={{
                  marginTop: '-23px',
                  position: 'absolute',
                  zIndex: '-1',
                }}
              ></input>

              {/* <SelectField
                id="select-field-k-l"
                value={props.csd.currencyCode ? props.csd.currencyCode : ''}
                style={{ display: 'block' }}
                onChange={e => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  props.setCsd({ ...props.csd, currencyCode: e });
                }}
                disabled={currencies.length === 0}
                label="Currency Code"
                menuItems={currencies}
                simplifiedMenu={true}
                position={SelectField.Positions.BOTTOM_RIGHT}
                required
                disabled={
                  status === 'DRAFT' ||
                  status === 'ACCEPTED' ||
                  status === 'REJECTED'
                    ? false
                    : true
                }
              /> */}
            </Cell>
            {/* <Cell size={3}>
              <Autocomplete
                id="htsNumber"
                label="HTS"
                data={props.hts.slice(0, 10)}
                dataLabel={'find'}
                dataValue={'number'}
                filter={Autocomplete.fuzzyFilter}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                value={
                  props.csd && props.csd.hazmatCode && props.hts.length === 0
                    ? props.csd.hazmatCode
                    : props.hts.length > 0
                    ? props.hts.hts_number
                    : ''
                }
                onChange={e => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  searchHts(e);
                  props.setCsd({ ...props.csd, hazmatCode: e.toUpperCase() });
                }}
                onAutocomplete={v => {
                  if (v !== undefined) {
                    setFind('hts_number');
                    props.setCsd({
                      ...props.csd,
                      hazmatCode: v.toUpperCase()
                    });
                  }
                }}
              />
            </Cell> */}
          </Fragment>
        ) : null}
      </Grid>
      <Grid>
        <Cell size={2}>
          <TextField
            id="floating-center-title-marks-number"
            value={marksAndNumbers || ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              setMarksAndNumbers(e.toUpperCase());
            }}
            label="Marks & Numbers"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
        </Cell>
        {props.aws === 'House' ? (
          <Cell size={3}>
            <TextField
              id="floating-center-title-pck-tracking"
              // maxLength={35}
              value={props.awb.pkTrackingId ? props.awb.pkTrackingId : ''}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                if (e.length <= 35) {
                  props.setAwb({ ...props.awb, pkTrackingId: e.toUpperCase() });
                }
              }}
              label="Package Tracking #"
              lineDirection="center"
              disabled={
                (status !== 'DRAFT' && role === 'shipper') ||
                (props.aws === 'Simple' && status === 'DRAFT') ||
                (props.aws === 'Simple' && status === 'ACCEPTED') ||
                (props.aws === 'Simple' && status === 'REJECTED')
                  ? true
                  : (props.aws === 'Master' && status === 'DRAFT') ||
                    (props.aws === 'Master' && status === 'ACCEPTED') ||
                    (props.aws === 'Master' && status === 'REJECTED')
                  ? true
                  : (props.aws === 'House' && status === 'DRAFT') ||
                    (props.aws === 'House' && status === 'ACCEPTED') ||
                    (props.aws === 'House' && status === 'REJECTED')
                  ? false
                  : true
              }
              error={
                (props.awb.pkTrackingId && props.awb.pkTrackingId.length < 5) ||
                (props.awb.pkTrackingId && props.awb.pkTrackingId.length > 35)
                  ? true
                  : false
              }
              errorText="length must be at 5 characters long minimum, maximum 35"
            />
            <input
              ref={props.pkTrackingIdRef}
              type="text"
              value={props.awb.pkTrackingId}
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        ) : null}
        <Cell size={props.aws === 'House' ? 7 : 10}>
          <TextField
            id="floating-center-title-descriptions"
            value={props.wbl.cargoDesc ? props.wbl.cargoDesc : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              props.setWbl({ ...props.wbl, cargoDesc: e.toUpperCase() });
            }}
            label="Cargo Description"
            lineDirection="center"
            required
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
        </Cell>
      </Grid>
    </Fragment>
  );
};
