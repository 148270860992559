import React from 'react';
import styled from 'styled-components';

const GridContainer = ({ children, className, style }) => (
  <Container style={style}>
    <MainDiv className={className} style={style}>
      {children}
    </MainDiv>
  </Container>
);

const Container = styled.div`
  width: 100%;
  background-color: transparent;
`;

const MainDiv = styled.div`
  width: 100%;
  overflow: hidden;
`;

export default GridContainer;
