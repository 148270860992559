import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Cell,
  DataTable,
  FontIcon,
  Toolbar,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TextField,
  Button,
  SelectField,
  Card,
  CardTitle,
  CardActions,
  ListItem,
  MenuButton,
  TablePagination
} from 'react-md';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import feathers from '../../services/feathers';
import { toast } from 'react-toastify';
import Axios from 'axios';
const env = process.env;

export default props => {
  const { companyProps } = props;
  const [company, setCompany] = useState('');

  const [subscription, setSubscription] = useState({
    name: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    action: [],
    user: ''
  });
  const [fsc, setFSC] = useState(false);
  const [fer, setFER] = useState(false);
  const [fsn, setFSN] = useState(false);
  const [actionObject, setactionObject] = useState({
    messageTypes: [],
    channel: { mail: true, sms: false }
  });
  const [asending, setAsending] = useState({
    name: false,
    email: false
  });

  let form;

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      await checkToken();
    }
    tttt();
  }, []);

  useEffect(() => {
    async function tttt() {
      //getSuscriptions();
      if (props.match.params.company) {
        setCompany(props.match.params.company);

        if (props.match.params.id) {
          editSuscription(props.match.params.id);
        }
      } else if (companyProps) {
        setCompany(companyProps);
      }
    }
    tttt();
  }, []);

  useEffect(() => {
    setSubscription({ ...subscription, company: company });
  }, [company]);

  const setActions = async typeMessage => {
    let messages = actionObject.messageTypes;

    if (messages.includes(typeMessage)) {
      const index = messages.indexOf(typeMessage);
      if (index > -1) {
        messages.splice(index, 1);
      }
    } else {
      messages.push(typeMessage);
      setactionObject({ ...actionObject, messageTypes: messages });
    }
  };

  const saveSubscription = async () => {
    try {
      let subscriptionUpdated = await feathers
        .service('suscriptions')
        .update({}, subscription);

      //clearData();
      toast.success('Notification saved');
      props.history.push(`/client/${company}`);
      //getSuscriptions();
    } catch (e) {
      toast.error('Suscription not saved');
      console.log('error:', e);
    }
  };

  const clearData = async () => {
    setFSC(false);
    setFER(false);
    setFSN(false);
    actionObject.messageTypes = [];
    await setSubscription({
      name: '',
      lastName: '',
      company: company,
      phone: '',
      email: '',
      action: []
    });

    return true;
  };
  //   const getSuscriptions = async () => {
  //     let data = await feathers.service('suscriptions').find({
  //       query: {
  //         company: company,
  //         $sort: { name: 1 }
  //       }
  //     });
  //   };

  const editSuscription = async id => {
    //await clearData();
    let data = await feathers.service('suscriptions').find({
      query: {
        _id: id
      }
    });

    if (data && data.length > 0) {
      let actions = data[0].action;
      data[0].action = [];
      setSubscription(data[0]);

      if (actions && actions[0].messageTypes) {
        actions[0].messageTypes.forEach(t => {
          if (t == 'FSC') {
            setFSC(true);
          }
          if (t == 'FSN') {
            setFSN(true);
          }
          if (t == 'FER') {
            setFER(true);
          }
          setActions(t);
        });
      }
    }
  };

  return (
    <Fragment>
      <Grid className="grid-example">
        <Cell desktopOffset={2} size={8}>
          <Card style={{ paddingBottom: '1px' }}>
            <Toolbar colored title="Notifications"></Toolbar>
            <form
              ref={ref => {
                form = ref;
              }}
            >
              <Grid>
                <Cell size={4}>
                  <TextField
                    id="name"
                    required
                    label="Name"
                    value={subscription.name}
                    onChange={v => {
                      setSubscription({ ...subscription, name: v });
                    }}
                  />
                </Cell>
                <Cell size={4}>
                  <TextField
                    id="lastName"
                    required
                    label="LastName"
                    value={subscription.lastName}
                    onChange={v => {
                      setSubscription({ ...subscription, lastName: v });
                    }}
                  />
                </Cell>
                <Cell size={4}>
                  <TextField
                    id="phone"
                    label="Phone"
                    value={subscription.phone}
                    onChange={v => {
                      setSubscription({ ...subscription, phone: v });
                    }}
                    type="number"
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="email"
                    required
                    label="Email"
                    value={subscription.email}
                    onChange={v => {
                      setSubscription({ ...subscription, email: v });
                    }}
                    type="email"
                    error={
                      subscription.email &&
                      subscription.email.length &&
                      subscription.email.match(
                        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
                      )
                        ? false
                        : subscription.email && subscription.email.length > 0
                        ? true
                        : false
                    }
                    errorText={'you need to add a valid email '}
                  />
                </Cell>
                <Cell size={6}>
                  <FormLabel component="legend" style={{ marginTop: '10px' }}>
                    Type Notification
                  </FormLabel>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={fsc}
                          onClick={async e => {
                            await setFSC(!fsc);
                            setActions('FSC');
                            // this.filters();
                          }}
                          value="FSC"
                          inputProps={{
                            'aria-label': 'primary checkbox'
                          }}
                        />
                      }
                      label="FSC"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={fer}
                          onClick={async e => {
                            await setFER(!fer);
                            setActions('FER');
                          }}
                          value="FER"
                          inputProps={{
                            'aria-label': 'primary checkbox'
                          }}
                        />
                      }
                      label="FER"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={fsn}
                          onClick={async e => {
                            await setFSN(!fsn);
                            setActions('FSN');
                          }}
                          value="FSN"
                          inputProps={{
                            'aria-label': 'primary checkbox'
                          }}
                        />
                      }
                      label="FSN"
                    />
                  </FormGroup>
                </Cell>
                <Cell size={6}>
                  <Button
                    secondary
                    flat
                    onClick={async () => {
                      if (form.reportValidity()) {
                        await subscription.action.push(actionObject);
                        // console.log('object...', actionObject);
                        // console.log('subscription:   ', subscription);
                        saveSubscription();
                      } else
                        toast.error('Invalid data, please review the fields');
                    }}
                    disabled={
                      subscription.name === '' ||
                      subscription.lastName === '' ||
                      subscription.email === '' ||
                      (fsc == false && fer == false && fsn == false)
                    }
                  >
                    Save Subscription
                  </Button>
                  <Button
                    secondary
                    flat
                    onClick={() => props.history.push(`/client/${company}`)}
                  >
                    Cancel
                  </Button>
                </Cell>
              </Grid>
            </form>
          </Card>
        </Cell>
      </Grid>
    </Fragment>
  );
};
