import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default ({
  CloseDialog,
  open,
  Title,
  children,
  showSave = false,
  fullWidth = false,
  maxWidth = 'sm',
  Save = null,
}) => {
  return (
    <Fragment>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onBackdropClick={CloseDialog}
        onEscapeKeyDown={CloseDialog}
        open={open}
        onClose={CloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{Title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {showSave && (
            <Button onClick={Save} variant="outlined" color="primary">
              Save
            </Button>
          )}
          <Button onClick={CloseDialog} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
