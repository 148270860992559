import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Autocomplete, Checkbox, Grid } from 'react-md';
import Axios from 'axios';
import DatePickerRange from '../../components/common/DatePickerRange';
import { filterDefinitions } from '../../constants/filters/filters';
import {
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField
} from '@material-ui/core';

const env = process.env;

const Filters = ({ filters, setFilters, getData, setClients, ...props }) => {
  const { startDate, endDate, client } = filters;

  useEffect(() => {
    const getManifests = async () => await getData();
    getManifests();
  }, [startDate, endDate, client]);

  const setDates = async (startDate, endDate) =>
    setFilters({ ...filters, startDate, endDate });

  const handleChange = (value, ev) => {
    const { target } = ev;
    if (target) {
      const { id } = target;
      if (id == 'client') {
        if (value.length > 0) getClients(value);
        else {
          setFilters({ ...filters, [id]: value, change: true });
        }
      } else {
        if (!value.length)
          setFilters({ ...filters, [id]: value, change: true });
        else setFilters({ ...filters, [id]: value, change: false });
      }
    }
  };

  const handleAutocomplete = (id, val) =>
    setFilters({ ...filters, [id]: val, change: true });

  const handleCheck = async ({ target }) => {
    if (target) {
      const { id } = target;
      if (id) setFilters({ ...filters, [id]: !filters[id], change: true });
    }
  };

  const handleChangeText = ({ target }) => {
    if (target) setFilters({ ...filters, [target.id]: target.value });
  };

  const getClients = async name => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_CLIENT'] + '/client/', {
      params: { $autoComplete: name }
    });

    if (response && response.data) {
      let clientList = response.data.map(c => {
        return { value: c._id, label: c.name };
      });
      setClients(clientList);
    }
  };

  return (
    <>
      <GridMUI container="true">
        <GridMUI item="true" style={{ width: '70%' }}>
          <GridMUI container="true">
            <GridMUI
              item="true"
              style={{
                marginRight: '5px',
                marginTop: '25px',
                color: 'grey',
                fontWeight: '400'
              }}
            >
              <DatePickerRange
                startDate={startDate}
                endDate={endDate}
                dates={setDates}
              />
            </GridMUI>
            {filterDefinitions.map(
              (
                {
                  id,
                  label,
                  data,
                  fuzzyFilter,
                  checkbox,
                  title,
                  disabled,
                  role
                },
                key
              ) =>
                (fuzzyFilter &&
                  (!role || (role && role.includes(props.profile.role))) && (
                    <GridMUI
                      item="true"
                      key={key}
                      style={{ marginRight: '10px' }}
                    >
                      <Autocomplete
                        id={id}
                        label={label}
                        data={props[data] || []}
                        dataLabel="label"
                        dataValue="value"
                        filter={Autocomplete.fuzzyFilter}
                        //value={filters[id]}
                        onChange={handleChange}
                        onAutocomplete={val => handleAutocomplete(id, val)}
                        disabled={disabled}
                      />
                    </GridMUI>
                  )) ||
                (checkbox && (
                  <GridMUI item="true" key={key}>
                    <FormLabel component="legend">{title}</FormLabel>
                    <FormGroup row>
                      {checkbox.map(({ check, label }, checkKey) => (
                        <FormControlLabel
                          key={checkKey}
                          control={
                            <Checkbox
                              color="primary"
                              name={check}
                              id={check}
                              checked={filters[check] || false}
                              onClick={handleCheck}
                              onChange={handleCheck}
                              aria-label="primary checkbox"
                              aria-labelledby="primary checkbox"
                            />
                          }
                          label={label}
                          style={{ marginTop: '20px' }}
                          disabled={disabled}
                        />
                      ))}
                    </FormGroup>
                  </GridMUI>
                )) ||
                ((!role || role.includes(props.profile.role)) && (
                  <GridMUI item="true" key={key}>
                    <Field
                      id={id}
                      onChange={handleChangeText}
                      label={label}
                      value={filters[id]}
                      disabled={disabled}
                    />
                  </GridMUI>
                ))
            )}
          </GridMUI>
        </GridMUI>
        <GridMUI item="true" style={{ width: '30%' }}></GridMUI>
      </GridMUI>
    </>
  );
};

const GridMUI = styled(Grid)`
  margin: 0;
  padding: 4px;
`;

const Field = styled(TextField)`
  min-width: 155px !important;
  margin-top: 10px !important;
`;

export default Filters;
