const DocStatusEnum = {
  DRAFT: 1,
  SENT: 2,
  CANCELLED: 3,
  ACCEPTED: 4,
  REJECTED: 5,
  ACCEPTED_NO_ENTRY_ON_FILE: 6,
  ACCEPTED_FLIGHT_NOT_DEPARTED: 7,
  ACCEPTED_FLIGHT_NOT_DEPARTED_ENTRY: 8,
  properties: {
    1: { label: 'Draft', color: 'red' },
    2: { label: 'Sent', color: 'yellow' },
    3: { label: 'Cancelled', color: 'red' },
    4: { label: 'Accepted', color: 'green' },
    5: { label: 'Rejected', color: 'red' },
    6: { label: 'Accepted (No entry on file)', color: 'yellow' },
    7: { label: 'Accepted (Flight not departed)', color: 'yellow' },
    8: {
      label: 'Accepted (Flight not departed entry on file or not on file)',
      color: 'red'
    }
  }
};

export default (() => {
  if (Object.freeze) {
    return Object.freeze(DocStatusEnum);
  }

  return DocStatusEnum;
})();
