import React, { Fragment, useState, useEffect } from 'react'
import { DialogContainer, SelectField, Switch, Grid, Button, Cell, TextField, Subheader, Autocomplete } from "react-md";
import { useApi } from "../../services/api";
import { toast } from "react-toastify";

export default (props) => {
    const { visible, setEdit, doc, history } = props
    const [, , fetchDoc,] = useApi('ccapi', 'documents', undefined, 'post')
    const [, , fetchAirports,] = useApi('ccapi', 'codes/iata', undefined, 'get')
    const [, , fetchCodes,] = useApi('ccapi', 'codes', undefined, 'get')
    const [allCodes, setAllCodes] = useState([])
    const [usCodes, setUsCodes] = useState([])
    const [airlines, setAirlines] = useState([])
    const [manifest, setManifest] = useState({})
    const [fdaInd, setFdaIndicator] = useState(false)
    const [docType, setDoctype] = useState('')
    const [ccl, setCcl] = useState({ arrivalAirport: '', terminalOp: '' })
    const [awb, setAwb] = useState({ consolidationId: '', prefix: '', serialNumber: '', hawbNumber: '', pkTrackingId: '' })
    const [wbl, setWbl] = useState({ airportOrigin: '', numPieces: '', weightCode: '', weight: '', cargoDesc: '', destinationAirport: '', arrivalDatePTPAirport: '' })
    const [arr, setArr] = useState({ impCarrier: '', flightNumber: '', arrivalDate: '', brdPieceCnt: '', weightCode: '', weight: '', arrivalRef: '' })
    const [ced, setCed] = useState({ entryType: '', entryNumber: '' })
    const [agt, setAgt] = useState({ participantCode: '' })
    const [shp, setShp] = useState({ name: '', address: '', city: '', state: '', countryCode: '', postalCode: '', phoneNumber: '' })
    const [cne, setCne] = useState({ name: '', address: '', city: '', state: '', countryCode: '', postalCode: '', phoneNumber: '' })
    const [trn, setTrn] = useState({ destinationAirport: '', transferstatusId: '', bondedCarrierId: '', onwardCarrier: '', premisesId: '', inbondControlNum: '' })
    const [csd, setCsd] = useState({ originGoods: '', declaredValue: '', currencyCode: '', hazmatCode: '' })
    const [fda, setFda] = useState({ lineId: '' })

    useEffect(() => {
        let origin = doc.docOrigin
        getAirportCodes()
        getCodes()
        setManifest(doc)
        setDoctype(origin.SMI.componentId)
        setAwb({ ...awb, consolidationId: origin.AWB.lineId || '', prefix: origin.AWB.prefix, serialNumber: origin.AWB.serialNumber, hawbNumber: origin.AWB.hawbNumber || '', pkTrackingId: origin.AWB.pkTrackingId || '' })
        if (origin.CCL) setCcl({ ...ccl, arrivalAirport: origin.CCL.arrivalAirport || '', terminalOp: origin.CCL.arrivalAirport || '' })
        if (origin.WBL) setWbl({ ...wbl, airportOrigin: origin.WBL.airportOrigin || '', numPieces: origin.WBL.numPieces || '', weightCode: origin.WBL.weightCode || '', weight: origin.WBL.weight || '', cargoDesc: origin.WBL.cargoDesc || '', destinationAirport: origin.WBL.destinationAirport || '', arrivalDatePTPAirport: origin.WBL.arrivalDatePTPAirport || '' })
        if (origin.ARR) setArr({ ...arr, impCarrier: origin.ARR.impCarrier || '', flightNumber: origin.ARR.flightNumber || '', arrivalDate: origin.ARR.arrivalDate || '', brdPieceCnt: origin.ARR.brdPieceCnt || '', weightCode: origin.ARR.weightCode || '', weight: origin.ARR.weight || '', arrivalRef: origin.ARR.arrivalRef || '' })
        if (origin.AGT) setAgt({ ...agt, participantCode: origin.AGT.participantCode || '' })
        if (origin.CED) setAgt({ ...ced, entryType: origin.CED.entryType || '', entryNumber: origin.CED.entryNumber || '' })
        if (origin.SHP) setShp({ ...shp, name: origin.SHP.name || '', address: origin.SHP.address || '', city: origin.SHP.city || '', state: origin.SHP.state || '', countryCode: origin.SHP.countryCode || '', postalCode: origin.SHP.postalCode || '', phoneNumber: origin.SHP.phoneNumber || '' })
        if (origin.CNE) setCne({ ...cne, name: origin.CNE.name || '', address: origin.CNE.address || '', city: origin.CNE.city || '', state: origin.CNE.state || '', countryCode: origin.CNE.countryCode || '', postalCode: origin.CNE.postalCode || '', phoneNumber: origin.CNE.phoneNumber || '' })
        if (origin.TRN) setTrn({ ...trn, destinationAirport: origin.TRN.destinationAirport || '', transferstatusId: origin.TRN.transferstatusId || '', bondedCarrierId: origin.TRN.bondedCarrierId || '', onwardCarrier: origin.TRN.onwardCarrier || '', premisesId: origin.TRN.premisesId || '', inbondControlNum: origin.CNE.inbondControlNum || '' })
        if (origin.CSD) setCsd({ ...csd, originGoods: origin.CSD.originGoods || '', declaredValue: origin.CSD.declaredValue || '', currencyCode: origin.CSD.currencyCode || '', hazmatCode: origin.CSD.hazmatCode || '' })
        if (origin.FDA) setFda({ ...fda, lineId: origin.FDA.lineId || '' }) && setFdaIndicator(true)
    }, [doc])

    useEffect(() => {
        if (fdaInd) {
            setFda({ ...props.fda, lineId: 'FDA' })
            return
        }
        setFda({ ...props.fda, lineId: '' })
    }, [fdaInd])

    const getAirportCodes = async () => {
        const [result, err] = await fetchAirports()
        if (err) return
        setUsCodes(result[0].iataCodes)
        setAllCodes(result[1].iataCodes)
    }

    const getCodes = async () => {
        const [codes, err] = await fetchCodes({ query: { name: 'Airlines' } })
        if (err) return
        setAirlines(codes.airlines)
    }

    const send = async () => {
        let body = { SMI: { componentId: docType }, AWB: awb, WBL: wbl }
        body._id = doc._id
        if (Object.values(ccl).some(v => v !== '')) body.CCL = ccl
        if (Object.values(arr).some(v => v !== '')) body.ARR = arr
        if (Object.values(agt).some(v => v !== '')) body.AGT = agt
        if (Object.values(ced).some(v => v !== '')) body.AGT = ced
        if (Object.values(shp).some(v => v !== '')) body.SHP = shp
        if (Object.values(cne).some(v => v !== '')) body.CNE = cne
        if (Object.values(trn).some(v => v !== '')) body.TRN = trn
        if (Object.values(csd).some(v => v !== '')) body.CSD = csd
        if (Object.values(fda).some(v => v !== '')) body.FDA = fda

        let [res, err] = await fetchDoc({ body })
        if (err.length) {
            toast.error(err[0].doc.meta.errors[0].message.replace('\\', ''))
        } else {
            toast.info('Document saved')
            setEdit(false)
            history.push({ pathname: '/send-manifest' })
        }
    }

    return (
        <Fragment>
            {
                Object.keys(manifest).length ? (
                    < DialogContainer
                        height={'100%'}
                        width={'100%'}
                        contentStyle={{ height: '100%' }}
                        id="speed-boost"
                        visible={visible}
                        title={`Do you want to edit this document?`}
                        onHide={() => setEdit(false)}
                    >
                        <Subheader className="md-grid" primaryText="Flight Details" />
                        <Grid>
                            <Cell size={4}>
                                {
                                    airlines !== undefined ? (
                                        <Autocomplete
                                            id="codes"
                                            required
                                            label="Airline"
                                            data={airlines}
                                            dataLabel={'name'}
                                            dataValue={'name'}
                                            filter={Autocomplete.fuzzyFilter}
                                            value={arr.impCarrier}
                                            onChange={(v) => setArr({ ...arr, impCarrier: v.toUpperCase() })}
                                            onAutocomplete={(v, i) => {
                                                let code = airlines.find(p => p.name === v)
                                                setArr({ ...arr, impCarrier: code.code })
                                            }}
                                        />
                                    ) : null
                                }
                            </Cell>
                            <Cell size={4}>
                                <TextField id="text-field" label="Flight #" value={arr.flightNumber} onChange={e => setArr({ ...arr, flightNumber: e.toUpperCase() })} />
                            </Cell>
                            <Cell size={4}>
                                {
                                    allCodes !== undefined ? (
                                        <Autocomplete
                                            id="all-codes"
                                            required
                                            label="Origin"
                                            data={allCodes}
                                            dataLabel={'iatacode'}
                                            dataValue={'iatacode'}
                                            filter={Autocomplete.fuzzyFilter}
                                            value={wbl.airportOrigin}
                                            onChange={(v) => setWbl({ ...wbl, airportOrigin: v.toUpperCase() })}
                                            onAutocomplete={(v, i) => {
                                                let code = allCodes.find(p => p.iatacode === v)
                                                setWbl({ ...wbl, airportOrigin: code.iatacode })
                                            }}
                                        />
                                    ) : null
                                }
                            </Cell>
                        </Grid>
                        <Grid>
                            <Cell size={4}>
                                {
                                    usCodes !== undefined ? (
                                        <Autocomplete
                                            id="us-codes"
                                            required
                                            label="1st US Arrival Airport"
                                            data={usCodes}
                                            dataLabel={'iatacode'}
                                            dataValue={'iatacode'}
                                            filter={Autocomplete.fuzzyFilter}
                                            value={ccl.arrivalAirport}
                                            onChange={(v) => setCcl({ ...ccl, arrivalAirport: v.toUpperCase() })}
                                            onAutocomplete={(v, i) => {
                                                let code = usCodes.find(p => p.iatacode === v)
                                                setCcl({ ...ccl, arrivalAirport: code.iatacode })
                                            }}
                                        />
                                    ) : null
                                }
                            </Cell>
                            <Cell size={4}>
                                <TextField id="text-field" label="ETA (Day & Month)" value={arr.arrivalDate} onChange={e => setArr({ ...arr, arrivalDate: e.toUpperCase() })} />
                            </Cell>
                            <Cell size={4}>
                                {
                                    airlines !== undefined ? (
                                        <Autocomplete
                                            id="codes"
                                            required
                                            label="Terminal Operator"
                                            data={airlines}
                                            dataLabel={'code'}
                                            dataValue={'code'}
                                            filter={Autocomplete.fuzzyFilter}
                                            value={ccl.terminalOp}
                                            onChange={(v) => setCcl({ ...ccl, terminalOp: v.toUpperCase() })}
                                            onAutocomplete={(v, i) => {
                                                let code = airlines.find(p => p.code === v)
                                                setCcl({ ...ccl, terminalOp: code.code })
                                            }}
                                        />
                                    ) : null
                                }
                            </Cell>
                        </Grid>
                        <Grid>
                            <Cell size={3}>
                                <TextField id="text-field" label="AWB Prefix" value={awb.prefix} maxLength={3} min={3} onChange={e => setAwb({ ...awb, prefix: e.toUpperCase() })} />
                            </Cell>
                            <Cell size={3}>
                                <TextField id="text-field" label="AWB #" value={awb.serialNumber} maxLength={8} min={8} onChange={e => setAwb({ ...awb, serialNumber: e.toUpperCase() })} />
                            </Cell>
                            <Cell size={3}>
                                <TextField id="text-field" label="HAWB #" value={awb.hawbNumber} maxLength={12} onChange={e => setAwb({ ...awb, hawbNumber: e.toUpperCase() })} />
                            </Cell>
                            {
                                docType === 'FRI' ? (
                                    <Cell size={3}>
                                        <TextField id="text-field" label="Nominated Agent" value={agt.participantCode} onChange={e => setAgt({ ...agt, participantCode: e.toUpperCase() })} />
                                    </Cell>
                                ) : null
                            }
                        </Grid>
                        {
                            docType === 'FXI' || docType === 'FXC' ? (
                                <Grid>
                                    <Cell size={4}>
                                        <TextField id="text-field" value={ced.entryType} onChange={e => setCed({ ...ced, entryType: e.toUpperCase() })} label="Entry Type" />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" value={ced.entryNumber} onChange={e => setCed({ ...ced, entryNumber: e.toUpperCase() })} label="Consolidated Entry Number" />
                                    </Cell>
                                </Grid>
                            ) : null
                        }
                        <Grid>
                            <Cell size={6}>
                                <Subheader className="md-grid" primaryText="Shipper" />
                                <Grid>
                                    <Cell size={8}>
                                        <TextField id="text-field" label="Name" value={shp.name} onChange={e => setShp({ ...shp, name: e.toUpperCase() })} />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="Phone" value={shp.phoneNumber} onChange={e => setShp({ ...shp, phoneNumber: e.toUpperCase() })} />
                                    </Cell>
                                </Grid>
                                <Grid>
                                    <Cell size={8}>
                                        <TextField id="text-field" label="Address" value={shp.address} onChange={e => setShp({ ...shp, address: e.toUpperCase() })} />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="Postal Code" value={shp.postalCode} onChange={e => setShp({ ...shp, postalCode: e.toUpperCase() })} />
                                    </Cell>
                                </Grid>
                                <Grid>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="City" value={shp.city} onChange={e => setShp({ ...shp, city: e.toUpperCase() })} />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="State" value={shp.state} onChange={e => setShp({ ...shp, state: e.toUpperCase() })} />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="Country Code" value={shp.countryCode} onChange={e => setShp({ ...shp, countryCode: e.toUpperCase() })} />
                                    </Cell>
                                </Grid>
                            </Cell>
                            <Cell size={6}>
                                <Subheader className="md-grid" primaryText="Consignee" />
                                <Grid>
                                    <Cell size={8}>
                                        <TextField id="text-field" label="Name" value={cne.name} onChange={e => setCne({ ...cne, name: e.toUpperCase() })} />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="Phone" value={cne.phoneNumber} onChange={e => setCne({ ...cne, phoneNumber: e.toUpperCase() })} />
                                    </Cell>
                                </Grid>
                                <Grid>
                                    <Cell size={8}>
                                        <TextField id="text-field" label="Address" value={cne.address} onChange={e => setCne({ ...cne, address: e.toUpperCase() })} />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="Postal Code" value={cne.postalCode} onChange={e => setCne({ ...cne, postalCode: e.toUpperCase() })} />
                                    </Cell>
                                </Grid>
                                <Grid>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="City" value={cne.city} onChange={e => setCne({ ...cne, city: e.toUpperCase() })} />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="State" value={cne.state} onChange={e => setCne({ ...cne, state: e.toUpperCase() })} />
                                    </Cell>
                                    <Cell size={4}>
                                        <TextField id="text-field" label="Country Code" value={cne.countryCode} onChange={e => setCne({ ...cne, countryCode: e.toUpperCase() })} />
                                    </Cell>
                                </Grid>
                            </Cell>
                        </Grid>
                        <Subheader className="md-grid" primaryText="Cargo Details" />
                        <Grid>
                            <Switch id="switch-fda" className="md-cell md-cell--3" style={{ marginTop: 28 }} type="switch" label="FDA Indicator" name="fda" onChange={e => setFdaIndicator(!fdaInd)} />
                            <Cell size={3}>
                                <TextField id="text-field" label="Piece Count" value={wbl.numPieces} onChange={e => setWbl({ ...wbl, numPieces: e.toUpperCase() })} />
                            </Cell>
                            <Cell size={3}>
                                <TextField id="text-field" label="Weight" value={wbl.weight} onChange={e => setWbl({ ...wbl, weight: e.toUpperCase() })} />
                            </Cell>
                            <SelectField id="select-field-1" className="md-cell md-cell--2" value={wbl.weightCode} onChange={e => setWbl({ ...wbl, weightCode: e.toUpperCase() })} label="Lbs or Kgs" menuItems={['L', 'K']} simplifiedMenu={true} />
                        </Grid>
                        <Grid>
                            <Cell size={3}>
                                <TextField id="text-field" label="Package Tracking #" value={awb.pkTrackingId} onChange={e => setAwb({ ...awb, pkTrackingId: e.toUpperCase() })} />
                            </Cell>
                            <Cell size={3}>
                                <TextField id="text-field" label="Description" value={wbl.cargoDesc} onChange={e => setWbl({ ...wbl, cargoDesc: e.toUpperCase() })} />
                            </Cell>
                        </Grid>
                        <Grid>
                            <Cell size={6} tabletSize={3} phoneSize={4}>
                                <Button flat secondary onClick={e => setEdit(false)}>Close</Button>
                            </Cell>
                            <Cell size={6} tabletSize={5} phoneSize={4}>
                                <Button flat primary
                                    onClick={send}
                                >
                                    Send Change
                                </Button>
                            </Cell>
                        </Grid>
                    </DialogContainer >
                ) : null
            }
        </Fragment>
    )
}