import { Grid, Cell, TextField, SelectionControlGroup, Button } from 'react-md';
import React, { Fragment, useState, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import feathers from '../../services/feathers';

export default props => {
  // const [ready, setReady] = useState(true)
  const { close, AWB, HAWB, houses } = props;
  const [rfa, setRfa] = useState({ amendCode: '' });
  const [HOUSES, setHOUSES] = useState([]);
  const [awb, setAwb] = useState({
    prefix: '',
    serialNumber: '',
    hawbNumber: ''
  });
  // const [, , sendFsq,] = useApi('ccapi', 'FSQ', undefined, 'post')
  const [, , fetchDoc] = useApi('ccapi', 'documents', undefined, 'post');
  const [ready, setReady] = useState(AWB ? true : false);
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');

  //Amendment Codes
  const amendmentCodes = [
    {
      value: '01',
      label:
        '01 - Not laden aboard per evidence from foreign shipper or amended bill of lading.'
    },
    {
      value: '02',
      label:
        '02 - Error in manifesting. Not laden on this flight. Laden on subsequent flight for transportation to the U.S.'
    },
    {
      value: '03',
      label: '03 - Clerical error in manifesting per air waybill in files.'
    },
    {
      value: '04',
      label:
        '04 - Pilfered or prematurely landed prior to arrival in the U.S. per signed statement of master/ his agent or log extract in the carrier/deconsolidator/freight forwarder files.'
    },
    {
      value: '05',
      label:
        '05 - Erroneously duplicated by another air waybill on same flight.'
    },
    {
      value: '06',
      label:
        '06 - Prematurely unladen or over carried to another U.S. port where proper disposition was made per evidence in our files.'
    },
    {
      value: '07',
      label:
        '07 - Inadvertently retained onboard and taken foreign per statement of master/his agent. Amended air waybill or landing certificate in our files.'
    },
    {
      value: '08',
      label:
        '08 - Container stripped under CBP Supervision. Foreign seals affixed abroad were intact per evidence in our files.'
    },
    {
      value: '09',
      label:
        '09 - Merchandise apparently pilfered on dock while in custody of carrier.'
    },
    {
      value: '10',
      label:
        '10 - Inadvertently delivered without CBP authorization. Goods will be redelivered intact or carrier will pay duty and taxes.'
    },
    {
      value: '11',
      label: '11 - Overage; omitted from manifest through clerical error.'
    },
    {
      value: '12',
      label:
        '12 - Overage; manifested for discharge at another port and inadvertently discharged at this port.'
    },
    {
      value: '13',
      label:
        '13 -Proper entry filed or placed in G.O. per entry or G.O. number.'
    },
    {
      value: '14',
      label:
        '14 - Merchandise inadvertently delivered to consignee without CBP authorization. Merchandise will be redelivered intact or liquidated damages paid.'
    },
    {
      value: '15',
      label:
        '15 - Merchandise cannot be located and has apparently been lost. Liquidated damages will be paid.'
    },
    {
      value: '16',
      label: '16 - Error in quantity manifested at airport of origin.'
    },
    { value: '17', label: '17 - Restuffing a container.' },
    {
      value: '18',
      label: '18 - Transmission delayed by electronic communications failure.'
    },
    {
      value: '19',
      label:
        '19 - Amendment to non-critical fields or provision of additional data to record. Quantity manifested is not changed and original report was timely.'
    },
    {
      value: '20',
      label:
        '20 - Provision of detail data to air waybill record by a Deconsolidator.'
    },
    {
      value: '21',
      label:
        '21 - Provision of detail data to an express consignment record or a change in optional data previously submitted for an express consignment record.'
    }
  ];

  let AWBPSN = React.createRef();
  if (AWB) {
    useEffect(() => {
      // document.getElementById('AWBPSN').click();
      // document.getElementById('AWBPSN').value = AWB || '';
      // AWBPSN.value = AWB || '';
      let houses2 = [{ label: '', value: '' }];
      houses.map(value =>
        houses2.push({
          label: value.hawbNumber,
          value: value.hawbNumber
        })
      );
      // houses2.pop({ label: 'a', value: 'a' });
      setHOUSES(houses2);

      console.log('houses :', houses2);
      setAwb({
        prefix: AWB.split('-')[0],
        serialNumber: AWB.split('-')[1],
        hawbNumber: HAWB || ''
      });
    }, [AWB]);

    // consultar con feathers
  }

  // useEffect(() => {
  //     if ((awb.hawbNumber.length !== 0 && fsq.requestCode) || (awb.serialNumber.length !== 0 && fsq.requestCode)) {
  //         setReady(false)
  //     } else {
  //         setReady(true)
  //     }
  // }, [fsq, awb])
  const send = async id => {
    let [response, err] = await doSend({ body: [id] });
    if (err) return;
    toast.success(response.message);
    // getPendings();
    // setLoading(true);
  };

  const create = async willSend => {
    let query = {
      'docOrigin.AWB.prefix': awb.prefix,
      'docOrigin.AWB.serialNumber': awb.serialNumber,
      'docOrigin.SMI.componentId': {
        $in: ['FRI', 'FRC', 'FXC', 'FXI']
      },
      $sort: { 'meta.date': -1 },
      $limit: 1
    };
    if (awb.hawbNumber) {
      query = {
        'docOrigin.AWB.prefix': awb.prefix,
        'docOrigin.AWB.serialNumber': awb.serialNumber,
        'docOrigin.AWB.hawbNumber': awb.hawbNumber,
        'docOrigin.SMI.componentId': {
          $in: ['FRI', 'FRC', 'FXC', 'FXI']
        },
        $sort: { 'meta.date': -1 },
        $limit: 1
      };
    }
    let result = await feathers.service('manifests').find({
      query: query
    });

    let ccl = result[0].docOrigin.CCL;
    let arr = result[0].docOrigin.ARR;
    rfa.componentId = 'RFA';
    let body = { SMI: { componentId: 'FRX' }, AWB: awb, RFA: rfa };
    if (ccl && arr)
      body = {
        SMI: { componentId: 'FRX' },
        CCL: ccl,
        AWB: awb,
        ARR: arr,
        RFA: rfa
      };
    let [apifsq, err] = await fetchDoc({
      body: body
    });
    if (err) return;
    if (apifsq) {
      if (apifsq.errors) {
        let detail = '';
        for (let i = 0; i < apifsq.errors.length; i++) {
          if (apifsq.errors.length - 1 === i)
            detail += ` ${apifsq.errors[i].message}`;
          else detail += ` ${apifsq.errors[i].message},`;
        }

        toast.warn(`${apifsq.message}, ${detail}`);
      } else {
        // console.log('apifsq :', apifsq.message._id);
        toast.success(
          `FRX for ${apifsq.message.docOrigin.AWB.prefix}-${apifsq.message.docOrigin.AWB.serialNumber} successfully created.`
        );
        if (willSend) {
          await send(apifsq.message._id);
        }
      }
    } else {
      toast.error(`Error: something goes wrong, try again`);
    }
  };

  const setMaster = e => {
    let prefix = e.split('-')[0];
    let serialNumber = e.split('-')[1];
    if (serialNumber !== undefined) {
      // console.log(serialNumber)
      setAwb({ ...awb, prefix, serialNumber });
      //   console.log(awb);
      setReady(true);
    }
  };

  return (
    <Fragment>
      <Grid>
        <Cell size={6}>
          <TextField
            id="AWBPSN"
            ref={AWBPSN}
            defaultValue={AWB}
            helpText="Example: 999-12345678"
            onChange={e => setMaster(e)}
            maxLength={12}
            label="AWB #"
            lineDirection="center"
          />
        </Cell>
        <Cell size={6}>
          {/* <SelectField
              id="codes"
              label="House AWB #"
              fullWidth={true}
              menuItems={HOUSES}
              onChange={async e => {
                await setAwb({ ...awb, hawbNumber: e });
              }}
            /> */}
          <TextField
            id="HAWB"
            className="texttttt"
            defaultValue={HAWB}
            onChange={e => setAwb({ ...awb, hawbNumber: e })}
            label="House AWB #"
            lineDirection="center"
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={12}>
          <SelectionControlGroup
            id="selection-control-group-radios"
            name="radio-example"
            type="radio"
            label="Status Request Code"
            defaultValue={false}
            controls={amendmentCodes}
            controlClassName="md-cell md-cell--6"
            inline={true}
            onChange={e => setRfa({ ...rfa, amendCode: e })}
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={2}>
          <Button
            style={{ display: AWB ? 'none' : 'block' }}
            raised
            secondary
            iconBefore={false}
            onClick={async e => {
              await create(false);
            }}
            disabled={!ready}
          >
            Create
          </Button>
          <Button
            style={{ display: AWB ? 'block' : 'none' }}
            raised
            secondary
            iconBefore={false}
            onClick={async e => {
              await create(true);
              // await send();
              close();
            }}
            disabled={!ready}
          >
            Create & Send
          </Button>
        </Cell>
        <Cell style={{ display: AWB ? 'block' : 'none' }} size={2}>
          <Button raised secondary iconBefore={false} onClick={close}>
            Cancel
          </Button>
        </Cell>
      </Grid>
    </Fragment>
  );
};
