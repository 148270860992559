import { Cell, TextField, Subheader, Grid, Autocomplete } from 'react-md';
import React, { Fragment } from 'react';
import { throttle } from 'lodash/function';
import feathers from '../../../services/feathers';

export default (props) => {
  const {
    role,
    status,
    cneEmail,
    cnePhoneNumber,
    cnePostalCode,
    cneCountryRef,
  } = props;
  const searchPartyCons = throttle(async (query) => {
    let consignees = await feathers.service('party').find({
      query: {
        search: query,
        type: 'consignee',
      },
    });

    if (consignees.length > 0) {
      let listCons = [];

      listCons = consignees.length
        ? consignees.map((value) => ({
            ...value,
            find: value.name,
          }))
        : null;
      props.setConsignees(listCons);
    }
  }, 250);

  const search = throttle(async (query) => {
    let countryCode = null;
    if (query._id) {
      countryCode = query.countrycode;
    } else {
      countryCode = query;
    }
    let countries = await feathers.service('countries').find({
      query: {
        $type: 'Country',
        $csd: countryCode,
      },
    });

    if (countries.length > 0) {
      let csd = [];
      csd = countries.length
        ? countries.map((value) => ({
            ...value,
            find: value.code + ' - ' + value.name,
            txt: value.code + '-' + value.name,
          }))
        : null;

      props.setCountriesCne(csd);

      if (query._id) {
        let concatCountry = csd[0].txt;
        props.setAutocompletesCne({
          ...props.autocompletesCne,
          cneCountry: concatCountry,
        });

        props.setCne({
          ...props.cne,
          name: query.name,
          phoneNumber: query.phone,
          address: query.address,
          postalCode: query.zip,
          city: query.city,
          state: query.state,
          countryCode: query.countrycode,
          email: query.email,
        });
      }
    }
  }, 250);

  return (
    <Fragment>
      <Grid>
        <Cell size={10}>
          {/* <TextField
            id="floating-center-title"
            required
            value={props.cne.name}
            onChange={e =>
              props.setCne({ ...props.cne, name: e.toUpperCase() })
            }
            label="Name"
            lineDirection="center"
          /> */}
          {props.consigneeList !== undefined ? (
            <Autocomplete
              id="consigneesAuto"
              required
              label="Name"
              data={props.consigneeList.slice(0, 10)}
              dataLabel={'find'}
              dataValue={'_id'}
              filter={Autocomplete.fuzzyFilter}
              findInlineSuggestion={Autocomplete.findIgnoreCase}
              value={
                props.cne.name && props.consigneeList.length === 0
                  ? props.cne.name
                  : props.consigneeList.length > 0
                  ? props.cne.name
                  : ''
              }
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                searchPartyCons(e);

                props.setCne({ ...props.cne, name: e });
              }}
              onAutocomplete={(v) => {
                let consigneeSelected = props.consigneeList.filter(
                  (i) => i._id === v
                );
                search(consigneeSelected[0]);

                // props.setCne({
                //   ...props.cne,
                //   name: consigneeSelected[0].name,
                //   phoneNumber: consigneeSelected[0].phone,
                //   address: consigneeSelected[0].address,
                //   postalCode: consigneeSelected[0].zip,
                //   city: consigneeSelected[0].city,
                //   state: consigneeSelected[0].state,
                //   countryCode: consigneeSelected[0].countrycode,
                //   email: consigneeSelected[0].email
                // });
              }}
              disabled={
                (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
              }
            />
          ) : null}
        </Cell>
      </Grid>
      <Grid>
        <Cell size={10}>
          <TextField
            // maxLength={35}
            id="floating-center-title-addres-c"
            required
            value={props.cne.address ? props.cne.address : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 35) {
                props.setCne({ ...props.cne, address: e.toUpperCase() });
              }
            }}
            label="Address"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            // error={
            //   props.cne.address
            //     ? props.cne.address.length > 35
            //       ? true
            //       : false
            //     : false
            // }
            // errorText="length must be less than 35 characters"
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={4}>
          <TextField
            // maxLength={17}
            id="floating-center-title-city-c"
            required
            value={props.cne.city ? props.cne.city : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 17) {
                props.setCne({ ...props.cne, city: e.toUpperCase() });
              }
            }}
            label="City"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            // error={
            //   props.cne.state
            //     ? props.cne.state.length > 17
            //       ? true
            //       : false
            //     : false
            // }
            // errorText="length must be less than 17 characters"
          />
        </Cell>
        <Cell size={4}>
          <TextField
            // maxLength={9}
            id="floating-center-title-state-c"
            value={props.cne.state ? props.cne.state : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 9) {
                props.setCne({ ...props.cne, state: e.toUpperCase() });
              }
            }}
            label="State/Province"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            // requireds
            // error={
            //   props.cne.state
            //     ? props.cne.state.length > 9
            //       ? true
            //       : false
            //     : false
            // }
            // errorText="length must be less than 9 characters"
          />
        </Cell>
        <Cell size={4}>
          {/* <TextField
            // maxLength={2}
            id="floating-center-title-country-c"
            required
            value={props.cne.countryCode ? props.cne.countryCode : ''}
            onChange={e => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 2) {
                props.setCne({ ...props.cne, countryCode: e.toUpperCase() });
              }
            }}
            label="Country"
            lineDirection="center"
            disabled={
              status === 'DRAFT' ||
              status === 'ACCEPTED' ||
              status === 'REJECTED'
                ? false
                : true
            }
            error={
              props.cne.countryCode
                ? props.cne.countryCode.length < 2
                  ? true
                  : false
                : false
            }
            errorText="length must be at 2 characters long"
          /> */}
          <Autocomplete
            id="title-country-consignee"
            label="Country"
            data={
              status === 'DRAFT' ||
              status === 'ACCEPTED' ||
              status === 'REJECTED' ||
              status === 'SENDING'
                ? props.countriesCne
                : []
            }
            dataLabel={'find'}
            dataValue={'txt'}
            value={props.autocompletesCne.cneCountry}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length >= 1) {
                search(e);
              }
              props.setCne({
                ...props.cne,
                countryCode: e.toUpperCase(),
              });
              if (props.autocompletesCne) {
                props.setAutocompletesCne({
                  ...props.autocompletesCne,
                  cneCountry: e.toUpperCase(),
                });
              }
            }}
            onAutocomplete={async (v) => {
              if (v !== undefined) {
                let find = v.split('-');

                props.setCne({
                  ...props.cne,
                  countryCode: find[0],
                });
                if (props.autocompletesCne) {
                  let f = props.countriesCne.filter((x) => x.txt === v)[0];
                  props.setAutocompletesCne({
                    ...props.autocompletesCne,
                    cneCountry: f.find,
                  });
                }
              }
            }}
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            required
          />
          <input
            ref={cneCountryRef}
            type="text"
            value={
              props.cne.countryCode
                ? props.cne.countryCode
                : props.cne.countrycode
                ? props.cne.countrycode
                : ''
            }
            required
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
      </Grid>
      <Grid>
        <Cell size={4}>
          <TextField
            id="floating-center-title-Email-c"
            value={props.cne.email ? props.cne.email : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              props.setCne({ ...props.cne, email: e });
            }}
            label="Email"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            type="email"
            error={
              props.cne.email &&
              props.cne.email.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
              )
                ? false
                : props.cne.email && props.cne.email.length > 0
                ? true
                : false
            }
            errorText={'you need to add a valid email'}
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
          <input
            ref={cneEmail}
            value={props.cne.email ? props.cne.email : ''}
            required={
              props.cne && props.cne.email && props.cne.email.length > 0
                ? true
                : false
            }
            type="email"
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
        <Cell size={4}>
          <TextField
            id="floating-center-title-phone-c"
            value={
              props.cne.phoneNumber
                ? props.cne.phoneNumber
                : props.cne.phone
                ? props.cne.phone
                : ''
            }
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 14) {
                props.setCne({ ...props.cne, phoneNumber: e.toUpperCase() });
              }
            }}
            label="Phone"
            error={
              props.cne &&
              props.cne.phoneNumber &&
              props.cne.phoneNumber.length &&
              props.cne.phoneNumber.length <= 0
                ? true
                : false
            }
            errorText={
              'length must be greater or equal than 1 character and less than 14 characters long'
            }
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            type="number"
            min="0"
          />
          <input
            ref={cnePhoneNumber}
            value={props.cne.phoneNumber ? props.cne.phoneNumber : ''}
            required={
              props.cne &&
              props.cne.phoneNumber &&
              props.cne.phoneNumber.length > 0
                ? true
                : false
            }
            type="number"
            min="0"
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
        <Cell size={4}>
          <TextField
            id="floating-center-title-postal-code-c"
            value={
              props.cne.postalCode
                ? props.cne.postalCode
                : props.cne.zip
                ? props.cne.zip
                : ''
            }
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 9) {
                props.setCne({ ...props.cne, postalCode: e.toUpperCase() });
              }
            }}
            label="Zip Code"
            error={
              props.cne &&
              props.cne.postalCode &&
              props.cne.postalCode.length &&
              props.cne.postalCode.length <= 0
                ? true
                : false
            }
            errorText={
              'length must be greater or equal than 1 character and less than 9 characters long'
            }
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
          <input
            ref={cnePostalCode}
            value={props.cne.postalCode ? props.cne.postalCode : ''}
            required={
              props.cne &&
              props.cne.postalCode &&
              props.cne.postalCode.length > 0
                ? true
                : false
            }
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
      </Grid>
    </Fragment>
  );
};
