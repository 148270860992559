import React, { Fragment, useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import feathers from '../../services/feathers';
import { Redirect, Route, Switch } from 'react-router-dom';

export default props => {
  const [statusUpload, setStatusUpload] = React.useState({});
  const [redirect, setRedirect] = React.useState(false);

  feathers.service('manifests').on('upload', data => {
    //TODO Redireccionar cuando termine
    setStatusUpload(data);
    if (data.type == 'FINISH') {
      setTimeout(() => {
        setRedirect(true);
      }, 2000);
    }
  });

  return (
    <div>
      Uploading and validating...
      <br />
      <br />
      <LinearProgress
        variant="determinate"
        value={
          (statusUpload && (statusUpload.cont / statusUpload.total) * 100) || 0
        }
      />
      <br />
      {(statusUpload && statusUpload.message) || ''}
      {redirect ? <Route render={() => <Redirect to="/awb-history" />} /> : ''}
    </div>
  );
};
