import Papa from 'papaparse';
import { Cell, Card, CardText, Button, CircularProgress } from 'react-md';
import Grid2 from 'react-md/lib/Grids/Grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import React, { useState, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import '../style.css';
import { DatePicker } from 'react-md';
import moment from 'moment';
import Axios from 'axios';
import feathers from '../../services/feathers';
import PagingContainer from '../../components/GridComponents/PagingPanel';
const env = process.env;

let xlsHeaders = {};

const xlsData = (transaction = []) => {
  return [
    xlsHeaders,
    {
      ySteps: 5,
      columns: [
        'User',
        'Status',
        'AWB Number',
        'HAWB Number',
        'Txn Date',
        'Txn Time',
        'Txn Description',
        'Qty',
        'UOM',
        'Airline',
        'Flight number',
        'Origin Airport',
        'US Arrival Airport',
        'Shipper Name',
        'Shipper Address',
        'Shipper City',
        'Shipper Country',
        'Shipper Postal/Zip Code',
        'Consignee Name',
        'Consignee Address',
        'Consignee City',
        'Consignee Country',
        'Consignee Zip Code',
        'Cargo Description',
        'Cargo Piece Count',
        'Cargo Weight',
        'LBS or KGS',
        'Country of Origin',
        'Value',
        'Currency',
        'HTS',
        'Package Tracking Number' /*,
            "Marks & Numbers"*/,
      ],

      data: transaction.map((r) => [
        r.user,
        r.status,
        r.serialNumber,
        r.hawbNumber || '',
        moment(r.date).format('L'),
        moment(r.date).format('LT'),
        r.billType,
        r.qty,
        r.trasn,
        r.terminalOp || '',
        r.flightNumber || '',
        r.airportOrigin || '',
        r.arrivalAirport || '',
        r.shipName || '',
        r.shipAddress || '',
        r.shipCity || '',
        r.shipCountryCode || '',
        r.shipPostalCode || '',
        r.cneName || '',
        r.cneAddress || '',
        r.cneCity || '',
        r.cneCountryCode || '',
        r.cnePostalCode || '',
        r.cargoDesc || '',
        r.numPieces || '',
        r.weight || '',
        r.weightCode || '',
        r.originGoods || '',
        r.declaredValue || '',
        r.currencyCode || '',
        r.hazmatCode || '',
        r.pkTrackingId || '' /*,
        r.marksNumbers*/,
      ]),
    },
  ];
};

export default (props) => {
  let toDate = Date.now();
  let fromDate = new Date();
  fromDate = fromDate.setDate(fromDate.getDate() - 1);

  const [value, setValue] = useState({
    serialNumber: '',
    hawbNumber: '',
    flight: '',
    eta: '',
    status: '',
    originAirport: '',
    arraivalAirport: '',
  });
  const [, , getSumm] = useApi(
    'ccapi',
    'manifest/transactions',
    undefined,
    'get'
  );
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [dataL, setDataL] = useState(0);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(50);
  const [range, setRange] = useState({
    from: fromDate,
    to: toDate,
  });

  const [exportData, setExportData] = useState('');

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        setRows(50);
        //   getTransaction({
        //     query: {
        //       report: true,
        //       from: fromDate,
        //       to: toDate,
        //       company: JSON.parse(localStorage.profile).company
        //     }
        //   });
      }
    }
    tttt();
  }, []);

  //useEffect(() => {}, [exportData]);

  const exportCSV = () => {
    let output = xlsData(filter);

    let papa = Papa.unparse(
      { fields: output[1].columns, data: output[1].data },
      {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        newline: '\r\n',
        skipEmptyLines: false, //or 'greedy',
        encoding: 'utf-8',
        download: true,
        delimitersToGuess: [
          ',',
          '\t',
          '|',
          ';',
          Papa.RECORD_SEP,
          Papa.UNIT_SEP,
        ],
      }
    );

    // let csvContent = 'data:text/csv;charset=utf-8,' + papa;
    // var encodedUri = encodeURI(csvContent);
    // window.open(encodedUri, '_self');

    let CsvString = 'data:application/csv,' + encodeURIComponent(papa);
    var x = document.createElement('A');
    x.setAttribute('href', CsvString);
    x.setAttribute('download', 'CCAIR-reports.csv');
    document.body.appendChild(x);
    x.click();
    //return encodedUri;
    //setExportData(encodedUri);
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        if (range.from === '' && range.to === '') {
          range.from = fromDate;
          range.to = toDate;
        } else {
          if (typeof range.from !== 'number') fromDate = Date.parse(range.from);
          if (typeof range.to !== 'number') {
            toDate = moment(range.to);
            toDate = Date.parse(toDate);
          }
          getTransaction({
            query: {
              report: true,
              from: fromDate,
              to: toDate,
              company: JSON.parse(localStorage.profile).company,
            },
          });
        }
      }
    }
    tttt();
  }, [range]);

  const getTransaction = async (params) => {
    //let [transaction, err]  = await getSumm(params || {});
    //console.log('params', params);
    setLoading(true);
    let result = await feathers.service('manifests').find({
      query: params.query,
    });
    let transaction = result;
    let err;

    if (err) return;
    if (transaction) {
      setFilter(transaction);
      setLoading(false);
      //setExportData(xlsData(transaction));
      setData(transaction);

      setDataL(transaction.length);
    } else {
      toast.error(`Error: something goes wrong, try again`);
    }
  };

  const handleEvent = (e, path) => {
    console.log('e :', e);
    setValue({ [path]: e });

    setFilter(
      data.filter(
        (f) =>
          f[path] &&
          f[path]
            .toString()
            // .includes(e)
            .trim()
            .toLowerCase()
            .indexOf(e.toLowerCase()) !== -1
      )
    );
    if (e === '') {
      let rows = data.length;
      setDataL(rows);
    }
  };

  const clear = () => {
    setValue({ masterAwb: '', hawb: '', flight: '', eta: '', status: '' });
    setFilter(data.slice(0, 50));
  };

  const clearDates = () => {
    setRange({ from: fromDate, to: toDate });
    setFilter(data.slice(0, 50));
  };

  const handlePagination = (start, rowsPerPage) => {
    setRows(rowsPerPage);
    setFilter(data.slice(start, start + rowsPerPage));
  };

  xlsHeaders = {
    columns: [
      {
        title: 'Transaction Reports',
        width: { wch: 20 },
        style: { font: { sz: '24', bold: true } },
      }, //pixels width
      { title: '', width: { wpx: 80 } }, //char width
    ],
    data: [
      [
        { value: ' ', style: { font: { sz: '12', bold: true } } },
        { value: ' ', style: { font: { bold: false } } },
      ],
      [
        { value: 'Company', style: { font: { sz: '12', bold: true } } },
        {
          value: 'Customs City Global Solutions Inc. ',
          style: { font: { italic: true } },
        },
      ],
      [
        {
          value: 'Transactions from: ',
          style: { font: { sz: '12', bold: true } },
        },
        {
          value:
            moment(range.from).format('L') +
            ' To ' +
            moment(range.to).format('L'),
          style: { font: { italic: true } },
        },
      ],
    ],
  };

  return (
    <Grid2>
      {filter ? (
        <Cell size={12}>
          <Card tableCard>
            <CardText>
              {/* <TableCardHeader visible={false} title="Transactions"> */}
              <Grid2>
                <div className="md-cell md-cell--5">
                  <span style={{ fontSize: 35, color: 'gray' }}>
                    Transactions
                  </span>
                </div>

                <DatePicker
                  id="inline-date-picker-portait"
                  label="from"
                  value={moment(range.from).format('L')}
                  defaultValue={moment(range.from).format('L')}
                  maxDate={new Date(range.to)}
                  className="md-cell md-cell--2"
                  locales="en-US"
                  inline
                  displayMode="portrait"
                  fullWidth={false}
                  autoOk
                  style={{ marginLeft: '200px' }}
                  onChange={(e) => setRange({ ...range, from: e })}
                />

                <DatePicker
                  id="inline-date-picker-portait-1"
                  label="to"
                  value={moment(range.to).format('L')}
                  defaultValue={moment(range.to).format('L')}
                  minDate={new Date(range.from)}
                  className="md-cell md-cell--2"
                  locales="en-US"
                  inline
                  displayMode="portrait"
                  fullWidth={false}
                  autoOk
                  onChange={(e) => setRange({ ...range, to: e })}
                />

                <Button
                  onClick={() => {
                    exportCSV();
                  }}
                >
                  Export CSV
                </Button>
                {/* <a
                  className="btn btn-success"
                  onClick={() => {
                    exportCSV();
                  }}
                  href={exportData}
                  target="_self"
                  filename={'name'}
                  data={exportData}
                >
                  Export CSV
                </a> */}
              </Grid2>

              {loading ? (
                <div style={{ marginTop: '40px', marginBottom: '40px' }}>
                  <CircularProgress
                    id="loading"
                    centered={true}
                    className="loading-blue"
                    scale={2}
                  ></CircularProgress>
                </div>
              ) : (
                <Grid
                  rows={filter}
                  style={{ backgroundColor: 'red' }}
                  columns={[
                    {
                      name: 'status',
                      title: 'Status',
                      getCellValue: (row) => {
                        return row.status ? row.status : 'N/A';
                        // return `${row.docOrigin.AWB ? row.docOrigin.AWB.prefix : ''}-${
                        //   row.docOrigin.AWB ? row.docOrigin.AWB.serialNumber : ''
                        // }`;
                      },
                    },
                    {
                      name: 'user',
                      title: 'User',
                      getCellValue: (row) => (row.user ? row.user : 'N/A'),
                    },
                    {
                      name: 'awb',
                      title: 'AWB #',
                      getCellValue: (row) => {
                        return `
                      ${row.serialNumber ? row.serialNumber : 'N/A'}
                    `;
                      },
                    },
                    {
                      name: 'hawb',
                      title: 'HAWB #',
                      getCellValue: (row) =>
                        row.hawbNumber ? row.hawbNumber : 'N/A',
                    },
                    {
                      name: 'txnDate',
                      title: 'Txn Date',
                      getCellValue: (row) =>
                        moment(row.date).format('MM/DD/YYYY'),
                    },
                    {
                      name: 'txnTime',
                      title: 'Txn Time',
                      getCellValue: (row) =>
                        moment(row.date).format('HH:mm:ss'),
                    },
                    {
                      name: 'txnDescription',
                      title: 'Txn Description',
                      getCellValue: (row) =>
                        row.billType ? row.billType : 'N/A',
                    },
                    {
                      name: 'qty',
                      title: 'Qty',
                      getCellValue: (row) => (row.qty ? row.qty : 'N/A'),
                    },
                    {
                      name: 'uom',
                      title: 'UOM',
                      getCellValue: (row) => (row.trasn ? row.trasn : 'N/A'),
                    },
                    {
                      name: 'airline',
                      title: 'Airline',
                      getCellValue: (row) =>
                        row.terminalOp ? row.terminalOp : 'N/A',
                    },
                    {
                      name: 'flight',
                      title: 'Flight #',
                      getCellValue: (row) =>
                        row.flightNumber ? row.flightNumber : 'N/A',
                    },
                    {
                      name: 'originAirport',
                      title: 'Origin Airport',
                      getCellValue: (row) =>
                        row.airportOrigin ? row.airportOrigin : 'N/A',
                    },
                    {
                      name: 'usAirport',
                      title: 'US Arrival Airport',
                      getCellValue: (row) =>
                        row.arrivalAirport ? row.arrivalAirport : 'N/A',
                    },
                    {
                      name: 'shipperName',
                      title: 'Shipper Name',
                      getCellValue: (row) =>
                        row.shipName ? row.shipName : 'N/A',
                    },
                    {
                      name: 'shipperAddress',
                      title: 'Shipper Address',
                      getCellValue: (row) =>
                        row.shipAddress ? row.shipAddress : 'N/A',
                    },
                    {
                      name: 'shipperCity',
                      title: 'Shipper City',
                      getCellValue: (row) =>
                        row.shipCity ? row.shipCity : 'N/A',
                    },
                    {
                      name: 'shipperCountry',
                      title: 'Shipper Country',
                      getCellValue: (row) =>
                        row.shipCountry ? row.shipCountry : 'N/A',
                    },
                    {
                      name: 'shipperZip',
                      title: 'Shipper Zip Code',
                      getCellValue: (row) =>
                        row.shipPostalCode ? row.shipPostalCode : 'N/A',
                    },
                    {
                      name: 'consigneeName',
                      title: 'Consignee Name',
                      getCellValue: (row) =>
                        row.cneName ? row.cneName : 'N/A',
                    },
                    {
                      name: 'consigneeAddress',
                      title: 'Consignee Address',
                      getCellValue: (row) =>
                        row.cneAddress ? row.cneAddress : 'N/A',
                    },
                    {
                      name: 'consigneeCity',
                      title: 'Consignee City',
                      getCellValue: (row) =>
                        row.cneCity ? row.cneCity : 'N/A',
                    },
                    {
                      name: 'consigneeCountry',
                      title: 'Consignee Country',
                      getCellValue: (row) =>
                        row.cneCountry ? row.cneCountry : 'N/A',
                    },
                    {
                      name: 'consigneeZip',
                      title: 'Consignee Zip Code',
                      getCellValue: (row) =>
                        row.cnePostalCode ? row.cnePostalCode : 'N/A',
                    },
                    {
                      name: 'cargoDescription',
                      title: 'Cargo Description',
                      getCellValue: (row) =>
                        row.cargoDesc ? row.cargoDesc : 'N/A',
                    },
                    {
                      name: 'cargoPiece',
                      title: 'Cargo Piece Count',
                      getCellValue: (row) =>
                        row.numPieces ? row.numPieces : 'N/A',
                    },
                    {
                      name: 'cargoWeight',
                      title: 'Cargo Weight',
                      getCellValue: (row) => (row.weight ? row.weight : 'N/A'),
                    },
                    {
                      name: 'lbsKgs',
                      title: 'LBS or KGS',
                      getCellValue: (row) => {
                        return row.weightCode ? row.weightCode : 'N/A';
                      },
                    },
                    {
                      name: 'countryOfOrigin',
                      title: 'Country of Origin',
                      getCellValue: (row) =>
                        row.originGoods ? row.originGoods : 'N/A',
                    },
                    {
                      name: 'value',
                      title: 'Value',
                      getCellValue: (row) =>
                        row.declaredValue ? row.declaredValue : 'N/A',
                    },
                    {
                      name: 'currency',
                      title: 'Currency',
                      getCellValue: (row) =>
                        row.currencyCode ? row.currencyCode : 'N/A',
                    },
                    {
                      name: 'hts',
                      title: 'HTS',
                      getCellValue: (row) =>
                        row.hazmatCode ? row.hazmatCode : 'N/A',
                    },
                    {
                      name: 'package',
                      title: 'Package Tracking #',
                      getCellValue: (row) =>
                        row.pkTrackingId ? row.pkTrackingId : 'N/A',
                    },
                  ]}
                >
                  <SortingState
                  // defaultSorting={[{ columnName: 'product', direction: 'asc' }]}
                  />
                  <IntegratedSorting />
                  <FilteringState defaultFilters={[]} />
                  <IntegratedFiltering />
                  <PagingState defaultCurrentPage={0} pageSize={50} />
                  <IntegratedPaging />
                  <Table
                    columnExtensions={[
                      { columnName: 'shipperAddress', wordWrapEnabled: true },
                      { columnName: 'shipperName', wordWrapEnabled: true },
                      { columnName: 'user', width: '200' },
                    ]}
                    // containerComponent={ContainerTable}
                  />
                  <TableFilterRow />
                  <TableHeaderRow showSortingControls />
                  <PagingPanel containerComponent={PagingContainer} />
                </Grid>
              )}
            </CardText>
          </Card>
        </Cell>
      ) : null}
    </Grid2>
  );
};
