export const dateFormats = {
  grid_full_date: 'MM/DD/YYYY HH:mm:ss A',
};

export const sideBar = {
  toolPanels: [
    {
      id: 'columns',
      labelDefault: 'Columns',
      labelKey: 'columns',
      iconKey: 'columns',
      toolPanel: 'agColumnsToolPanel',
      toolPanelParams: {
        suppressRowGroups: true,
        suppressValues: true,
        suppressPivots: true,
        suppressPivotMode: true,
        suppressSideButtons: false,
        suppressColumnFilter: false,
        suppressColumnSelectAll: false,
        suppressColumnExpandAll: false,
      },
    },
    {
      id: 'filters',
      labelDefault: 'Filters',
      labelKey: 'filters',
      iconKey: 'filter',
      toolPanel: 'agFiltersToolPanel',
    },
  ],
  defaultToolPanel: '',
};

export const FSNOptions = [
  { label: 'OPTIONS', value: '', hidden: true },
  { label: 'Create FSQ', value: 'FSQ' },
  { label: 'Create ASN', value: 'ASN' },
  { label: 'Print', value: 'print' },
];
