import React from 'react';
import styled from 'styled-components';
import Main from './Main';

function Table(props) {
  return (
    <div>
      <MainWrap>
        <Main {...props}></Main>
      </MainWrap>
    </div>
  );
}

const MainWrap = styled.div``;

export default Table;
