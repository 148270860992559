import {
  Card,
  CardTitle,
  CardText,
  DataTable,
  TableBody,
  TableRow,
  TableColumn,
  CircularProgress
} from 'react-md';
import React, { Fragment } from 'react';

export default props => {
  let { value, title, id, loading } = props;

  return (
    <Card raise tableCard style={{ height: '100%' }}>
      <CardTitle title={title} subtitle="Top 5" />
      <CardText>
        {!loading ? (
          <Fragment>
            {value.length > 0 ? (
              <DataTable plain style={{ height: '240px' }}>
                {value.map((d, i) => (
                  <TableBody id="data-table" key={i}>
                    <TableRow key={i}>
                      <TableColumn>{d.name}</TableColumn>
                      <TableColumn>{d.transactions}</TableColumn>
                    </TableRow>
                  </TableBody>
                ))}
              </DataTable>
            ) : (
              'No records found for selected date range.'
            )}
          </Fragment>
        ) : (
          <div id="data-table-loading">
            <CircularProgress
              style={{ height: 170 }}
              scale={4}
              centered={true}
              id={id}
              key={id}
            />
          </div>
        )}
      </CardText>
    </Card>
  );
};
