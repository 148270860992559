import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Cell, Card, CardTitle, Button, CardText } from 'react-md';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
// import feathers from '../../services/feathers';

export default (props) => {
  const { close, AWB, flightNumber, type, getManifest } = props;
  const [deleteBody, setBody] = useState({
    prefix: '',
    serialNumber: '',
    type: '',
    flightNumber: null,
  });
  const [, , fetchDelete] = useApi(
    'ccapi',
    'documents/deletemanifest',
    undefined,
    'post'
  );
  if (AWB) {
    useEffect(() => {
      setBody({
        prefix: AWB.split('-')[0],
        serialNumber: AWB.split('-')[1],
        //hawbNumber: HAWB || ''
        flightNumber: flightNumber,
        type: type,
      });
    }, [AWB]);
  }

  const del = async () => {
    let message = null;
    if (deleteBody.prefix !== '') {
      let [res, err] = await fetchDelete({ body: deleteBody });
      if (err) {
        console.log('Error...', err);
        return;
      }
      if (res.message) {
        message = res.message;
      }

      if (res.error) toast.error(message);
      else toast.success(message);
    }
  };

  return (
    <Fragment>
      <Grid>
        <Cell size={8} tabletSize={8} phoneSize={12}>
          <Card>
            <CardTitle title={`Do you want to delete Manifest ${AWB}?`} />
            <CardText>
              <Grid>
                <Cell size={2}>
                  <Button
                    raised
                    secondary
                    iconBefore={false}
                    onClick={async (e) => {
                      await del();
                      close();
                      getManifest();
                    }}
                  >
                    Delete
                  </Button>
                </Cell>
                <Cell size={2}>
                  <Button
                    raised
                    secondary
                    iconBefore={false}
                    onClick={(e) => {
                      close();
                    }}
                  >
                    Close
                  </Button>
                </Cell>
              </Grid>
            </CardText>
          </Card>
        </Cell>
      </Grid>
    </Fragment>
  );
};
