import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import { AgGridReact } from 'ag-grid-react';
import 'chart.js';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardText,
  Cell,
  DatePicker,
  FontIcon,
  Grid,
  TableCardHeader,
  TextField
} from 'react-md';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import DefaultCell from '../../components/tables/DefaultCell';
import { useApi } from '../../services/api';
import feathers from '../../services/feathers';
import DocStatus from './DocStatusEnum';

export default props => {
  const [, , fetchDocuments] = useApi(
    'ccapi',
    'documents/search',
    undefined,
    'get'
  );
  const [range, setRange] = useState({
    from: moment()
      .subtract(1, 'day')
      .startOf('day'),
    to: moment().endOf('day')
  });
  const [awb, setAwb] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(50);
  const [filter, setFilter] = useState([]);
  const [value, setValue] = useState({
    serialNumber: '',
    hawbNumber: '',
    shipper: '',
    consignee: '',
    flight: '',
    eta: '',
    destination: '',
    origin: ''
  });

  const [frameworkComponents] = useState({
    defaultCell: DefaultCell
  });

  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState([]);

  useEffect(() => {
    console.log('Initial data request');
    getManifests();
  }, []);

  const getManifests = async () => {
    setLoading(true);

    try {
      // getDocuments({
      //   from: moment()
      //     .subtract(1, 'day')
      //     .startOf('day')
      //     .format('x'),
      //   to: moment()
      //     .endOf('day')
      //     .format('x'),
      //   types: ['FRI', 'FXI', 'FRC', 'FXC', 'FRX', 'FXX'],
      //   awb: awb
      // });

      let result = await feathers.service('manifests').find({
        query: {
          $agregation: {}
        }
      });

      setLoading(false);

      console.log(result[0].data);

      setColumnDefs([
        {
          headerName: 'Doc Type',
          valueGetter: params => {
            const { House, Master } = params.data;
            return Master || House ? `Master` : `Simple`;
          },
          filter: true,
          sortable: true
        },
        {
          headerName: 'AWB#',
          valueGetter: params => {
            const { _id } = params.data;
            return (_id && `${_id.prefix}-${_id.serialNumber}`) || 'N/A';
          },
          cellRenderer: 'defaultCell',
          cellRendererParams: params => {
            const { _id } = params.data;

            return {
              to: {
                pathname: `/manifest-details/${_id.prefix}-${_id.serialNumber}`
              }
            };
          },
          filter: true,
          sortable: true
        }
      ]);

      setRowData(result[0].data);
    } catch (error) {
      setLoading(false);
      console.error('Request error:', error.message);
    }
  };

  // useEffect(() => {
  //   setLoading(false);
  // }, [loading]);

  const getDocOrigin = data => {
    const { docOrigin } = data;
    return docOrigin;
  };

  const getDocuments = async query => {
    setLoading(true);

    // console.log(query);

    const [searchResult, err] = await fetchDocuments({ query: query });
    // console.log('searchResult:', searchResult, err);
    setLoading(false);

    if (err) return;
    setFilter(searchResult.slice(0, 50));
    setData(searchResult);

    setColumnDefs([
      {
        headerName: 'Origin',
        valueGetter: params => {
          const { WBL } = getDocOrigin(params.data);
          return (WBL && WBL.airportOrigin) || 'N/A';
        }
      },
      {
        headerName: 'Destination',
        valueGetter: params => {
          const { CCL } = getDocOrigin(params.data);
          return (CCL && CCL.arrivalAirport) || 'N/A';
        }
      },
      {
        headerName: 'Shipper',
        valueGetter: params => {
          const { SHP } = getDocOrigin(params.data);
          return (SHP && SHP.name && SHP.name.slice(0, 10)) || 'N/A';
        }
      },
      {
        headerName: 'Consignee',
        valueGetter: params => {
          const { CNE } = getDocOrigin(params.data);
          return (CNE && CNE.name && CNE.name.slice(0, 10)) || 'N/A';
        }
      },
      {
        headerName: 'Piece Count',
        valueGetter: params => {
          const { WBL } = getDocOrigin(params.data);
          return (WBL && WBL.numPieces) || 'N/A';
        }
      },
      {
        headerName: 'Weight',
        valueGetter: params => {
          const { WBL } = getDocOrigin(params.data);
          return (WBL && `${WBL.weight}${WBL.weightCode}`) || 'N/A';
        }
      },
      {
        headerName: 'Status',
        valueGetter: params => {
          return DocStatus.properties[DocStatus.DRAFT].label;
        },
        cellRenderer: 'defaultCell'
      },
      {
        headerName: 'Status Notification',
        valueGetter: params => {
          return 'From FSN';
        },
        cellRenderer: 'defaultCell'
      }
    ]);

    setRowData(searchResult);
  };

  const applyFilter = () => {
    let query = {};
    let from = moment(range.from).startOf('day');
    let to = moment(range.to).endOf('day');
    query.to = to.format('x');
    query.from = from.format('x');
    query.types = ['FRI', 'FXI', 'FRC', 'FXC', 'FRX', 'FXX'];
    query.awb = awb;
    getDocuments(query);
  };

  const handlePagination = (start, rowsPerPage) => {
    setRows(rowsPerPage);
    setFilter(data.slice(start, start + rowsPerPage));
  };

  const handleEvent = (e, path, segment) => {
    setValue({ [path]: e });
    setFilter(
      data.filter(f => f.docOrigin[segment][path].toUpperCase().includes(e))
    );
  };

  return (
    <Fragment>
      <Grid style={{ paddingTop: 0 }}>
        <Cell size={3} phoneSize={12} tabletSize={12}>
          {/* <span style={{ fontSize: 35, color: 'gray' }}>Search Manifest</span> */}
        </Cell>
        <DatePicker
          id="inline-date-picker-portait"
          label="from"
          value={moment(range.from).format('L')}
          defaultValue={moment(range.from).format('L')}
          maxDate={new Date(range.to)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={e => setRange({ ...range, from: e })}
        />
        <DatePicker
          id="inline-date-picker-portait"
          label="to"
          value={moment(range.to).format('L')}
          defaultValue={moment(range.to).format('L')}
          minDate={new Date(range.from)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={e => setRange({ ...range, to: e })}
        />

        <TextField
          id="floating-center-title"
          label="AirWay Bill"
          lineDirection="center"
          className="md-cell md-cell--2"
          onChange={e => setAwb(e)}
        />
        <Button
          className="md-cell"
          style={{ marginTop: 25 }}
          icon
          iconEl={
            <FontIcon
              secondary
              className="fas fa-search"
              onClick={applyFilter}
            />
          }
        />
      </Grid>
      <Grid>
        <Cell size={12}>
          <Card raise tableCard>
            <CardText>
              <TableCardHeader title="ACE Air eManifest" visible={false} />
              {loading ? (
                <ActivityIndicator message="Loading data, please wait..." />
              ) : (
                <Fragment>
                  <div
                    className="ag-theme-material"
                    style={{
                      height: '70vh',
                      width: '100%'
                    }}
                  >
                    <AgGridReact
                      reactNext={true}
                      columnDefs={columnDefs}
                      rowData={rowData}
                      frameworkComponents={frameworkComponents}
                    />
                  </div>
                </Fragment>
              )}
            </CardText>
          </Card>
        </Cell>
      </Grid>
    </Fragment>
  );
};
