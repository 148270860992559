import { Cell, Grid } from 'react-md';
import { FSN2 } from '../../components/';
import React, { Fragment, useEffect } from 'react';
import '../style.css';
import Axios from 'axios';
const env = process.env;

export default (props) => {
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    const check = async () => await checkToken();
    check();
  }, []);

  return (
    <Fragment>
      <Grid>
        <Cell size={12} phoneSize={12} tabletSize={12}>
          <span style={{ fontSize: 35, color: 'gray' }}>
            Arrival Status Notification
          </span>
        </Cell>
      </Grid>
      <Grid>
        <Cell size={12}>
          <FSN2 {...props} />
        </Cell>
      </Grid>
    </Fragment>
  );
};
