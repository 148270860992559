import React, { Component } from 'react';
import { FlatfileButton } from '@flatfile/react';
import { Flat_File_Importer_License_Key } from '../../constants/Keys';
import Slide from '@material-ui/core/Slide';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import {
  createFlatfile,
  registerRecordHook,
  sendDataOnly
} from '../../functions/flatfileAirFunctions';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class FlatFileImporterOnlyButtonComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: this.props.text ? this.props.text : 'Update',
      csvData: [],
      ...createFlatfile(this.props.schema)
    };

    this.customer = { userId: '123456', name: 'Test' };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState) {}

  render() {
    return (
      <ButtonOnly
        licenseKey={Flat_File_Importer_License_Key}
        customer={this.customer}
        settings={{
          title: `Import ${this.state.title}`,
          type: this.state.type,
          fields: this.state.fields,
          managed: true,
          styleOverrides: {
            borderRadius: '4px',
            primaryButtonColor: '#019EE1',
            primaryButtonFontSize: '1.5rem',
            primaryButtonFontColor: '#fff',
            secondaryButtonColor: '#c0bcbc',
            secondaryButtonFontSize: '1.5rem',
            secondaryButtonFontColor: '#fff',
            invertedButtonColor: '#019EE1',
            successColor: '#019EE1',
            warningColor: '#fce444',
            fontFamily: 'inherit'
          }
        }}
        onData={async results => {
          let data = await sendDataOnly(
            results.data,
            this.props.setDataFlatfile
          );

          const csvData =
            Array.isArray(results && results.$data) &&
            results.$data.map(({ data }) => data);
          // this.props && this.props.setDataFlatfile && this.props.setOpen;
          this.props.setDataFlatfile(csvData);
          // this.props.setOpen(true);

          // ----------
          if (data.error) toast.error(data.error);
          // console.log('results :>> ', results);
          return data.message;
        }}
        onCancel={() => {
          console.log('Close');
        }}
        onRecordInit={async record => {
          return await registerRecordHook(record, this.state).then(rest => {
            return rest;
          });
        }}
        onRecordChange={async record => {
          return await registerRecordHook(record, this.state).then(rest => {
            return rest;
          });
        }}
      >
        {this.state.text}
      </ButtonOnly>
    );
  }
}

const ButtonOnly = styled(FlatfileButton)`
  // width: 200px;
  color: #fff;
  background-color: #019ee1;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  border: 0;
  cursor: pointer;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
`;

export default FlatFileImporterOnlyButtonComponent;
