import React, { Component } from 'react';
import { FlatfileButton } from '@flatfile/react';
import { Flat_File_Importer_License_Key } from '../../constants/Keys';
import ShowError from '../../views/documents/ShowError';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import {
  Card,
  CardTitle,
  CardText,
  FontIcon,
  DialogContainer,
  Button
} from 'react-md';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide
} from '@material-ui/core';

import {
  createFlatfile,
  registerRecordHook,
  sendData,
  saveAirAMSType86,
  getSuscriptionType86
} from '../../functions/flatfileAirFunctions';
import UploadModal from '../UploanModal/';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

class FlatFileImporterComponentCCAir extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: '',
      listError: [],
      sendEmail: false,
      showModal: false,
      resultData: {},
      dataMapped: {},
      ...createFlatfile(this.props.schema),
      isDisabled: this.props.isDisabled,
      setIsDisabled: this.props.setIsDisabled,
      open: false,
      legend: '0 of 0 documents created...',
      progress: 0
    };

    this.customer = { userId: '123456', name: 'Test' };
  }

  async componentDidMount() {
    this.setState(await getSuscriptionType86());
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isDisabled !== this.props.isDisabled) {
      this.setState({ isDisabled: this.props.isDisabled });
    }
  }

  closeToastClick(e) {
    this.setState({ error: '', listError: [] });
    this.handleClose();
  }

  handleClose() {
    let close = this.state.error ? true : false;
    return close;
  }

  render() {
    const setOpen = open => this.setState({ ...this.state, open });
    const setProgress = (progress, legend) =>
      this.setState({ ...this.state, progress, legend });
    const setErrorProgress = (progressError, legendError, progress, legend) =>
      this.setState({
        ...this.state,
        progressError,
        legendError,
        progress,
        legend
      });
    return (
      <>
        <UploadModal
          open={this.state.open}
          legend={this.state.legend}
          progress={this.state.progress}
          progressError={this.state.progressError}
          legendError={this.state.legendError}
        />
        <Dialog
          maxWidth="md"
          width="md"
          fullWidth={true}
          open={this.state.error ? true : false}
          onClose={this.handleClose.bind(this)}
          onBackdropClick={this.handleClose.bind(this)}
          onEscapeKeyDown={this.handleClose.bind(this)}
          TransitionComponent={Transition}
        >
          <DialogTitle id="responsive-dialog-title" style={{ padding: '0px' }}>
            <AppBar position="sticky" style={{ backgroundColor: '#e74c3c' }}>
              <Toolbar>
                <Typography variant="h6" style={{ color: 'white' }}>
                  {'Errors'}
                </Typography>
                <div
                  style={{ display: 'flex', flexGrow: 1, alignItems: 'center' }}
                ></div>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={this.closeToastClick.bind(this)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
          </DialogTitle>
          <DialogContent>
            {this.state.listError && this.state.listError.length > 0 ? (
              <ShowError rows={this.state.listError}> </ShowError>
            ) : (
              <CardText> {this.state.error} </CardText>
            )}
          </DialogContent>
        </Dialog>

        <FlatfileButton
          licenseKey={Flat_File_Importer_License_Key}
          customer={this.customer}
          settings={{
            title: `Import ${this.state.title}`,
            type: this.state.type,
            fields: this.state.fields,
            managed: true,
            styleOverrides: {
              borderRadius: '4px',
              primaryButtonColor: '#019EE1',
              primaryButtonFontSize: '1.5rem',
              primaryButtonFontColor: '#fff',
              secondaryButtonColor: '#c0bcbc',
              secondaryButtonFontSize: '1.5rem',
              secondaryButtonFontColor: '#fff',
              invertedButtonColor: '#019EE1',
              successColor: '#019EE1',
              warningColor: '#fce444',
              fontFamily: 'inherit'
            }
          }}
          onData={async results => {
            let data = await sendData(
              results,
              this.props.schema,
              this.state.sendEmail,
              setOpen,
              setProgress,
              setErrorProgress
            );
            data.error && this.setState({ error: data.error });
            data.errors && this.setState({ listError: data.errors });
            data.resultData && this.setState({ resultData: data.resultData });
            data.dataMapped && this.setState({ dataMapped: data.dataMapped });
            data.showModal && this.setState({ showModal: data.showModal });

            if (data.message === 'Success!')
              setTimeout(() => {
                this.props.history.push('/awb-history');
              }, 1000);

            this.state.setIsDisabled(false);
            return data.message;
          }}
          onCancel={() => {
            this.state.setIsDisabled(false);
          }}
          onRecordInit={async record => {
            return await registerRecordHook(record, this.state).then(rest => {
              return rest;
            });
          }}
          onRecordChange={async record => {
            return await registerRecordHook(record, this.state).then(rest => {
              return rest;
            });
          }}
          render={(importer, launch) => {
            return (
              <ButtonLoader
                className="Button"
                disabled={this.state.isDisabled}
                onClick={() => {
                  this.state.setIsDisabled(true);
                  launch();
                }}
                onDoubleClick={() => {
                  this.state.setIsDisabled(true);
                  launch();
                }}
              >
                <ButtonContainer>
                  <CloseContainer></CloseContainer>
                  <ButtonIconUpload style={{ fontSize: '4rem' }}>
                    cloud_upload
                  </ButtonIconUpload>
                  <ButtonSpan>{this.state.title}</ButtonSpan>
                </ButtonContainer>
              </ButtonLoader>
            );
          }}
        />

        <DialogContainer
          id="speed-boost1"
          visible={this.state.showModal}
          dialogStyle={{ width: 'auto' }}
          contentStyle={{ paddingBottom: '14px' }}
          size={8}
          actions={[
            {
              raised: true,
              secondary: true,
              children: 'Save',
              onClick: async () => {
                this.setState({ error: '' });
                this.setState({ listError: [] });
                let data = await saveAirAMSType86(
                  this.state.resultData,
                  this.state.dataMapped,
                  false,
                  this.props.schema,
                  this.state.sendEmail
                );

                data.error && this.setState({ error: data.error });
                data.errors && this.setState({ listError: data.errors });
                data.resultData &&
                  this.setState({ resultData: data.resultData });
                data.dataMapped &&
                  this.setState({ dataMapped: data.dataMapped });

                !this.state.error && toast.success('Manifest Saved!.');

                this.setState({
                  ...this.state,
                  showModal: false,
                  dataMapped: {},
                  resultData: {}
                });

                if (data.message === 'Success!')
                  setTimeout(() => {
                    this.props.history.push('/awb-history');
                  }, 1000);
              }
            },
            {
              raised: true,
              secondary: true,
              children: 'Cancel',
              onClick: () => {
                this.setState({
                  ...this.state,
                  showModal: false,
                  dataMapped: {},
                  resultData: {},
                  error: '',
                  listError: []
                });
              }
            }
          ]}
          onHide={() => {}}
        >
          <Card size={12} tabletSize={10} phoneSize={12}>
            <CardTitle
              title={`For Type 86 filing, you need to activate the product.
              Would you still want to save the manifest? 
              \n(Warning: No type86 information will be saved!)`}
            />
          </Card>
        </DialogContainer>
      </>
    );
  }
}

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas: 'icon' 'text';
  height: 100%;
  width: 100%;
  background-color: white;
  :hover {
    background-color: rgb(239, 239, 239);
  }
`;

const ButtonLoader = styled(Button)`
  height: 225px;
  width: 335px;
  margin: 15px;
  padding: 0px;
  border-width: 0px;
  border-radius: 0.25rem;
  border: 0;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);
  transition: background 0.15s, box-shadow 0.3s, color 0.15s;
  &:disabled {
    opacity: 0.6;
    cursor: no-drop;
  }
`;

const ButtonIconUpload = styled(FontIcon)`
  color: #019ee1;
  height: 4rem !important;
  width: 4rem !important;
  margin: 0.25rem auto !important;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonSpan = styled.span`
  font-weight: bold;
  font-size: 18px;
  color: #4f5d73;
`;

export default FlatFileImporterComponentCCAir;
