import { Link } from 'react-router-dom';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import React, { useState, useEffect, Fragment } from 'react';
import {
  Grid,
  Cell,
  Card,
  Toolbar,
  ListItem,
  MenuButton,
  FontIcon,
  Button,
  DialogContainer,
} from 'react-md';
import TableDetails from '../../components/tables/TableDetails';
import {
  Grid as GridDX,
  Table as TableDX,
  TableHeaderRow,
  PagingPanel,
  TableRowDetail,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  RowDetailState,
} from '@devexpress/dx-react-grid';
import Axios from 'axios';
const env = process.env;
export default (props) => {
  const [partnership, setPartnership] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [, partnerships, fetchPartnership] = useApi('ccapi', 'partnership', []);
  const [, , fetchDelete] = useApi('ccapi', 'partnership', {}, 'delete');

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        fetchPartnership();
      }
    }
    tttt();
  }, []);

  const ContainerTable = ({ ...restProps }) => {
    return (
      <TableDX.Container
        style={{
          overflow: 'visible',
        }}
        {...restProps}
      />
    );
  };
  const CellDX = (props) => {
    const { column, value } = props;
    if (column && column.name === 'options') {
      return (
        <TableDX.Cell
          style={{
            overflow: 'visible',
          }}
        >
          {value}
        </TableDX.Cell>
      );
    }
    return <TableDX.Cell {...props} />;
  };
  const profile = JSON.parse(localStorage.profile);
  let columns = [
    {
      name: 'name',
      title: 'Name',
      getCellValue: (row) => {
        return `${row.sourceName}`;
      },
    },
    {
      name: 'SourceCode',
      title: 'Source Code',
      getCellValue: (row) => {
        return `${row.sourceCode || ''}`;
      },
    },
    {
      name: 'address',
      title: 'Address',
      getCellValue: (row) => {
        return `${row.sourceAddress || ''}`;
      },
    },
    {
      name: 'DestinationCode',
      title: 'Destination Code',
      getCellValue: (row) => {
        return `${row.destinationCode || ''}`;
      },
    },
    {
      name: 'options',
      title: 'Options',
      getCellValue: (row) => {
        return (
          <MenuButton
            icon
            id="menu-button-1"
            menuItems={
              profile.role === 'support' || profile.role === 'admin'
                ? [
                    <ListItem
                      key={1}
                      primaryText="Edit"
                      rightIcon={<FontIcon>edit</FontIcon>}
                      onClick={() => {
                        props.history.push(`/partnership/${row._id}`);
                      }}
                    />,

                    <ListItem
                      key={2}
                      primaryText="Delete"
                      rightIcon={<FontIcon>delete</FontIcon>}
                      onClick={() => {
                        setPartnership(row);
                        setShowModal(true);
                      }}
                    />,
                  ]
                : [
                    <ListItem
                      key={3}
                      primaryText="Edit"
                      rightIcon={<FontIcon>edit</FontIcon>}
                      onClick={() => {
                        props.history.push(`/partnership/${row._id}`);
                      }}
                    />,
                  ]
            }
            centered
          >
            more_vert
          </MenuButton>
        );
      },
    },
  ];
  let columnsClients = [
    {
      name: 'name',
      title: 'Name',
      getCellValue: (s) => {
        return (
          <Link to={`../client/${s._id}`}>
            {s.contact ? `${s.contact.name} ${s.contact.lastName}` : ''}
          </Link>
        );
      },
    },
    {
      name: 'email',
      title: 'Email',
      getCellValue: (s) => (s.contact ? s.contact.email : ''),
    },
    {
      name: 'phone',
      title: 'Phone',
      getCellValue: (s) => (s.contact ? s.contact.phone : ''),
    },
  ];

  return (
    <Fragment>
      <Grid className="grid-example" style={{ marginBottom: 50 }}>
        <Cell desktopOffset={2} size={8}>
          <Card>
            <Toolbar
              colored
              title="Partnerships"
              actions={
                <Button
                  component={Link}
                  to="/partnership"
                  tooltipLabel="Add partnership"
                  style={{ marginTop: '30px' }}
                  secondary
                  floating
                >
                  add
                </Button>
              }
            ></Toolbar>
            <GridDX rows={partnerships} columns={columns}>
              <RowDetailState />
              <SortingState
                defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
                columnExtensions={[
                  { columnName: 'options', sortingEnabled: false },
                ]}
              />
              <IntegratedSorting />
              <FilteringState
                columnExtensions={[
                  { columnName: 'options', filteringEnabled: false },
                ]}
              />
              <IntegratedFiltering />
              <PagingState defaultCurrentPage={0} defaultPageSize={10} />
              <IntegratedPaging />
              <TableDX
                containerComponent={ContainerTable}
                cellComponent={CellDX}
              />
              <TableFilterRow />
              <TableHeaderRow showSortingControls />
              <TableRowDetail
                contentComponent={(propsRow) => (
                  <TableDetails
                    rows={propsRow.row.Clients}
                    columns={columnsClients}
                    showFilter={false}
                    showSortingControls={false}
                    {...propsRow}
                  />
                )}
              />
              <PagingPanel pageSizes={[10, 25, 50, 0]} />
            </GridDX>
          </Card>
        </Cell>
      </Grid>
      <DialogContainer
        id="speed-boost"
        visible={showModal}
        title="Do you want to permanently delete this partnership?"
        actions={[
          {
            secondary: true,
            children: 'Delete',
            onClick: async () => {
              let [response, error] = await fetchDelete({
                params: [partnership._id],
              });
              await fetchPartnership({});
              setShowModal(false);
              if (!error) {
                toast.success(response.message);
              } else {
                toast.success(response.message);
              }
            },
          },
          {
            primary: true,
            children: 'Cancel',
            onClick: () => {
              setShowModal(false);
            },
          },
        ]}
        onHide={() => {}}
      ></DialogContainer>
    </Fragment>
  );
};
