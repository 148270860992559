import {
  Grid,
  Cell,
  DatePicker,
  Button,
  FontIcon,
  Card,
  TextField,
  CardText,
  DataTable,
  TableBody,
  TableRow,
  TableColumn,
  TableHeader,
  TablePagination
} from 'react-md';
import React, { Fragment, useEffect, useState } from 'react';
import { useApi } from '../../services/api';
import 'chart.js';
import moment from 'moment';
import Axios from 'axios';
const env = process.env;

export default props => {
  const [, , fetchDocuments] = useApi(
    'ccapi',
    'documents/search',
    undefined,
    'get'
  );
  const [range, setRange] = useState({
    from: new Date().setDate(new Date().getDate() - 1),
    to: Date.now()
  });
  const [awb, setAwb] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(50);
  const [filter, setFilter] = useState([]);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        getDocuments({
          from: new Date().setHours(
            new Date().getHours() - new Date().getHours()
          ),
          to: Date.now(),
          types: ['FDM']
        });
      }
    }
    tttt();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  const getDocuments = async query => {
    const [searchResult, err] = await fetchDocuments({ query: query });
    if (err) return;
    setFilter(searchResult.slice(0, 50));
    setData(searchResult);
  };

  const applyFilter = () => {
    let query = {};
    let from = new Date(range.from);
    let to = new Date(range.to);
    query.to = to.setDate(to.getDate());
    query.from = from.setDate(from.getDate());
    query.types = ['FDM'];
    query.awb = awb;
    getDocuments(query);
  };

  const handlePagination = (start, rowsPerPage) => {
    setRows(rowsPerPage);
    setFilter(data.slice(start, start + rowsPerPage));
  };

  return (
    <Fragment>
      <Grid style={{ paddingTop: 0 }}>
        <Cell size={3} phoneSize={12} tabletSize={12}>
          <span style={{ fontSize: 35, color: 'gray' }}>Search Departure</span>
        </Cell>
        <DatePicker
          id="inline-date-picker-portait"
          label="from"
          value={moment(range.from).format('L')}
          defaultValue={moment(range.from).format('L')}
          maxDate={new Date(range.to)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={e => setRange({ ...range, from: e })}
        />
        <DatePicker
          id="inline-date-picker-portait"
          label="to"
          value={moment(range.to).format('L')}
          defaultValue={moment(range.to).format('L')}
          minDate={new Date(range.from)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={e => setRange({ ...range, to: e })}
        />

        <TextField
          id="floating-center-title"
          label="AirWay Bill"
          lineDirection="center"
          className="md-cell md-cell--2"
          onChange={e => setAwb(e)}
        />
        <Button
          className="md-cell"
          style={{ marginTop: 25 }}
          icon
          iconEl={
            <FontIcon
              secondary
              className="fas fa-search"
              onClick={applyFilter}
            />
          }
        />
      </Grid>
      <Grid>
        {filter ? (
          <Cell size={12}>
            <Card raise tableCard>
              <CardText>
                <DataTable
                  baseId="simple-pagination"
                  fixedDividers
                  selectableRows={false}
                >
                  <TableHeader>
                    <TableRow>
                      <TableColumn className="header-style">
                        Doc Type
                      </TableColumn>
                      <TableColumn className="header-style">
                        AirWay Bill
                      </TableColumn>
                      <TableColumn className="header-style">
                        House Bill
                      </TableColumn>
                      <TableColumn className="header-style">
                        Shipper
                      </TableColumn>
                      <TableColumn className="header-style">
                        Consignee
                      </TableColumn>
                      <TableColumn className="header-style">
                        Piace Count
                      </TableColumn>
                      <TableColumn className="header-style">Weight</TableColumn>
                      <TableColumn className="header-style">Origin</TableColumn>
                      <TableColumn className="header-style">
                        Destination
                      </TableColumn>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {filter.map((content, i) => (
                      <TableRow key={i}>
                        {
                          <Fragment>
                            <TableColumn className="column-style">
                              {content.docOrigin.SMI.componentId}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {content.docOrigin.AWB.prefix}-
                              {content.docOrigin.AWB.serialNumber}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {content.docOrigin.AWB.hawbNumber}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {content.docOrigin.SHP.name &&
                                content.docOrigin.SHP.name.slice(0, 10)}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {content.docOrigin.CNE.name &&
                                content.docOrigin.CNE.name.slice(0, 10)}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {content.docOrigin.WBL.numPieces}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {content.docOrigin.WBL.weight}
                              {content.docOrigin.WBL.weightCode}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {content.docOrigin.WBL.airportOrigin}
                            </TableColumn>
                            <TableColumn className="column-style">
                              {content.docOrigin.CCL.arrivalAirport}
                            </TableColumn>
                          </Fragment>
                        }
                      </TableRow>
                    ))}
                  </TableBody>
                  <TablePagination
                    rows={data.length}
                    rowsPerPageLabel="rows"
                    rowsPerPage={rows}
                    onPagination={handlePagination}
                  />
                </DataTable>
              </CardText>
            </Card>
          </Cell>
        ) : null}
      </Grid>
    </Fragment>
  );
};
