import React from 'react';
import { Button as ButtonMUI } from '@material-ui/core';

const GridButton = ({ text, onClick, ...props }) => {
  const handleOnClick = () => onClick && onClick();
  return (
    <ButtonMUI onClick={handleOnClick} {...props}>
      {text}
    </ButtonMUI>
  );
};

export default GridButton;
