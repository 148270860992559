import React from 'react';

const Dropdown = ({ id, text: name, options = [], data = {}, onSelect }) => {
  const value = '';
  const className = 'ag-grid-dropdown';

  const onChange = async ({ target }) => {
    if (target) {
      const { value } = target;
      onSelect && onSelect(value, data);
    }
  };

  const selectProps = { name, id, onChange, className, value };

  const getOption = ({ label, ...props }, key) => (
    <option key={key} {...props}>
      {label}
    </option>
  );

  return <select {...selectProps}>{options.map(getOption)}</select>;
};

export default Dropdown;
