import { Link } from 'react-router-dom';
import _ from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import {
  DataTable,
  FontIcon,
  Toolbar,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  TextField,
  Button,
  Card,
  ListItem,
  MenuButton,
  TablePagination,
  DialogContainer
} from 'react-md';

import feathers from '../../services/feathers';
import { toast } from 'react-toastify';

export default props => {
  const { company } = props;
  const [suscriptionList, setSuscriptionList] = useState([]);
  const [suscriptionSliced, setSuscriptionSliced] = useState([]);
  const [asending, setAsending] = useState({
    clientName: false,
    name: false,
    email: false
  });
  const [showModal, setShowModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(false);

  let form;

  useEffect(() => {
    async function tttt() {
      getSuscriptions();
    }
    tttt();
  }, []);

  useEffect(() => {
    if (suscriptionList.length > 0) {
      setSuscriptionSliced(suscriptionList.slice(0, 15));
    } else {
      setSuscriptionSliced(suscriptionList);
    }
  }, [suscriptionList]);

  const getSuscriptions = async () => {
    // let data = await feathers.service('suscriptions').find({
    //   query: {
    //     //company: company,
    //     $sort: { name: 1 }
    //   }
    // });
    let data = await feathers.service('suscriptions').find({
      query: {
        $agregation: {
          suscriptionList: true,
          company: company
        }
      }
    });

    setSuscriptionList(data);
  };

  const sort = culumn => {
    let sort = _.orderBy(
      [...suscriptionList],
      [culumn],
      [!asending[culumn] ? 'asc' : 'desc']
    );
    setSuscriptionSliced([...sort]);
    setAsending({ ...asending, [culumn]: !asending[culumn] });
  };

  const filter = (v, filterBy) => {
    if (!v.length) {
      setSuscriptionSliced(suscriptionList);
    } else {
      setSuscriptionSliced(
        suscriptionList.filter(u => {
          if (filterBy == 'name') {
            return (
              u[filterBy].toLowerCase().includes(v.toLowerCase()) ||
              u['lastName'].toLowerCase().includes(v.toLowerCase())
            );
          } else {
            return u[filterBy].toLowerCase().includes(v.toLowerCase());
          }
        })
      );
    }
  };

  const deleteSuscription = async id => {
    try {
      let data = await feathers.service('suscriptions').remove(id);
      getSuscriptions();
      toast.success('Notification deleted');
    } catch (e) {
      toast.error('Suscription not saved');

      console.log('error:', e);
    }
  };

  return (
    <Fragment>
      <Card style={{ paddingBottom: '1px' }}>
        <Toolbar
          colored
          title="Notification Subscription List"
          actions={
            <Button
              component={Link}
              to={`/suscription/${company}`}
              tooltipLabel="Add Notification"
              style={{ marginTop: '30px' }}
              secondary
              floating
            >
              add
            </Button>
          }
        ></Toolbar>
        <DataTable baseId="123" plain>
          <TableHeader>
            <TableRow>
              <TableColumn
                sorted={asending['name']}
                onClick={() => sort('name')}
              >
                Suscription Name
              </TableColumn>
              <TableColumn
                sorted={asending['email']}
                onClick={() => sort('email')}
              >
                Email
              </TableColumn>
              {/* <TableColumn>Phone</TableColumn> */}
              <TableColumn
              // sorted={asending['isActivated']}
              // onClick={() => sort('isActivated')}
              >
                Notifications
              </TableColumn>
              <TableColumn />
            </TableRow>
          </TableHeader>
          <TableHeader>
            <TableRow key={0}>
              <TableColumn>
                {' '}
                <TextField
                  id="nameFull"
                  onChange={v => filter(v, 'name')}
                  leftIcon={<FontIcon>filter_list</FontIcon>}
                />
              </TableColumn>

              <TableColumn>
                {' '}
                <TextField
                  id="email"
                  onChange={v => filter(v, 'email')}
                  leftIcon={<FontIcon>filter_list</FontIcon>}
                />
              </TableColumn>
              <TableColumn></TableColumn>
              <TableColumn></TableColumn>
            </TableRow>
          </TableHeader>
          <TableBody>
            {suscriptionSliced.map((s, i) => (
              <TableRow key={i}>
                <TableColumn>{`${s.name} ${s.lastName}`}</TableColumn>
                {/* <TableColumn>{s.lastName} </TableColumn> */}
                <TableColumn>{s.email}</TableColumn>
                {/* <TableColumn>{s.phone}</TableColumn> */}
                <TableColumn>
                  {s.action.length > 0 && s.action[0].messageTypes
                    ? s.action[0].messageTypes.join(',')
                    : ''}
                </TableColumn>
                <TableColumn style={{ paddingRight: '0px' }}>
                  <MenuButton
                    id="menu"
                    icon
                    menuItems={[
                      <ListItem
                        key={1}
                        primaryText="Edit"
                        rightIcon={<FontIcon>edit</FontIcon>}
                        component={Link}
                        to={`/suscription/${company}/${s._id}`}
                        // onClick={() => {
                        //   props.history.push(
                        //     `/suscription/${company}/${s._id}`
                        //   );
                        //   //editSuscription(s._id);
                        // }}
                      />,
                      <ListItem
                        key={2}
                        primaryText="Delete"
                        rightIcon={<FontIcon>delete</FontIcon>}
                        onClick={() => {
                          setSubscriptionId(s._id);
                          setShowModal(true);
                        }}
                      />
                    ]}
                    centered
                  >
                    more_vert
                  </MenuButton>
                </TableColumn>
              </TableRow>
            ))}
          </TableBody>
          <TablePagination
            rows={suscriptionList.length}
            rowsPerPageLabel={'Rows'}
            onPagination={(s, rpp) =>
              setSuscriptionSliced(suscriptionList.slice(s, s + rpp))
            }
            //className={'footertTable'}
          />
        </DataTable>
      </Card>

      <DialogContainer
        id="speed-boost"
        visible={showModal}
        title="Do you want to permanently delete this notification subscription?"
        actions={[
          {
            secondary: true,
            children: 'Delete',
            onClick: async () => {
              deleteSuscription(subscriptionId);
              setSubscriptionId(null);
              setShowModal(false);
            }
          },
          {
            primary: true,
            children: 'Cancel',
            onClick: () => {
              setShowModal(false);
            }
          }
        ]}
        onHide={() => {}}
      ></DialogContainer>
    </Fragment>
  );
};
