import React, { useState } from 'react';
import { Fragment } from 'react';
import { TextField } from 'react-md';
import CargoDatepicker from '../../documents/Components/CargoDatepicker';

const Checks = (props) => {
  const { data, setData } = props;
  // const { onClose, AWB } = props;
  // const { prefix, serialNumber } = AWB;

  const ChangeDate = (date) => {
    setData({ ...data, date: date });
  };

  return (
    <Fragment>
      <CargoDatepicker date={data.date} ChangeDate={ChangeDate} />
      <TextField
        value={data.comment}
        onChange={(e) => setData({ ...data, comment: e })}
        multiline="true"
        id="outlined-basic"
        label="Comment"
        variant="outlined"
      />
    </Fragment>
  );
};

export default Checks;
