import React, { useState, useEffect } from 'react';
import {
  Card,
  CardText,
  Cell,
  Grid,
  TextField,
  SelectField,
  Subheader,
  Button,
  Switch,
} from 'react-md';
import { Typography, Button as ButtonMUI } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Axios from 'axios';
import { toast } from 'react-toastify';
import { useApi } from '../../../services/api';

const env = process.env;

const code = [
  '3 In-bond arrived at destination', //Sent by the bonded custodian at the CBP Control Destination
  '4 Local transfer of consolidated cargo', //to a Deconsolidator has been accomplished
  '7 In-bond exported at destination', //Sent by the bonded custodian at the CBP Control Destination
];

const FSN = (props) => {
  const { onClose, data, getData, AWB } = props;
  const { prefix, serialNumber, flightInfo } = data;
  const {
    AirlineFlight: impCarrier,
    Flight: flightNumber,
    Airline,
  } = flightInfo;
  const arrivalAirport = flightInfo['1st US Port of Arrival']
    ? flightInfo['1st US Port of Arrival'].split(' - ')[0]
    : '';
  const terminalOp = Airline ? Airline.split(' - ')[0] : '';
  const date = flightInfo['Arrival Date'] || moment();
  const arrivalDate = moment(date).format('DDMMM').toUpperCase();
  const [formData, setFormData] = useState({
    AWB: { prefix, serialNumber },
    ARR: { impCarrier, flightNumber, arrivalDate },
    CCL: { arrivalAirport, terminalOp },
    ASN: { statusCode: '' },
  });
  const [PARNs, setPARNs] = useState(false);
  const [startDate, setStartDate] = useState(Date.now());
  const [, , fetchDoc] = useApi('ccapi', 'documents', undefined, 'post');
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');

  useEffect(() => {
    setStartDate(moment(arrivalDate).valueOf());
  }, [arrivalDate]);

  useEffect(() => {
    if (!PARNs) {
      const genCharArray = (charA, charZ) => {
        var a = [],
          i = charA.charCodeAt(0),
          j = charZ.charCodeAt(0);
        for (; i <= j; ++i) {
          a.push(String.fromCharCode(i));
        }
        return a;
      };
      setPARNs(genCharArray('A', 'Z'));
    }
  }, [PARNs]);

  const setTextValues = (regex, long = 0, value = '', object = formData) => {
    if (regex) {
      if (value === '' || regex.test(value)) {
        if (value.length <= long) {
          setFormData({ ...object });
        }
      }
    }
  };

  const handleChange = (_, { target }) => {
    if (target) {
      const { id, value } = target;
      const upperValue = value ? value.toUpperCase() : '';
      const numberRe = /^[0-9\b]*$/;
      const textRe = /^[a-zA-Z0-9\b]*$/;
      const impCarrierRe = /^[^><.|_º,!"@·#&/()=?'¿¡`+*´¨Çç{}–…:; -]*$/;
      const arrivalPortRe = /^[a-zA-Z\b]*$/;

      let data = {
        ...formData,
        AWB: { ...formData.AWB },
        ARR: { ...formData.ARR },
        CCL: { ...formData.CCL },
        ASN: { ...formData.ASN },
      };

      if (id === 'prefix') {
        data.AWB[id] = upperValue;
        setTextValues(textRe, 3, upperValue, data);
      }
      if (id === 'serialNumber') {
        data.AWB[id] = upperValue;
        setTextValues(numberRe, 8, upperValue, data);
      }
      if (id === 'impCarrier') {
        data.ARR[id] = upperValue;
        setTextValues(impCarrierRe, 3, upperValue, data);
      }
      if (id === 'flightNumber') {
        data.ARR[id] = upperValue;
        setTextValues(numberRe, 8, value, data);
      }
      if (id === 'arrivalAirport') {
        data.CCL[id] = upperValue;
        setTextValues(arrivalPortRe, 3, upperValue, data);
      }
      if (id === 'terminalOp') {
        data.CCL[id] = upperValue;
        setTextValues(textRe, 3, upperValue, data);
      }
    }
  };

  const handleSelect = (value, id) => {
    if (id === 'arrivalRef' && value)
      setFormData({ ...formData, ARR: { ...formData.ARR, [id]: value } });

    if (id === 'statusCode' && value) {
      const match = value.match(/[0-9]{1}/g)[0];
      setFormData({ ...formData, ASN: { ...formData.ASN, [id]: match } });
    }
  };

  const handleDate = (value, id) => {
    if (value && id === 'startDate') {
      const arrivalDate = moment(value).format('DDMMM').toUpperCase();
      setFormData({ ...formData, ARR: { ...formData.ARR, arrivalDate } });
      setStartDate(value);
    }
  };

  const CustomInput = ({ value, onClick }) => (
    <ButtonMUI onClick={onClick} variant="outlined">
      {value}
    </ButtonMUI>
  );

  const send = async (id) => {
    const [result, error] = await doSend({ body: [id] });
    if (error) return;
    toast.success(result.message);
    onClose();
    await getData();
  };

  const create = async (willSend) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;

    const axios_result = await Axios.get(
      `${env['REACT_APP_AUTH']}/validPartnership`
    );

    if (axios_result.data.success) {
      let { ASN, ARR, ...data } = JSON.parse(JSON.stringify(formData));
      ASN.componentId = 'ASN';
      ARR.componentId = 'ARR';

      const body = { SMI: { componentId: 'FSN' }, ASN, ARR, ...data };
      const [apiFSN, err] = await fetchDoc({ body });

      if (err) return;

      if (apiFSN) {
        if (apiFSN.errors) {
          let detail = '';

          Array.isArray(apiFSN.errors) &&
            apiFSN.errors.forEach(({ message }) => {
              detail += detail === '' ? `${message}` : `, ${message}`;
            });

          toast.warn(`${apiFSN.message}, ${detail}`);
        } else {
          const {
            message: {
              _id,
              docOrigin: {
                AWB: { prefix, serialNumber },
              },
            },
          } = apiFSN;

          toast.success(
            `FSN for ${prefix}-${serialNumber} successfully created.`
          );

          if (willSend) send(_id);
        }
      } else toast.error('Error');
    } else toast.warn(axios_result.data.message);
  };

  return (
    <div>
      <Grid>
        <Cell size={8} desktopOffset={2} tabletSize={8} phoneSize={12}>
          <Card>
            <CardText>
              <Grid>
                <Cell size={3}>
                  <TextField
                    id="prefix"
                    onChange={handleChange}
                    label="AWB #"
                    value={formData.AWB.prefix || ''}
                    lineDirection="center"
                    required
                  />
                </Cell>
                <Cell size={3}>
                  <TextField
                    id="serialNumber"
                    onChange={handleChange}
                    label="AWB #"
                    value={formData.AWB.serialNumber || ''}
                    lineDirection="center"
                    required
                  />
                </Cell>
                <Cell size={3}>
                  <Switch
                    style={{ marginTop: 20 }}
                    id="checkbox-read-material-design-spec"
                    name="simple-checkboxes[]"
                    label="MAWB Indicator"
                    value="material-design"
                    checked={formData.AWB.consolidationId === 'M'}
                    onChange={(e) => {
                      if (e) {
                        setFormData({
                          ...formData,
                          AWB: {
                            ...formData.AWB,
                            consolidationId: 'M',
                            hawbNumber: '',
                          },
                        });
                      } else {
                        setFormData({
                          ...formData,
                          AWB: { ...formData.AWB, consolidationId: '' },
                        });
                      }
                    }}
                  />
                </Cell>
                <SelectField
                  className="md-cell md-cell--3"
                  onChange={(e) => handleSelect(e, 'arrivalRef')}
                  label="PARN #"
                  style={{ maxHeight: '120px' }}
                  menuItems={PARNs || []}
                  lineDirection="center"
                />
              </Grid>
            </CardText>
          </Card>
        </Cell>
      </Grid>
      <Grid>
        <Cell size={8} desktopOffset={2} tabletSize={8} phoneSize={12}>
          <Card>
            <CardText>
              <Subheader
                className="md-grid"
                primary
                primaryText="Flight Details"
              />
              <Grid>
                <Cell size={3} tabletSize={2}>
                  <TextField
                    id="impCarrier"
                    value={formData.ARR.impCarrier || ''}
                    onChange={handleChange}
                    label="Airline"
                    lineDirection="center"
                  />
                </Cell>
                <Cell size={3} tabletSize={2}>
                  <TextField
                    id="flightNumber"
                    value={formData.ARR.flightNumber || ''}
                    onChange={handleChange}
                    label="Flight #"
                    lineDirection="center"
                  />
                </Cell>
                <Cell size={3} tabletSize={2}>
                  <TextField
                    id="arrivalAirport"
                    value={formData.CCL.arrivalAirport || ''}
                    onChange={handleChange}
                    label="Arrival Airline"
                    lineDirection="center"
                  />
                </Cell>
                <Cell size={3} tabletSize={2}>
                  <TextField
                    id="terminalOp"
                    value={formData.CCL.terminalOp || ''}
                    onChange={handleChange}
                    label="Terminal Operator (Airline)"
                    lineDirection="center"
                  />
                </Cell>
              </Grid>
              <Grid>
                <Cell size={3}>
                  <Typography>Select a Date</Typography>
                  <br />
                  <DatePicker
                    selected={startDate}
                    dateFormat="ddMMM"
                    customInput={<CustomInput />}
                    onChange={(e) => handleDate(e, 'startDate')}
                    withPortal
                  />
                </Cell>
                <SelectField
                  className="md-cell md-cell--9"
                  onChange={(e) => handleSelect(e, 'statusCode')}
                  label="FSN Reason Code"
                  menuItems={code}
                  lineDirection="center"
                />
              </Grid>
              <Grid>
                <Cell size={3}>
                  <Button
                    style={{ display: AWB ? 'none' : 'block' }}
                    raised
                    secondary
                    iconBefore={false}
                    onClick={() => create(false)}
                  >
                    Create
                  </Button>
                  <Button
                    style={{ display: AWB ? 'block' : 'none' }}
                    raised
                    secondary
                    iconBefore={false}
                    onClick={() => create(true)}
                    disabled={
                      formData.AWB.prefix.length < 3 ||
                      formData.AWB.serialNumber.length < 8 ||
                      formData.ARR.impCarrier.length < 2 ||
                      formData.ARR.flightNumber === '' ||
                      formData.CCL.arrivalAirport.length < 2 ||
                      formData.CCL.terminalOp.length < 2 ||
                      formData.ASN.statusCode === ''
                    }
                  >
                    Create & Send
                  </Button>
                </Cell>
                <Cell style={{ display: AWB ? 'block' : 'none' }} size={2}>
                  <Button raised secondary iconBefore={false} onClick={onClose}>
                    Cancel
                  </Button>
                </Cell>
              </Grid>
            </CardText>
          </Card>
        </Cell>
      </Grid>
    </div>
  );
};

export default FSN;
