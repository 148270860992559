import {
  Card,
  CardText,
  Cell,
  Grid,
  Button,
  FontIcon,
  DataTable,
  TableHeader,
  TableCardHeader,
  TableColumn,
  TableRow,
  TableBody,
  TablePagination,
  EditDialogColumn,
  SelectField
} from 'react-md';
import React, { useState, useEffect, Fragment } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import _ from 'lodash';
import '../style.css';
import Axios from 'axios';
const env = process.env;

export default props => {
  const [value, setValue] = useState({
    serialNumber: '',
    hawbNumber: '',
    shipper: '',
    consignee: '',
    flight: '',
    eta: '',
    destination: '',
    origin: ''
  });
  const [, , getPending] = useApi('ccapi', 'documents', undefined, 'get');
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(50);
  const [toSend, setToSend] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(false);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        getPendings({ types: ['FDM'] });
      }
    }
    tttt();
  }, []);

  useEffect(() => {
    setSelected(false);
    setLoading(false);
  }, [loading]);

  const getPendings = async () => {
    let [pending, err] = await getPending({ query: { types: ['FDM'] } });
    console.log(pending);
    if (err) return;
    pending.map(p => (p.checked = false));
    setFilter(pending.slice(0, 50));
    setData(pending);
  };

  const handleEvent = (e, path, segment) => {
    setValue({ [path]: e });
    setFilter(_.filter(data, [`docOrigin.${segment}.${path}`, e]));
  };

  const handlePagination = (start, rowsPerPage) => {
    setRows(rowsPerPage);
    setFilter(data.slice(start, start + rowsPerPage));
  };

  const sendDocs = async () => {
    let [response, err] = await doSend({ body: toSend });
    if (err) return;
    toast.success(response.message);
    getPendings();
    setLoading(true);
  };

  const checked = (e, action) => {
    let ids = toSend;
    let fl = filter;

    if (action === 'all') {
      ids.length === data.length
        ? ids.splice(0, ids.length)
        : e.map(d => ids.indexOf(d) !== -1 || ids.push(d));
      fl.map(f => (f.checked = !f.checked));
      setLoading(true);
    } else {
      fl.map(f => (f._id === e ? (f.checked = !f.checked) : ''));
      ids.indexOf(e) !== -1 ? ids.splice(ids.indexOf(e), 1) : ids.push(e);
      setLoading(true);
    }
    setFilter(fl);
    setToSend(ids);
  };

  const changeAction = (e, c) => {
    let d = data;
    let f = filter;
    if (e === 'Add') {
      f.splice(
        filter.findIndex(fl => fl._id === c._id),
        1
      );
      d.splice(
        data.findIndex(dt => dt._id === c._id),
        1
      );
      c.docOrigin.SMI.componentId =
        c.docOrigin.CED !== undefined ? 'FXI' : 'FRI';
    } else if (e === 'Change') {
      c.docOrigin.SMI.componentId =
        c.docOrigin.CED !== undefined ? 'FXC' : 'FRC';
    } else {
      c.docOrigin.SMI.componentId = 'FRX';
    }
    d.push(c);
    f.push(c);
    setData(d);
    setFilter(d);
  };

  const placeholder = c => {
    if (
      c.docOrigin.SMI.componentId === 'FRI' ||
      c.docOrigin.SMI.componentId === 'FXI'
    ) {
      return 'Add';
    } else if (
      c.docOrigin.SMI.componentId === 'FRC' ||
      c.docOrigin.SMI.componentId === 'FXC'
    ) {
      return 'Change';
    } else {
      return 'Cancel';
    }
  };

  return (
    <Grid>
      <Cell size={12} style={{ marginBottom: '30px' }}>
        <Card raise tableCard>
          <Button
            style={{ marginTop: '80px' }}
            floating
            secondary
            fixed
            onClick={sendDocs}
            fixedPosition="tr"
          >
            send
          </Button>
          <CardText>
            <TableCardHeader title="Send Message Departure" visible={false} />
            <DataTable
              baseId="simple-pagination"
              fixedDividers
              selectableRows={true}
            >
              <TableHeader>
                <TableRow
                  onCheckboxClick={e =>
                    checked(
                      data.map(d => d._id),
                      'all'
                    )
                  }
                >
                  <TableColumn>Importing Carrier</TableColumn>
                  <TableColumn>Flight #</TableColumn>
                  <TableColumn>Arrival Date</TableColumn>
                  <TableColumn>Lift Date</TableColumn>
                  <TableColumn>Actual Flight #</TableColumn>
                  <TableColumn>Actual Carrier</TableColumn>
                  {/* <TableColumn className="header-style">Action</TableColumn>
                                    <EditDialogColumn className="header-style" textFieldStyle={{ width: 80 }} placeholder="AWB #" onChange={e => handleEvent(e, 'serialNumber', 'AWB')} value={value.serialNumber} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                    <EditDialogColumn className="header-style" textFieldStyle={{ width: 80 }} placeholder="HAWB #" onChange={e => handleEvent(e, 'hawbNumber', 'AWB')} value={value.hawbNumber} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                    <EditDialogColumn className="header-style" textFieldStyle={{ width: 80 }} placeholder="Shipper" onChange={e => handleEvent(e, 'name', 'SHP')} value={value.shipper} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                    <EditDialogColumn className="header-style" textFieldStyle={{ width: 80 }} placeholder="Consignee" onChange={e => handleEvent(e, 'name', 'CNE')} value={value.consignee} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                    <TableColumn className="header-style">Piace Count</TableColumn>
                                    <TableColumn className="header-style">Weight</TableColumn>
                                    <EditDialogColumn className="header-style" textFieldStyle={{ width: 80 }} placeholder="Origin" onChange={e => handleEvent(e, 'airportOrigin', 'WBL')} value={value.origin} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                    <EditDialogColumn className="header-style" textFieldStyle={{ width: 80 }} placeholder="Destination" onChange={e => handleEvent(e, 'arrivalAirport', 'CCL')} value={value.destination} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                    <EditDialogColumn className="header-style" textFieldStyle={{ width: 80 }} placeholder="Flight #" onChange={e => handleEvent(e, 'flightNumber', 'ARR')} value={value.flight} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} />
                                    <EditDialogColumn className="header-style" textFieldStyle={{ width: 80 }} placeholder="ETA" onChange={e => handleEvent(e, 'arrivalDate', 'ARR')} value={value.eta} inline leftIcon={<FontIcon>filter_list</FontIcon>} inlineIcon={null} /> */}
                </TableRow>
              </TableHeader>
              <TableBody>
                {filter.length
                  ? filter.map((c, i) => (
                      <TableRow
                        key={i}
                        selected={c.checked}
                        onCheckboxClick={e => checked(c._id)}
                      >
                        {
                          <Fragment>
                            <TableColumn>
                              {c.docOrigin.DEP.impCarrier}
                            </TableColumn>
                            <TableColumn>
                              {c.docOrigin.DEP.flightNumber}
                            </TableColumn>
                            <TableColumn>
                              {c.docOrigin.DEP.arrivalDate}
                            </TableColumn>
                            <TableColumn>
                              {c.docOrigin.DEP.liftDate}
                            </TableColumn>
                            <TableColumn>
                              {c.docOrigin.DEP.actualFlightNum}
                            </TableColumn>
                            <TableColumn>
                              {c.docOrigin.DEP.actualCarrier}
                            </TableColumn>
                            {/* <TableColumn className="column-style">
                                                        <SelectField
                                                            id="select-field"
                                                            placeholder={placeholder(c)}
                                                            menuItems={['Add', 'Change', 'Cancel']}
                                                            position={SelectField.Positions.BELOW}
                                                            onChange={e => changeAction(e, c)}
                                                        />
                                                    </TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.AWB.prefix}-{c.docOrigin.AWB.serialNumber}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.AWB.hawbNumber || ''}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.SHP.name && c.docOrigin.SHP.name.slice(0, 10)}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.CNE.name && c.docOrigin.CNE.name.slice(0, 10)}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.WBL.numPieces}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.WBL.weight}{c.docOrigin.WBL.weightCode}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.WBL.airportOrigin}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.CCL.arrivalAirport}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.ARR.flightNumber}</TableColumn>
                                                    <TableColumn className="column-style">{c.docOrigin.ARR.arrivalDate}</TableColumn> */}
                          </Fragment>
                        }
                      </TableRow>
                    ))
                  : null}
              </TableBody>
              <TablePagination
                rows={data.length}
                rowsPerPageLabel="rows"
                rowsPerPage={rows}
                onPagination={handlePagination}
              />
            </DataTable>
          </CardText>
        </Card>
      </Cell>
    </Grid>
  );
};
