import {
  Grid,
  Cell,
  TextField,
  SelectionControlGroup,
  Button,
  Autocomplete,
} from 'react-md';
import React, { Fragment, useState, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import feathers from '../../services/feathers';
import Axios from 'axios';
const env = process.env;

export default (props) => {
  const { close, AWB, HAWB, getManifest, row } = props;
  const [houses, setHouses] = useState([]);
  const [fsq, setFsq] = useState({ requestCode: '' });
  const [awb, setAwb] = useState({
    prefix: '',
    serialNumber: '',
    hawbNumber: '',
  });
  const items = [
    {
      label: '01 - Request for routing information',
      value: '01',
    },
    {
      label: '02 - Request for current record status',
      value: '02',
    },
    {
      label: '03 - Request for nominated agent',
      value: '03',
    },
    {
      label: '04 - Request for retransmission of FSN messages',
      value: '04',
    },
    {
      label:
        '05 - Request for: routing information, current record status, nominated agent, current AWB information on file returned in FSC/10 message',
      value: '05',
    },
    {
      label: '06 - Request for HAWB information for supplied MAWB.',
      value: '06',
      disabled: row.type === 'Consolidation',
    },
  ];

  const [, , fetchDoc] = useApi(
    'ccapi',
    `documents${props.company ? '?company=' + props.company : ''}`,
    undefined,
    'post'
  );
  const [ready, setReady] = useState(false);
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');

  let AWBPSN = React.createRef();
  if (AWB) {
    useEffect(() => {
      setAwb({
        prefix: AWB.split('-')[0],
        serialNumber: AWB.split('-')[1],
        hawbNumber: HAWB || '',
      });
    }, [AWB]);
  }

  const send = async (id) => {
    let [response, err] = await doSend({ body: [id] });
    if (err) return;
    toast.success(response.message);
    let manifestUpdate = await feathers.service('manifests').patch(row.lastId, {
      'meta.editDate': Date.now(),
    });
    console.log({ manifestUpdate });
  };

  const create = async (willSend) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response2 = await Axios.get(
      env['REACT_APP_AUTH'] + '/validPartnership'
    );
    if (response2.data.success) {
      let query = {
        'docOrigin.AWB.prefix': awb.prefix,
        'docOrigin.AWB.serialNumber': awb.serialNumber,
        'docOrigin.SMI.componentId': {
          $in: ['FRI', 'FRC', 'FXC', 'FXI', 'FRX', 'FXX'],
        },
        $sort: { 'meta.date': -1 },
        $limit: 1,
      };
      if (awb.hawbNumber) {
        query = {
          'docOrigin.AWB.prefix': awb.prefix,
          'docOrigin.AWB.serialNumber': awb.serialNumber,
          'docOrigin.AWB.hawbNumber': awb.hawbNumber,
          'docOrigin.SMI.componentId': {
            $in: ['FRI', 'FRC', 'FXC', 'FXI', 'FRX', 'FXX'],
          },
          $sort: { 'meta.date': -1 },
          $limit: 1,
        };
      }
      let result = await feathers.service('manifests').find({
        query: query,
      });
      let ccl =
        (result &&
          result.length &&
          result[0].docOrigin &&
          result[0].docOrigin.CCL) ||
        null;
      let arr =
        (result &&
          result.length &&
          result[0].docOrigin &&
          result[0].docOrigin.ARR) ||
        null;
      fsq.componentId = 'FSQ';
      let body = { SMI: { componentId: 'FSQ' }, AWB: awb, FSQ: fsq };
      if (ccl)
        body = { SMI: { componentId: 'FSQ' }, CCL: ccl, AWB: awb, FSQ: fsq };
      if (arr) {
        delete body.FSQ;
        body.ARR = arr;
        body.FSQ = fsq;
      }
      let [apifsq, err] = await fetchDoc({
        body: body,
      });
      if (err) return;
      if (apifsq) {
        if (apifsq.errors) {
          let detail = '';
          for (let i = 0; i < apifsq.errors.length; i++) {
            if (apifsq.errors.length - 1 === i)
              detail += ` ${apifsq.errors[i].message}`;
            else detail += ` ${apifsq.errors[i].message},`;
          }

          toast.warn(`${apifsq.message}, ${detail}`);
        } else {
          toast.success(
            `FSQ for ${apifsq.message.docOrigin.AWB.prefix}-${apifsq.message.docOrigin.AWB.serialNumber} successfully created.`
          );
          if (willSend) {
            await send(apifsq.message._id);
          }
          let updateRow = await feathers.service('awb-status').patch(row._id, {
            manifestEdited: Date.now(),
          });
          if (updateRow) {
            await getManifest();
          }
        }
      } else {
        toast.error(`Error: something goes wrong, try again`);
      }
    } else {
      toast.warn(response2.data.message);
    }
  };
  const setPrefix = (e) => {
    setAwb({ ...awb, prefix: e });
    setReady(
      e.length === 3 && awb.serialNumber.length === 8 && fsq.requestCode !== ''
    );
  };
  const setSerialNumber = (e) => {
    setAwb({ ...awb, serialNumber: e });
    setReady(
      awb.prefix.length === 3 && e.length === 8 && fsq.requestCode !== ''
    );
  };
  const searchHouse = async (hawbNumber) => {
    let result = await feathers.service('house-status').find({
      query: {
        $findHouse: {
          hawbNumber: hawbNumber,
          prefix: [awb.prefix],
          serialNumber: [awb.serialNumber],
        },
      },
    });
    setHouses(result);
  };

  return (
    <Fragment>
      <Grid>
        <Cell size={2}>
          <TextField
            id="AWBPSN"
            ref={AWBPSN}
            value={awb.prefix}
            helpText="Example: 999"
            onChange={(e) => {
              const re = /^[a-zA-Z0-9\b]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 3) {
                  setPrefix(e.toUpperCase());
                }
              }
            }}
            label="AWB Prefix"
            lineDirection="center"
          />
        </Cell>
        <Cell size={5}>
          <TextField
            id="AWBPSN"
            ref={AWBPSN}
            value={awb.serialNumber}
            helpText="Example: 12345678"
            onChange={(e) => {
              const re = /^[0-9\b]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 8) {
                  setSerialNumber(e);
                }
              }
            }}
            label="AWB #"
            lineDirection="center"
          />
        </Cell>
        <Cell size={5}>
          <Autocomplete
            id="hawbAuto"
            label="HAWB"
            data={houses}
            dataLabel={'label'}
            dataValue={'value'}
            filter={Autocomplete.fuzzyFilter}
            value={awb.hawbNumber}
            onChange={async (e) => {
              const re = /^[a-zA-Z0-9\b]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 12) {
                  setAwb({ ...awb, hawbNumber: e.toUpperCase() });
                  await searchHouse(e);
                }
              }
              setReady(
                row.type === 'Consolidation'
                  ? awb.prefix.length === 3 &&
                      awb.serialNumber.length === 8 &&
                      fsq.requestCode !== ''
                  : awb.prefix.length === 3 &&
                      awb.serialNumber.length === 8 &&
                      fsq.requestCode !== ''
              );
            }}
            disabled={row.type !== 'Consolidation'}
            onAutocomplete={(e) => {
              setAwb({ ...awb, hawbNumber: e.toUpperCase() });
              setReady(
                row.type === 'Consolidation'
                  ? awb.prefix.length === 3 &&
                      awb.serialNumber.length === 8 &&
                      fsq.requestCode !== ''
                  : awb.prefix.length === 3 &&
                      awb.serialNumber.length === 8 &&
                      fsq.requestCode !== ''
              );
            }}
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={12}>
          <SelectionControlGroup
            id="selection-control-group-radios"
            name="radio-example"
            type="radio"
            label="Status Request Code"
            defaultValue={false}
            controls={items}
            onChange={(e) => {
              setFsq({ ...fsq, requestCode: e });
              setReady(
                row.type == 'Consolidation'
                  ? awb.prefix.length === 3 &&
                      awb.serialNumber.length === 8 &&
                      e.length !== ''
                  : awb.prefix.length === 3 &&
                      awb.serialNumber.length === 8 &&
                      e.length !== ''
              );
            }}
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={2}>
          <Button
            style={{ display: AWB ? 'none' : 'block' }}
            raised
            secondary
            iconBefore={false}
            onClick={async (e) => {
              await create(false);
            }}
            disabled={!ready}
          >
            Create
          </Button>
          <Button
            style={{ display: AWB ? 'block' : 'none' }}
            raised
            secondary
            iconBefore={false}
            onClick={async () => {
              await create(true);
              close();
            }}
            disabled={!ready}
          >
            Create & Send
          </Button>
        </Cell>
        <Cell style={{ display: AWB ? 'block' : 'none' }} size={2}>
          <Button raised secondary iconBefore={false} onClick={close}>
            Cancel
          </Button>
        </Cell>
      </Grid>
    </Fragment>
  );
};
