const headerReport = `<div style="width: 100%; border-bottom: 2px solid #2e8ae6; font-size: 22px; padding-top: 10px; color: #2e8ae6; font-family: times;">CBP Freight Status Report</div>
<div style="width: 100%; margin-top:5%;">
<div style="float: left; width: 48%; padding: 1%">
<p>AWB #: <strong>{{prefix}}-{{serialNumber}}</strong></p>
<p>HAWB #: <strong>{{hawbNumber}}</strong></p>
<p>Airline: <strong>{{airline}}</strong></p>
<p>Flight #: <strong>{{flightNumber}}</strong></p>
<p>1<sup>st</sup> US Airport of Arrival: <strong>{{usPort}}</strong></p>
<p>Quantity: <strong>{{qty}}</strong></p>
<p>Transfer to: <strong>{{transferTo}}</strong></p>
<p>Date of Arrival: <strong>{{arrivalDate}}</strong></p>
</div>
<div style="float: right; width: 48%; padding: 1%">
<p>CBP Status Notification: <strong>{{statusNotification}}</strong></p>
<p>Additional Information: <strong>{{{additionalInfo}}}</strong></p>
<p>CBP Notification Date &amp; Time: <strong>{{cbpProcessingDate}}</strong></p>
<p>Entry Type - <strong>{{entryType}}</strong></p>
<p>Entry # - <strong>{{entryNumber}}</strong></p>
<p>Remarks -<strong>{{remarks}}</strong></p>
</div>
</div>`;

const headerSummary = `
<div style="width: 100%; border-bottom: 2px solid #2e8ae6; font-size: 22px; padding-top: 10px; color: #2e8ae6; font-family: times;">CBP Freight Status Report Summary</div>
<div style="width: 100%; margin-top:5%;">
<div style="float: left; width: 48%; padding: 1%">
<p>AWB #: <strong>{{prefix}}-{{serialNumber}}</strong></p>
<p>HAWB #: <strong>{{house}}</strong></p>
<p>Airline: <strong>{{airline}}</strong></p>
<p>Flight #: <strong>{{flightNumber}}</strong></p>
</div>
<div style="float: right; width: 48%; padding: 1%">
<p>1<sup>st</sup> US Airport of Arrival: <strong>{{usPort}}</strong></p>
<p>Transfer to: <strong>{{transferTo}}</strong></p>
<p>Date of Arrival: <strong>{{arrivalDate}}</strong></p>
<p>Remarks: <strong>{{remarks}}</strong></p>
</div>
</div>
`;

const tableSummary = `
<div style="width: 100%; float: left;">
  <table style="border: 1px solid black; border-collapse: collapse; width: 100%">
    <tr>
      <th style="border: 1px solid black;">Notification</th>
      <th style="border: 1px solid black;">Summary</th>
      <th style="border: 1px solid black;">Quantity</th>
    </tr>
    {{#summary}}
    <tr>
    <td style="border: 1px solid black;">{{notification}}</td>
    <td style="border: 1px solid black;">{{message}}</td>
    <td style="border: 1px solid black;">{{quantity}}</td>
    </tr>
    {{/summary}}
  </table>
</div>
<div style="width: 100%; float: left; margin-top: 5%">
  <p><strong>{{checkIn}} {{checkOut}}</strong></p>
  <p>{{#checkIn}}Signature:{{/checkIn}}{{#checkOut}}Signature:{{/checkOut}}</p>
</div>
`;

const ediFormat = `
<div style="width: 100%; float: left; margin-top: 5%;">
<div><strong>CBP EDI Message</strong></div>
<div style="font-family: times;">{{{edi}}}</div>
</div>
`;

const consolidationReport = `
  <div style=" width: 95%; border: 1px solid black; padding: 10px; border-radius: 10px " >
    <div style=" font-size: 18px; color: #797878; font-weight: bold " >Customs Status</div>
    <hr />
    <table style=" width: 100%; color: #797878 ">
      <tr>
        <td />
        <td>MAWB</td>
        <td>Total HAWB Count</td>
        <td>HAWB Submitted</td>
        <td>HAWB Rejected</td>
        <td>1C</td>
        <td>AMS Hold</td>
      </tr>
      <tr><td colspan="7"><hr /></td></tr>
      <tr>
        <td>AMS Status:</td>
        <td>{{prefix}}-{{serialNumber}}</td>
        <td>{{total}}</td>
        <td>{{submitted}}</td>
        <td>{{rejected}}</td>
        <td>{{released}}</td>
        <td>{{AMSHold}}</td>
      </tr>
    </table>
  </div>
  <br />
  <div style=" width: 95%; border: 1px solid black; padding: 10px; border-radius: 10px " >
    <div style=" font-size: 18px; color: #797878; font-weight: bold ">HAWB's Hold</div>
    <hr />
    <table style=" width: 100%; color: #797878 ">
      <tr>
        <td>HAWB</td>
        <td>Submission Date</td>
        <td>Response Date</td>
        <td>Marks and Numbers</td>
        <td>Arrival Date</td>
        <td>Shipper</td>
        <td>Consignee</td>
      </tr>
      <tr><td colspan="7"><hr /></td></tr>
      {{#Holds}}
      <tr>
        <td>{{hawbNumber}}</td>
        <td>{{submissionDate}}</td>
        <td>{{responseDate}}</td>
        <td>{{marksAndNumbers}}</td>
        <td>{{arrivalDate}}</td>
        <td>{{shipper}}</td>
        <td>{{consignee}}</td>
      </tr>
      {{/Holds}}
    </table>
  </div>
  <br />
  <div style=" width: 95%; border: 1px solid black; padding: 10px; border-radius: 10px " >
    <div style=" font-size: 18px; color: #797878; font-weight: bold ">MAWB Information</div>
    <hr />
    <table style=" width: 100%; color: #797878 ">
      <tr>
        <td>Client</td>
        <td>Airport of Origin</td>
        <td>Airport of Arrival</td>
        <td>Carrier</td>
        <td>Flight No.</td>
        <td>Arrival Date</td>
      </tr>
      <tr><td colspan="6"><hr /></td></tr>
      <tr>
        <td>{{client}}</td>
        <td>{{airportOrigin}}</td>
        <td>{{arrivalAirport}}</td>
        <td>{{carrier}}</td>
        <td>{{flightNumber}}</td>
        <td>{{arrivalDate}}</td>
      </tr>
    </table>
  </div>
`;

export {
  headerReport,
  headerSummary,
  tableSummary,
  ediFormat,
  consolidationReport,
};
