import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const ArrivalFSN = ({ row, ...restProps }) => {
  return (
    <Table.Cell {...restProps}>
      {row.docTranslated.ARR2
        ? 'Flight #: ' +
          (row.docTranslated.ARR2.impCarrier || '') +
          (row.docTranslated.ARR2.flightNumber || '')
        : ''}
      {row.docTranslated.ARR2 && row.docTranslated.ARR2.arrivalDate ? (
        <br />
      ) : null}
      {row.docTranslated.ARR2 && row.docTranslated.ARR2.arrivalDate
        ? ' Arrival Date: ' + row.docTranslated.ARR2.arrivalDate
        : ''}
      {row.docTranslated.CCL && row.docTranslated.CCL.arrivalAirport ? (
        <br />
      ) : null}
      {row.docTranslated.CCL && row.docTranslated.CCL.arrivalAirport
        ? ' Arrival Airport: ' + row.docTranslated.CCL.arrivalAirport
        : ''}
      {row.docTranslated.CCL && row.docTranslated.CCL.terminalOp ? (
        <br />
      ) : null}
      {row.docTranslated.CCL && row.docTranslated.CCL.terminalOp
        ? ' Terminal Operator: ' + row.docTranslated.CCL.terminalOp
        : ''}
      {row.docTranslated.CSN &&
      row.docTranslated.CSN.actionCode &&
      row.docTranslated.CSN.actionCode === '1F' ? (
        <br />
      ) : null}
      {row.docTranslated.CSN &&
      row.docTranslated.CSN.actionCode &&
      row.docTranslated.CSN.actionCode === '1F'
        ? 'Firms Code: ' + row.docTranslated.CSN.remarks
        : ''}
    </Table.Cell>
  );
};

export default ArrivalFSN;
