import React, { useState, useEffect, Fragment } from 'react';
import {
  Grid,
  Cell,
  TextField,
  Button,
  Card,
  CardActions,
  Toolbar,
  DialogContainer
} from 'react-md';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import ReactPasswordStrength from 'react-password-strength';
import Axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import feathers from '../../services/feathers';
const env = process.env;

export default props => {
  // let company = JSON.parse(localStorage.profile).company;
  // const [userId, setModal] = useState(false);

  const [, , doChangePassword] = useApi('auth', 'changePassword', {}, 'put');
  const [user, setuser] = useState({
    name: '',
    lastName: '',
    email: '',
    phone: ''
  });
  const [modal, setModal] = useState(false);
  const [, userData, getuser] = useApi('auth', 'user', user);
  const [, , doFetch] = useApi('auth', 'user', {}, 'post');
  const [password, setPassword] = useState({
    password: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [companyInfo, setCompany] = useState({ companyName: '' });
  const [updateProfile, setUpdateProfile] = useState(true);

  useEffect(() => {
    let company = JSON.parse(localStorage.profile).company;
    let userId = userData._id;

    //delete userData._id
    setuser(userData);
    if (userId) {
      getSuscription(company, userId, userData);
    }
  }, [userData]);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        let profile = JSON.parse(localStorage.getItem('profile'));
        setCompany({ ...companyInfo, companyName: profile.companyName });
        getuser({ params: [profile.id] });
      }
    }
    tttt();
  }, []);

  useEffect(() => {
    isValidForm();
  }, [user]);

  const setValue = val => {
    setuser({ ...user, ...val });

    if (val.name || val.lastName || val.phone) {
      setSubscription({ ...subscription, ...val });
    }
  };
  const [fsc, setFSC] = useState(false);
  const [fer, setFER] = useState(false);
  const [fsn, setFSN] = useState(false);
  const [type86, setType86] = useState(false);
  const [actionObject, setactionObject] = useState({
    messageTypes: [],
    channel: { mail: true, sms: false }
  });
  const [subscription, setSubscription] = useState({
    name: '',
    lastName: '',
    company: '',
    phone: '',
    email: '',
    action: [],
    user: ''
  });

  const getSuscription = async (company, userId, user) => {
    let data = await feathers.service('suscriptions').find({
      query: {
        company: company,
        user: userId
      }
    });

    if (data && data.length > 0) {
      let actions = data[0].action;
      data[0].action = [];
      setSubscription(data[0]);
      if (actions && actions[0].messageTypes) {
        actions[0].messageTypes.forEach(t => {
          if (t === 'FSC') {
            setFSC(true);
          }
          if (t === 'FSN') {
            setFSN(true);
          }
          if (t === 'FER') {
            setFER(true);
          }
          if (t === 'TYPE86') {
            setType86(true);
          }
          setActions(t);
        });
      }
    } else {
      let tempSuscription = {
        name: user.name,
        lastName: user.lastName,
        company: company,
        phone: user.phone,
        email: user.email,
        action: [],
        user: userId
      };
      setSubscription(tempSuscription);
    }
  };

  const setActions = async typeMessage => {
    let messages = actionObject.messageTypes;

    if (messages.includes(typeMessage)) {
      const index = messages.indexOf(typeMessage);
      if (index > -1) {
        messages.splice(index, 1);
      }
    } else {
      messages.push(typeMessage);
      setactionObject({ ...actionObject, messageTypes: messages });
    }
  };

  const saveSubscription = async () => {
    try {
      await feathers.service('suscriptions').update({}, subscription);

      toast.success('User profile successfully updated.');
    } catch (e) {
      toast.error('Profile not saved');
      console.log('error:', e);
    }
  };

  const isValidForm = () => {
    if (user.name && user.lastName && user.phone) {
      if (updateProfile === false) {
        setUpdateProfile(true);
      }
    } else {
      if (updateProfile === true) {
        setUpdateProfile(false);
      }
    }
  };

  const saveUser = async () => {
    let userBody = { ...user };

    delete userBody._id;
    let [result, error] = await doFetch({
      body: userBody,
      params: [user._id]
    });

    if (!error) {
      subscription.action = [];
      await subscription.action.push(actionObject);
      saveSubscription();
      //toast.success('User profile successfully updated.');
    } else {
      if (result && result.message) {
        toast.error(result.message);
      }
      if (result && result.error) {
        toast.error(result.error);
      }

      console.log('error saving user', error, result);
    }
  };

  return (
    <Grid>
      {/* <Cell size={3}></Cell> */}
      <Cell desktopOffset={2} size={8}>
        <Card>
          <form>
            <Toolbar colored title="Profile" />
            <Grid>
              <Cell size={4}>
                <TextField
                  id="name"
                  required
                  label="Name"
                  value={user.name}
                  onChange={v => setValue({ name: v })}
                />
              </Cell>
              <Cell size={4}>
                <TextField
                  id="lastName"
                  required
                  label="Last Name"
                  value={user.lastName}
                  onChange={v => setValue({ lastName: v })}
                />
              </Cell>
              <Cell size={4}>
                <TextField
                  id="email"
                  required
                  label="Email"
                  value={user.email}
                  disabled
                  onChange={v => setValue({ email: v })}
                />
              </Cell>
            </Grid>
            <Grid>
              <Cell size={4}>
                <TextField
                  id="phone"
                  required
                  label="Phone"
                  value={user.phone}
                  onChange={v => setValue({ phone: v })}
                />
              </Cell>
              <Cell size={4}>
                <TextField
                  id="companyName"
                  required
                  label="Company Name"
                  value={companyInfo.companyName}
                  disabled
                  //onChange={v => setValue({ email: v })}
                />
              </Cell>
              <Cell size={4}>
                <FormLabel component="legend" style={{ marginTop: '10px' }}>
                  Type Notification
                </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={fsc}
                        onClick={async e => {
                          await setFSC(!fsc);
                          setActions('FSC');
                        }}
                        value="FSC"
                        inputProps={{
                          'aria-label': 'primary checkbox'
                        }}
                      />
                    }
                    label="FSC"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={fer}
                        onClick={async e => {
                          await setFER(!fer);
                          setActions('FER');
                        }}
                        value="FER"
                        inputProps={{
                          'aria-label': 'primary checkbox'
                        }}
                      />
                    }
                    label="FER"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={fsn}
                        onClick={async e => {
                          await setFSN(!fsn);
                          setActions('FSN');
                        }}
                        value="FSN"
                        inputProps={{
                          'aria-label': 'primary checkbox'
                        }}
                      />
                    }
                    label="FSN"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={type86}
                        onClick={async e => {
                          await setType86(!type86);
                          setActions('TYPE86');
                        }}
                        value="TYPE86"
                        inputProps={{
                          'aria-label': 'primary checkbox'
                        }}
                      />
                    }
                    label="TYPE 86"
                  />
                </FormGroup>
              </Cell>
            </Grid>
            <CardActions centered>
              <Button
                secondary
                flat
                onClick={() => {
                  setModal(true);
                }}
              >
                Change Password
              </Button>
              <Button
                secondary
                flat
                onClick={async () => {
                  // subscription.action = [];
                  // await subscription.action.push(actionObject);
                  //saveSubscription();
                  saveUser();
                }}
                disabled={!updateProfile}
              >
                Update Profile
              </Button>
            </CardActions>
          </form>
        </Card>
        <br></br>
      </Cell>

      {/* <Cell size={3}></Cell> */}

      <DialogContainer
        height={400}
        width={450}
        id="simple-action-dialog"
        visible={modal}
        onHide={() => {}}
        actions={[
          <Button
            flat
            primary
            onClick={async () => {
              let [, error] = await doChangePassword({
                body: password
              });
              if (error) {
                toast.error('Error, please try again');
              } else if (
                (error === false && password.newPassword === '') ||
                password.confirmPassword === ''
              ) {
                toast.error('you must fill all the fields ');
              } else if (
                (error === true && password.newPassword === '') ||
                password.confirmPassword === ''
              ) {
                toast.error('you must fill all the fields ');
              } else if (password.newPassword !== password.confirmPassword) {
                toast.error('Error, please try again');
              } else {
                toast.success('Password successfully changed.');
                toast.info('You must sign in again.');
                localStorage.clear();
                window.location.reload();
              }
            }}
          >
            Change
          </Button>,
          <Button
            flat
            secondary
            onClick={() => {
              setModal(false);
            }}
          >
            Cancel
          </Button>
        ]}
        title="New Password"
      >
        <TextField
          id="simple-action-dialog-field"
          label="Password"
          type={'password'}
          value={password.password}
          onChange={v => {
            setPassword({ ...password, password: v });
          }}
        />
        <Fragment>New Password</Fragment>
        <ReactPasswordStrength
          className="ReactPasswordStrength"
          minLength={12}
          minScore={12}
          value={password.newPassword}
          scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
          changeCallback={v => {
            setPassword({ ...password, newPassword: v.password });
          }}
          required
        />
        {/* <TextField
          id="simple-action-dialog-field"
          label="New Password"
          type={'password'}
          value={password.newPassword}
          onChange={v => setPassword({ ...password, newPassword: v })}
        /> */}

        <TextField
          id="simple-action-dialog-field"
          label="Confirm Password"
          type={'password'}
          value={password.confirmPassword}
          onChange={v => {
            setPassword({ ...password, confirmPassword: v });
          }}
          error={
            password.confirmPassword !== password.newPassword ? true : false
          }
          errorText="Incorrect Password"
          required
        />
      </DialogContainer>
    </Grid>
  );
};
