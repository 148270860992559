import React, { useEffect, useState } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
// import {
//   FSNMessage,
//   FSCMessage,
//   docType
// } from '../../../components/awb-history/Codes';
// import CallMadeIcon from '@material-ui/icons/CallMade';
// import CallReceivedIcon from '@material-ui/icons/CallReceived';
// import moment from 'moment';
import feathers from '../../../../services/feathers';
import ViewEmail from './ViewEmail';
import Button from '@material-ui/core/Button';
import moment from 'moment';

export default (props) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [name, setName] = React.useState('');
  const [rows, setRows] = useState([]);
  // console.log('props :>> ', props);
  useEffect(() => {
    init(props);
  }, [props.currentTab]);
  const init = async (props2) => {
    let result = await feathers.service('notifications').find({
      query: {
        prefix: props2.prefix,
        serialNumber: props2.serialNumber,
        //hawbNumber: props2.hawbNumber || null,
        type: props2.type === 'House' ? 'Consolidation' : props2.type,
        flightNumber: props.flightNumber || null,
      },
    });
    console.log('result :>> ', result, props2);
    setRows(result);
  };
  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ marginBottom: 80 }}>
      <ViewEmail
        open={open}
        name={name}
        onClose={handleClose}
        value={value}
      ></ViewEmail>
      <Grid
        rows={rows}
        columns={[
          {
            name: 'awb',
            title: 'AWB',
            getCellValue: (row) => `${row.prefix}-${row.serialNumber}`,
          },
          {
            name: 'hawb',
            title: 'HAWB',
            getCellValue: (row) => row.hawbNumer || '',
          },
          {
            name: 'subject',
            title: 'Subject',
            getCellValue: (row) => row.subject,
          },
          {
            name: 'receiver',
            title: 'Receiver',
            getCellValue: (row) => row.receiver,
          },
          {
            name: 'date',
            title: 'Date',
            getCellValue: (row) =>
              row.date ? moment(row.date).format('LLL') : '',
          },
          {
            name: 'view',
            title: 'View',
            getCellValue: (row) => {
              return (
                <Button
                  onClick={(c) => {
                    setValue(row.emailBody);
                    setName(row.subject);
                    setTimeout(() => {
                      handleClickListItem();
                    }, 10);
                  }}
                  variant="outlined"
                  color="primary"
                >
                  View
                </Button>
              );
            },
          },
        ]}
      >
        <Table />
        <TableHeaderRow />
      </Grid>
    </div>
  );
};
