import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card, CardText, Cell, CircularProgress, Grid } from 'react-md';
import { Button } from '@material-ui/core';
import Axios from 'axios';
import Filters from './Filters';
import Table from './Table';
import feathers from '../../services/feathers';
import moment from 'moment';
import { externalFilters, getHeight as getH } from './functions/functions';
import Drawer from './Drawer';
import _ from 'lodash';
import Mustache from 'mustache';
import {
  headerReport,
  headerSummary,
  tableSummary,
} from '../../constants/HTMLTemplates/PDFTemplates';
import html2pdf from 'html2pdf.js';
import DialogForm from '../../components/common/DialogForm';
import FSQ from './components/FSQ';
import FSN from './components/FSN';
import Modal from './components/Modal';
import Checks from './components/Checks';

const services = { requests: feathers.service('requests') };

const env = process.env;

// 29-DECEMBER 05:44 PM
const reportDate = (date) =>
  date ? moment(date).format('DD-MMMM H:mm A') : '';

// Dec. 29th, 2020
const summaryDate = (date) =>
  date ? moment(date).format('MMM. Do, YYYY') : '';

const FSNHistory = (props) => {
  const startDate = localStorage.startDate || new Date();
  const endDate = localStorage.endDate || new Date();
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [update, setUpdate] = useState(false);
  const [allData, setAllData] = useState([]);
  const [rowDataFiltered, setRowDataFiltered] = useState([]);
  const [selection, setSelection] = useState([]);
  const [hawbs, setHawbs] = useState([]);
  const [clients, setClients] = useState([]);
  const [AWB, setAWB] = useState({});
  const [modalData, setModalData] = useState({ open: false });
  const [edi, setEdi] = useState('');
  const [gridApi, setGridApi] = useState({});
  const [openModalChecks, setOpenModalChecks] = useState(false);
  const [checkData, setCheckData] = useState({
    prefix: null,
    serialNumber: null,
    type: null,
    date: null,
    comment: null,
  });

  const profile = localStorage.profile ? JSON.parse(localStorage.profile) : {};
  const clientName = (profile && profile.companyName) || '';
  const [filters, setFilters] = useState({ startDate, endDate, clientName });

  const getData = async () => {
    setUpdate(true);
    const { startDate: from, endDate: to, client: companyFilter } = filters;
    const startDate = moment(from).startOf('day').valueOf();
    const endDate = moment(to).endOf('day').valueOf();
    let { company, role } = JSON.parse(localStorage.profile);

    if (role == 'support') {
      company = companyFilter || '';
    }

    const query = { $summaryFsn: { startDate, endDate, company, role } };
    let res;
    try {
      res = await services.requests.find({ query });
      res = res.map((it, i) => ({ ...it, number: i + 1, height: getH(it) }));
    } catch (error) {
      console.error(error);
    } finally {
      setHawbs([]);
      setAllData(res);
      setUpdate(false);
    }
  };

  useEffect(() => {
    const applyFilters = () => {
      const filtered = externalFilters(allData, filters);
      setRowDataFiltered(filtered);
    };
    applyFilters();
  }, [filters, allData]);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token + '';

    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  const triggerChildAlert = () => getData();

  const onGridChanges = ({ api }) => {
    const {
      rowModel: { rowsToDisplay },
    } = api;

    const display = rowsToDisplay.map(({ data }) => data);

    const gridSelection = api.getSelectedRows();

    const visibleSelection = gridSelection.filter((gs) => display.includes(gs));

    setSelection(visibleSelection);
  };

  useEffect(() => console.debug({ selection }), [selection]);

  const createReport = (template, data) =>
    (template && data && Mustache.render(template, data)) || '';

  const printPDF = (name, report) =>
    html2pdf()
      .from(`<div style="padding: 5%;">${report}</div>`)
      .to('pdf')
      .save(name);

  const gridActions = {
    awb_link: (_, params) => {
      setAWB(params);
      setIsOpenDrawer(true);
    },
    option_select: (key, params) => {
      switch (key) {
        case 'print':
          let data = JSON.parse(JSON.stringify(params));
          const summary = [];

          const { name: userName, email } = JSON.parse(localStorage.profile);

          data.airline = data.flightInfo['Airline'];
          data.flightNumber = data.flightInfo['Flight #'];
          data.usPort = data.flightInfo['1st US Port of Arrival'];
          data.arrivalDate = summaryDate(data.flightInfo['Arrival Date']);
          data.collector = userName || email;

          Array.isArray(data.notificationSummary) &&
            data.notificationSummary.forEach(({ code, qty: quantity }) => {
              let notification = code.substring(0, code.indexOf(' - '));
              let message = code.substring(
                code.indexOf(' - ') + 3,
                code.length
              );
              summary.push({ notification, message, quantity });
            });

          data.summary = summary;
          data.house = `${data.isHouse && `${data.isHouse} - `}${
            data.totalHouse
          } HAWB's`;
          data.transferTo = data.flightInfo['Transfer To'];

          const report = createReport(`${headerSummary}${tableSummary}`, data);

          const fileName = `${params.prefix}-${params.serialNumber} (${
            params.totalHouse || 0
          } HAWB's)`;

          printPDF(fileName, report);
          return;
        case 'FSQ':
        case 'ASN':
          const titles = {
            FSQ: `Create Freight Status Query (${key})`,
            ASN: `Create Arrival Status Notification (${key})`,
          };
          let title = titles[key];

          const AWB =
            params.prefix && params.serialNumber
              ? `${params.prefix}-${params.serialNumber}`
              : null;

          const { company } = JSON.parse(localStorage.profile);

          const FSQData = { data: params, getData, company, AWB };

          const FSNData = { data: params, getData, company, AWB };

          const modal = { open: true, type: key, title };

          setModalData({
            ...modal,
            ...(key === 'FSQ' && FSQData),
            ...(key === 'ASN' && FSNData),
          });
          return;
        default:
          break;
      }
    },
    viewEDI_button: (_, params) => {
      setEdi(params);
      setIsOpenModal(true);
    },
    print_button: (_, params) => {
      let preReport = JSON.parse(JSON.stringify(params));
      let additionalInfo = '';

      Array.isArray(preReport.additionalInfo) &&
        preReport.additionalInfo.forEach(({ label, value }) => {
          additionalInfo += `${label} ${value}<br>`;
        });

      preReport.cbpProcessingDate = reportDate(preReport.cbpProcessingDate);
      preReport.arrivalDate = reportDate(preReport.arrivalDate);
      preReport.additionalInfo = additionalInfo;

      const report = createReport(headerReport, preReport);

      const name = `${params.prefix}-${params.serialNumber}`;

      printPDF(name, report);
    },
    showCheckModal: (_, params) => {
      let type = _ === 'checkIn' ? 'In' : 'Out';
      let date = new Date();
      if (
        params.Checks &&
        params.Checks.hasOwnProperty(`check${type}`) &&
        params.Checks[`check${type}`] !== null
      ) {
        date = new Date(params.Checks[`check${type}`]);
        console.log('entro :>> ', params.Checks[`check${type}`]);
      }

      setCheckData({
        type: type,
        prefix: params.prefix,
        serialNumber: params.serialNumber,
        date: date,
        comment: (params.Checks && params.Checks[`comment${type}`]) || '',
      });

      setOpenModalChecks(true);
    },
    onSelectionChanged: onGridChanges,
    onFilterChanged: onGridChanges,
  };

  const toggleModal = () => setIsOpenModal(false);

  const handleClose = () => setModalData({ open: false });

  const modalContent = (key) => {
    switch (key) {
      case 'FSQ':
        return <FSQ {...modalData} onClose={handleClose} />;
      case 'ASN':
        return <FSN {...modalData} onClose={handleClose} />;
      default:
        return null;
    }
  };

  const handleCloseChecks = () => setOpenModalChecks(false);

  const saveCheck = async () => {
    let data = {
      type: checkData.type,
      prefix: checkData.prefix,
      serialNumber: checkData.serialNumber,
      date: +checkData.date,
      comment: checkData.comment,
    };
    let result = await feathers.service('checks').create(data);
    let oldData = JSON.parse(JSON.stringify(allData));
    let updated = false;
    oldData.forEach((document, key) => {
      if (
        document.prefix === data.prefix &&
        document.serialNumber === data.serialNumber &&
        gridApi
      ) {
        const rowNode = gridApi.getRowNode(key);
        updated = true;
        if (rowNode) {
          let newDocument = JSON.parse(JSON.stringify(document));
          if (!newDocument.Checks) {
            newDocument.Checks = {};
          }
          if (data.type === 'In') {
            newDocument.Checks.checkIn = data.date;
            newDocument.Checks.commentIn = data.comment;
            // newDocument.Checks.checkOut = null;
            // newDocument.Checks.commentOut = null;
          } else {
            // newDocument.Checks.checkIn = null;
            // newDocument.Checks.commentIn = null;
            newDocument.Checks.checkOut = data.date;
            newDocument.Checks.commentOut = data.comment;
          }
          rowNode.setData(newDocument);
          oldData[key] = newDocument;
        }
      }
    });
    if (updated) {
      setAllData(oldData);
    }
    await handleCloseChecks();
  };

  return (
    <Container>
      <Grid>
        <Cell size={6}>
          <Span>FSN History</Span>
        </Cell>
        <MDCellEnd size={6}>
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              onClick={async (e) => {
                if (await checkToken()) triggerChildAlert();
              }}
            >
              {update ? <Progress id="refresh" /> : 'Refresh'}
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              onClick={async (e) => {
                if (await checkToken()) props.history.push('/upload-flatfile2');
              }}
            >
              Upload CSV
            </Button>
          </ButtonWrapper>
        </MDCellEnd>
      </Grid>
      <Grid>
        <Cell size={12}>
          <Card raise tableCard>
            <CardText style={{ paddingTop: '0px' }}>
              <Filters
                filters={filters}
                setFilters={setFilters}
                getData={getData}
                hawbs={hawbs}
                clients={clients}
                setClients={setClients}
                profile={profile}
              />
              <Table
                data={rowDataFiltered}
                getData={getData}
                gridActions={gridActions}
                setGridApi={setGridApi}
              />
            </CardText>
          </Card>
        </Cell>
      </Grid>
      <Drawer
        AWB={AWB}
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        gridActions={gridActions}
        setAWB={setAWB}
        isOpenModal={isOpenModal}
        toggleModal={toggleModal}
        data={edi}
      />
      <DialogForm
        title={modalData.title}
        open={modalData.open}
        onClose={handleClose}
      >
        {modalContent(modalData.type)}
      </DialogForm>
      <Modal
        handleClose={handleCloseChecks}
        open={openModalChecks}
        title={`Check ${checkData.type}`}
        showOptionalButton={true}
        textOptionalButton={'Save'}
        handleOptionalButton={saveCheck}
      >
        <Checks data={checkData} setData={setCheckData} />
      </Modal>
    </Container>
  );
};

const Container = styled.div``;

const MDCellEnd = styled(Cell)`
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  margin-left: 16px;
`;

const Span = styled.span`
  font-size: 35px;
  color: gray;
`;

const Progress = styled(CircularProgress)`
  margin-bottom: 0px;
  margin-top: 0px;
`;

export default FSNHistory;
