import { Card, CardText, Cell, Grid } from 'react-md';
import { FDM } from '../../components/';
import React, { Fragment, useEffect } from 'react';
import '../style.css';
import Axios from 'axios';
const env = process.env;

export default props => {
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      await checkToken();
    }
    tttt();
  }, []);
  return (
    <Fragment>
      <Grid>
        <Cell size={3} phoneSize={12} tabletSize={12}>
          <span style={{ fontSize: 35, color: 'gray' }}>Departure Message</span>
        </Cell>
      </Grid>
      <Grid>
        <Cell size={8} desktopOffset={2} style={{ marginBottom: '30px' }}>
          <Card raise tableCard>
            <CardText>
              <FDM />
            </CardText>
          </Card>
        </Cell>
      </Grid>
    </Fragment>
  );
};
