import React from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import PagingContainer from '../GridComponents/PagingPanel';

const Cell = (props) => {
  const { column } = props;
  if (column.name === 'menu') {
    return (
      <Table.Cell
        style={{
          overflow: 'visible',
          paddingRight: '0px',
        }}
      >
        {props.value}
      </Table.Cell>
    );
  }

  return <Table.Cell {...props} />;
};
export default (props) => {
  const {
    columns,
    rows,
    showFilter,
    showSortingControls,
    tableColumnExtention,
  } = props;

  const ContainerTable = ({ ...restProps }) => {
    return (
      <Table.Container
        style={{
          overflow: 'auto',
        }}
        {...restProps}
      />
    );
  };

  return (
    <Grid rows={rows} columns={columns}>
      <SortingState
        defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
        columnExtensions={[{ columnName: 'options', sortingEnabled: false }]}
      />
      <IntegratedSorting />
      {showFilter ? (
        <FilteringState
          columnExtensions={[
            { columnName: 'options', filteringEnabled: false },
          ]}
        />
      ) : null}
      {showFilter ? <IntegratedFiltering /> : null}
      <PagingState defaultCurrentPage={0} defaultPageSize={10} />
      <IntegratedPaging />
      <Table
        cellComponent={Cell}
        columnExtensions={tableColumnExtention ? tableColumnExtention : []}
      />
      {showFilter ? <TableFilterRow /> : null}

      <TableHeaderRow showSortingControls={showSortingControls} />
      <PagingPanel containerComponent={PagingContainer} />
    </Grid>
  );
};
