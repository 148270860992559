import { Button } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { Card, CardText, Cell, Grid } from 'react-md';
import styled from 'styled-components';
import Table from './Table';
import Axios from 'axios';
import { CircularProgress } from 'react-md';
const env = process.env;

export default function AWBHistory(props) {
  const [update, setUpdateManifest] = useState(false);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token + '';
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  const triggerChildAlert = () => {
    //console.log('props', props);
    setUpdateManifest(true);
  };
  return (
    <Fragment>
      <Grid style={{ alignItems: 'center' }}>
        <Cell size={6}>
          <span style={{ fontSize: 35, color: 'gray' }}>
            ACE Air eManifest History
          </span>
        </Cell>
        <Cell
          size={6}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {/* Refresh button */}
          <UtilityButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              onClick={async (e) => {
                if (await checkToken()) {
                  //props.history.push('/upload2');
                  triggerChildAlert();
                }
              }}
              // href="/upload2"
            >
              {update === false ? (
                'Refresh'
              ) : (
                <CircularProgress
                  id="refresHistory"
                  style={{ marginBottom: '0px', marginTop: '0px' }}
                ></CircularProgress>
              )}
            </Button>
          </UtilityButtonWrapper>

          {/* Import button */}
          <UtilityButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              onClick={async (e) => {
                if (await checkToken()) {
                  props.history.push('/upload-flatfile');
                }
              }}
              // href="/upload2"
            >
              Upload CSV
            </Button>
          </UtilityButtonWrapper>

          {/* Create FRI manifest button */}
          <UtilityButtonWrapper>
            <Button
              variant="contained"
              color="primary"
              onClick={async (e) => {
                if (await checkToken()) {
                  props.history.push('/create-manifest');
                }
              }}
              // href="/create-manifest"
            >
              New Airway Bill
            </Button>
          </UtilityButtonWrapper>

          {/* Send button */}
          <UtilityButtonWrapper>
            {/* <Button
              variant="outlined"
              color="primary"
              onClick={async e => {
                if (await checkToken()) {
                  props.history.push('/send-manifest');
                }
              }}
              // href="/send-manifest"
            >
              View Drafts
            </Button> */}
          </UtilityButtonWrapper>
        </Cell>
      </Grid>
      <Grid>
        <Cell size={12}>
          <Card raise tableCard>
            <CardText>
              <Table
                {...props}
                update={update}
                setUpdateManifest={setUpdateManifest}
              ></Table>
            </CardText>
          </Card>
        </Cell>
      </Grid>
    </Fragment>
  );
}

const UtilityButtonWrapper = styled.div`
  margin-left: 16px;
`;
