import {
  Cell,
  Card,
  CardText,
  Button,
  CircularProgress,
  SelectField,
  FontIcon,
} from 'react-md';
import Grid2 from 'react-md/lib/Grids/Grid';
import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import { DatePicker } from 'react-md';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useApi } from '../../services/api';
import Axios from 'axios';
import feathers from '../../services/feathers';
import _ from 'lodash';
import Papa from 'papaparse';
import PagingContainer from '../../components/GridComponents/PagingPanel';
const env = process.env;

const xlsData = (transaction = []) => {
  let xlsHeaders = {};
  return [
    xlsHeaders,
    {
      ySteps: 5,
      columns: [
        'Partner',
        'Client',
        'User',
        'Doc Type',
        'Type',
        'Docs Quantity',
      ],

      data: transaction.map((r) => [
        r.partnerSource,
        r.clientContactName
          ? `${r.clientContactName} ${r.clientContactLastName}`
          : r.clientName
          ? `${r.clientName}`
          : 'N/A',
        r._id.user ? r._id.user : 'N/A',
        r._id.docType || '',
        r.Type || '',
        r.countDocs,
      ]),
    },
  ];
};

export default (props) => {
  let form;
  let [toDate, setToDate] = useState(Date.now());
  let [fromDate, setFromDate] = useState(new Date());

  const [range, setRange] = useState({
    from: fromDate,
    to: toDate,
  });
  const [loading, setLoading] = useState(false);
  const [partnershipData, setPartnershipData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [allClient, setAllClient] = useState([]);
  const [reportType, setReportType] = useState('');
  const [, users, fetchUser] = useApi('auth', 'user', []);
  const [userData, setUserData] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [filters, setFilters] = useState({
    partner: '',
    client: '',
    user: '',
    docType: '',
  });
  const reportTypeList = [
    {
      label: 'Transactions by User',
      value: 'user',
    },
    {
      label: 'Transactions by Client',
      value: 'client',
    },
    {
      label: 'Transactions by Partner',
      value: 'partner',
    },
  ];
  const docTypeList = ['', 'FRI', 'FRC', 'FRX', 'FDM', 'FSN', 'FSQ'];
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        await getPartnership();
        await getClient();
        await getUsers();
      }
    }
    tttt();
  }, []);

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        if (range.from === '' && range.to === '') {
          range.from = fromDate;
          range.to = toDate;
          fromDate = fromDate.setDate(fromDate.getDate() - 1);
        } else {
          if (typeof range.from !== 'number') {
            setFromDate(Date.parse(range.from));
          }
          if (typeof range.to !== 'number') {
            toDate = moment(range.to);
            setToDate(Date.parse(toDate));
          }
        }
      }
    }
    tttt();
  }, [range]);

  const partnership = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_CCAPI'] + '/partnership/');
    return response;
  };

  const client = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_CCAPI'] + '/client/');
    return response;
  };

  const getPartnership = async () => {
    //get clients
    let partner = await partnership();
    //console.log('partner:', partner);
    let CompanyItemArray = [];
    CompanyItemArray.push({
      label: ``,
      value: ``,
    });

    for (const element of partner.data) {
      CompanyItemArray.push({
        label: `${element.sourceName}`,
        value: `${element._id}`,
        client: element.Clients,
      });
    }
    let sort = await _.orderBy([...CompanyItemArray], ['label'], ['asc']);

    await setPartnershipData(sort);
  };

  const getClient = async () => {
    let clients = await client();
    let CompanyItemArray = [];
    CompanyItemArray.push({
      label: ``,
      value: ``,
    });
    for (const element of clients.data) {
      CompanyItemArray.push({
        label: element.contactName
          ? `${element.contactName} ${element.contactLastname}`
          : `${element.name}`,
        value: `${element._id}`,
        users: element.Users,
      });
    }
    setAllClient(CompanyItemArray);
    await setClientData(CompanyItemArray);
  };

  const loadClientPerPartner = async (partnerId) => {
    let partner = partnershipData.filter((item) => {
      return item.value == partnerId;
    });

    if (partner && partner.length > 0 && partner[0].client) {
      let CompanyItemArray = [];
      if (partner[0].client.length > 0) {
        CompanyItemArray.push({
          label: ``,
          value: ``,
        });
        let clientIds = [];
        for (const element of partner[0].client) {
          clientIds.push(element._id);
        }

        CompanyItemArray = await allClient.filter((u) => {
          return clientIds.includes(u.value);
        });

        CompanyItemArray = await _.orderBy(
          [...CompanyItemArray],
          ['label'],
          ['asc']
        );
      }
      setClientData(CompanyItemArray);
    }
    if (partnerId == '') {
      setClientData(allClient);
    }
  };

  const loadUserPerClient = async (company) => {
    let myCompany = _.find([...allClient], { value: company });
    let usersArray = [];
    if (company == '') {
      setUserData(allUsers);
    } else if (myCompany && myCompany.users && myCompany.users.length > 0) {
      usersArray.push({ label: ``, value: `` });

      for (const element of myCompany.users) {
        let user = _.find([...allUsers], { value: element.email });
        if (user) {
          usersArray.push(user);
        }
      }

      usersArray = await _.orderBy([...usersArray], ['label'], ['asc']);
      setUserData(usersArray);
    } else {
      setUserData(usersArray);
    }
  };

  const getUsers = async () => {
    let users = await fetchUser({});
    let CompanyItemArray = [];
    CompanyItemArray.push({
      label: ``,
      value: ``,
    });
    for (const element of users[0]) {
      CompanyItemArray.push({
        label: `${element.name} ${element.lastName}`,
        value: `${element.email}`,
        company: element.company,
      });
    }

    setAllUsers(CompanyItemArray);
    await setUserData(CompanyItemArray);
  };

  const getTransaction = async () => {
    if (reportType != '') {
      setLoading(true);

      // console.log('reportType: ', reportType);
      // console.log('fromDate: ', fromDate);
      // console.log('toDate: ', toDate);
      //console.log('filters: ', filters);
      try {
        let result = await feathers.service('requests').find({
          query: {
            $agregation: {
              reportType: reportType,
              startDate: fromDate,
              endDate: toDate,
              partner: filters.partner,
              client: filters.client,
              user: filters.user,
              docType: filters.docType,
            },
          },
        });

        await setReportData(result);
        setLoading(false);
      } catch (error) {
        console.log('error :>> ', error);
        setLoading(false);
      }
    } else {
      toast.error('A value for report type is required.');
    }
  };

  const exportCSV = () => {
    let output = xlsData(reportData);

    let papa = Papa.unparse(
      { fields: output[1].columns, data: output[1].data },
      {
        quotes: false, //or array of booleans
        quoteChar: '"',
        escapeChar: '"',
        delimiter: ',',
        newline: '\r\n',
        skipEmptyLines: false, //or 'greedy',
        encoding: 'utf-8',
        download: true,
        delimitersToGuess: [
          ',',
          '\t',
          '|',
          ';',
          Papa.RECORD_SEP,
          Papa.UNIT_SEP,
        ],
      }
    );

    let CsvString = 'data:application/csv,' + encodeURIComponent(papa);
    var x = document.createElement('A');
    x.setAttribute('href', CsvString);
    x.setAttribute(
      'download',
      `CCAIR-report-transactions-${reportType}-${moment().format(
        'YYYYMMDDhmmss'
      )}.csv`
    );
    document.body.appendChild(x);
    x.click();
    //return encodedUri;
    //setExportData(encodedUri);
  };

  return (
    <Fragment>
      <Grid2>
        <Cell size={5} phoneSize={12} tabletSize={12}>
          <span style={{ fontSize: 35, color: 'gray' }}>
            Transactions Summary
          </span>
        </Cell>
      </Grid2>
      <Grid2>
        <Cell size={12}>
          <Card tableCard>
            <CardText>
              {/* <TableCardHeader visible={false} title="Transactions"> */}
              <form
                ref={(ref) => {
                  form = ref;
                }}
              >
                <Grid2>
                  {/* <div className="md-cell md-cell--5">
                <span style={{ fontSize: 35, color: 'gray' }}>
                  Transactions Summary
                </span>
              </div> */}
                  <Cell size={3}>
                    <SelectField
                      id="reportTypes"
                      label="Report Type"
                      placeholder="ReportType"
                      className="md-cell md-cell--12"
                      menuItems={reportTypeList}
                      value={reportType || ''}
                      onChange={async (v) => {
                        if (v != 'user') {
                          setFilters({ ...filters, user: '' });
                        }
                        if (v == 'partner') {
                          setFilters({ ...filters, client: '', user: '' });
                        }

                        setReportType(v);
                      }}
                      required
                      errorText="A value for Report type is required."
                    />
                  </Cell>
                  <Cell size={6}>
                    <DatePicker
                      id="inline-date-picker-portait"
                      label="from"
                      value={moment(range.from).format('L')}
                      defaultValue={moment(range.from).format('L')}
                      maxDate={new Date(range.to)}
                      className="md-cell md-cell--2"
                      locales="en-US"
                      inline
                      displayMode="portrait"
                      fullWidth={false}
                      autoOk
                      style={{ display: 'inline' }}
                      onChange={(e) => setRange({ ...range, from: e })}
                    />

                    <DatePicker
                      id="inline-date-picker-portait-1"
                      label="to"
                      value={moment(range.to).format('L')}
                      defaultValue={moment(range.to).format('L')}
                      minDate={new Date(range.from)}
                      className="md-cell md-cell--2"
                      locales="en-US"
                      inline
                      displayMode="portrait"
                      fullWidth={false}
                      autoOk
                      style={{ display: 'inline' }}
                      onChange={(e) => setRange({ ...range, to: e })}
                    />

                    {/* <a
                  className="btn btn-success"
                  onClick={() => {
                    exportCSV();
                  }}
                  href={exportData}
                  target="_self"
                  filename={'name'}
                  data={exportData}
                >
                  Export CSV
                </a> */}
                  </Cell>
                  <Cell
                    size={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      raised
                      secondary
                      onClick={() => {
                        exportCSV();
                      }}
                      iconClassName="fa fa-file-export"
                      style={{ marginLeft: '5px' }}
                    >
                      Export CSV
                    </Button>
                  </Cell>
                </Grid2>
                <Grid2 style={{ paddingTop: '0px', marginTop: '0px' }}>
                  <Cell size={12}>
                    <SelectField
                      className="md-cell md-cell--3"
                      label="Partnership"
                      placeholder="Partnership"
                      menuItems={partnershipData}
                      value={filters.partner || ''}
                      onChange={async (v) => {
                        loadClientPerPartner(v);
                        setFilters({ ...filters, partner: v, client: '' });
                      }}
                    />

                    <SelectField
                      id="clientList"
                      className="md-cell md-cell--3"
                      label="Client"
                      placeholder="Client"
                      menuItems={clientData}
                      value={filters.client || ''}
                      onChange={async (v) => {
                        loadUserPerClient(v);
                        setFilters({ ...filters, client: v });
                      }}
                      disabled={reportType == 'partner' ? true : false}
                    />

                    <SelectField
                      id="userList"
                      className="md-cell md-cell--3"
                      label="User"
                      placeholder="User"
                      menuItems={userData}
                      value={filters.user || ''}
                      disabled={
                        reportType == '' || reportType == 'user' ? false : true
                      }
                      onChange={async (v) => {
                        setFilters({ ...filters, user: v });
                      }}
                    />

                    <SelectField
                      id="docTypes"
                      label="Document Type"
                      placeholder="Document Type"
                      className="md-cell md-cell--2"
                      menuItems={docTypeList}
                      value={filters.docType || ''}
                      onChange={async (v) => {
                        setFilters({ ...filters, docType: v });
                      }}
                    />
                    <Button
                      icon
                      secondary
                      iconEl={<FontIcon secondary className="fas fa-search" />}
                      onClick={async (e) => {
                        getTransaction();
                      }}
                      className="md-cell"
                      style={{ marginTop: 25 }}
                    />
                  </Cell>
                </Grid2>
              </form>
              {loading ? (
                <div style={{ marginTop: '40px', marginBottom: '40px' }}>
                  <CircularProgress
                    id="loading"
                    centered={true}
                    className="loading-blue"
                    scale={2}
                  ></CircularProgress>
                </div>
              ) : (
                <Grid
                  rows={reportData}
                  style={{ backgroundColor: 'red' }}
                  columns={[
                    {
                      name: 'partner',
                      title: 'Partner',
                      getCellValue: (row) => {
                        return row.partnerSource ? row.partnerSource : '';
                        // return `${row.docOrigin.AWB ? row.docOrigin.AWB.prefix : ''}-${
                        //   row.docOrigin.AWB ? row.docOrigin.AWB.serialNumber : ''
                        // }`;
                      },
                    },
                    {
                      name: 'client',
                      title: 'Client',
                      getCellValue: (row) => {
                        return row.clientContactName
                          ? `${row.clientContactName} ${row.clientContactLastName}`
                          : row.clientName
                          ? `${row.clientName}`
                          : 'N/A';
                      },
                    },
                    {
                      name: 'user',
                      title: 'User',
                      getCellValue: (row) =>
                        row._id.user ? row._id.user : 'N/A',
                    },
                    {
                      name: 'docType',
                      title: 'Doc Type',
                      getCellValue: (row) => {
                        return row._id.docType ? row._id.docType : '';
                      },
                    },
                    {
                      name: 'type',
                      title: 'Type',
                      getCellValue: (row) => {
                        return row.Type ? row.Type : '';
                      },
                    },
                    {
                      name: 'countDocs',
                      title: 'Docs Quantity',
                      getCellValue: (row) => {
                        return row.countDocs;
                      },
                    },
                  ]}
                >
                  <SortingState
                  // defaultSorting={[{ columnName: 'product', direction: 'asc' }]}
                  />
                  <IntegratedSorting />
                  <FilteringState defaultFilters={[]} />
                  <IntegratedFiltering />
                  <PagingState defaultCurrentPage={0} pageSize={50} />
                  <IntegratedPaging />
                  <Table
                    columnExtensions={[
                      { columnName: 'partner', wordWrapEnabled: true },
                      { columnName: 'client', wordWrapEnabled: true },
                      { columnName: 'user', wordWrapEnabled: true },
                    ]}
                    // containerComponent={ContainerTable}
                  />
                  <TableFilterRow />
                  <TableHeaderRow showSortingControls />
                  <PagingPanel containerComponent={PagingContainer} />
                </Grid>
              )}
            </CardText>
          </Card>
        </Cell>
      </Grid2>
    </Fragment>
  );
};
