import PropTypes from 'prop-types';
import React from 'react';
import { FontIcon, ListItem, MenuButton } from 'react-md';
import { Link } from 'react-router-dom';
import { fontSize } from '@material-ui/system';
import Axios from 'axios';
const env = process.env;

const KebabMenu = ({ id, className, menuItems, setLogged }) => {
  let prof = localStorage.profile ? JSON.parse(localStorage.profile) : {};

  return (
    <MenuButton
      flat
      id={id}
      position="tr"
      className={className}
      menuItems={menu(setLogged, prof)}
      iconChildren="person"
    >
      {prof.name}
      <div
        style={{ fontSize: '9px' }}
      >{`${prof.role} / ${prof.companyName}`}</div>
    </MenuButton>
  );
};

KebabMenu.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default KebabMenu;

const menu = (setLogged, prof) => {
  let arr = [];

  if (prof.role === 'admin' || prof.role === 'support') {
    arr.push(
      <ListItem
        key={3}
        primaryText="Users"
        component={Link}
        to={'/users/list'}
        rightIcon={<FontIcon iconClassName={'fas fa-users'} />}
      />
    );
  }
  if (prof.role === 'support') {
    arr.push(
      <ListItem
        key={4}
        primaryText="Clients"
        component={Link}
        to={'/clients/list'}
        rightIcon={<FontIcon iconClassName={'fas fa-users'} />}
      />
    );
    arr.push(
      <ListItem
        key={5}
        primaryText="Partnerships"
        component={Link}
        to={'/partnerships/list'}
        rightIcon={<FontIcon iconClassName={'fas fa-users'} />}
      />
    );
    arr.push(
      <ListItem
        key={6}
        primaryText="Portal Announcements"
        component={Link}
        to={'/notifications/list'}
        rightIcon={<FontIcon iconClassName={'fas fa-bell'} />}
      />
    );
  }

  if (prof.role === 'admin') {
    arr.push(
      <ListItem
        key={4}
        primaryText="Client Account"
        component={Link}
        to={`/client/${prof.company}`}
        rightIcon={<FontIcon iconClassName={'fas fa-user-cog'} />}
      />
    );
  }

  arr.push(
    <ListItem
      key={2}
      primaryText="Profile"
      component={Link}
      to={'/profile'}
      rightIcon={<FontIcon iconClassName={'fas fa-user'} />}
    />,
    <ListItem
      key={1}
      primaryText="Sign Out"
      rightIcon={<FontIcon iconClassName={'fas fa-sign-out-alt'} />}
      onClick={async () => {
        Axios.defaults.headers.common['Authorization'] = localStorage.token;
        const response = await Axios.post(env['REACT_APP_AUTH'] + '/logout/');
        localStorage.clear();
        setLogged(false);
      }}
    />
  );
  return arr;
};
