import React from 'react';
import { PagingPanel } from '@devexpress/dx-react-grid-material-ui';

const PagingComponent = ({ className, ...props }) => (
  <PagingPanel.Container
    {...props}
    className={className || 'MyPaginContainer'}
  />
);

export default PagingComponent;
