import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const ButtonCargoIn = ({ value, otherName, column, row, ...restProps }) => {
  const { openDialogCargo, setRowDialog } = column;
  let date = null;
  let text = null;
  if (row.cargoIn !== '') {
    date = moment(row.cargoIn).format('MM/DD/YYYY HH:mm');
    text = row.commentIn;
  }

  return (
    <Table.Cell {...restProps}>
      {value !== 'N/A' ? (
        <Button
          key={otherName}
          color="primary"
          variant="outlined"
          onClick={(e) => {
            setRowDialog(row);
            openDialogCargo(true, otherName, date, row.commentIn);
          }}
        >
          {value ? date : otherName}
          {value ? <br /> : null}
          {value ? text : null}
        </Button>
      ) : (
        value
      )}
    </Table.Cell>
  );
};

export default ButtonCargoIn;
