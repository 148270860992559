import {
  Cell,
  TextField,
  Grid,
  SelectField,
  Autocomplete,
  SelectionControlGroup,
} from 'react-md';
import React, { Fragment, useState } from 'react';
import { throttle } from 'lodash/function';
import feathers from '../../../services/feathers';

export default (props) => {
  const {
    role,
    status,
    bondedPremisesType,
    setBondedPremisesType,
    transferType,
  } = props;
  const [allCodes, setAllCodes] = useState([]);
  const [airline, setAirline] = useState([]);
  const [airlineOC, setAirlineOC] = useState([]);
  const [firms, setFirms] = useState([]);
  const [destinationAirport1, setDestinationAirport1] = useState('');
  // const [transferType, setTransferType] = useState('inAirport');

  const search = throttle(async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $params: query,
      },
    });
    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city +
              ' - ' +
              value.alias,
            txt:
              value.iata === 'N'
                ? value.icao + '-' + value.iata
                : value.icao + '-' + value.iata,
          }))
        : null;
      setAllCodes(ccl);
    }
  }, 250);
  const searchAir = throttle(async (query) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });
    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt: value.iata === '' ? value.icao : value.iata,
          }))
        : null;
      setAirline(air);
    }
  }, 250);
  const searchFirm = throttle(async (query) => {
    let cbpcodes = await feathers.service('firms').find({
      query: {
        $agregation: {},
        $firms: query,
      },
    });
    if (cbpcodes.length > 0) {
      let firms2 = [];

      firms2 = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.codes.name +
              ' - ' +
              value.codes.code +
              ' - ' +
              value.codes.city,
            code: value.codes.code,
          }))
        : null;
      setFirms(firms2);
    }
  }, 250);

  const searchAirOnwardCarrier = throttle(async (query) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });
    if (cbpcodes.length > 0) {
      let air2 = [];

      air2 = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt: value.iata === '' ? value.icao : value.iata,
          }))
        : null;
      setAirlineOC(air2);
    }
  }, 250);

  return (
    <Fragment>
      <Cell>
        <SelectionControlGroup
          id="selection-control-group-radio-transfer"
          name="radio-transfer"
          type="radio"
          label="Transfer type"
          value={transferType}
          inline={true}
          style={{ padding: '0px' }}
          controls={[
            {
              label: 'Transfer within the airport',
              value: 'inAirport',
            },
            {
              label: 'Transfer outside the airport',
              value: 'outAirport',
            },
          ]}
          onChange={(e) => {
            //console.log('e: ', e);
            props.setTransferType(e);

            if (status && status == 'DRAFT') {
              if (e === 'inAirport') {
                props.setTrn({
                  ...props.trn,
                  onwardCarrier: '',
                  bondedCarrierId: '',
                });
              } else {
                props.setTrn({
                  ...props.trn,
                  premisesId: '',
                });

                if (props.autocompletesTransferAir) {
                  props.setAutocompletesTransferAir({
                    ...props.autocompletesTransferAir,
                    premisesId: '',
                  });
                }

                if (props.autocompletesTransferFIRMS) {
                  props.setAutocompletesTransferFIRMS({
                    ...props.autocompletesTransferFIRMS,
                    premisesId: '',
                  });
                }
              }
            }
          }}
        />
      </Cell>
      <Cell size={6}>
        <SelectField
          id="select-field-1"
          value={props.trn.transferstatusId ? props.trn.transferstatusId : ''}
          className="md-cell md-cell--8"
          onChange={(e) => {
            if (props.setChange) {
              props.setChange(false);
            }
            props.setTrn({ ...props.trn, transferstatusId: e.toUpperCase() });
          }}
          menuItems={['I', 'D', 'R']}
          disabled={
            (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
          }
          label="Domestic (D) / International (I) / FROB indicator (R)"
          lineDirection="center"
        />
        <input
          ref={props.transferstatusIdRef}
          type="text"
          value={props.trn.transferstatusId ? props.trn.transferstatusId : ''}
          required
          onChange={() => {}}
          style={{
            marginTop: '30px',
            position: 'absolute',
            zIndex: '-1',
            marginLeft: -450,
          }}
        ></input>
      </Cell>

      <Grid>
        <Cell size={4}>
          <Autocomplete
            id="all-codes-1"
            required
            label="Destination Airport"
            data={allCodes.slice(0, 10)}
            dataLabel={'find'}
            dataValue={'txt'}
            value={
              props.autocompletesTransfer
                ? props.autocompletesTransfer.destinationAirport
                : destinationAirport1
            }
            filter={Autocomplete.fuzzyFilter}
            findInlineSuggestion={Autocomplete.findIgnoreCase}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }

              search(e);
              props.setTrn({
                ...props.trn,
                destinationAirport: e.toUpperCase(),
              });

              if (props.autocompletesTransfer) {
                props.setAutocompletesTransfer({
                  ...props.autocompletesTransfer,
                  destinationAirport: e.toUpperCase(),
                });
              } else {
                setDestinationAirport1(e.toUpperCase());
              }
            }}
            onAutocomplete={(v) => {
              let find = v.split('-');
              let c = allCodes;

              props.setTrn({
                ...props.trn,
                destinationAirport: v === 'N' ? c[0].icao : find[1],
              });
              if (props.autocompletesTransfer) {
                let f = allCodes.filter((x) => x.txt === v)[0];
                props.setAutocompletesTransfer({
                  ...props.autocompletesTransfer,
                  destinationAirport: f.find,
                });
              } else {
                let f = allCodes.filter((x) => x.txt === v)[0];
                setDestinationAirport1(f.find);
              }
              //return (props.trn.destinationAirport = v);
            }}
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
          <input
            ref={props.destinationAirportRef}
            type="text"
            value={
              props.trn.destinationAirport ? props.trn.destinationAirport : ''
            }
            required
            onChange={() => {}}
            style={{
              marginTop: '-25px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
        {transferType != 'inAirport' && (
          <>
            <Cell size={4}>
              <TextField
                id="floating-center-title-1"
                value={
                  props.trn.bondedCarrierId ? props.trn.bondedCarrierId : ''
                }
                onChange={(e) => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  if (e.length <= 12) {
                    props.setTrn({
                      ...props.trn,
                      bondedCarrierId: e.toUpperCase(),
                    });
                  }
                }}
                label="Bonded Carrier ID"
                lineDirection="center"
                helpText="Formats Accepted : NN-NNNNNNNAA, NN-NNNNNNNNN, NNN-NN-NNNN, NNNNNN-NNNNN"
                error={
                  props.trn.bondedCarrierId
                    ? props.trn.bondedCarrierId.length < 12
                      ? true
                      : false
                    : false
                }
                errorText="length must be at least 11 characters long and Formats Accepted :NN-NNNNNNNAA, NN-NNNNNNNNN, NNN-NN-NNNN, NNNNNN-NNNNN"
                disabled={
                  (status !== 'DRAFT' && role === 'shipper') ||
                  status === 'SENT'
                }
              />
              <input
                ref={props.bondedCarrierIdRef}
                type="text"
                value={
                  props.trn.bondedCarrierId ? props.trn.bondedCarrierId : ''
                }
                onChange={() => {}}
                style={{
                  marginTop: '-25px',
                  position: 'absolute',
                  zIndex: '-1',
                }}
              ></input>
            </Cell>
          </>
        )}
      </Grid>
      <Grid>
        {transferType === 'inAirport' ? (
          <>
            <Cell size={4}>
              <SelectField
                id="select-field-3"
                className="md-cell md-cell--7"
                menuItems={['FIRMS', 'Airline']}
                label="Bonded Premises Identifier"
                lineDirection="center"
                value={bondedPremisesType}
                onChange={(e) => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  setBondedPremisesType(e);
                }}
                disabled={
                  (status !== 'DRAFT' && role === 'shipper') ||
                  status === 'SENT'
                }
              />
            </Cell>
            <Cell size={4}>
              {bondedPremisesType === 'Airline' ? (
                <Autocomplete
                  id="Air"
                  label="Airline"
                  data={airline.slice(0, 10)}
                  dataLabel={'find'}
                  dataValue={'txt'}
                  value={
                    props.autocompletesTransferAir
                      ? props.autocompletesTransferAir.premisesId
                      : airline.length > 0
                      ? airline.name
                      : ''
                  }
                  filter={null}
                  findInlineSuggestion={Autocomplete.findIgnoreCase}
                  onChange={(e) => {
                    if (props.setChange) {
                      props.setChange(false);
                    }
                    searchAir(e);
                    props.setTrn({
                      ...props.trn,
                      premisesId: e.toUpperCase(),
                    });

                    if (props.autocompletesTransferAir) {
                      props.setAutocompletesTransferAir({
                        ...props.autocompletesTransferAir,
                        premisesId: e.toUpperCase(),
                      });
                    }
                  }}
                  onAutocomplete={(v) => {
                    let find = v.split('-');
                    let c = airline;

                    props.setTrn({
                      ...props.trn,
                      premisesId: v === 'N' ? c[0].icao : find[0] || find[1],
                    });

                    if (props.autocompletesTransferAir) {
                      let f = airline.filter((x) => x.txt === v)[0];
                      props.setAutocompletesTransferAir({
                        ...props.autocompletesTransferAir,
                        premisesId: f.find,
                      });
                    }
                  }}
                  disabled={
                    (status !== 'DRAFT' && role === 'shipper') ||
                    status === 'SENT'
                  }
                />
              ) : (
                <Autocomplete
                  id="firms"
                  label="FIRMS"
                  data={firms.slice(0, 10)}
                  dataLabel={'find'}
                  dataValue={'code'}
                  value={
                    props.autocompletesTransferFIRMS
                      ? props.autocompletesTransferFIRMS.premisesId
                      : firms.length > 0
                      ? firms.name
                      : ''
                  }
                  filter={Autocomplete.fuzzyFilter}
                  findInlineSuggestion={Autocomplete.findIgnoreCase}
                  onChange={(e) => {
                    if (props.setChange) {
                      props.setChange(false);
                    }
                    searchFirm(e);
                    props.setTrn({
                      ...props.trn,
                      premisesId: e.toUpperCase(),
                    });
                  }}
                  onAutocomplete={(v) => {
                    if (v !== undefined) {
                      props.setTrn({
                        ...props.trn,
                        premisesId: v.toUpperCase(),
                      });
                      return (props.trn.premisesId = v);
                    }
                  }}
                  disabled={
                    (status !== 'DRAFT' && role === 'shipper') ||
                    status === 'SENT'
                  }
                />
              )}
              <input
                ref={props.premisesIdRef}
                type="text"
                value={props.trn.premisesId ? props.trn.premisesId : ''}
                onChange={() => {}}
                style={{
                  marginTop: '-25px',
                  position: 'absolute',
                  zIndex: '-1',
                }}
              ></input>
            </Cell>
          </>
        ) : (
          <>
            <Cell size={4}>
              {bondedPremisesType === 'Airline' ? (
                <Autocomplete
                  id="OnwardCarrier"
                  label="Onward Carrier"
                  data={airlineOC.slice(0, 10)}
                  dataLabel={'find'}
                  dataValue={'txt'}
                  value={
                    props.autocompleteTransferAirOC
                      ? props.autocompleteTransferAirOC.onwardCarrier
                      : airlineOC.length > 0
                      ? airlineOC.name
                      : ''
                  }
                  filter={null}
                  findInlineSuggestion={Autocomplete.findIgnoreCase}
                  onChange={(e) => {
                    if (props.setChange) {
                      props.setChange(false);
                    }
                    searchAirOnwardCarrier(e);
                    props.setTrn({
                      ...props.trn,
                      onwardCarrier: e.toUpperCase(),
                    });

                    if (props.autocompleteTransferAirOC) {
                      props.setAutocompleteTransferAirOC({
                        ...props.autocompleteTransferAirOC,
                        onwardCarrier: e.toUpperCase(),
                      });
                    }
                  }}
                  onAutocomplete={(v) => {
                    let find = v.split('-');
                    let c = airlineOC;

                    props.setTrn({
                      ...props.trn,
                      onwardCarrier: v === 'N' ? c[0].icao : find[0] || find[1],
                    });

                    if (props.autocompleteTransferAirOC) {
                      let f = airlineOC.filter((x) => x.txt === v)[0];
                      props.setAutocompleteTransferAirOC({
                        ...props.autocompleteTransferAirOC,
                        onwardCarrier: f.find,
                      });
                    }
                  }}
                  disabled={
                    (status !== 'DRAFT' && role === 'shipper') ||
                    status === 'SENT'
                  }
                />
              ) : (
                <TextField
                  id="floating-center-title-5"
                  value={props.trn.onwardCarrier ? props.trn.onwardCarrier : ''}
                  onChange={(e) => {
                    if (props.setChange) {
                      props.setChange(false);
                    }
                    if (e.length <= 3) {
                      props.setTrn({
                        ...props.trn,
                        onwardCarrier: e.toUpperCase(),
                      });
                    }
                  }}
                  label="Onward Carrier"
                  lineDirection="center"
                  error={
                    props.trn.onwardCarrier
                      ? props.trn.onwardCarrier.length <= 3 &&
                        props.trn.onwardCarrier.length >= 2
                        ? false
                        : true
                      : false
                  }
                  errorText="length must be at least 2 characters long"
                  disabled={
                    (status !== 'DRAFT' && role === 'shipper') ||
                    status === 'SENT'
                  }
                />
              )}

              <input
                ref={props.onwardCarrierRef}
                type="text"
                value={props.trn.onwardCarrier ? props.trn.onwardCarrier : ''}
                onChange={() => {}}
                style={{
                  marginTop: '-25px',
                  position: 'absolute',
                  zIndex: '-1',
                }}
              ></input>
            </Cell>
          </>
        )}
        <Cell size={4}>
          <TextField
            id="floating-center-title-4"
            value={props.trn.inbondControlNum ? props.trn.inbondControlNum : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 9) {
                props.setTrn({
                  ...props.trn,
                  inbondControlNum: e.toUpperCase(),
                });
              }
            }}
            label="9 Digit in Bond Control Number"
            lineDirection="center"
            maxLength={9}
            error={
              props.trn.inbondControlNum
                ? props.trn.inbondControlNum.length < 9
                  ? true
                  : false
                : false
            }
            errorText="length must be at  9 characters long"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
          <input
            ref={props.inbondControlNumRef}
            type="text"
            value={props.trn.inbondControlNum ? props.trn.inbondControlNum : ''}
            onChange={() => {}}
            style={{
              marginTop: '-25px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
      </Grid>
    </Fragment>
  );
};
