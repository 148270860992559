import React, { Fragment } from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
const AdditionalInfo = ({ row, ...restProps }) => {
  if (row.docTranslated.FSC) {
    if (row.docTranslated.FSC.answerCode === '10') {
      return (
        <Table.Cell {...restProps}>
          {row.docTranslated.ARR2 && row.docTranslated.ARR2.impCarrier
            ? 'Airline: ' + row.docTranslated.ARR2.impCarrier
            : ''}
          {row.docTranslated.ARR2 && row.docTranslated.ARR2.flightNumber ? (
            <br />
          ) : null}
          {row.docTranslated.ARR2 && row.docTranslated.ARR2.flightNumber
            ? ' Flight #: ' + row.docTranslated.ARR2.flightNumber
            : ''}
          {row.docTranslated.ARR2 && row.docTranslated.ARR2.arrivalDate ? (
            <br />
          ) : null}
          {row.docTranslated.ARR2 && row.docTranslated.ARR2.arrivalDate
            ? ' Arrival Date: ' + row.docTranslated.ARR2.arrivalDate
            : ''}
          {row.docTranslated.TRN &&
            row.docTranslated.TRN.map((content, i) => (
              <Fragment>
                {content.destinationAirport ? <br /> : null}
                {content.destinationAirport
                  ? `Destination Airport: ${content.destinationAirport}`
                  : ''}
                {content.transferstatusId ? <br /> : null}
                {content.transferstatusId
                  ? `Transfer Status: ${content.transferstatusId}`
                  : ''}
                {content.bondedCarrierId ? <br /> : null}
                {content.bondedCarrierId
                  ? `Bonded Carrier: ${content.bondedCarrierId}`
                  : ''}
                {content.onwardCarrier ? <br /> : null}
                {content.onwardCarrier
                  ? `Onward Carrier: ${content.onwardCarrier}`
                  : ''}
                {content.premisesId ? <br /> : null}
                {content.premisesId
                  ? `Premises Identifier: ${content.premisesId}`
                  : ''}
                {content.inbondControlNum ? <br /> : null}
                {content.inbondControlNum
                  ? `Inbond Control #: ${content.inbondControlNum}`
                  : ''}
              </Fragment>
            ))}
        </Table.Cell>
      );
    } else {
      let addInfo = row.docTranslated.TXT
        ? row.docTranslated.TXT.map(function (value) {
            return `${value.information} `;
          })
        : [];
      return (
        <Table.Cell {...restProps}>
          <div>
            {addInfo.map((content, i) => (
              <Fragment>
                {content}
                <br />
              </Fragment>
            ))}
          </div>
        </Table.Cell>
      );
    }
  } else {
    let addInfo = row.docTranslated.TXT
      ? row.docTranslated.TXT.map(function (value) {
          return `${value.information} `;
        })
      : [];
    return (
      <Table.Cell {...restProps}>
        <div>
          {addInfo.map((content, i) => (
            <Fragment>
              {content}
              <br />
            </Fragment>
          ))}
        </div>
      </Table.Cell>
    );
  }
};

export default AdditionalInfo;
