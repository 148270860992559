import { Cell, TextField, Subheader, Grid, Autocomplete } from 'react-md';
import React, { Fragment } from 'react';
import { throttle } from 'lodash/function';
import feathers from '../../../services/feathers';

export default (props) => {
  const {
    role,
    status,
    shpEmail,
    shpPhoneNumber,
    shpPostalCode,
    shpCountryRef,
  } = props;
  const searchPartyShp = throttle(async (query) => {
    let shippers = await feathers.service('party').find({
      query: {
        search: query,
        type: 'shipper',
      },
    });

    if (shippers.length > 0) {
      let shps = [];

      shps = shippers.length
        ? shippers.map((value) => ({
            ...value,
            find: value.name,
          }))
        : null;
      props.setShippers(shps);
    }
  }, 250);

  const search = throttle(async (query) => {
    let countryCode = null;
    if (query._id) {
      countryCode = query.countrycode;
    } else {
      countryCode = query;
    }
    let countries = await feathers.service('countries').find({
      query: {
        $type: 'Country',
        $csd: countryCode,
      },
    });

    if (countries.length > 0) {
      let csd = [];
      csd = countries.length
        ? countries.map((value) => ({
            ...value,
            find: value.code + ' - ' + value.name,
            txt: value.code + '-' + value.name,
          }))
        : null;

      props.setCountriesShp(csd);
      if (query._id) {
        let concatCountry = csd[0].txt;
        props.setAutocompletesShp({
          ...props.autocompletesShp,
          shpCountry: concatCountry,
        });
        props.setShp({
          ...props.shp,
          name: query.name,
          phoneNumber: query.phone,
          address: query.address,
          postalCode: query.zip,
          city: query.city,
          state: query.state,
          countryCode: query.countrycode,
          email: query.email,
        });
      }
    }
  }, 250);

  return (
    <Fragment>
      <Grid>
        <Cell size={10}>
          {/* <TextField
            id="floating-center-title"
            value={props.shp.name}
            onChange={e => {
              props.setShp({ ...props.shp, name: e.toUpperCase() });
            }}
            label="Name"
            lineDirection="center"
            required
          /> */}

          {props.shippersList !== undefined ? (
            <Autocomplete
              id="shippersAuto"
              required
              label="Name"
              data={props.shippersList.slice(0, 10)}
              dataLabel={'find'}
              dataValue={'_id'}
              filter={Autocomplete.fuzzyFilter}
              findInlineSuggestion={Autocomplete.findIgnoreCase}
              value={
                props.shp.name && props.shippersList.length === 0
                  ? props.shp.name
                  : props.shippersList.length > 0
                  ? props.shp.name
                  : ''
              }
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                searchPartyShp(e);

                props.setShp({ ...props.shp, name: e });
              }}
              onAutocomplete={(v) => {
                let c = props.shippersList;
                let shipperSelected = props.shippersList.filter(
                  (i) => i._id === v
                );
                search(shipperSelected[0]);

                // props.setShp({
                //   ...props.shp,
                //   name: shipperSelected[0].name,
                //   phoneNumber: shipperSelected[0].phone,
                //   address: shipperSelected[0].address,
                //   postalCode: shipperSelected[0].zip,
                //   city: shipperSelected[0].city,
                //   state: shipperSelected[0].state,
                //   countryCode: shipperSelected[0].countrycode,
                //   email: shipperSelected[0].email
                // });
              }}
              disabled={
                (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
              }
            />
          ) : null}
        </Cell>
      </Grid>
      <Grid>
        <Cell size={10}>
          <TextField
            // maxLength={35}
            id="floating-center-title-addres"
            value={props.shp.address ? props.shp.address : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 35) {
                props.setShp({ ...props.shp, address: e.toUpperCase() });
              }
            }}
            label="Address"
            lineDirection="center"
            required
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            // error={
            //   props.shp.address
            //     ? props.shp.address.length > 35
            //       ? true
            //       : false
            //     : false
            // }
            // errorText="length must be less than 35 characters"
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={4}>
          <TextField
            // maxLength={17}
            id="floating-center-title-city"
            value={props.shp.city ? props.shp.city : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 17) {
                props.setShp({ ...props.shp, city: e.toUpperCase() });
              }
            }}
            label="City"
            lineDirection="center"
            required
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            // error={
            //   props.shp.city
            //     ? props.shp.city.length > 17
            //       ? true
            //       : false
            //     : false
            // }
            // errorText="length must be less than 17 characters"
          />
        </Cell>
        <Cell size={4}>
          <TextField
            // maxLength={9}
            id="floating-center-title-state"
            value={props.shp.state ? props.shp.state : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 9) {
                props.setShp({ ...props.shp, state: e.toUpperCase() });
              }
            }}
            label="State/Province"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            // required
            // error={
            //   props.shp.state
            //     ? props.shp.state.length > 9
            //       ? true
            //       : false
            //     : false
            // }
            // errorText="length must be less than 9 characters"
          />
        </Cell>
        <Cell size={4}>
          {/* <TextField
            id="floating-center-title-country"
            // maxLength={2}
            value={
              props.autocompletes
                ? props.autocompletes.originGoods
                : props.countries.code
            }
            onChange={e => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 2) {
                props.setShp({ ...props.shp, countryCode: e.toUpperCase() });
              }
            }}
            label="Country"
            lineDirection="center"
            required
            disabled={
              status === 'DRAFT' ||
              status === 'ACCEPTED' ||
              status === 'REJECTED'
                ? false
                : true
            }
            error={
              props.shp.countryCode
                ? props.shp.countryCode.length < 2
                  ? true
                  : false
                : false
            }
            errorText="length must be at 2 characters long"
          /> */}
          <Autocomplete
            id="title-country-shipper"
            label="Country"
            data={
              status === 'DRAFT' ||
              status === 'ACCEPTED' ||
              status === 'REJECTED' ||
              status === 'SENDING'
                ? props.countriesShp
                : []
            }
            dataLabel={'find'}
            dataValue={'txt'}
            findInlineSuggestion={Autocomplete.findIgnoreCase}
            value={
              props.autocompletesShp.shpCountry
                ? props.autocompletesShp.shpCountry
                : props.countriesShp.name
            }
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length >= 1) {
                search(e);
              }
              props.setShp({
                ...props.shp,
                countryCode: e.toUpperCase(),
              });
              if (props.autocompletesShp) {
                props.setAutocompletesShp({
                  ...props.autocompletesShp,
                  shpCountry: e.toUpperCase(),
                });
              }
            }}
            onAutocomplete={async (v) => {
              if (v !== undefined) {
                let find = v.split('-');

                props.setShp({
                  ...props.shp,
                  countryCode: find[0],
                });
                if (props.autocompletesShp) {
                  let f = props.countriesShp.filter((x) => x.txt === v)[0];
                  props.setAutocompletesShp({
                    ...props.autocompletes,
                    shpCountry: f.find,
                  });
                }
              }
            }}
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            required
          />
          <input
            ref={shpCountryRef}
            type="text"
            value={
              props.shp.countryCode
                ? props.shp.countryCode
                : props.shp.countrycode
                ? props.shp.countrycode
                : ''
            }
            required
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
      </Grid>
      <Grid>
        <Cell size={4}>
          <TextField
            id="floating-center-title-email"
            value={props.shp.email ? props.shp.email : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              props.setShp({ ...props.shp, email: e });
            }}
            label="Email"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            type="email"
            error={
              props.shp &&
              props.shp.email &&
              props.shp.email.length &&
              props.shp.email.match(
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
              )
                ? false
                : props.shp.email && props.shp.email.length > 0
                ? true
                : false
            }
            errorText={'you need to add a valid email '}
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
          <input
            ref={shpEmail}
            value={props.shp.email ? props.shp.email : ''}
            required={
              props.shp && props.shp.email && props.shp.email.length > 0
                ? true
                : false
            }
            type="email"
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
        <Cell size={4}>
          <TextField
            id="floating-center-title-phone"
            value={
              props.shp.phoneNumber
                ? props.shp.phoneNumber
                : props.shp.phone
                ? props.shp.phone
                : ''
            }
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 14) {
                props.setShp({ ...props.shp, phoneNumber: e.toUpperCase() });
              }
            }}
            label="Phone"
            error={
              props.shp &&
              props.shp.phoneNumber &&
              props.shp.phoneNumber.length &&
              props.shp.phoneNumber.length <= 0
                ? true
                : false
            }
            errorText={
              'length must be greater or equal than 1 character and less than 14 characters long'
            }
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            type="number"
            min="0"
          />
          <input
            ref={shpPhoneNumber}
            value={props.shp.phoneNumber ? props.shp.phoneNumber : ''}
            required={
              props.shp &&
              props.shp.phoneNumber &&
              props.shp.phoneNumber.length > 0
                ? true
                : false
            }
            type="number"
            min="0"
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
        <Cell size={4}>
          <TextField
            id="floating-center-title-postal-code"
            value={
              props.shp.postalCode
                ? props.shp.postalCode
                : props.shp.zip
                ? props.shp.zip
                : ''
            }
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 9) {
                props.setShp({ ...props.shp, postalCode: e.toUpperCase() });
              }
            }}
            label="Zip Code"
            error={
              props.shp &&
              props.shp.postalCode &&
              props.shp.postalCode.length &&
              props.shp.postalCode.length <= 0
                ? true
                : false
            }
            errorText={
              'length must be greater or equal than 1 character and less than 9 characters long'
            }
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
          />
          <input
            ref={shpPostalCode}
            value={props.shp.postalCode ? props.shp.postalCode : ''}
            required={
              props.shp &&
              props.shp.postalCode &&
              props.shp.postalCode.length > 0
                ? true
                : false
            }
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
      </Grid>
    </Fragment>
  );
};
