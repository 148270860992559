import React from 'react';
import { components } from '../../components/Ag-Grid/CellRenderer/';
import { gridDate } from '../Date-Functions/date-functions';
import { dateFormats } from '../../constants/Ag-Grid/constants';
import moment from 'moment';

export const valueFormatter = ({ node: { allLeafChildren, data } }) =>
  data || (allLeafChildren && allLeafChildren.map(({ data }) => data));

export const valueGetter = ({ data, colDef }) => {
  if (data && colDef) {
    const { field } = colDef;
    const { grid_full_date } = dateFormats;
    const noLastDot = (s) => (s ? s.replace(/\.$/, '') : '');
    let res = '';

    switch (field) {
      case 'createDate':
      case 'submittedDate':
      case 'responseDate':
      case 'cbpProcessingDate':
      case 'date':
        return gridDate(data[field], grid_full_date);
      case 'AWB#':
      case 'AWB':
        return `${data.prefix || ''}-${data.serialNumber || ''}`;
      case 'isSplit':
        return data[field] ? 'Yes' : 'No';
      case 'totalHouse':
        return data[field] > 0 ? `${data[field]}` : '';
      case 'transferStatus':
      case 'arrivalStatus':
        if (Array.isArray(data[field])) {
          data[field].forEach(({ status, qty, total, ...arrived }) => {
            const { arrivedAirline: airline, arrivedDate: date } = arrived;
            const { arrivedFlight: flight, parn } = arrived;
            let s = noLastDot(status);
            res += s ? `\n${s} (${qty || total || 0})` : '';
            let p = `${parn ? `PARN # ${parn}` : ''}`;
            let d = date ? moment(date).format('DD-MMM').toUpperCase() : '';
            let arrivedData = `\nArrived ${airline} ${flight} ${d} ${p}`;
            res += s ? arrivedData : '';
          });
        }
        return res;
      case 'statusSummary':
      case 'notificationSummary':
        Array.isArray(data[field]) &&
          data[field].forEach(({ status, code, errorCodes, qty }) => {
            if (errorCodes && errorCodes.length) {
              errorCodes.forEach((s) => {
                res += s ? `\n${s} (${qty || 0})` : '';
              });
            } else {
              let s = noLastDot(status || code);
              res += s ? `\n${s} (${qty || 0})` : '';
            }
          });
        return res;
      case 'errorSummary':
        Array.isArray(data[field]) &&
          data[field].forEach(({ code, errorCodes, qty }) => {
            Array.isArray(errorCodes) &&
              errorCodes.forEach((s) => {
                res += s ? `\n${s}` : '';
              });
            res += code && !isNaN(qty) ? `\n${code} (${qty})` : '';
          });
        return res;
      case 'statusNotification':
        if (Array.isArray(data[field])) {
          data[field].forEach((s) => {
            res += s ? `\n${s}` : '';
          });
        }
        return res;
      case 'additionalInfo':
        if (Array.isArray(data[field])) {
          data[field].forEach(({ label, value }) => {
            res += `\n${label} ${value}`;
          });
        }
        return res;
      case 'flightInfo':
        if (typeof data[field] === 'object') {
          for (const name in data[field]) {
            if (Object.hasOwnProperty.call(data[field], name)) {
              const value = data[field][name];
              const valid = [
                'Airline',
                '1st US Port of Arrival',
                'Flight #',
                'Arrival Date',
                'Transfer To',
              ];

              if (name === 'FSC10' && value) res += `\n${name}: ${value || ''}`;

              if (valid.includes(name)) res += `\n${name}: ${value || ''}`;
            }
          }
        }
        return res;
      case 'options':
        return data;
      default:
        return data[field];
    }
  }
  return '';
};

//#region render components deconstruction
const { Cell, Link, Dropdown, GridButton, Summary, Type } = components;
//#endregion render components deconstruction

export const cellRendererFramework = (params) => {
  const { value, colDef, valueFormatted, rowIndex, data } = params;
  const { field, functions, options, headerName } = colDef;
  const id = `${field}-${rowIndex}`;
  const RowData =
    typeof valueFormatted === 'object' ? valueFormatted : value || data;

  const onClick = (fn, key, data) => fn && fn(key, data);

  switch (field) {
    case 'AWB':
      if (valueFormatted && typeof valueFormatted === 'object') {
        if (functions) {
          const { awb_link } = functions;
          const handleLink = () => awb_link && awb_link(field, valueFormatted);
          return <Link text={value} id={id} onClick={handleLink} />;
        }
      }
      return <Cell value={value} id={id} />;
    case 'AWB#':
      return <Cell value={value} id={id} />;
    case 'transferStatus':
    case 'arrivalStatus':
    case 'statusSummary':
    case 'notificationSummary':
    case 'flightInfo':
    case 'statusNotification':
    case 'additionalInfo':
    case 'errorSummary':
      return <Summary data={value} id={id} />;
    case 'options':
      if (options) {
        const ID = (RowData && RowData._id) || id;
        if (functions) {
          const { option_select } = functions;
          const onSelect = (key, params) =>
            option_select && option_select(key, params);
          return (
            <Dropdown
              text="Options"
              data={RowData}
              options={options}
              id={ID}
              onSelect={onSelect}
            />
          );
        }
      }
      return <Cell value="" id={id} />;
    case 'viewEDI':
      if (functions) {
        const { viewEDI_button } = functions;
        return (
          <GridButton
            text={headerName}
            onClick={() => onClick(viewEDI_button, field, RowData)}
            variant="outlined"
            color="primary"
          />
        );
      }
      return <Cell value="" id={id} />;
    case 'print':
      if (functions) {
        const { print_button } = functions;
        return (
          <GridButton
            text={headerName}
            onClick={() => onClick(print_button, field, RowData)}
            variant="outlined"
          />
        );
      }
      return <Cell value="" id={id} />;
    case 'checkOut':
      if (functions) {
        const { showCheckModal } = functions;
        if (!RowData.Checks || !RowData.Checks.commentOut) {
          return (
            <GridButton
              text={(RowData.Checks && RowData.Checks.commentOut) || headerName}
              onClick={() => onClick(showCheckModal, field, RowData)}
              variant="outlined"
              color="primary"
            />
          );
        }
        return (
          <Link
            text={
              <Summary
                data={`${moment(
                  RowData.Checks && RowData.Checks.checkOut
                ).format('MM/DD/YYYY HH:mm')}\n${
                  RowData.Checks && RowData.Checks.commentOut
                }`}
                id={id}
              />
            }
            onClick={() => onClick(showCheckModal, field, RowData)}
            id={id}
          />
        );
      }
      return <Cell value="" id={id} />;
    case 'checkIn':
      if (functions) {
        const { showCheckModal } = functions;
        if (!RowData.Checks || !RowData.Checks.commentIn) {
          return (
            <GridButton
              text={(RowData.Checks && RowData.Checks.commentIn) || headerName}
              onClick={() => onClick(showCheckModal, field, RowData)}
              variant="outlined"
              color="primary"
            />
          );
        }
        return (
          <Link
            text={
              <Summary
                data={`${moment(
                  RowData.Checks && RowData.Checks.checkIn
                ).format('MM/DD/YYYY HH:mm')}\n${
                  RowData.Checks && RowData.Checks.commentIn
                }`}
                id={id}
              />
            }
            onClick={() => onClick(showCheckModal, field, RowData)}
            id={id}
          />
        );
      }
      return <Cell value="" id={id} />;
    case 'type':
      return <Type value={value} id={id} />;
    default:
      return <Cell value={value} id={id} />;
  }
};
