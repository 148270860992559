import { Cell, TextField, Subheader, SelectField, Grid } from 'react-md';
import React, { Fragment } from 'react';

export default (props) => {
  const { role, status } = props;

  let abc = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];

  return (
    <Fragment>
      <Grid>
        <SelectField
          id="select-field-1_arrivalRef"
          className="md-cell md-cell--6"
          value={props.arr.arrivalRef ? props.arr.arrivalRef : ''}
          onChange={(e) => {
            if (props.setChange) {
              props.setChange(false);
            }
            props.setArr({ ...props.arr, arrivalRef: e.toUpperCase() });
          }}
          menuItems={abc}
          label="Part Arrival Reference (PART)"
          disabled={
            (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
          }
          required
        />
        <input
          ref={props.arrivalRefRef}
          type="text"
          value={props.arr.arrivalRef ? props.arr.arrivalRef : ''}
          required
          onChange={() => {}}
          style={{
            marginTop: '25px',
            position: 'absolute',
            zIndex: '-1',
          }}
        ></input>

        <Cell size={4}>
          <TextField
            id="floating-center-title-11"
            value={props.arr.brdPieceCnt ? props.arr.brdPieceCnt : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              props.setArr({ ...props.arr, brdPieceCnt: e.toUpperCase() });
            }}
            label="Pieces"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            required
            type="number"
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={6}>
          <TextField
            id="floating-center-title-12"
            value={props.arr.weight ? props.arr.weight : ''}
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              props.setArr({ ...props.arr, weight: e.toUpperCase() });
            }}
            label="Weight"
            lineDirection="center"
            disabled={
              (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
            }
            required
            type="number"
          />
        </Cell>

        <SelectField
          id="select-field-1_weightCode"
          className="md-cell md-cell--6"
          value={props.arr.weightCode ? props.arr.weightCode : ''}
          onChange={(e) => {
            if (props.setChange) {
              props.setChange(false);
            }
            props.setArr({ ...props.arr, weightCode: e.toUpperCase() });
          }}
          label="Lbs or Kgs"
          menuItems={['L', 'K']}
          simplifiedMenu={true}
          position={SelectField.Positions.BOTTOM_RIGHT}
          disabled={
            (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
          }
          required
        />
        <input
          ref={props.weightCodeRef}
          type="text"
          value={props.arr.weightCode ? props.arr.weightCode : ''}
          required
          onChange={() => {}}
          style={{
            marginTop: '25px',
            position: 'absolute',
            zIndex: '-1',
            marginLeft: 950,
          }}
        ></input>
      </Grid>
    </Fragment>
  );
};
