import {
  FilteringState,
  GroupingState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  RowDetailState,
  SortingState
} from '@devexpress/dx-react-grid';
import {
  Grid,
  PagingPanel,
  Table,
  TableFilterRow,
  TableGroupRow,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui';
import React, { Fragment } from 'react';
import Paper from '@material-ui/core/Paper';
import PagingContainer from '../../components/GridComponents/PagingPanel';

export default function ShowError(props) {
  let rows = props.rows;

  return (
    <Fragment>
      <Paper>
        <Grid
          rows={rows}
          columns={[
            {
              name: 'key',
              title: 'AWB Number',
              getCellValue: row => {
                return `${row.key}`;
              }
            },
            {
              name: 'sn',
              title: 'AWB Number',
              getCellValue: row => {
                return `${row.key}`;
              }
            },
            {
              name: 'mesagge',
              title: 'Message',
              getCellValue: row => {
                return `${row.message}`;
              }
            },
            {
              name: 'row',
              title: 'Row',
              getCellValue: row => {
                return `${row.row}`;
              }
            }
          ]}
        >
          <RowDetailState />
          <SortingState
            defaultSorting={[{ columnName: 'row', direction: 'asc' }]}
          />
          <IntegratedSorting />
          <GroupingState grouping={[{ columnName: 'key' }]} />
          <IntegratedGrouping />
          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
          <Table
            columnExtensions={[
              { columnName: 'mesagge', wordWrapEnabled: true }
            ]}
          />
          <TableFilterRow />
          <TableHeaderRow showSortingControls />
          <TableGroupRow />
          <PagingPanel containerComponent={PagingContainer} />
        </Grid>
      </Paper>
    </Fragment>
  );
}
