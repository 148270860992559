import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardText, Cell, Grid, Button, CardTitle } from 'react-md';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import feathers from '../../services/feathers';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import { Switch } from 'react-md';
import { DeleteManifest } from '../../components';
import { Button as BTN } from '@material-ui/core';
import {
  TransferDetails,
  PermitProceed,
  SplitIndicator,
  FlightDetails,
  Shipper,
  Consignee,
  CargoDetails,
} from '../../components/documents/manifest';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import ShowDetailsManifest from './ShowDetailsManifest';
import Axios from 'axios';
import Lottie from 'react-lottie';
import LoadingAnimation from '../../assets/loading-strip-loop.json';
const env = process.env;

let form;
let impC = React.createRef();
let tOp = React.createRef();
let airO = React.createRef();
let arrAirport = React.createRef();
let prefixRef = React.createRef();
let sNRef = React.createRef();
let shpEmail = React.createRef();
let shpPhoneNumber = React.createRef();
let shpPostalCode = React.createRef();
let cneEmail = React.createRef();
let cnePhoneNumber = React.createRef();
let cnePostalCode = React.createRef();
let hawbRef = React.createRef();
let cneCountryRef = React.createRef();
let shpCountryRef = React.createRef();
let cargoCountryRef = React.createRef();
let cargoCurrencyRef = React.createRef();
let flightNumberRef = React.createRef();
let destinationAirportRef = React.createRef();
let arrivalRefRef = React.createRef();
let weightCodeRef = React.createRef();
let transferstatusIdRef = React.createRef();
let destinationAirportRefT = React.createRef();
let bondedCarrierIdRef = React.createRef();
let premisesIdRef = React.createRef();
let onwardCarrierRef = React.createRef();
let inbondControlNumRef = React.createRef();
let pkTrackingIdRef = React.createRef();
let weightCodeCargoRef = React.createRef();

export default (props) => {
  let email = JSON.parse(localStorage.profile).email;
  const [date, setDate] = useState();
  const [AWB, setAWB] = useState({
    prefix: '',
    serial: '',
    hawbNumber: '',
    flightNumber: '',
  });
  let {
    _id,
    status,
    prefix,
    sn,
    userCreated,
    userSubmission,
    manifestCreated,
    lastRequest,
    lastResponse,
    rowEdit,
  } = props.match.params;

  const { path } = props.match;
  const { onClose, onGetManifest, pristine } = props;
  const [profile] = useState(JSON.parse(localStorage.profile));
  const [loading, setLoading] = useState(true);
  const [showDelete, setDelete] = useState(false);
  const [, , fetchDoc] = useApi('ccapi', 'documents2', undefined, 'post');
  const [airlines, setAirlines] = useState([]);
  const [allCodes, setAllCodes] = useState([]);
  const [usCodes, setUsCodes] = useState([]);
  const [hts, setHts] = useState([]);
  const [edit, setEdit] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [fda, setFda] = useState(false);
  const [transfer, setTransfer] = useState({});
  const [wbl, setWbl] = useState({});
  const [arr, setArr] = useState({ componentId: 'ARR' });
  const [active, setActive] = useState(false);
  const [split, setSplit] = useState(false);
  const [permit, setPermit] = useState(false);
  const [startDate, setStartDate] = useState({
    arr: new Date(),
    wbl: new Date(),
  });
  const [aws, setAws] = useState('Simple');
  const [body, setBody] = useState([]);
  const [shippersList, setShippers] = useState([]);
  const [consigneeList, setConsignees] = useState([]);
  const [house, setHouse] = useState(false);
  const [simple, setSimple] = useState(false);
  const [ccl, setCcl] = useState({ arrivalAirport: '', terminalOp: '' });
  const [marksAndNumbers, setMarksAndNumbers] = useState('');
  const [terminal, setTerminal] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesShp, setCountriesShp] = useState([]);
  const [countriesCne, setCountriesCne] = useState([]);

  const [change, setChange] = useState(true);
  useEffect(() => {
    pristine(change);
  }, [change]);

  const [lastData, setLastData] = useState({});

  const [shp, setShp] = useState({
    componentId: 'SHP',
    name: '',
    address: '',
    city: '',
    state: '',
    countryCode: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
  });
  const [cne, setCne] = useState({
    componentId: 'CNE',
    name: '',
    address: '',
    city: '',
    state: '',
    countryCode: '',
    postalCode: '',
    phoneNumber: '',
  });
  const [awb, setAwb] = useState({
    consolidationId: '',
    prefix: '',
    serialNumber: '',
    hawbNumber: '',
    pkTrackingId: '',
  });
  const [AWBF, setAWBF] = useState({
    prefix: '',
    serial: '',
    hawbNumber: '',
    flightNumber: '',
  });
  const [agt, setAgt] = useState({ participantCode: '' });
  const [csd, setCsd] = useState({
    originGoods: '',
    declaredValue: '',
    currencyCode: '',
    hazmatCode: '',
  });
  const [autocompletes, setAutocompletes] = useState({
    originGoods: '',
    shpCountry: '',
    cneCountry: '',
  });
  const [autocompletesA, setAutocompletesA] = useState({});
  const [autocompletesCurr, setAutocompletesCurr] = useState({});
  const [autocompletesT, setAutocompletesT] = useState({});
  const [autocompletesAir, setAutocompletesAir] = useState({});
  const [autocompletesAirArr, setAutocompletesAirArr] = useState({});
  const [autocompletesAirPermit, setAutocompletesAirPermit] = useState({});
  const [autocompletesTransfer, setAutocompletesTransfer] = useState({});
  const [autocompletesTransferAir, setAutocompletesTransferAir] = useState({});
  const [autocompletesTransferFIRMS, setAutocompletesTransferFIRMS] = useState(
    {}
  );
  const [autocompleteTransferAirOC, setAutocompleteTransferAirOC] = useState(
    {}
  );
  const [autocompletesShp, setAutocompletesShp] = useState({ shpCountry: '' });
  const [autocompletesCne, setAutocompletesCne] = useState({ cneCountry: '' });

  const [bondedPremisesType, setBondedPremisesType] = useState('Airline');

  const [transferType, setTransferType] = useState('inAirport');

  const sss = async (s, segment) => {
    let countries2 = await feathers.service('countries').find({
      query: {
        code: s,
      },
    });
    let countries = countries2.map((value) => ({
      ...value,
      find: value.code + ' - ' + value.name,
    }));
    // setCsd({ ...csd, originGoods: countries[0].code });
    if (countries.length) {
      if (segment === 'CSD') {
        setAutocompletes({ ...autocompletes, originGoods: countries[0].find });
        setCountries(countries);
      } else if (segment === 'SHP') {
        if (s) {
          setAutocompletesShp({
            ...autocompletesShp,
            shpCountry: countries[0].find,
          });

          setCountriesShp(countries);
        }
      } else if (segment === 'CNE') {
        if (s) {
          setAutocompletesCne({
            ...autocompletesCne,
            cneCountry: countries[0].find,
          });
          setCountriesCne(countries);
        }
      }
    }
  };
  const ccc = async (query) => {
    let currency = await feathers.service('currencies').find({
      query: {
        // $agregation: {},
        $curr: query,
      },
    });
    if (currency.length > 0) {
      let curr = [];

      curr = currency.length
        ? currency.map((value) => ({
            ...value.currency,
            find: value.code + ' - ' + value.name,
          }))
        : null;
      if (curr.length) {
        setAutocompletesCurr({
          ...autocompletesCurr,
          currencyCode: curr[0].find,
        });
        setCurrency(curr);
      }
    }
    // if (currency.length > 0) {
    //   let curr = [];

    //   curr = currency.length
    //     ? currency.map(value => ({
    //         ...value.currency,
    //         find:
    //           value.currencies[0].currencycode +
    //           ' - ' +
    //           value.currencies[0].currency
    //       }))
    //     : null;
    //   // if (curr.length) {
    //   setAutocompletesCurr({ ...autocompletesCurr, currencyCode: curr[0].find });
    //   setCurrency(curr);
    //   // }
    // }
  };

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };
  const cache = async (prefix, sn, deleteMS, manifestEdited = 0) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');

    const response = await Axios.post(env['REACT_APP_CCAPI'] + '/cache/', {
      prefix: prefix,
      serialNumber: sn,
      delete: deleteMS,
      manifestEdited: manifestEdited,
    });
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        getManifest(_id);
      }
    }
    tttt();
  }, []);

  const searchAIR = async (query) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });

    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt: value.iata === '' ? value.icao : value.iata,
            // ,
            // txt: value.iata === undefined ? value.icao : 'cccc'
          }))
        : null;

      setAutocompletesA({ ...autocompletesA, impCarrier: air[0].find });
    } else {
      setAutocompletesA({ ...autocompletesA, impCarrier: query });
    }
  };
  const searchTerminal = async (query) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });

    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt: value.iata === '' ? value.icao : value.iata,
            // ,
            // txt: value.iata === undefined ? value.icao : 'cccc'
          }))
        : null;
      setAutocompletesT({ ...autocompletesT, terminalOp: air[0].find });
    } else {
      setAutocompletesT({ ...autocompletesT, terminalOp: query });
    }
  };
  const search = async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $params: query,
      },
    });
    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt: value.iata === 'N' ? value.icao : value.iata,
          }))
        : null;
      setAutocompletesAir({ ...autocompletesAir, airportOrigin: ccl[0].find });
    } else {
      setAutocompletesAir({ ...autocompletesAir, airportOrigin: query });
    }
  };
  const searchPermit = async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $params: query,
      },
    });
    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt: value.iata === 'N' ? value.icao : value.iata,
          }))
        : null;
      setAutocompletesAirPermit({
        ...autocompletesAirPermit,
        destinationAirport: ccl[0].find,
      });
    } else {
      setAutocompletesAirPermit({
        ...autocompletesAirPermit,
        destinationAirport: query,
      });
    }
  };
  const searchCCL = async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $ccl: query,
      },
    });

    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt: value.iata === 'N' ? value.icao : value.iata,
          }))
        : null;
      setAutocompletesAirArr({
        ...autocompletesAirArr,
        arrivalAirport: ccl[0].find,
      });
    } else {
      setAutocompletesAirArr({
        ...autocompletesAirArr,
        arrivalAirport: query,
      });
    }
  };
  const searchTransfer = async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $params: query,
      },
    });

    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt: value.iata === 'N' ? value.icao : value.iata,
          }))
        : null;
      setAutocompletesTransfer({
        ...autocompletesTransfer,
        destinationAirport: ccl[0].find,
      });
    } else {
      setAutocompletesTransfer({
        ...autocompletesTransfer,
        destinationAirport: query,
      });
    }
  };

  const searchTransferAir = async (query) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });

    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt: value.iata === '' ? value.icao : value.iata,
          }))
        : null;

      setAutocompletesTransferAir({
        ...autocompletesTransferAir,
        premisesId: air[0].find,
      });
    } else {
      setAutocompletesTransferAir({
        ...autocompletesTransferAir,
        premisesId: query,
      });
    }
  };
  const searchTransferFIRMS = async (query) => {
    let cbpcodes = await feathers.service('firms').find({
      query: {
        $agregation: {},
        $firms: query,
      },
    });

    if (cbpcodes.length > 0) {
      let firms2 = [];

      firms2 = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.codes.name +
              ' - ' +
              value.codes.code +
              ' - ' +
              value.codes.city,
            code: value.codes.code,
          }))
        : null;

      setAutocompletesTransferFIRMS({
        ...autocompletesTransferFIRMS,
        premisesId: firms2[0].find,
      });
    } else {
      setAutocompletesTransferFIRMS({
        ...autocompletesTransferFIRMS,
        premisesId: query,
      });
    }
  };

  const searchTransferAirOC = async (query) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });

    if (cbpcodes.length > 0) {
      let air = [];
      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt: value.iata === '' ? value.icao : value.iata,
          }))
        : null;

      setAutocompleteTransferAirOC({
        ...autocompleteTransferAirOC,
        onwardCarrier: air[0].find,
      });
    } else {
      setAutocompleteTransferAirOC({
        ...autocompleteTransferAirOC,
        onwardCarrier: query,
      });
    }
  };

  const findParty = async (obj, type) => {
    let party = await feathers.service('party').find({
      query: {
        search: obj.name,
        type: type,
      },
    });
    let partner = party.filter((p) => p.name === obj.name);
    let email = null;
    if (partner.length > 0) {
      email = partner[0].email || '';
    }
    if (type === 'shipper') {
      setShp({
        ...shp,
        name: obj.name,
        phoneNumber: obj.phoneNumber,
        address: obj.address,
        postalCode: obj.postalCode,
        city: obj.city,
        state: obj.state,
        countryCode: obj.countryCode,
        email: email,
      });
    } else {
      setCne({
        ...cne,
        name: obj.name,
        phoneNumber: obj.phoneNumber,
        address: obj.address,
        postalCode: obj.postalCode,
        city: obj.city,
        state: obj.state,
        countryCode: obj.countryCode,
        email: email,
      });
    }

    //setShippers(party);
  };
  const getManifest = async (id) => {
    setLoading(true);
    try {
      let docOrigin = {};
      let document;
      if (id === null) {
        docOrigin = await feathers.service('manifests').find({
          query: {
            'docOrigin.AWB.prefix': prefix,
            'docOrigin.AWB.serialNumber': sn,
            'docOrigin.AWB.consolidationId': 'M',
          },
        });
        document = docOrigin[0];
        setBody(docOrigin[0]);
        setLoading(false);
        setShowDetails(true);
      } else {
        docOrigin = await feathers.service('manifests').find({
          query: {
            _id: id,
          },
        });
        if (docOrigin.length) {
          docOrigin = await feathers.service('manifests').get(id);

          await setBody(docOrigin);
          setLoading(false);
          setShowDetails(true);
        } else {
          docOrigin = await feathers.service('docs-pending').get(id);

          await setBody(docOrigin);
          setLoading(false);
          setEdit(true);
        }
        document = docOrigin;
      }

      setDate(
        moment(document.meta.date).format('MM/DD/YYYY HH:mm:ss').toUpperCase()
      );
      setAWB({
        ...AWB,
        prefix: document.docOrigin.AWB.prefix,
        serial: document.docOrigin.AWB.serialNumber,
        hawbNumber: document.docOrigin.AWB.hawbNumber,
        flightNumber:
          document.docOrigin.ARR && document.docOrigin.ARR.flightNumber
            ? document.docOrigin.ARR.flightNumber
            : '',
      });
      setAWBF({
        ...AWBF,
        prefix: document.docOrigin.AWB.prefix,
        serial: document.docOrigin.AWB.serialNumber,
        hawbNumber: document.docOrigin.AWB.hawbNumber,
        flightNumber:
          document.docOrigin.ARR && document.docOrigin.ARR.flightNumber
            ? document.docOrigin.ARR.flightNumber
            : '',
      });
      if (
        document.docOrigin.AWB.consolidationId &&
        document.docOrigin.AWB.consolidationId === 'M'
      ) {
        setAws('Master');
      }
      if (
        document.docOrigin.AWB.hawbNumber &&
        document.docOrigin.AWB.hawbNumber !== ''
      ) {
        setAws('House');
      }
      if (document.docOrigin.WBL) {
        search(document.docOrigin.WBL.airportOrigin);
        if (document.docOrigin.WBL.destinationAirport) {
          if (document.docOrigin.WBL.destinationAirport.length > 0) {
            setPermit(true);
          }
        }

        setWbl(document.docOrigin.WBL);
      }
      if (document.docOrigin.WBL) {
        if (document.docOrigin.WBL.destinationAirport) {
          searchPermit(document.docOrigin.WBL.destinationAirport);
        }

        setWbl(document.docOrigin.WBL);
      }
      if (document.docOrigin.CCL) {
        setCcl(document.docOrigin.CCL);
        searchTerminal(document.docOrigin.CCL.terminalOp);
        searchCCL(document.docOrigin.CCL.arrivalAirport);
      }
      if (document.docOrigin.marksAndNumbers) {
        setMarksAndNumbers(document.docOrigin.marksAndNumbers);
      }
      if (document.docOrigin.AWB) {
        setAwb(document.docOrigin.AWB);
      }

      if (document.docOrigin.SHP) {
        sss(document.docOrigin.SHP.countryCode, 'SHP');
        findParty(document.docOrigin.SHP, 'shipper');
        //setShp(document.docOrigin.SHP);
      }
      if (document.docOrigin.CNE) {
        sss(document.docOrigin.CNE.countryCode, 'CNE');
        findParty(document.docOrigin.CNE, 'consignee');
        //setCne(document.docOrigin.CNE);
      }
      if (document.docOrigin.CSD) {
        setCsd(document.docOrigin.CSD);

        sss(document.docOrigin.CSD.originGoods, 'CSD');
        ccc(document.docOrigin.CSD.currencyCode, 'CSD');
      }
      if (document.docOrigin.FDA !== undefined) {
        setFda(true);
      }

      if (document.docOrigin.TRN) {
        setActive(true);
        setTransfer(document.docOrigin.TRN);
        setBondedPremisesType(
          document.docOrigin.TRN.premisesId.length === 4 ? 'FIRMS' : 'Airline'
        );
        searchTransfer(document.docOrigin.TRN.destinationAirport);
        if (document.docOrigin.TRN.premisesId) {
          // if (document.docOrigin.TRN.premisesId.length == 4) {
          searchTransferFIRMS(document.docOrigin.TRN.premisesId);
          // } else {
          searchTransferAir(document.docOrigin.TRN.premisesId);
          // }
        }

        if (document.docOrigin.TRN.onwardCarrier) {
          searchTransferAirOC(document.docOrigin.TRN.onwardCarrier);
        }

        if (
          document.docOrigin.TRN.onwardCarrier ||
          document.docOrigin.TRN.bondedCarrierId
        ) {
          setTransferType('outAirport');
        }
      }

      if (document.docOrigin.ARR) {
        searchAIR(document.docOrigin.ARR.impCarrier);
        if (document.docOrigin.ARR.arrivalRef) {
          setSplit(true);
        }
        setArr(document.docOrigin.ARR);
      }
      if (document.docOrigin.AGT) {
        setAgt(document.docOrigin.AGT);
      }
    } catch (error) {
      // No borrar Consola
      console.log('error :', error);
      setLoading(false);
    }
  };
  const validateInputs = async () => {
    if (
      prefixRef.current &&
      prefixRef.current.value &&
      prefixRef.current.value.length >= 3 &&
      prefixRef.current.value.match(/^[a-zA-Z0-9]+$/)
    ) {
      prefixRef.current.setCustomValidity('');
    } else {
      prefixRef.current.setCustomValidity(
        'Must be 3 alphanumeric characters long'
      );
    }

    if (
      sNRef.current &&
      sNRef.current.value &&
      sNRef.current.value.length >= 8 &&
      sNRef.current.value > 0 &&
      sNRef.current.value.match(/^[0-9]+$/)
    ) {
      sNRef.current.setCustomValidity('');
    } else {
      sNRef.current.setCustomValidity('Must be 8 positive integer numbers');
    }

    if (house) {
      if (
        hawbRef.current &&
        hawbRef.current.value &&
        hawbRef.current.value.length >= 1 &&
        hawbRef.current.value.match(/^[a-zA-Z0-9]+$/)
      ) {
        hawbRef.current.setCustomValidity('');
      } else {
        hawbRef.current.setCustomValidity(
          'Must be 1 - 12 alphanumeric characters long'
        );
      }
    }

    if (impC.current && impC.current.value && impC.current.value.length <= 3) {
      impC.current.setCustomValidity('');
    } else {
      impC.current.setCustomValidity('Invalid data');
    }

    if (aws != 'House') {
      if (
        flightNumberRef.current &&
        flightNumberRef.current.value &&
        flightNumberRef.current.value.length > 2 &&
        flightNumberRef.current.value > 0 &&
        flightNumberRef.current.value.match(/^[0-9]+$/)
      ) {
        flightNumberRef.current.setCustomValidity('');
      } else {
        flightNumberRef.current.setCustomValidity('Invalid data');
      }
    }

    if (tOp.current && tOp.current.value && tOp.current.value.length <= 3) {
      tOp.current.setCustomValidity('');
    } else {
      tOp.current.setCustomValidity('Invalid data');
    }

    if (airO.current && airO.current.value && airO.current.value.length <= 3) {
      airO.current.setCustomValidity('');
    } else {
      airO.current.setCustomValidity('Invalid data');
    }

    if (
      arrAirport.current &&
      arrAirport.current.value &&
      arrAirport.current.value.length <= 3
    ) {
      arrAirport.current.setCustomValidity('');
    } else {
      arrAirport.current.setCustomValidity('Invalid data');
    }

    if (
      (shpEmail.current &&
        shpEmail.current.value &&
        shpEmail.current.value.match(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        )) ||
      shpEmail.current.value.length === 0
    ) {
      shpEmail.current.setCustomValidity('');
    } else {
      shpEmail.current.setCustomValidity('Invalid email');
    }
    if (
      (shpPhoneNumber.current &&
        shpPhoneNumber.current.value &&
        shpPhoneNumber.current.value.match(/^[a-zA-Z0-9]+$/)) ||
      shpPhoneNumber.current.value.length === 0
    ) {
      shpPhoneNumber.current.setCustomValidity('');
    } else {
      shpPhoneNumber.current.setCustomValidity(
        'Must be 1 - 14 alphanumeric characters long'
      );
    }
    if (
      (shpPostalCode.current &&
        shpPostalCode.current.value &&
        shpPostalCode.current.value.match(/^[a-zA-Z0-9]+$/)) ||
      shpPostalCode.current.value.length === 0
    ) {
      shpPostalCode.current.setCustomValidity('');
    } else {
      shpPostalCode.current.setCustomValidity(
        'Must be 1 - 9 alphanumeric characters long'
      );
    }

    if (
      shpCountryRef.current &&
      shpCountryRef.current.value &&
      shpCountryRef.current.value.length <= 2
    ) {
      shpCountryRef.current.setCustomValidity('');
    } else {
      shpCountryRef.current.setCustomValidity('Invalid Country Code');
    }

    if (
      (cneEmail.current &&
        cneEmail.current.value &&
        cneEmail.current.value.match(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
        )) ||
      cneEmail.current.value.length === 0
    ) {
      cneEmail.current.setCustomValidity('');
    } else {
      cneEmail.current.setCustomValidity('Invalid email');
    }

    if (
      (cnePhoneNumber.current &&
        cnePhoneNumber.current.value &&
        cnePhoneNumber.current.value.match(/^[a-zA-Z0-9]+$/)) ||
      cnePhoneNumber.current.value.length === 0
    ) {
      cnePhoneNumber.current.setCustomValidity('');
    } else {
      cnePhoneNumber.current.setCustomValidity(
        'Must be 1 - 14 alphanumeric characters long'
      );
    }

    if (
      (cnePostalCode.current &&
        cnePostalCode.current.value &&
        cnePostalCode.current.value.match(/^[a-zA-Z0-9]+$/)) ||
      cnePostalCode.current.value.length === 0
    ) {
      cnePostalCode.current.setCustomValidity('');
    } else {
      cnePostalCode.current.setCustomValidity(
        'Must be 1 - 9 alphanumeric characters long'
      );
    }

    if (
      cneCountryRef.current &&
      cneCountryRef.current.value &&
      cneCountryRef.current.value.length <= 2
    ) {
      cneCountryRef.current.setCustomValidity('');
    } else {
      cneCountryRef.current.setCustomValidity('Invalid Country Code');
    }
    if (
      weightCodeCargoRef.current &&
      weightCodeCargoRef.current.value &&
      weightCodeCargoRef.current !== null
    ) {
      if (weightCodeCargoRef.current.value.length >= 1) {
        weightCodeCargoRef.current.setCustomValidity('');
      } else {
        weightCodeCargoRef.current.setCustomValidity('Choose one');
      }
    }
    if (
      cargoCountryRef &&
      cargoCountryRef.current &&
      cargoCountryRef.current.value &&
      cargoCountryRef.current.value !== null
    ) {
      if (cargoCountryRef.current.value.length <= 2) {
        cargoCountryRef.current.setCustomValidity('');
      } else {
        cargoCountryRef.current.setCustomValidity('Invalid Country Code');
      }
    }

    if (
      cargoCurrencyRef &&
      cargoCurrencyRef.current &&
      cargoCurrencyRef.current.value &&
      cargoCurrencyRef.current.value !== null
    ) {
      if (cargoCurrencyRef.current.value.length <= 3) {
        cargoCurrencyRef.current.setCustomValidity('');
      } else {
        cargoCurrencyRef.current.setCustomValidity('Invalid Currency Code');
      }
    }

    if (
      destinationAirportRef &&
      destinationAirportRef.current &&
      destinationAirportRef.current !== null
    ) {
      if (destinationAirportRef.current.value.length >= 3) {
        destinationAirportRef.current.setCustomValidity('');
      } else {
        destinationAirportRef.current.setCustomValidity('Invalid data');
      }
    }
    if (
      arrivalRefRef &&
      arrivalRefRef.current &&
      arrivalRefRef.current !== null
    ) {
      if (arrivalRefRef.current.value.length >= 1) {
        arrivalRefRef.current.setCustomValidity('');
      } else {
        arrivalRefRef.current.setCustomValidity('Choose one');
      }
    }
    if (
      weightCodeRef &&
      weightCodeRef.current &&
      weightCodeRef.current !== null
    ) {
      if (weightCodeRef.current.value.length >= 1) {
        weightCodeRef.current.setCustomValidity('');
      } else {
        weightCodeRef.current.setCustomValidity('Choose one');
      }
    }
    if (
      transferstatusIdRef &&
      transferstatusIdRef.current &&
      transferstatusIdRef.current !== null
    ) {
      if (transferstatusIdRef.current.value.length >= 1) {
        transferstatusIdRef.current.setCustomValidity('');
      } else {
        transferstatusIdRef.current.setCustomValidity('Choose one');
      }
    }
    if (
      destinationAirportRefT &&
      destinationAirportRefT.current &&
      destinationAirportRefT.current !== null
    ) {
      if (destinationAirportRefT.current.value.length <= 3) {
        destinationAirportRefT.current.setCustomValidity('');
      } else {
        destinationAirportRefT.current.setCustomValidity('Invalid data');
      }
    }
    if (
      bondedCarrierIdRef &&
      bondedCarrierIdRef.current &&
      bondedCarrierIdRef.current !== null
    ) {
      if (
        (bondedCarrierIdRef.current.value.length <= 12 &&
          bondedCarrierIdRef.current.value.length >= 11) ||
        bondedCarrierIdRef.current.value.length === 0
      ) {
        bondedCarrierIdRef.current.setCustomValidity('');
      } else {
        bondedCarrierIdRef.current.setCustomValidity(
          'Invalid bonded CarrierID'
        );
      }
    }
    if (
      premisesIdRef &&
      premisesIdRef.current &&
      premisesIdRef.current !== null
    ) {
      if (
        (premisesIdRef.current.value.length <= 4 &&
          premisesIdRef.current.value.length >= 2) ||
        premisesIdRef.current.value.length === 0
      ) {
        premisesIdRef.current.setCustomValidity('');
      } else {
        premisesIdRef.current.setCustomValidity('Invalid data');
      }
    }
    if (
      onwardCarrierRef &&
      onwardCarrierRef.current &&
      onwardCarrierRef.current !== null
    ) {
      if (
        (onwardCarrierRef.current.value.length <= 3 &&
          onwardCarrierRef.current.value.length >= 2) ||
        onwardCarrierRef.current.value.length === 0
      ) {
        onwardCarrierRef.current.setCustomValidity('');
      } else {
        onwardCarrierRef.current.setCustomValidity('Invalid data');
      }
    }
    if (
      inbondControlNumRef &&
      inbondControlNumRef.current &&
      inbondControlNumRef.current !== null
    ) {
      if (
        inbondControlNumRef.current.value.length === 9 ||
        inbondControlNumRef.current.value.length === 0
      ) {
        inbondControlNumRef.current.setCustomValidity('');
      } else {
        inbondControlNumRef.current.setCustomValidity('Invalid data');
      }
    }
    if (
      pkTrackingIdRef &&
      pkTrackingIdRef.current &&
      pkTrackingIdRef.current !== null
    ) {
      if (pkTrackingIdRef.current.value.length > 0) {
        if (
          pkTrackingIdRef.current.value.match(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
          ) ||
          pkTrackingIdRef.current.value.length >= 5
        ) {
          pkTrackingIdRef.current.setCustomValidity('');
        } else {
          pkTrackingIdRef.current.setCustomValidity('Invalid Tracking #');
        }
      }
    }
  };
  const editDocument = async (option) => {
    let update = {};
    body.docOrigin._id = _id;
    update._id = _id;

    let firstDate = moment(startDate).format('DDMMM').toUpperCase();
    if (edit) {
      body.docOrigin.docPending = true;
      update.docPending = true;
      delete body.header;
      delete body.meta;
      delete body._id;
    } else {
      body.docOrigin.edit = true;
      update.edit = true;
    }
    if (aws !== 'House') {
      delete body.docOrigin.AWB.hawbNumber;
    }
    if (aws === 'Master') {
      body.docOrigin.AWB.consolidationId = 'M';
    }
    body.docOrigin.TRN = transfer;
    body.docOrigin.WBL.destinationAirport = wbl.destinationAirport;
    body.docOrigin.WBL.arrivalDatePTPAirport = wbl.arrivalDatePTPAirport;

    if (body.docOrigin.ARR || arr.impCarrier) {
      //arr.componentId = 'ARR';
      body.docOrigin.ARR = arr;
      if (!split) {
        if (body.docOrigin.ARR.hasOwnProperty('boardedQuantityId')) {
          delete body.docOrigin.ARR.boardedQuantityId;
        }
        delete body.docOrigin.ARR.arrivalRef;
        delete body.docOrigin.ARR.brdPieceCnt;
        delete body.docOrigin.ARR.weight;
        delete body.docOrigin.ARR.weightCode;
      }
      // body.docOrigin.ARR.impCarrier = arr.impCarrier;
      // body.docOrigin.ARR.flightNumber = arr.flightNumber;
      // body.docOrigin.ARR.arrivalDate = arr.arrivalDate;
      // body.docOrigin.ARR.arrivalRef = arr.arrivalRef;
      // body.docOrigin.ARR.brdPieceCnt = arr.brdPieceCnt;
      // body.docOrigin.ARR.weight = arr.weight;
      // body.docOrigin.ARR.weightCode = arr.weightCode;
    }

    body.docOrigin.WBL = wbl;

    if (body.docOrigin.CCL || arr.impCarrier) {
      body.docOrigin.CCL = ccl;
    }
    if (!arr.impCarrier) {
      delete body.docOrigin.ARR;
      delete body.docOrigin.CCL;
    }

    body.docOrigin.AWB = awb;
    body.docOrigin.SHP = shp;
    body.docOrigin.CNE = cne;
    body.docOrigin.CSD = csd;
    body.docOrigin.AGT = agt;
    body.docOrigin.marksAndNumbers = marksAndNumbers;
    // console.log('body.docOrigin ==>:', body.docOrigin);
    if (agt.participantCode === '') {
      delete body.docOrigin.AGT;
    }
    if (csd.originGoods === '') {
      delete body.docOrigin.CSD;
    } else {
      if (Object.values(csd).some((v) => v !== '')) {
        if (!body.docOrigin.CSD.componentId) {
          body.docOrigin.CSD = { componentId: 'CSD', ...csd };
        }
      }
    }
    if (!active) {
      delete body.docOrigin.TRN;
    }
    if (!permit) {
      delete body.docOrigin.WBL.destinationAirport;
      delete body.docOrigin.WBL.arrivalDatePTPAirport;
    }

    validateInputs();
    if (form.reportValidity()) {
      //Save Shipper
      let bodyShp;
      if (Object.values(body.docOrigin.SHP).some((v) => v !== '')) {
        bodyShp = {
          name: body.docOrigin.SHP.name,
          address: body.docOrigin.SHP.address,
          city: body.docOrigin.SHP.city,
          state: body.docOrigin.SHP.state,
          countrycode: body.docOrigin.SHP.countryCode,
          type: 'shipper',
          phone: body.docOrigin.SHP.phoneNumber,
          zip: body.docOrigin.SHP.postalCode,
          email: body.docOrigin.SHP.email,
        };
        try {
          let shipperUpdated = await feathers
            .service('party')
            .update({}, bodyShp);
        } catch (e) {}
      }
      //Save Consignee
      let bodyCne;
      if (Object.values(body.docOrigin.CNE).some((v) => v !== '')) {
        bodyCne = {
          name: body.docOrigin.CNE.name,
          address: body.docOrigin.CNE.address,
          city: body.docOrigin.CNE.city,
          state: body.docOrigin.CNE.state,
          countrycode: body.docOrigin.CNE.countryCode,
          type: 'consignee',
          phone: body.docOrigin.CNE.phoneNumber,
          zip: body.docOrigin.CNE.postalCode,
          email: body.docOrigin.CNE.email,
        };

        try {
          let cneUpdated = await feathers.service('party').update({}, bodyCne);
        } catch (e) {}
      }
      delete body.docOrigin.SHP.email;
      delete body.docOrigin.CNE.email;
      if (
        body.docOrigin.ARR &&
        body.docOrigin.ARR.hasOwnProperty('brdPieceCnt') &&
        body.docOrigin.ARR.brdPieceCnt != ''
      ) {
        body.docOrigin.ARR.boardedQuantityId = 'B';
      }

      if (body.docOrigin.hasOwnProperty('SMI')) {
        update.SMI = body.docOrigin.SMI;
      }
      if (
        body.docOrigin.hasOwnProperty('CCL') &&
        body.docOrigin.CCL.hasOwnProperty('arrivalAirport') &&
        body.docOrigin.CCL.arrivalAirport != ''
      ) {
        update.CCL = body.docOrigin.CCL;
      }
      if (body.docOrigin.hasOwnProperty('AWB')) {
        update.AWB = body.docOrigin.AWB;
      }
      if (body.docOrigin.hasOwnProperty('WBL')) {
        update.WBL = body.docOrigin.WBL;
      }
      if (
        body.docOrigin.hasOwnProperty('ARR') &&
        body.docOrigin.ARR.hasOwnProperty('impCarrier') &&
        body.docOrigin.ARR.impCarrier != ''
      ) {
        update.ARR = body.docOrigin.ARR;
      }
      if (body.docOrigin.hasOwnProperty('AGT')) {
        update.AGT = body.docOrigin.AGT;
      }
      if (body.docOrigin.hasOwnProperty('SHP')) {
        update.SHP = body.docOrigin.SHP;
      }
      if (body.docOrigin.hasOwnProperty('CNE')) {
        update.CNE = body.docOrigin.CNE;
      }
      if (body.docOrigin.hasOwnProperty('TRN')) {
        update.TRN = body.docOrigin.TRN;
        update.TRN.componentId = 'TRN';
      }
      if (body.docOrigin.hasOwnProperty('CSD')) {
        update.CSD = body.docOrigin.CSD;
      }
      if (body.docOrigin.hasOwnProperty('FDA')) {
        update.FDA = body.docOrigin.FDA;
      }
      update.marksAndNumbers = body.docOrigin.marksAndNumbers;
      let [res, err] = await fetchDoc({ body: update });

      // setShp(bodyShp);
      // setCne(bodyCne);

      if (res.errors !== undefined) {
        toast.error(res.message);
        for (let i = 0; i < res.errors.length; i++) {
          toast.error(res.errors[i].message);
        }
        setShp(body.docOrigin.SHP);
        setCne(body.docOrigin.CNE);
      } else if (err.length) {
        toast.error(err[0].doc.meta.errors[0].message.replace('\\', ''));
      } else {
        let saved = res.message;
        let prefix = saved.docOrigin.AWB.prefix;
        let serial = saved.docOrigin.AWB.serialNumber;
        let hawbNumber = saved.docOrigin.AWB.hawbNumber
          ? saved.docOrigin.AWB.hawbNumber
          : null;
        let newFlightNumber =
          saved.docOrigin.ARR && saved.docOrigin.ARR.flightNumber
            ? saved.docOrigin.ARR.flightNumber
            : null;
        let id;
        let where = null;
        let patch = null;
        let manifestStatusId = rowEdit._id;

        if (status === 'DRAFT') {
          patch = {
            prefix: prefix,
            serialNumber: serial,
            flightNumber: newFlightNumber,
            manifestEdited: Date.now(),
            type: aws,
          };
        } else {
          patch = {
            flightNumber: newFlightNumber,
            // status: 'DRAFT',
            // docType: 'FRC',
            manifestEdited: Date.now(),
            type: aws,
          };
        }
        if (
          (status === 'DRAFT' && hawbNumber !== null) ||
          (status !== 'DRAFT' && hawbNumber !== null)
        ) {
          patch = {
            prefix: prefix,
            serialNumber: serial,
            flightNumber: newFlightNumber,
            hawbNumber: hawbNumber,
            manifestEdited: Date.now(),
            type: aws,
          };

          let houseUpdate = await feathers
            .service('houses-cache')
            .patch(manifestStatusId, patch);

          if (AWB.flightNumber) {
            where = {
              prefix: AWB.prefix,
              serialNumber: AWB.serial,
              flightNumber: AWB.flightNumber,
            };
          } else {
            where = {
              prefix: AWB.prefix,
              serialNumber: AWB.serial,
            };
          }

          id = await feathers.service('awb-status').find({
            query: {
              where,
            },
          });

          if (id.length > 0) {
            for (const h of id) {
              if (h.hawb.length > 0) {
                h.hawb.filter((f) => {
                  if (f.hawbNumber === AWB.hawbNumber) {
                    delete f.hawbNumber;
                    f.hawbNumber = hawbNumber;
                    id = [h];
                  }
                });
              }
            }
          }
          manifestStatusId = id[0]._id;

          patch = {
            prefix: prefix,
            serialNumber: serial,
            flightNumber: newFlightNumber,
            hawb: id[0].hawb,
            manifestEdited: Date.now(),
          };
        }
        let updateStatus = await feathers
          .service('awb-status')
          .patch(manifestStatusId, patch);

        // await cache(prefix, serial, false);
        await cache(AWBF.prefix, AWBF.serial, false, Date.now());

        if (status === 'DRAFT') {
          rowEdit.flightNumber = updateStatus.flightNumber;
          rowEdit.serialNumber = updateStatus.serialNumber;
          rowEdit.prefix = updateStatus.prefix;
          rowEdit.hawb = updateStatus.hawb;
          rowEdit.type = aws;
        } else {
          rowEdit.flightNumber = updateStatus.flightNumber;
          //rowEdit.status = 'DRAFT';
          rowEdit.docType = 'FRC';
        }
        //rowEdit.requestDate = Date.now();
        setBody(res.message);
        if (edit) {
          toast.info('AWB Succesfully Saved.');

          if (path !== '/awb-history/:awb') {
            getManifest(_id);
          } else {
            if (option == 2) {
              props.onClose(false);
            }
            if (!hawbNumber) {
              props.onRowEdited(rowEdit);
            } else {
              props.getManifests(0);
              props.onGetManifest();
            }
          }
          if (option == 3) {
            props.history.push('create-manifest');
          }
          if (option == 4) {
            props.history.push(
              `create-manifest?id=${body.docOrigin._id}&clone=true&status=${status}`
            );
          }
        } else {
          toast.info('AWB Succesfully Saved.');
          if (option == 2) {
            props.onClose(false);
          }
          if (!hawbNumber) {
            props.onRowEdited(rowEdit);
          } else {
            props.getManifests(0);
          }
          if (option == 3) {
            props.history.push('create-manifest');
          }
          if (option == 4) {
            props.history.push(
              `create-manifest?id=${body.docOrigin._id}&clone=true&status=${status}`
            );
          }
        }
      }
    }
  };
  const save = async () => {
    await editDocument(1);
    setChange(true);
  };
  const saveGoBack = async () => {
    await editDocument(2);
  };
  const saveCreateNew = async () => {
    await editDocument(3);
  };
  const saveClone = async () => {
    await editDocument(4);
    // funcion para clonar
  };
  const clone = async () => {
    props.history.push(`create-manifest?id=${_id}&clone=true&status=${status}`);
  };

  return (
    <Fragment>
      <form
        ref={(ref) => {
          form = ref;
        }}
      >
        {loading ? (
          <Fragment>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: LoadingAnimation,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={400}
              width={400}
              isStopped={false}
              isPaused={false}
            />
            <ActivityIndicator message="Loading data, please wait..." />
          </Fragment>
        ) : (
          <Fragment>
            {/* Manifest details */}
            <Grid size={12}>
              <Cell size={12}>
                <Card raise tableCard>
                  <CardTitle style={{ backgroundColor: '#599dde' }}>
                    <h2
                      style={{ color: 'white' }}
                      class="md-card-title--title md-card-title--large md-text"
                    >
                      Manifest Details
                    </h2>
                  </CardTitle>
                  <CardText>
                    <ShowDetailsManifest
                      awb={AWB}
                      date={date}
                      aws={aws}
                      status={status}
                      email={email}
                      userCreated={userCreated}
                      userSubmission={userSubmission}
                      manifestCreated={manifestCreated}
                      lastRequest={lastRequest}
                      lastResponse={lastResponse}
                    />
                  </CardText>
                </Card>
              </Cell>
            </Grid>

            {/* Flight details */}
            <Grid size={12} className="mt-5">
              <Cell size={12}>
                <Card raise tableCard>
                  <CardTitle style={{ backgroundColor: '#599dde' }}>
                    <h2
                      style={{ color: 'white' }}
                      class="md-card-title--title md-card-title--large md-text"
                    >
                      Flight Details
                    </h2>
                  </CardTitle>
                  <CardText>
                    <FlightDetails
                      role={profile.role}
                      create={false}
                      expr={false}
                      arr={arr}
                      setArr={setArr}
                      awb={awb}
                      setAwb={setAwb}
                      agt={agt}
                      setAgt={setAgt}
                      wbl={wbl}
                      setWbl={setWbl}
                      aws={aws}
                      setAws={setAws}
                      ccl={ccl}
                      setCcl={setCcl}
                      house={house}
                      setHouse={setHouse}
                      simple={simple}
                      setSimple={setSimple}
                      setAllCodes={setAllCodes}
                      allCodes={allCodes}
                      setUsCodes={setUsCodes}
                      usCodes={usCodes}
                      setAirlines={setAirlines}
                      airlines={airlines}
                      terminal={terminal}
                      setTerminal={setTerminal}
                      change={change}
                      setChange={setChange}
                      setAutocompletes={setAutocompletes}
                      autocompletes={autocompletes}
                      setAutocompletesCurr={setAutocompletesCurr}
                      autocompletesCurr={autocompletesCurr}
                      setAutocompletesA={setAutocompletesA}
                      autocompletesA={autocompletesA}
                      setAutocompletesT={setAutocompletesT}
                      autocompletesT={autocompletesT}
                      setAutocompletesAir={setAutocompletesAir}
                      autocompletesAir={autocompletesAir}
                      setAutocompletesAirArr={setAutocompletesAirArr}
                      autocompletesAirArr={autocompletesAirArr}
                      setAutocompletesAirPermit={setAutocompletesAirPermit}
                      autocompletesAirPermit={autocompletesAirPermit}
                      status={status}
                      docType={
                        rowEdit && rowEdit.docType ? rowEdit.docType : null
                      }
                      impC={impC}
                      tOp={tOp}
                      airO={airO}
                      arrAirport={arrAirport}
                      prefixRef={prefixRef}
                      sNRef={sNRef}
                      hawbRef={hawbRef}
                      flightNumberRef={flightNumberRef}
                    />
                  </CardText>
                </Card>
              </Cell>
            </Grid>

            {/* Shipper and consignee */}
            <Grid size={12}>
              {/* Shipper */}
              <Cell size={6}>
                <Card raise tableCard>
                  <CardTitle style={{ backgroundColor: '#599dde' }}>
                    <h2
                      style={{ color: 'white' }}
                      class="md-card-title--title md-card-title--large md-text"
                    >
                      Shipper
                    </h2>
                  </CardTitle>
                  <CardText>
                    <Shipper
                      role={profile.role}
                      shp={shp}
                      setShp={setShp}
                      setShippers={setShippers}
                      shippersList={shippersList}
                      change={change}
                      setChange={setChange}
                      status={status}
                      setAutocompletesShp={setAutocompletesShp}
                      autocompletesShp={autocompletesShp}
                      setCountriesShp={setCountriesShp}
                      countriesShp={countriesShp}
                      shpEmail={shpEmail}
                      shpPhoneNumber={shpPhoneNumber}
                      shpPostalCode={shpPostalCode}
                      shpCountryRef={shpCountryRef}
                    />
                  </CardText>
                </Card>
              </Cell>

              {/* Consignee */}
              <Cell size={6}>
                <Card raise tableCard>
                  <CardTitle style={{ backgroundColor: '#599dde' }}>
                    <h2
                      style={{ color: 'white' }}
                      class="md-card-title--title md-card-title--large md-text"
                    >
                      Consignee
                    </h2>
                  </CardTitle>
                  <CardText>
                    <Consignee
                      role={profile.role}
                      cne={cne}
                      setCne={setCne}
                      setConsignees={setConsignees}
                      consigneeList={consigneeList}
                      change={change}
                      setChange={setChange}
                      status={status}
                      setAutocompletesCne={setAutocompletesCne}
                      autocompletesCne={autocompletesCne}
                      setCountriesCne={setCountriesCne}
                      countriesCne={countriesCne}
                      cneEmail={cneEmail}
                      cnePhoneNumber={cnePhoneNumber}
                      cnePostalCode={cnePostalCode}
                      cneCountryRef={cneCountryRef}
                    />
                  </CardText>
                </Card>
              </Cell>
            </Grid>

            {/* Cargo details */}
            <Grid size={12}>
              <Cell size={12}>
                <Card raise tableCard>
                  <CardTitle style={{ backgroundColor: '#599dde' }}>
                    <h2
                      style={{ color: 'white' }}
                      class="md-card-title--title md-card-title--large md-text"
                    >
                      Cargo Details
                    </h2>
                  </CardTitle>
                  <CardText>
                    <CargoDetails
                      role={profile.role}
                      awb={awb}
                      setAwb={setAwb}
                      marksAndNumbers={marksAndNumbers}
                      setMarksAndNumbers={setMarksAndNumbers}
                      csd={csd}
                      setCsd={setCsd}
                      setHts={setHts}
                      hts={hts}
                      wbl={wbl}
                      setWbl={setWbl}
                      fda={fda}
                      setFda={setFda}
                      setCountries={setCountries}
                      countries={countries}
                      setCurrency={setCurrency}
                      currency={currency}
                      change={change}
                      setChange={setChange}
                      autocompletes={autocompletes}
                      setAutocompletes={setAutocompletes}
                      setAutocompletesCurr={setAutocompletesCurr}
                      autocompletesCurr={autocompletesCurr}
                      status={status}
                      cargoCountryRef={cargoCountryRef}
                      cargoCurrencyRef={cargoCurrencyRef}
                      pkTrackingIdRef={pkTrackingIdRef}
                      aws={aws}
                      setAws={setAws}
                      weightCodeCargoRef={weightCodeCargoRef}
                    />
                  </CardText>
                </Card>
              </Cell>
            </Grid>

            {/* Switches */}
            <Grid size={12}>
              <Cell
                size={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                }}
              >
                <Switch
                  id="switch-permit"
                  type="switch"
                  label="Flight is moving under PTP"
                  name="permit"
                  checked={permit}
                  onChange={(e) => {
                    setChange(false);
                    setPermit(e);
                  }}
                  disabled={
                    status === 'DRAFT' ||
                    status === 'ACCEPTED' ||
                    status === 'REJECTED' ||
                    status === 'SENDING'
                      ? false
                      : true
                  }
                  style={{ marginRight: 16 }}
                />

                <Switch
                  id="split-shipment"
                  type="switch"
                  label="Split Shipment?"
                  name="transferDetails"
                  checked={split}
                  onChange={(e) => {
                    setChange(false);
                    setSplit(e);
                  }}
                  disabled={
                    status === 'DRAFT' ||
                    status === 'ACCEPTED' ||
                    status === 'REJECTED' ||
                    status === 'SENDING'
                      ? false
                      : true
                  }
                  style={{ marginRight: 16 }}
                />

                <Switch
                  id="switch-transfer-details"
                  type="switch"
                  label="In bond shipment or local transfer to a bonded facility?"
                  name="transferDetails"
                  checked={active}
                  onChange={(e) => {
                    setChange(false);
                    setActive(e);
                    if (e) {
                      setCsd({ ...csd, currencyCode: 'USD' });
                    } else setCsd({ ...csd, currencyCode: '' });
                  }}
                  disabled={
                    status === 'DRAFT' ||
                    status === 'ACCEPTED' ||
                    status === 'REJECTED' ||
                    status === 'SENDING'
                      ? false
                      : true
                  }
                />
              </Cell>
            </Grid>
          </Fragment>
        )}

        {/* Permit to proceed */}
        {permit ? (
          <Grid size={12}>
            <Cell size={12}>
              <Card>
                <CardTitle style={{ backgroundColor: '#599dde' }}>
                  <h2
                    style={{ color: 'white' }}
                    class="md-card-title--title md-card-title--large md-text"
                  >
                    Permit to Proceed
                  </h2>
                </CardTitle>
                <CardText>
                  <PermitProceed
                    role={profile.role}
                    wbl={wbl}
                    setWbl={setWbl}
                    // setAllCodes={setAllCodes}
                    // allCodes={allCodes}
                    // search={search}
                    //reference={ref}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    change={change}
                    setChange={setChange}
                    status={status}
                    setAutocompletesAirPermit={setAutocompletesAirPermit}
                    autocompletesAirPermit={autocompletesAirPermit}
                    destRef={destinationAirportRef}
                  />
                </CardText>
              </Card>
            </Cell>
          </Grid>
        ) : null}

        {/* Split indicator */}
        {split ? (
          <Grid size={12}>
            <Cell size={12}>
              <Card>
                <CardTitle style={{ backgroundColor: '#599dde' }}>
                  <h2
                    style={{ color: 'white' }}
                    class="md-card-title--title md-card-title--large md-text"
                  >
                    Split Shipment Indicator
                  </h2>
                </CardTitle>
                <CardText>
                  <SplitIndicator
                    role={profile.role}
                    arr={arr}
                    setArr={setArr}
                    change={change}
                    status={status}
                    setChange={setChange}
                    arrivalRefRef={arrivalRefRef}
                    weightCodeRef={weightCodeRef}
                  />
                </CardText>
              </Card>
            </Cell>
          </Grid>
        ) : null}

        {/* Transfer details */}
        {active ? (
          <Grid size={12}>
            <Cell size={12}>
              <Card>
                <CardTitle style={{ backgroundColor: '#599dde' }}>
                  <h2
                    style={{ color: 'white' }}
                    class="md-card-title--title md-card-title--large md-text"
                  >
                    Transfer Details (in-bond or local transfer)
                  </h2>
                </CardTitle>
                <CardText>
                  <TransferDetails
                    role={profile.role}
                    setTrn={setTransfer}
                    trn={transfer}
                    bondedPremisesType={bondedPremisesType}
                    setBondedPremisesType={setBondedPremisesType}
                    // setAllCodes={setAllCodes}
                    // allCodes={allCodes}
                    //search={search}
                    change={change}
                    setChange={setChange}
                    status={status}
                    autocompletesTransfer={autocompletesTransfer}
                    setAutocompletesTransfer={setAutocompletesTransfer}
                    autocompletesTransferAir={autocompletesTransferAir}
                    setAutocompletesTransferAir={setAutocompletesTransferAir}
                    autocompletesTransferFIRMS={autocompletesTransferFIRMS}
                    setAutocompletesTransferFIRMS={
                      setAutocompletesTransferFIRMS
                    }
                    transferstatusIdRef={transferstatusIdRef}
                    destinationAirportRef={destinationAirportRefT}
                    bondedCarrierIdRef={bondedCarrierIdRef}
                    premisesIdRef={premisesIdRef}
                    inbondControlNumRef={inbondControlNumRef}
                    onwardCarrierRef={onwardCarrierRef}
                    autocompleteTransferAirOC={autocompleteTransferAirOC}
                    setAutocompleteTransferAirOC={setAutocompleteTransferAirOC}
                    transferType={transferType}
                    setTransferType={setTransferType}
                  ></TransferDetails>
                </CardText>
              </Card>
            </Cell>
          </Grid>
        ) : null}

        {/* Toolbar */}
        <Grid size={12}>
          <Cell
            size={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            {edit && path !== '/awb-history/:awb' ? (
              <BTN
                variant="outlined"
                color="primary"
                style={{ marginRight: '20px' }}
                onClick={() => {
                  props.history.push({
                    pathname: `/send-manifest`,
                  });
                }}
              >
                Go Back
              </BTN>
            ) : null}
            {edit || showDetails ? (
              <Fragment>
                <BTN
                  variant="outlined"
                  color="primary"
                  disabled={
                    (status !== 'DRAFT' && profile.role === 'shipper') || change
                  }
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  onClick={() => {
                    saveGoBack();
                  }}
                >
                  Save and Go Back
                </BTN>
                <BTN
                  variant="outlined"
                  color="primary"
                  disabled={
                    (status !== 'DRAFT' && profile.role === 'shipper') || change
                  }
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  onClick={() => {
                    save();
                  }}
                >
                  Save
                </BTN>
                <BTN
                  variant="outlined"
                  color="primary"
                  disabled={
                    (status !== 'DRAFT' && profile.role === 'shipper') || change
                  }
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  onClick={() => {
                    saveCreateNew();
                  }}
                >
                  Save and Create New
                </BTN>
                <BTN
                  variant="contained"
                  color="primary"
                  disabled={
                    (status !== 'DRAFT' && profile.role === 'shipper') || change
                  }
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  onClick={() => {
                    saveClone();
                  }}
                >
                  Save and Clone
                </BTN>
                <BTN
                  variant="contained"
                  disabled={status !== 'DRAFT' && profile.role === 'shipper'}
                  color="primary"
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  onClick={() => {
                    clone();
                  }}
                >
                  Clone
                </BTN>
              </Fragment>
            ) : null}
            {(edit && (status === 'CANCELLED' || status === 'DRAFT')) ||
            (showDetails && (status === 'CANCELLED' || status === 'DRAFT')) ? (
              <BTN
                variant="outlined"
                color="secondary"
                style={{ marginRight: '20px' }}
                onClick={() => {
                  setDelete(true);
                }}
              >
                Delete
              </BTN>
            ) : null}

            {showDetails || path === '/awb-history/:awb' ? (
              <BTN
                variant="outlined"
                color="primary"
                style={{ marginRight: '20px' }}
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </BTN>
            ) : null}
          </Cell>
        </Grid>

        {/* Delete confirmation */}
        <DeleteManifest
          {...props}
          visible={showDelete}
          setDelete={setDelete}
          doc={{ _id: edit ? _id : null }}
          manifestStatus={{ _id: rowEdit ? rowEdit._id : null }}
          onClose={onClose}
          onGetManifest={onGetManifest}
        />
      </form>
    </Fragment>
  );
};
