import React, { useState, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Grid,
  Cell,
  TextField,
  Button,
  Card,
  CardActions,
  Toolbar,
  SelectField,
} from 'react-md';
import Axios from 'axios';
const env = process.env;
export default (props) => {
  const [partnershipData, setPartnershipData] = useState([]);
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      await checkToken();
    }
    tttt();
  }, []);
  const [, data, doFetch] = useApi('ccapi', 'partnership', null, 'post');
  const [partnership, setPartnership] = useState({
    sourceCode: '',
    sourceCodeACAS: '',
    sourceName: '',
    sourceAddress: '',
    destinationCode: '',
    destinationCodeACAS: '',
    destinationName: '',
    destinationAddress: '',
  });
  let sourceCodeRef = React.createRef();
  let sourceCodeACASRef = React.createRef();
  let sourceNameRef = React.createRef();
  let sourceAddressRef = React.createRef();
  let destinationCodeRef = React.createRef();
  let destinationCodeACASRef = React.createRef();
  let destinationNameRef = React.createRef();
  let destinationAddressRef = React.createRef();
  const [, partnerData, getPartnership] = useApi(
    'ccapi',
    'partnership',
    partnership
  );

  useEffect(() => {
    delete partnerData._id;
    let data = {
      sourceCode: partnerData.sourceCode,
      sourceCodeACAS: partnerData.sourceCodeACAS,
      sourceName: partnerData.sourceName,
      sourceAddress: partnerData.sourceAddress,
      destinationCode: partnerData.destinationCode,
      destinationCodeACAS: partnerData.destinationCodeACAS,
      destinationName: partnerData.destinationName,
      destinationAddress: partnerData.destinationAddress,
    };
    setPartnership(data);
  }, [partnerData]);

  useEffect(() => {
    if (props.match.params.id) {
      getPartnership({ params: [props.match.params.id] });
    }
  }, [data]);
  const save = async () => {
    if (form.reportValidity() && (await validate())) {
      let [result, error] = await doFetch({
        body: partnership,
        params: [props.match.params.id] || [],
      });
      if (!error) {
        props.history.push('/partnerships/list');
        toast.success(
          props.match.params.id
            ? 'Partnership successfully Updated.'
            : 'Partnership successfully Created.'
        );
      }
    }
  };
  const validate = async () => {
    return (
      partnership.hasOwnProperty('sourceCode') &&
      (partnership.sourceCode.length == 7 ||
        partnership.sourceCode.length == 0) &&
      partnership.hasOwnProperty('sourceCodeACAS') &&
      (partnership.sourceCodeACAS.length == 7 ||
        partnership.sourceCodeACAS.length == 0) &&
      partnership.sourceName &&
      partnership.sourceName.trim() != '' &&
      partnership.sourceAddress &&
      partnership.sourceAddress.trim() != '' &&
      partnership.destinationCode &&
      partnership.destinationCode.trim() != '' &&
      partnership.hasOwnProperty('destinationCodeACAS') &&
      (partnership.destinationCodeACAS.length == 7 ||
        partnership.destinationCodeACAS.length == 0) &&
      // partnership.destinationCodeACAS &&
      // partnership.destinationCodeACAS.trim() != '' &&
      partnership.destinationName &&
      partnership.destinationName.trim() != '' &&
      partnership.destinationAddress &&
      partnership.destinationAddress.trim() != ''
    );
  };
  let form = null;

  return (
    <Grid>
      <Cell offset={4} size={4}>
        <Card>
          <form
            ref={(ref) => {
              form = ref;
            }}
          >
            <Toolbar colored title="Partnership" />
            <Grid>
              <Cell size={6}>
                <TextField
                  ref={sourceNameRef}
                  id="sourceName"
                  autoComplete={'off'}
                  required
                  label="Source Name"
                  value={partnership.sourceName || ''}
                  error={
                    partnership.sourceName &&
                    partnership.sourceName.trim() == ''
                      ? true
                      : false
                  }
                  errorText="this field is required"
                  onChange={async (v) => {
                    await setPartnership({
                      ...partnership,
                      sourceName: v,
                    });
                  }}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  ref={sourceAddressRef}
                  id="sourceAddress"
                  autoComplete={'off'}
                  required
                  label="Source Address"
                  value={partnership.sourceAddress || ''}
                  error={
                    partnership.sourceAddress &&
                    partnership.sourceAddress.trim() == ''
                      ? true
                      : false
                  }
                  errorText="this field is required"
                  onChange={async (v) => {
                    await setPartnership({
                      ...partnership,
                      sourceAddress: v,
                    });
                  }}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  ref={sourceCodeRef}
                  id="sourceCodeAirAMS"
                  label="Originator Code (AirAMS)"
                  autoComplete={'off'}
                  value={partnership.sourceCode || ''}
                  // required
                  error={
                    partnership.sourceCode &&
                    partnership.sourceCode != '' &&
                    partnership.sourceCode.length < 7
                      ? true
                      : false
                  }
                  errorText="length must be 7 alphanumeric characters long"
                  onChange={async (v) => {
                    const re = /^[a-zA-Z0-9\b]*$/;
                    if (v === '' || re.test(v)) {
                      if (v.length < 8) {
                        await setPartnership({
                          ...partnership,
                          sourceCode: v.toUpperCase(),
                        });
                      }
                    }
                  }}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  ref={sourceCodeACASRef}
                  id="sourceCodeACAS"
                  label="Originator Code (ACAS)"
                  autoComplete={'off'}
                  value={partnership.sourceCodeACAS || ''}
                  // required
                  error={
                    partnership.sourceCodeACAS &&
                    partnership.sourceCodeACAS != '' &&
                    partnership.sourceCodeACAS.length < 7
                      ? true
                      : false
                  }
                  errorText="length must be 7 alphanumeric characters long"
                  onChange={async (v) => {
                    const re = /^[a-zA-Z0-9\b]*$/;
                    if (v === '' || re.test(v)) {
                      if (v.length < 8) {
                        await setPartnership({
                          ...partnership,
                          sourceCodeACAS: v.toUpperCase(),
                        });
                      }
                    }
                  }}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  ref={destinationNameRef}
                  id="destinationName"
                  autoComplete={'off'}
                  required
                  label="Destination Name"
                  value={partnership.destinationName || ''}
                  error={
                    partnership.destinationName &&
                    partnership.destinationName.trim() == ''
                      ? true
                      : false
                  }
                  errorText="this field is required"
                  onChange={async (v) => {
                    await setPartnership({
                      ...partnership,
                      destinationName: v,
                    });
                  }}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  ref={destinationAddressRef}
                  id="destinationAddress"
                  autoComplete={'off'}
                  required
                  label="Destination Address"
                  value={partnership.destinationAddress || ''}
                  error={
                    partnership.destinationAddress &&
                    partnership.destinationAddress.trim() == ''
                      ? true
                      : false
                  }
                  errorText="this field is required"
                  onChange={async (v) => {
                    await setPartnership({
                      ...partnership,
                      destinationAddress: v,
                    });
                  }}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  ref={destinationCodeRef}
                  id="destinationCodeAirAMS"
                  autoComplete={'off'}
                  // required
                  label="Destination Code (AirAMS)"
                  value={partnership.destinationCode || ''}
                  // error={
                  //   partnership.destinationCode &&
                  //   partnership.destinationCode.trim() == ''
                  //     ? true
                  //     : false
                  // }
                  // errorText="this field is required"
                  error={
                    partnership.destinationCode &&
                    partnership.destinationCode != '' &&
                    partnership.destinationCode.length < 7
                      ? true
                      : false
                  }
                  errorText="length must be 7 alphanumeric characters long"
                  // onChange={async (v) => {
                  //   await setPartnership({
                  //     ...partnership,
                  //     destinationCode: v.toUpperCase(),
                  //   });
                  // }}
                  onChange={async (v) => {
                    const re = /^[a-zA-Z0-9\b]*$/;
                    if (v === '' || re.test(v)) {
                      if (v.length < 8) {
                        await setPartnership({
                          ...partnership,
                          destinationCode: v.toUpperCase(),
                        });
                      }
                    }
                  }}
                />
              </Cell>
              <Cell size={6}>
                <TextField
                  ref={destinationCodeACASRef}
                  id="destinationCodeACAS"
                  autoComplete={'off'}
                  label="Destination Code (ACAS)"
                  value={partnership.destinationCodeACAS || ''}
                  error={
                    partnership.destinationCodeACAS &&
                    partnership.destinationCodeACAS != '' &&
                    partnership.destinationCodeACAS.length < 7
                      ? true
                      : false
                  }
                  errorText="length must be 7 alphanumeric characters long"
                  // onChange={async (v) => {
                  //   await setPartnership({
                  //     ...partnership,
                  //     destinationCodeACAS: v.toUpperCase(),
                  //   });
                  // }}
                  onChange={async (v) => {
                    const re = /^[a-zA-Z0-9\b]*$/;
                    if (v === '' || re.test(v)) {
                      if (v.length < 8) {
                        await setPartnership({
                          ...partnership,
                          destinationCodeACAS: v.toUpperCase(),
                        });
                      }
                    }
                  }}
                />
              </Cell>
            </Grid>
            <CardActions centered>
              <Button
                onClick={async (e) => {
                  await save();
                }}
                flat
              >
                Save
              </Button>
              <Button
                secondary
                flat
                onClick={() => props.history.push('/partnerships/list')}
              >
                Cancel
              </Button>
            </CardActions>
          </form>
        </Card>
      </Cell>
      <Cell size={4}></Cell>
    </Grid>
  );
};
