import React, { useState, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Grid,
  Cell,
  TextField,
  Button,
  Card,
  CardActions,
  Toolbar,
  Checkbox,
  SelectField
} from 'react-md';
import Axios from 'axios';
import feathers from '../../services/feathers';
import moment from 'moment';
const env = process.env;
export default props => {
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        if (props.match.params.id) {
          await getNotification(props.match.params.id);
        }
      }
    }
    tttt();
  }, []);
  const getNotification = async id => {
    let response = await feathers.service('system-notifications').get(id, {});
    delete response._id;
    setNotification(response);
  };
  const saveNotification = async () => {
    if (form.reportValidity() && (await validate())) {
      if (props.match.params.id) {
        let response = await feathers
          .service('system-notifications')
          .update(props.match.params.id, notification);
        if (response && response._id) {
          toast.success('Announcement successfully updated.');
          props.history.push('/notifications/list');
        } else {
          toast.warn('Please check the information');
        }
      } else {
        let response = await feathers
          .service('system-notifications')
          .create(notification);
        if (response && response._id) {
          toast.success('Announcement successfully created.');
          props.history.push('/notifications/list');
        } else {
          toast.warn('Please check the information');
        }
      }
    }
  };
  let [notification, setNotification] = useState({
    CSMSNumber: '',
    title: '',
    description: '',
    language: '',
    account: '',
    active: false,
    createdAt: null,
    updatedAt: null
  });
  let CSMSNumberRef = React.createRef();
  let titleRef = React.createRef();
  let descriptionRef = React.createRef();
  let languageRef = React.createRef();
  let accountRef = React.createRef();

  const save = async () => {
    // if (form.reportValidity() && (await validate())) {
    //   let [result, error] = await doFetch({
    //     body: notification,
    //     params: [props.match.params.id] || []
    //   });
    //   if (!error) {
    //     props.history.push('/notifications/list');
    //     toast.success(
    //       props.match.params.id
    //         ? 'notification successfully Updated.'
    //         : 'notification successfully Created.'
    //     );
    //   }
    // }
  };
  const validate = async () => {
    return (
      notification.CSMSNumber != '' &&
      notification.title &&
      notification.title.trim() != '' &&
      notification.description &&
      notification.description.trim() != '' &&
      notification.language &&
      notification.language.trim() != '' &&
      notification.account &&
      notification.account.trim() != ''
    );
  };
  let form = null;

  return (
    <Grid>
      <Cell offset={2} size={8}>
        <Card>
          <form
            ref={ref => {
              form = ref;
            }}
          >
            <Toolbar colored title="Portal Announcement" />
            <Grid>
              <Cell size={3}>
                <TextField
                  ref={CSMSNumberRef}
                  id="CSMSNumber"
                  label="CSMS #"
                  autoComplete={'off'}
                  value={notification.CSMSNumber || ''}
                  required
                  error={
                    notification.CSMSNumber && notification.CSMSNumber == ''
                      ? true
                      : false
                  }
                  errorText="this field is required"
                  onChange={async v => {
                    const re = /^[0-9\b]*$/;
                    if (v === '' || re.test(v)) {
                      await setNotification({
                        ...notification,
                        CSMSNumber: v.toUpperCase()
                      });
                    }
                  }}
                />
              </Cell>
              <Cell size={9}>
                <TextField
                  ref={titleRef}
                  id="title"
                  autoComplete={'off'}
                  required
                  label="Title"
                  value={notification.title || ''}
                  error={
                    notification.title && notification.title.trim() == ''
                      ? true
                      : false
                  }
                  errorText="this field is required"
                  onChange={async v => {
                    await setNotification({
                      ...notification,
                      title: v
                    });
                  }}
                />
              </Cell>
              <Cell size={12}>
                <TextField
                  ref={descriptionRef}
                  id="description"
                  autoComplete={'off'}
                  required
                  label="Description"
                  value={notification.description || ''}
                  error={
                    notification.description &&
                    notification.description.trim() == ''
                      ? true
                      : false
                  }
                  errorText="this field is required"
                  onChange={async v => {
                    await setNotification({
                      ...notification,
                      description: v
                    });
                  }}
                />
              </Cell>
              <SelectField
                ref={languageRef}
                id="language"
                autoComplete={'off'}
                required
                label="Language"
                className="md-cell md-cell-4"
                menuItems={[
                  { value: 'English', label: 'English' },
                  { value: 'Spanish', label: 'Spanish' }
                ]}
                value={notification.language || ''}
                error={
                  notification.language && notification.language.trim() == ''
                    ? true
                    : false
                }
                errorText="this field is required"
                onChange={async v => {
                  await setNotification({
                    ...notification,
                    language: v
                  });
                }}
              />
              <SelectField
                ref={accountRef}
                id="account"
                autoComplete={'off'}
                required
                label="Account"
                className="md-cell md-cell-4"
                menuItems={[
                  { value: 'All', label: 'All' },
                  { value: 'Admin', label: 'Admin' },
                  { value: 'User', label: 'User' }
                ]}
                value={notification.account || ''}
                error={
                  notification.account && notification.account.trim() == ''
                    ? true
                    : false
                }
                errorText="this field is required"
                onChange={async v => {
                  await setNotification({
                    ...notification,
                    account: v
                  });
                }}
              />

              <Cell size={4}>
                <Checkbox
                  id="checkbox-read-material-design-spec"
                  name="simple-checkboxes[]"
                  label="Active"
                  checked={notification.active ? notification.active : false}
                  onChange={(v, e) => {
                    setNotification({ ...notification, active: v });
                  }}
                />
              </Cell>
            </Grid>
            <CardActions centered>
              <Button
                onClick={async e => {
                  notification.createdAt = moment().valueOf();
                  notification.updatedAt = props.match.params.id
                    ? moment().valueOf()
                    : null;
                  await saveNotification();
                }}
                flat
              >
                Save
              </Button>
              <Button
                secondary
                flat
                onClick={() => props.history.push('/notifications/list')}
              >
                Cancel
              </Button>
            </CardActions>
          </form>
        </Card>
      </Cell>
      <Cell size={4}></Cell>
    </Grid>
  );
};
