import Papa from 'papaparse';
import React, { Fragment, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import {
  Avatar,
  Button as BUTTON,
  Card,
  CardText,
  CardTitle,
  Cell,
  DialogContainer,
  FontIcon,
  Grid,
  List,
  ListItem
} from 'react-md';
import { toast } from 'react-toastify';
import { Preview } from '../';
import { Dropzone } from '../../components/';
import { useApi } from '../../services/api';
import ShowError from '../documents/ShowError';
import { Redirect, Route, Switch } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TemplateCSV from '../../assets/ACE Air CSV Upload_v11.csv';
import ExplanationDoc from '../../assets/ACE Air CSV Upload_v11.csv';
import { Link } from '@material-ui/core';
import Lottie from 'react-lottie';
import feathers from '../../services/feathers';
import ProgressBar from './uploadDoc2Progress';

import LoadingAnimation from '../../assets/analyze.json';
import Axios from 'axios';
const env = process.env;
let countRedirect = 0;

const downloadCsvFile = () => {
  const element = document.createElement('a');
  console.log('element', element);
  element.href = TemplateCSV;
  element.download = 'ACE Air CSV Upload_v11.csv';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const downloadExplanationDoc = () => {
  const element = document.createElement('a');
  element.href = ExplanationDoc;
  element.download = 'ACE Air Explanation Document V10.xls';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export default props => {
  // const profile = JSON.parse(localStorage.profile)
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      await checkToken();
    }
    tttt();
  }, []);

  const [files, setFiles] = useState([]);
  const [document, setDocument] = useState(null);
  const [visible, setVisible] = useState(false);
  const [erase, setErase] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const [, , fetchDoc, isError] = useApi(
    'ccapi',
    'cbp/air/upload2/csv',
    undefined,
    'post'
  );
  const [checkErrors, setCheckErrors] = useState({
    error: false,
    listError: [],
    row: []
  });
  const [redirect, setRedirect] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [readingFile, setReadingFile] = React.useState(false);
  const [fileUpload, setFileUpload] = useState({});

  //const docName = props.document.files.name.replace('.csv', '');

  const readFile = file => {
    setReadingFile(true);
    setFileUpload(file);
    Papa.parse(file, {
      delimiter: '', // auto-detect
      newline: '', // auto-detect
      quoteChar: '"',
      escapeChar: '"',
      skipEmptyLines: true,
      // fastMode: true,
      delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      complete: results => {
        fetchDoc({ body: results.data }).then(response => {
          console.log('response', response && response[0]);

          if (response && response[0].docsWithErrors != undefined) {
            toast.error(`${file.name} has failed`);
            setCheckErrors({
              error: true,
              listError: response[0].docsWithErrors
              //row: response[0].meta
            });
            setOpen(true);
          }
          // else {
          //   toast.success(`${file.name} succesfully imported`);

          //   // let files = [...props.files];
          //   // files.splice(props.files.indexOf(props.document.file), 1);
          //   //props.setFiles(files);
          //   //setDocument(null);
          //   let newArray = [...files];
          //   newArray.splice(files.indexOf(file), 1);
          //   setFiles(newArray);
          //   setVisible(false);
          //   setErase(null);
          //   setRedirect(true);
          // }
        });
      }
    });
  };

  const deleteDoc = file => {
    toast.info(`${file.name} has deleted`);
    let newArray = [...files];
    newArray.splice(files.indexOf(file), 1);
    setFiles(newArray);
    setVisible(false);
    setErase(null);
  };

  const handleKeyDown = e => {
    const key = e.which || e.keyCode;
    if (key === 13 || key === 32) {
      // also close on enter or space keys
      setVisible(false);
    }
  };

  const hide = () => {
    setErase(null);
    setVisible(false);
  };

  const handleClose = () => {
    setReadingFile(false);
    setOpen(false);
    setCheckErrors({
      error: false,
      listError: []
    });

    setFiles([]);
    setVisible(false);
    setErase(null);
  };

  // feathers.service('manifests').on('upload', data => {
  //   //TODO Redireccionar cuando termine

  //   if (data.type === 'FINISH' && data.total === data.cont) {
  //     countRedirect++;
  //     if (countRedirect === 1) {
  //       toast.success(`${fileUpload.name} succesfully imported`);
  //       let newArray = [...files];
  //       newArray.splice(files.indexOf(fileUpload), 1);
  //       setFiles(newArray);
  //       setVisible(false);
  //       setErase(null);
  //       setRedirect(true);
  //     }
  //   }
  // });

  return redirect == true ? (
    <Route render={() => <Redirect to="/awb-history" />} />
  ) : checkErrors.error == true ? (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={'xl'}
        open={open}
        // onClose={() => {
        //   handleClose();
        // }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" color="red">
          Errors in csv
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {
              'The file contains errors, please click `RETRY UPLOAD` and try to upload the corrected file.  '
            }
          </DialogContentText>
          <ShowError rows={checkErrors.listError}></ShowError>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            Retry Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <Fragment>
      {!document ? (
        <Grid>
          <Cell size={6} offset={3}>
            <Card tableCard={true}>
              <CardTitle title="Upload Document" subtitle={`CSV document`} />
              <CardText>
                {readingFile ? (
                  <>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: LoadingAnimation,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice'
                        }
                      }}
                      height={400}
                      width={400}
                      isStopped={false}
                      isPaused={false}
                    />
                    <div
                      style={{
                        textAlign: 'center',
                        marginBottom: 50,
                        color: 'gray'
                      }}
                    >
                      <ProgressBar></ProgressBar>
                    </div>
                  </>
                ) : (
                  <>
                    <Grid style={{ paddingTop: '0px' }}>
                      <Cell size={4} tabletSize={6} phoneSize={12}>
                        <Button
                          variant="contained"
                          onClick={downloadCsvFile}
                          color="primary"
                        >
                          Download CSV Template
                        </Button>
                      </Cell>
                      <Cell size={6} tabletSize={6} phoneSize={12}>
                        <Button
                          variant="contained"
                          onClick={downloadExplanationDoc}
                          color="primary"
                        >
                          Download Explanation Document
                        </Button>
                      </Cell>
                    </Grid>
                    <Dropzone files={files} setFiles={setFiles} />
                    <List>
                      {files.length
                        ? files.map((f, i) => (
                            <ListItem
                              key={i}
                              leftAvatar={
                                <Avatar
                                  suffix="blue"
                                  icon={
                                    <FontIcon iconClassName="fas fa-file-csv" />
                                  }
                                />
                              }
                              primaryText={f.name}
                              secondaryText={`${f.size} bytes`}
                              onClick={() => readFile(f)}
                              renderChildrenOutside
                            >
                              <BUTTON
                                icon
                                primary
                                onClick={() => {
                                  setErase(f);
                                  setVisible(true);
                                }}
                              >
                                delete
                              </BUTTON>
                            </ListItem>
                          ))
                        : null}
                    </List>
                  </>
                )}
              </CardText>
            </Card>
          </Cell>
        </Grid>
      ) : (
        <Fragment>
          {isLoading ? (
            <ReactLoading
              type="bubbles"
              color="#0d112b"
              height={'20%'}
              width={'20%'}
            />
          ) : (
            <Preview
              document={document}
              setDocument={setDocument}
              files={files}
              setFiles={setFiles}
            />
          )}
        </Fragment>
      )}
      <DialogContainer
        id="simple-list-dialog"
        visible={visible}
        title="You want to delete this document?"
        onHide={hide}
      >
        <List onClick={hide} onKeyDown={handleKeyDown}>
          <ListItem
            onClick={() => deleteDoc(erase)}
            primaryText="Yes, delete"
            rightIcon={<FontIcon secondary>check</FontIcon>}
          />
          <ListItem
            onClick={hide}
            primaryText="No, go back"
            rightIcon={<FontIcon primary iconClassName="fas fa-times" />}
          />
        </List>
      </DialogContainer>
    </Fragment>
  );
};
