import React from 'react';
import { AgGridReact } from '@ag-grid-community/react';
import TableContainer from '../Components/TableContainer';
import { AllModules } from '@ag-grid-enterprise/all-modules';

const style = { height: '50vh', marginBottom: '70px' };

const DataTableFSN = ({ data, getData }) => {
  const defaultColDefs = {
    sortable: true,
    width: 255,
    flex: 1,
    gridActions: {}
    // sortable: true,
    // groupable: false,
    // resizable: true,
    // width: 255,
    // filter: 'agTextColumnFilter',
    // flex: 1,
    // gridActions: {},
    // rowData: data
  };

  const columnDefs = [
    { headerName: 'AWB Prefix', field: 'AWBPrefix' },
    { headerName: 'AWB Number', field: 'AWBNumber' },
    { headerName: 'MAWB Indicator', field: 'MAWBIndicator' },
    { headerName: 'HAWB Number', field: 'HAWBNumber' },
    { headerName: 'Part Arrival Reference', field: 'partArrivalReference' },
    { headerName: 'Airline', field: 'airLine' },
    { headerName: 'Arrival Airport', field: 'arrivalAirport' },
    { headerName: 'Terminal Operator', field: 'terminalOperator' },
    { headerName: 'Flight Number', field: 'flightNumber' },
    { headerName: 'Date Of Arrival', field: 'dateOfArrival' },
    { headerName: 'FSN Reason Code', field: 'dateOfArrival' }
  ];

  return (
    <TableContainer className="ag-theme-material" style={style}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDefs}
        modules={AllModules}
        rowData={data}
        pagination={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
      />
    </TableContainer>
  );
};
// }
export default DataTableFSN;
