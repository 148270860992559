import React, { Fragment, useState, useEffect } from 'react';
import { Grid, Cell, TextField, SelectionControlGroup, Button } from 'react-md';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import Axios from 'axios';
const env = process.env;

export default (props) => {
  const { close, AWB, HAWB } = props;
  const [fsq, setFsq] = useState({ requestCode: '' });
  const [awb, setAwb] = useState({
    prefix: '',
    serialNumber: '',
    hawbNumber: '',
  });
  const [, , fetchDoc] = useApi('ccapi', 'documents', undefined, 'post');
  const [ready, setReady] = useState(false);
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');

  let AWBPSN = React.createRef();
  if (AWB) {
    useEffect(() => {
      setAwb({
        prefix: AWB.split('-')[0],
        serialNumber: AWB.split('-')[1],
        hawbNumber: HAWB || '',
      });
    }, [AWB]);
  }

  const send = async (id) => {
    let [response, err] = await doSend({ body: [id] });
    if (err) return;
    toast.success(response.message);
    setTimeout(() => {
      props.history.push('/request-query');
    }, 1000);
  };

  const create = async (willSend) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response2 = await Axios.get(
      env['REACT_APP_AUTH'] + '/validPartnership'
    );
    if (response2.data.success) {
      fsq.componentId = 'FSQ';
      let body = { SMI: { componentId: 'FSQ' }, AWB: awb, FSQ: fsq };

      let [apifsq, err] = await fetchDoc({
        body: body,
      });
      if (err) return;
      if (apifsq) {
        if (apifsq.errors) {
          let detail = '';
          for (let i = 0; i < apifsq.errors.length; i++) {
            if (apifsq.errors.length - 1 === i)
              detail += ` ${apifsq.errors[i].message}`;
            else detail += ` ${apifsq.errors[i].message},`;
          }

          toast.warn(`${apifsq.message}, ${detail}`);
        } else {
          toast.success(
            `FSQ for ${apifsq.message.docOrigin.AWB.prefix}-${apifsq.message.docOrigin.AWB.serialNumber} successfully created.`
          );
          if (true) {
            await send(apifsq.message._id);
          }
        }
      } else {
        toast.error(`Error: something goes wrong, try again`);
      }
    } else {
      toast.warn(response2.data.message);
    }
  };
  const setPrefix = (e) => {
    setAwb({ ...awb, prefix: e });
    setReady(
      e.length === 3 && awb.serialNumber.length === 8 && fsq.requestCode !== ''
    );
  };
  const setSerialNumber = (e) => {
    setAwb({ ...awb, serialNumber: e });
    setReady(
      awb.prefix.length === 3 && e.length === 8 && fsq.requestCode !== ''
    );
  };

  return (
    <Fragment>
      <Grid>
        <Cell size={2}>
          <TextField
            id="AWBPSN"
            ref={AWBPSN}
            value={awb.prefix}
            helpText="Example: 999"
            onChange={(e) => {
              const re = /^[a-zA-Z0-9\b]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 3) {
                  setPrefix(e.toUpperCase());
                }
              }
            }}
            label="AWB Prefix"
            lineDirection="center"
          />
        </Cell>
        <Cell size={5}>
          <TextField
            id="AWBPSN"
            ref={AWBPSN}
            value={awb.serialNumber}
            helpText="Example: 12345678"
            onChange={(e) => {
              const re = /^[0-9\b]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 8) {
                  setSerialNumber(e);
                }
              }
            }}
            label="AWB #"
            lineDirection="center"
          />
        </Cell>
        <Cell size={5}>
          <TextField
            id="HAWB"
            className="texttttt"
            value={awb.hawbNumber}
            onChange={(e) => {
              const re = /^[a-zA-Z0-9\b]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 12) {
                  setAwb({ ...awb, hawbNumber: e.toUpperCase() });
                }
              }
            }}
            label="House AWB #"
            lineDirection="center"
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={12}>
          <SelectionControlGroup
            id="selection-control-group-radios"
            name="radio-example"
            type="radio"
            label="Status Request Code"
            defaultValue={false}
            controls={items}
            onChange={(e) => {
              setFsq({ ...fsq, requestCode: e });
              setReady(
                awb.prefix.length === 3 &&
                  awb.serialNumber.length === 8 &&
                  e.length !== ''
              );
            }}
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={2}>
          <Button
            style={{ display: AWB ? 'none' : 'block' }}
            raised
            secondary
            iconBefore={false}
            onClick={async (e) => {
              await create(false);
            }}
            disabled={!ready}
          >
            Create & Send
          </Button>
          <Button
            style={{ display: AWB ? 'block' : 'none' }}
            raised
            secondary
            iconBefore={false}
            onClick={async (e) => {
              await create(true);
              close();
            }}
            disabled={!ready}
          >
            Create & Send
          </Button>
        </Cell>
        <Cell style={{ display: AWB ? 'block' : 'none' }} size={2}>
          <Button raised secondary iconBefore={false} onClick={close}>
            Cancel
          </Button>
        </Cell>
      </Grid>
    </Fragment>
  );
};

const items = [
  {
    label: '01 - Request for routing information',
    value: '01',
  },
  {
    label: '02 - Request for current record status',
    value: '02',
  },
  {
    label: '03 - Request for nominated agent',
    value: '03',
  },
  {
    label: '04 - Request for retransmission of FSN messages',
    value: '04',
  },
  {
    label:
      '05 - Request for: routing information, current record status, nominated agent, current AWB information on file returned in FSC/10 message',
    value: '05',
  },
  {
    label: '06 - Request for HAWB information for supplied MAWB.',
    value: '06',
  },
];
