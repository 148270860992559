import _ from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardText,
  Cell,
  DataTable,
  EditDialogColumn,
  FontIcon,
  Grid,
  ListItem,
  MenuButton,
  SelectField,
  TableBody,
  TableCardHeader,
  TableColumn,
  TableHeader,
  TablePagination,
  TableRow
} from 'react-md';
import { toast } from 'react-toastify';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import { useApi } from '../../services/api';
import '../style.css';
import { DeleteManifest } from '../../components';
import Axios from 'axios';
const env = process.env;

export default props => {
  const [value, setValue] = useState({
    serialNumber: '',
    hawbNumber: '',
    shipper: '',
    consignee: '',
    flight: '',
    eta: '',
    destination: '',
    origin: ''
  });
  const [, , getPending] = useApi('ccapi', 'documents', undefined, 'get');
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');
  const [filter, setFilter] = useState([]);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(50);
  const [toSend, setToSend] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState(false);
  const [showDelete, setDelete] = useState(false);
  const [detail, setDetail] = useState({});
  const [fileToDelete, setFileToDelete] = useState({});
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        getPendings({ types: ['FRI', 'FXI', 'FRC', 'FXC', 'FRX', 'FXX'] });
      }
    }
    tttt();
  }, []);

  useEffect(() => {
    setSelected(false);
    setLoading(false);
  }, [loading]);

  const getPendings = async () => {
    setLoading(true);

    let [pending, err] = await getPending({
      query: { types: ['FRI', 'FXI', 'FRC', 'FXC', 'FRX', 'FXX'] }
    });

    setLoading(false);

    pending = pending.filter(
      item => item.meta && item.meta.status && item.meta.status === 'valid'
    );

    if (err) return;
    pending.map(p => (p.checked = false));
    setFilter(pending.slice(0, 50));
    setData(pending);
  };

  const handleEvent = (e, path, segment) => {
    setValue({ [path]: e });
    setFilter(_.filter(data, [`docOrigin.${segment}.${path}`, e]));
  };

  const handlePagination = (start, rowsPerPage) => {
    setRows(rowsPerPage);
    setFilter(data.slice(start, start + rowsPerPage));
  };

  const sendDocs = async () => {
    if (await checkToken()) {
      props.history.push('awb-history');
      let [response, err] = await doSend({ body: toSend });
      if (err) return;
      toast.success(response.message);
      // getPendings();
      // setLoading(true);
    }
    // let body =
  };

  const checked = (e, action) => {
    let ids = toSend;
    let fl = filter;

    if (action === 'all') {
      ids.length === data.length
        ? ids.splice(0, ids.length)
        : e.map(d => ids.indexOf(d._id) !== -1 || ids.push(d._id));
      fl.filter(f =>
        f.meta.status === 'valid'
          ? (f.checked = !f.checked)
          : (f.checked = false)
      );
      setLoading(true);
    } else {
      if (e.meta.status === 'invalid') {
        return toast.error('Can not selected invalid documents');
      }
      fl.map(f => (f._id === e._id ? (f.checked = !f.checked) : ''));
      ids.indexOf(e._id) !== -1
        ? ids.splice(ids.indexOf(e._id), 1)
        : ids.push(e._id);
      setLoading(true);
    }
    setFilter(fl);
    setToSend(ids);
  };

  const changeAction = (e, c) => {
    let d = data;
    let f = filter;
    f.splice(
      filter.findIndex(fl => fl._id === c._id),
      1
    );
    d.splice(
      data.findIndex(dt => dt._id === c._id),
      1
    );
    if (e === 'Add') {
      c.docOrigin.SMI.componentId =
        c.docOrigin.CED !== undefined ? 'FXI' : 'FRI';
    } else if (e === 'Change') {
      c.docOrigin.SMI.componentId =
        c.docOrigin.CED !== undefined ? 'FXC' : 'FRC';
    } else {
      c.docOrigin.SMI.componentId = 'FRX';
    }
    d.push(c);
    f.push(c);
    setData(d);
    setFilter(d);
  };

  const placeholder = c => {
    if (
      c.docOrigin.SMI.componentId === 'FRI' ||
      c.docOrigin.SMI.componentId === 'FXI'
    ) {
      return 'Add';
    } else if (
      c.docOrigin.SMI.componentId === 'FRC' ||
      c.docOrigin.SMI.componentId === 'FXC'
    ) {
      return 'Change';
    } else {
      return 'Cancel';
    }
  };

  const changeAll = e => {
    let d = data;
    if (e === 'Add') {
      d.map(dt => {
        if (
          dt.docOrigin.SMI.componentId === 'FRC' ||
          dt.docOrigin.SMI.componentId === 'FRX'
        ) {
          dt.docOrigin.SMI.componentId = 'FRI';
        }
        if (
          dt.docOrigin.SMI.componentId === 'FXC' ||
          dt.docOrigin.SMI.componentId === 'FXX'
        ) {
          dt.docOrigin.SMI.componentId = 'FXI';
        }
      });
    }
    if (e === 'Change') {
      d.map(dt => {
        if (
          dt.docOrigin.SMI.componentId === 'FRI' ||
          dt.docOrigin.SMI.componentId === 'FRX'
        ) {
          dt.docOrigin.SMI.componentId = 'FRC';
        }
        if (
          dt.docOrigin.SMI.componentId === 'FXI' ||
          dt.docOrigin.SMI.componentId === 'FXX'
        ) {
          dt.docOrigin.SMI.componentId = 'FXC';
        }
      });
    }
    if (e === 'Cancel') {
      d.map(dt => {
        if (
          dt.docOrigin.SMI.componentId === 'FRC' ||
          dt.docOrigin.SMI.componentId === 'FRI'
        ) {
          dt.docOrigin.SMI.componentId = 'FRX';
        }
        if (
          dt.docOrigin.SMI.componentId === 'FXC' ||
          dt.docOrigin.SMI.componentId === 'FXI'
        ) {
          dt.docOrigin.SMI.componentId = 'FXX';
        }
      });
    }
    setData(d);
    setLoading(true);
  };

  const toDeleteFile = file => {
    delete file.checked;
    setFileToDelete(file);
    setDelete(true);
  };

  return (
    <Grid>
      <Cell size={12} style={{ marginBottom: '30px' }}>
        <Card raise tableCard>
          <Button
            style={{ marginTop: '80px' }}
            floating
            secondary
            fixed
            onClick={sendDocs}
            fixedPosition="tr"
          >
            send
          </Button>
          <CardText>
            <TableCardHeader title="Send Manifest" visible={false} />
            {loading ? (
              <ActivityIndicator message="Loading data, please wait..." />
            ) : (
              <DataTable
                baseId="simple-pagination"
                fixedDividers
                selectableRows={true}
              >
                <TableHeader>
                  <TableRow
                    onCheckboxClick={e =>
                      checked(
                        data.filter(d => d.meta.status === 'valid'),
                        'all'
                      )
                    }
                  >
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 80 }}
                      placeholder="AWB #"
                      onChange={e => handleEvent(e, 'serialNumber', 'AWB')}
                      value={value.serialNumber}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 80 }}
                      placeholder="HAWB #"
                      onChange={e => handleEvent(e, 'hawbNumber', 'AWB')}
                      value={value.hawbNumber}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 80 }}
                      placeholder="Consolidation"
                      onChange={e => handleEvent(e, 'consolidationId', 'AWB')}
                      value={value.consolidationId}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 90 }}
                      placeholder="Doc type"
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 80 }}
                      placeholder="Shipper"
                      onChange={e => handleEvent(e, 'name', 'SHP')}
                      value={value.shipper}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 100 }}
                      placeholder="Consignee"
                      onChange={e => handleEvent(e, 'name', 'CNE')}
                      value={value.consignee}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <TableColumn className="header-style">
                      Piece Count
                    </TableColumn>
                    <TableColumn className="header-style">Weight</TableColumn>
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 80 }}
                      placeholder="Origin"
                      onChange={e => handleEvent(e, 'airportOrigin', 'WBL')}
                      value={value.origin}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 80 }}
                      placeholder="Destination"
                      onChange={e => handleEvent(e, 'arrivalAirport', 'CCL')}
                      value={value.destination}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 80 }}
                      placeholder="Flight #"
                      onChange={e => handleEvent(e, 'flightNumber', 'ARR')}
                      value={value.flight}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <EditDialogColumn
                      className="header-style"
                      textFieldStyle={{ width: 80 }}
                      placeholder="ETA"
                      onChange={e => handleEvent(e, 'arrivalDate', 'ARR')}
                      value={value.eta}
                      inline
                      leftIcon={<FontIcon>filter_list</FontIcon>}
                      inlineIcon={null}
                    />
                    <TableColumn className="header-style">Status</TableColumn>
                    <TableColumn
                      style={{ width: 10, padding: 0 }}
                    ></TableColumn>
                    <TableColumn className="header-style">Valid</TableColumn>
                    <TableColumn className="header-style">
                      <SelectField
                        id="select-field"
                        placeholder="Action"
                        menuItems={['Add', 'Change', 'Cancel']}
                        onChange={e => changeAll(e)}
                      />
                    </TableColumn>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filter.length
                    ? filter.map((c, i) => {
                        const { docOrigin } = c;
                        {
                          /* console.log('docOrigin:', docOrigin); */
                        }

                        return (
                          <TableRow
                            key={i}
                            selected={c.checked}
                            onCheckboxClick={e => checked(c)}
                          >
                            <Fragment>
                              <TableColumn className="column-style">
                                <a
                                  href=""
                                  onClick={() => {
                                    props.history.push({
                                      pathname: `/manifest-details/${c._id}/DRAFT`
                                    });
                                  }}
                                >
                                  {(docOrigin.AWB &&
                                    `${docOrigin.AWB.prefix}-${docOrigin.AWB.serialNumber}`) ||
                                    'N/A'}
                                </a>
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.AWB && docOrigin.AWB.hawbNumber) ||
                                  'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.AWB &&
                                  docOrigin.AWB.consolidationId) ||
                                  'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {docOrigin.AWB.hawbNumber != ''
                                  ? 'House'
                                  : docOrigin.AWB.consolidationId != ''
                                  ? 'Master'
                                  : docOrigin.AWB.hawbNumber === '' &&
                                    docOrigin.AWB.consolidationId === ''
                                  ? 'Simple'
                                  : 'Simple'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.SHP && docOrigin.SHP.name) || 'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.CNE && docOrigin.CNE.name) || 'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.WBL && docOrigin.WBL.numPieces) ||
                                  'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.WBL &&
                                  `${docOrigin.WBL.weight}${docOrigin.WBL.weightCode}`) ||
                                  'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.WBL &&
                                  docOrigin.WBL.airportOrigin) ||
                                  'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.CCL &&
                                  docOrigin.CCL.arrivalAirport) ||
                                  'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.ARR &&
                                  docOrigin.ARR.flightNumber) ||
                                  'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                {(docOrigin.ARR && docOrigin.ARR.arrivalDate) ||
                                  'N/A'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                DRAFT
                              </TableColumn>
                              <TableColumn style={{ padding: 5 }}>
                                <MenuButton
                                  id="menu"
                                  icon
                                  menuItems={[
                                    <ListItem
                                      key={1}
                                      primaryText="Details"
                                      rightIcon={<FontIcon>list</FontIcon>}
                                      onClick={() => {
                                        props.history.push({
                                          //pathname: `/manifest-details/${c._id}`
                                          pathname: '/detail',
                                          state: {
                                            id: c._id,
                                            status: 'DRAFT',
                                            from: 'send-manifest'
                                          }
                                        });
                                      }}
                                    />,
                                    <ListItem
                                      key={2}
                                      primaryText="Delete"
                                      rightIcon={<FontIcon>delete</FontIcon>}
                                      onClick={() => {
                                        toDeleteFile(c);
                                      }}
                                      // onClick={() => {
                                      //   props.history.push({
                                      //     pathname: '/detail',
                                      //     state: {
                                      //       id: c._id,
                                      //       status: 'DRAFT',
                                      //       from: 'send-manifest'
                                      //     }
                                      //   });
                                      // }}
                                    />
                                  ]}
                                  centered
                                >
                                  more_vert
                                </MenuButton>
                              </TableColumn>
                              <TableColumn className="column-style">
                                {c.meta.status === 'invalid' ? 'No' : 'Yes'}
                              </TableColumn>
                              <TableColumn className="column-style">
                                <SelectField
                                  id="select-field"
                                  placeholder={placeholder(c)}
                                  menuItems={['Add', 'Change', 'Cancel']}
                                  position={SelectField.Positions.BELOW}
                                  onChange={e => changeAction(e, c)}
                                />
                              </TableColumn>
                            </Fragment>
                          </TableRow>
                        );
                      })
                    : null}
                </TableBody>
                <TablePagination
                  rows={data.length}
                  rowsPerPageLabel="rows"
                  rowsPerPage={rows}
                  onPagination={handlePagination}
                />
              </DataTable>
            )}
          </CardText>
        </Card>
      </Cell>
      {showDelete ? (
        <DeleteManifest
          {...props}
          visible={showDelete}
          setDelete={setDelete}
          doc={fileToDelete}
          getPendings={getPendings}
        />
      ) : null}
    </Grid>
  );
};
