import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';

const FlightDetails = ({ row, ...restProps }) => {
  return (
    <Table.Cell {...restProps}>
      {row.docOrigin.ARR
        ? 'Flight #: ' +
          (row.docOrigin.ARR.impCarrier || '') +
          (row.docOrigin.ARR.flightNumber || '')
        : ''}
      {row.docOrigin.ARR && row.docOrigin.ARR.arrivalDate ? <br /> : null}
      {row.docOrigin.ARR && row.docOrigin.ARR.arrivalDate
        ? 'Arrival Date: ' + row.docOrigin.ARR.arrivalDate
        : ''}
      {row.docOrigin.ARR && row.docOrigin.ARR.arrivalRef ? <br /> : null}
      {row.docOrigin.ARR && row.docOrigin.ARR.arrivalRef
        ? 'Split Shipment Part: ' + row.docOrigin.ARR.arrivalRef
        : ''}
      {row.docOrigin.CCL && row.docOrigin.CCL.arrivalAirport ? <br /> : null}
      {row.docOrigin.CCL && row.docOrigin.CCL.arrivalAirport
        ? 'Arrival Airport: ' + row.docOrigin.CCL.arrivalAirport
        : ''}
      {row.docOrigin.CCL && row.docOrigin.CCL.terminalOp ? <br /> : null}
      {row.docOrigin.CCL && row.docOrigin.CCL.terminalOp
        ? 'Terminal Operator: ' + row.docOrigin.CCL.terminalOp
        : ''}
    </Table.Cell>
  );
};

export default FlightDetails;
