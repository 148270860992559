import React from 'react';
import styled from 'styled-components';

const TableContainer = ({ children, className, style }) => (
  <Container style={style}>
    <Div className={className} style={style}>
      {children}
    </Div>
  </Container>
);

const Container = styled.div`
  width: 100%;
  padding: 10px 0px;
  background-color: transparent;
`;

const Div = styled.div`
  /* height: 62.5vh; */
  width: 100%;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  overflow: hidden;
`;

export default TableContainer;
