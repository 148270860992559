import React from 'react';
import styled from 'styled-components';

const Summary = ({ data, id }) => {
  const arrayData = data.split('\n').filter((s) => s);
  return (
    <Wrapper id={id}>
      {arrayData.map((s, i) => (
        <P key={i}>{s}</P>
      ))}
    </Wrapper>
  );
};

const P = styled.p`
  margin: 0px;
  padding: 0px;
`;

const Wrapper = styled.div``;

export default Summary;
