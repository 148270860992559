import { Switch, Route, Redirect } from 'react-router-dom';
import { Login, ChangePassword } from './views/user';
import { Login2 } from './views';
import { KebabMenu, SideBar } from './components';
import { ToastContainer } from 'react-toastify';
import logo from './assets/img/ccNewLogo2.png';
import { Toolbar, Button } from 'react-md';
import React, { useState } from 'react';
import Routes from './Routes';
import './App.scss';
// import 'bootstrap/dist/css/bootstrap.min.css';
import styled from 'styled-components';
export default (props) => {
  const [visible, setVisible] = useState(false);
  const [logged, setLogged] = useState(localStorage.getItem('token'));
  const [profile, setProfile] = useState({});
  const reload = (version) => {
    let profile = JSON.parse(localStorage.profile);
    if (version !== profile.version) {
      profile.version = version;
      localStorage.setItem('profile', JSON.stringify(profile));
      window.location.reload(true);
    }
  };

  if (!logged) {
    return (
      <Switch>
        <Route
          path="/loginold"
          render={(props) => (
            <Login
              {...props}
              reload={reload}
              setLogged={setLogged}
              setProfile={setProfile}
            />
          )}
        />
        <Route
          path="/login"
          exact
          render={(props) => (
            <Login2
              {...props}
              reload={reload}
              setLogged={setLogged}
              setProfile={setProfile}
            />
          )}
        />
        <Route
          path="/changepassword"
          reload={reload}
          setLogged={setLogged}
          setProfile={setProfile}
          component={ChangePassword}
        />
        <Route render={() => <Redirect to="/login" />} />
        <ToastContainer autoClose={8000} />
      </Switch>
    );
  } else {
    return (
      <div>
        <Toolbar
          fixed
          themed
          nav={
            <Button
              icon
              className={props.className}
              onClick={(e) => setVisible(!visible)}
            >
              menu
            </Button>
          }
          title={
            <LogoContainer>
              <img src={logo} width="232" height="44" alt="Customs City" />
            </LogoContainer>
          }
          actions={
            <KebabMenu
              id="toolbar-colored-kebab-menu"
              reload={reload}
              setLogged={setLogged}
            />
          }
        />
        <SideBar visible={visible} profile={profile} setVisible={setVisible} />
        <div style={{ marginTop: 60 }}>
          <Routes reload={reload} setLogged={setLogged} />
          <ToastContainer autoClose={8000} />
        </div>
        <div style={{ color: '#aaa' }}></div>
      </div>
    );
  }
};

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;
