import React, { Component, Fragment } from 'react';
import {
  CircularProgress,
  SelectField,
  ListItem,
  MenuButton,
  Autocomplete,
} from 'react-md';
import {
  Drawer,
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  IconButton,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Grid as GridMUI,
  Button as Button2,
} from '@material-ui/core';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableRowDetail,
  TableFilterRow,
  TableSelection,
} from '@devexpress/dx-react-grid-material-ui';
import Notifications from '../../../components/common/Notifications';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  RowDetailState,
  FilteringState,
  IntegratedFiltering,
  TableColumnVisibility,
  SelectionState,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import { toast } from 'react-toastify';
import { CheckCircleRounded, CancelRounded, Close } from '@material-ui/icons';
import _ from 'lodash';
import moment from 'moment';
import Axios from 'axios';
import feathers from '../../../services/feathers';
import Houses from './Houses';
import Documents from './Documents';
import ManifestDetails from '../../../views/documents/ManifestDetails';
import { FSNMessage, entryType } from '../../../components/awb-history/Codes';
import FSQ from '../../../components/documents/FSQ';
import FSN from '../../../components/documents/FSN';
import FDM from '../../../components/documents/FDM';
import FRX from '../../../components/documents/FRX';
import DeleteManifestSupport from '../../../components/documents/DeleteManifestSupport';
import DeleteManifestDraftsView from '../../../components/documents/DeleteManifestDrafts';
import DialogForm from '../../../components/common/DialogForm';
import DatePickerRange from '../../../components/common/DatePickerRange';
import Status from './components/Status';
import StatsSummary from './components/StatsSummary';
import NumberOfHousesBtn from './components/NumberOfHousesBtn';
import AWBColumn from './components/AWBColumn';
import EventNotifications from './components/EventNotifications';
import PagingContainer from '../../../components/GridComponents/PagingPanel';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import TotalStatus from './components/totalStatus';
import SentModal from '../../../components/SentModal';

const env = process.env;
const cellTheme = createMuiTheme({
  palette: {
    secondary: { main: '#019ee1' },
  },
});

const selectCellComponent = (props) => {
  return (
    <MuiThemeProvider theme={cellTheme}>
      <TableSelection.Cell {...props} />
    </MuiThemeProvider>
  );
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      style={{ marginTop: 50 }}
      {...other}
    >
      {value === index && <Box component="div">{children}</Box>}
    </div>
  );
};

const HighlightedCell = ({ value }) => {
  return (
    <Table.Cell
      style={{
        overflow: 'visible',
      }}
    >
      {value}
    </Table.Cell>
  );
};

const DateRequestCell = ({ value, ...restProps }) => {
  let splitDate = value.toString().split(' ');
  let fullDate = '';
  let fullHour = '';

  if (splitDate.length > 0) {
    fullDate = splitDate[0];
    fullHour = splitDate[1];
  }
  return (
    <Table.Cell
      {...restProps}
      style={{
        overflow: 'visible',
      }}
    >
      <div>
        {fullDate} <br /> {fullHour}
      </div>
    </Table.Cell>
  );
};

const NumberHousesCell = ({ value, row, ...restProps }) => {
  return (
    <NumberOfHousesBtn
      key={`${row.serialNumber}-${row.flightNumber}-${row.type}`}
      value={value}
      row={row}
      restProps={restProps}
    />
  );
};
const AWBCell = ({ value, ...restProps }) => {
  return <AWBColumn value={value} restProps={restProps}></AWBColumn>;
};

const StatusCell = ({ value, row, ...restProps }) => {
  if (value == 'loading') {
    return (
      <Table.Cell {...restProps}>
        <CircularProgress
          id="loading"
          centered={true}
          className="loading-blue"
        ></CircularProgress>
      </Table.Cell>
    );
  } else {
    return (
      <Table.Cell
        {...restProps}
        style={{ cursor: row.type !== 'Consolidation' ? 'pointer' : 'default' }}
        onClick={async (e) => {
          if (row.type !== 'Consolidation') {
            await restProps.column.setRow({
              currentTab: 0,
              index: 0,
              row: row,
              prefix: row.prefix,
              serialNumber: row.serialNumber,
              impCarrier: row.impCarrier,
              arrivalDate: row.arrivalDate,
              flightNumber: row.flightNumber,
              type: row.type,
              date: row.manifestCreated,
            });
            await restProps.column.openDetails(
              true,
              row.type == 'Master' ? row.prefix : null,
              row.type == 'Master' ? row.serialNumber : null,
              row.lastId,
              row.status,
              row.userCreated,
              row.userSubmission,
              row.manifestCreated,
              row.requestDate,
              row.responseDate,
              row,
              undefined,
              1
            );
          }
        }}
      >
        <Status
          key={`${row.serialNumber}-${row.flightNumber}-${row.type}`}
          row={row}
        />
      </Table.Cell>
    );
  }
};

const Cell = (props) => {
  const { column } = props;
  // console.log('column', props);
  if (column.name === 'options' || column.name === 'actions') {
    return <HighlightedCell {...props} />;
  }
  if (
    column.name === 'requestDate' ||
    column.name === 'responseDate' ||
    column.name === 'dateCreated' ||
    column.name === 'dateModified'
  ) {
    return <DateRequestCell {...props} />;
  }
  if (column.name === 'numberHouses') {
    return <NumberHousesCell {...props} />;
  }
  if (column.name === 'awb') {
    return <AWBCell {...props} />;
  }
  if (column.name === 'status') {
    return <StatusCell {...props} />;
  }
  return <Table.Cell {...props} />;
};

const ContainerTable = ({ ...restProps }) => {
  return (
    <Table.Container
      style={{
        overflow: 'auto',
      }}
      {...restProps}
    />
  );
};

const CustomInput = ({ value, onClick }) => (
  <Button2 variant="outlined" onClick={onClick}>
    {value}
  </Button2>
);

class Main extends Component {
  _isMounted = false;
  state = {
    _id: '',
    prefix: '',
    sn: '',
    details: false,
    userCreated: '',
    userSubmission: '',
    manifestCreated: '',
    lastRequest: '',
    lastResponse: '',
    rowEdit: {},
    rol: localStorage.profile ? JSON.parse(localStorage.profile) : {},
    rows: [
      { id: 0, product: 'DevExtreme', owner: 'DevExpress' },
      {
        id: 1,
        product: 'DevExtreme Reactive',
        owner: 'DevExpress',
      },
    ],
    startDate: localStorage.startDate
      ? localStorage.startDate
      : new Date().setDate(new Date().getDate()),
    endDate: localStorage.endDate ? localStorage.endDate : new Date(),
    profile: JSON.parse(localStorage.profile),
    allrows: [],
    master: false,
    consolidation: false,
    simple: false,
    send: false,
    canceled: false,
    accepted: false,
    rejected: false,
    draft: false,
    loading: true,
    loadingState: true,
    status: [{ awb: '', staus: '', message: [], loading: '' }],
    open: false,
    openDeleteDrafts: false,
    openFullScreen: false,
    hasFSN: false,
    hasFDM: false,
    has1C: false,
    hasAmsHold: false,
    selectedRows: [],
    selectAll: 'Add',
    selection: [],
    selectedAll: false,
    tabSelected: 0,
    row: {},
    isManifestDetailsPristine: true,
    activeDeleteDrafts: false,
    clientsAutoComplete: [],
    listPrefix: [],
    listSerialNumber: [],
    totalStatus: {
      total: 0,
      accepted: 0,
      draft: 0,
      rejected: 0,
      sent: 0,
      cancelled: 0,
    },
    progModal: {
      open: false,
      text: '',
      progress: 0,
    },
  };

  type = 1;
  data = {
    prefix: '',
    serialNumber: '',
    hawbNumber: '',
    houses: [],
    close: null,
  };
  awb = '';
  rowHouse = {
    totalHouses: 0,
    data: [],
    prefix: '',
    serialNumber: '',
    flightNumber: '',
    currentTab: 1,
    index: 1,
    rowNo: 0,
  };

  hawb = {};
  selectedClient = {};

  setSelection = async (
    { prefix, serialNumber, type, sendAs, hawb, flightNumber, ...rest },
    checked
  ) => {
    let data = this.state.selectedRows;

    if (checked) {
      data.push({
        prefix: prefix,
        serialNumber: serialNumber,
        type: type,
        arrivalRef: rest.arrivalRef || null,
        action: sendAs,
        // hawb: hawb.filter((x) => x.hawbNumber !== ''),
        status: rest.status,
        _id: rest._id,
      });
    } else {
      let c = [];

      for (const item of data) {
        let awb = `${item.prefix}-${item.serialNumber} - ${item.type} ${item.flightNumber}`;
        if (awb !== `${prefix}-${serialNumber} - ${type} ${flightNumber}`) {
          c.push(item);
        }
      }
      console.log('c :>> ', c);
      data = c;
    }

    let rowDraft = data.filter((x) => x.status == 'DRAFT').length;

    if (rowDraft == data.length && rowDraft > 0) {
      this.setState({ activeDeleteDrafts: true });
    } else {
      this.setState({ activeDeleteDrafts: false });
    }
    let allRows = this.state.allrows;
    let condition = false;
    if (allRows.length === data.length) {
      condition = true;
    }
    await this.setState({ selectedRows: data, selectedAll: condition });
  };

  deleteManifestDrafts = async (manifestSelecteds) => {
    if (await this.checkToken()) {
      let ids = manifestSelecteds.map((value) => {
        return value._id;
      });
      Axios.defaults.headers.common['Authorization'] = localStorage.token;
      await Axios.post(env['REACT_APP_CCAPI'] + '/deletedrafts/', { ids })
        .then((response) => {
          if (response.data.success) {
            toast.info(response.data.message);
            this.getManifest();
            this.filters();
            this.setState({ selectedRows: [], activeDeleteDrafts: false });
          }
        })
        .catch((error) => {
          //console.log('error: ', { error });
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          )
            toast.warn(error.response.data.message);
        });
    }
  };

  SendMMM = async (body) => {
    let total = 0;
    body.map((b) => (total += b.total || 0));
    let progress = 0;
    let percent = Math.ceil((progress / total) * 100);
    let text = `${progress} of ${total} documents sent...`;
    let open = true;

    try {
      Axios.defaults.headers.common['Authorization'] = localStorage.token;
      const response2 = await Axios.get(
        env['REACT_APP_AUTH'] + '/validPartnership'
      );
      if (response2.data.success) {
        this.setState({
          progModal: { open, text, progress: percent },
        });

        const headers = new Headers();
        headers.append('content-type', 'application/json');
        headers.set('Authorization', localStorage.token);
        await feathers.service('send').create({
          body: body,
          user: this.state.profile.email,
          company:
            this.state.profile.role === 'support' &&
            this.selectedClient &&
            this.selectedClient.value &&
            this.selectedClient.value !== ''
              ? this.selectedClient.value
              : null,
          type: 'Others',
        });

        text = `${total} of ${total} documents sent...`;
        percent = Math.ceil((total / total) * 100);
        this.setState({
          progModal: { open, text, progress: percent },
        });

        //set all data to Add
        this.setAllRowsAction('Add');

        setTimeout(() => {
          this.setState({
            progModal: { ...this.state.progModal, open: false },
          });
        }, 2000);
      } else {
        this.setState({
          progModal: { ...this.state.progModal, open: false },
        });
        console.log('response2 ', response2);
        toast.warn(response2.data.message);
      }
    } catch (error) {
      console.log('error message', error.message);
      this.setState({
        progModal: { ...this.state.progModal, open: false },
      });
      toast.error('SEND ERROR!... ' + error.message, { autoClose: false });
    }
  };

  setDates = async (start, end) => {
    await this.setState({
      startDate: start,
      endDate: end,
    });
    this.setState({ loading: true });
    this.selectedClient.value = '';
    await this.getManifest();
    this.filters();
  };

  getManifest = async () => {
    try {
      let result = await feathers.service('awb-status').find({
        query: {
          $agregation: {
            startDate: moment(this.state.startDate).startOf('day').valueOf(),
            endDate: moment(this.state.endDate).endOf('day').valueOf(),
            company: JSON.parse(localStorage.profile).company,
            role: JSON.parse(localStorage.profile).role,
            email: JSON.parse(localStorage.profile).email,
          },
        },
      });
      let number = 1;
      let hawbs = [];
      let serialNumber = [];
      let prefix = [];
      for (const manifest of result) {
        manifest.number = number;
        number++;
        manifest.checked = false;
        manifest.sendAs = 'Add';
        prefix.push(manifest.prefix);
        serialNumber.push(manifest.serialNumber);
      }

      console.log(result);
      this.setState({
        rows: result,
        allrows: result,
        loading: false,
        open: false,
        listPrefix: prefix,
        listSerialNumber: serialNumber,
      });

      this.props.setUpdateManifest(false);
    } catch (error) {
      this.setState({
        loading: false,
      });
      console.log('error :>> ', error);
    }
  };

  searchHouse = async (hawbNumber) => {
    let result = await feathers.service('house-status').find({
      query: {
        $findHouse: {
          hawbNumber: hawbNumber,
          prefix: this.state.listPrefix,
          serialNumber: this.state.listSerialNumber,
        },
      },
    });
    this.setState({ hawbs: result });
  };

  getClients = async (name) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_CLIENT'] + '/client/', {
      params: { $autoComplete: name },
    });

    if (response && response.data) {
      let clientList = response.data.map((c) => {
        return { value: c._id, label: c.name };
      });

      this.setState({
        clientsAutoComplete: clientList,
      });
    }
  };

  getTotalOfStatus = async () => {
    let role = JSON.parse(localStorage.profile).role;
    if (role == 'support') {
      let result = await feathers.service('awb-status').find({
        query: {
          $aggTotalStatus: {
            startDate: moment(this.state.startDate).startOf('day').valueOf(),
            endDate: moment(this.state.endDate).endOf('day').valueOf(),
            company:
              role == 'support'
                ? this.selectedClient.value || ''
                : JSON.parse(localStorage.profile).company,
            role: role,
          },
        },
      });

      if (result && result.length) {
        this.setState({
          totalStatus: result[0],
        });
      } else {
        this.setState({
          totalStatus: {
            total: 0,
            accepted: 0,
            draft: 0,
            rejected: 0,
            sent: 0,
            cancelled: 0,
          },
        });
      }
    }
  };

  listenFeathersStatus = () => {
    feathers.service('manifests').on('status', this.handleSetRowsStatus);
  };
  componentWillMount = () => {
    this._isMounted = true;
    this.checkTokenAndGetManifest();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
    feathers
      .service('manifests')
      .removeListener('status', this.handleSetRowsStatus);
  };

  checkTokenAndGetManifest = async () => {
    if (await this.checkToken()) {
      this.getManifest();
      this.getTotalOfStatus();
      this.listenFeathersStatus();
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.update == false && this.props.update == true) {
      this.setState({
        simple: false,
        consolidation: false,
        master: false,
        send: false,
        draft: false,
        canceled: false,
        rejected: false,
        accepted: false,
        hasFSN: false,
        hasFDM: false,
        loading: true,
      });
      this.selectedClient.value = '';
      this.getManifest();
      this.getTotalOfStatus();
    }
  }

  checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token + '';
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      this.props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    this.props.reload(response.data.version);
    return response.data.valid;
  };
  mapStatus = async (row, isConsolidation) => {
    let allStatus = {
      FRI: 'Send',
      FXI: 'Send',
      FRC: 'Send',
      FXC: 'Send',
      FRX: 'Send',
      FXX: 'Send',
      FSC: { '01': 'Cancelled', Others: 'Accepted' },
      FER: 'Rejected',
    };
    let status = '';
    let type = '';
    let hawb = [];
    row.consolidations = [];
    if (isConsolidation) {
      let consolidations = await feathers.service('manifests').find({
        query: {
          house: {
            serialNumber: row.serialNumber,
            prefix: row.prefix,
            flightNumber: row.flightNumber || null,
            company: JSON.parse(localStorage.profile).company,
            role: JSON.parse(localStorage.profile).role,
          },
        },
      });
      row.consolidations = consolidations;
      let Send = 0;
      let Canceled = 0;
      let Rejected = 0;
      let Accepted = 0;
      let cs = [];
      let iS = null;
      let iC = null;
      let iA = null;
      let iR = null;
      for (const i in consolidations) {
        let consolidation = consolidations[i];
        hawb.push({ hawbNumber: consolidation.hawbNumber });
        status = allStatus[consolidation.status];
        if (consolidation.status == 'FSC') {
          status = status[consolidation.statusCode == '01' ? '01' : 'Others'];
        }
        cs.push({
          type: consolidation.status,
          code: consolidation.statusCode,
          message: consolidation.statusCodeMessage,
          message2: consolidation.message,
        });
        if (status == 'Send') {
          Send++;
          iS = i;
        } else if (status == 'Cancelled') {
          Canceled++;
          iC = i;
        } else if (status == 'Accepted') {
          Accepted++;
          iA = i;
        } else if (status == 'Rejected') {
          Rejected++;
          iR = i;
        }
      }

      let index = null;
      if (Rejected > 0) {
        status = 'Rejected';
        index = iR;
      } else if (Send > 0) {
        status = 'Send';
        index = iS;
      } else if (Accepted > 0) {
        status = 'Accepted';
        index = iA;
      } else if (Canceled > 0) {
        status = 'Cancelled';
        index = iC;
      } else {
        status = 'Send';
        index = 0;
      }

      cs[index].status = status;
      cs[index].hawbs = {
        Rejected: Rejected,
        Send: Send,
        Accepted: Accepted,
        Canceled: Canceled,
      };
      type = cs[index];
    } else {
      status = allStatus[row.status];
      if (row.status == 'FSC') {
        status = status[row.statusCode == '01' ? '01' : 'Others'];
      }
      type = {
        hawbs: null,
        type: row.status,
        status: status,
        code: row.statusCode,
        message: row.statusCodeMessage,
        message2: row.message,
      };
    }
    row.hawb = hawb;
    row.status = status;
    row.statusCode = row.statusCode;
    row.statusCodeMessage = row.statusCodeMessage;
    row.filterStatus = status;
    row.statusObject = type;
    return row;
  };

  handleClickListItem = async (title, row, close, houses) => {
    this.data.AWB = `${row.prefix}-${row.serialNumber}`;
    this.data.HAWB = row.hawbNumber || '';
    this.data.houses = houses;
    this.data.close = close;
    this.data.getManifest = this.getManifest;
    this.data.row = row;
    if (this.type == 'Delete') {
      this.data.flightNumber = row.flightNumber;
      this.data.type = row.type;
      this.data.getManifest = this.getManifest;
    }
    this.setState({
      open: true,
      title: title,
    });
  };

  handleClose = async () => {
    this.setState({
      open: false,
    });
  };
  handleCloseDeleteDrafts = async () => {
    this.setState({
      openDeleteDrafts: false,
    });
  };

  handleCloseDetails = async (e) => {
    if (e == false) {
      this.setState({
        details: false,
      });
      this.setState({ ...this.state, tabSelected: 0 });
    } else {
      this.setState({ ...this.state, tabSelected: 0 });
      const { isManifestDetailsPristine } = this.state;

      // Close the dialog if form hasn't changed
      isManifestDetailsPristine &&
        this.setState({
          details: false,
        });

      // Ask for confirmation if form has changed
      !isManifestDetailsPristine &&
        window.confirm(
          'If you close this window your changes will be lost. Are you sure?'
        ) &&
        this.setState({
          details: false,
        });
    }
  };

  handleRowEDIted = async (rowEdited) => {
    await this.getManifest();
    this.changeWindowScroll();
    let rows = this.state.allrows;

    let checkRow = rows.filter((i) => {
      return (
        i.serialNumber == rowEdited.serialNumber &&
        i.prefix == rowEdited.prefix &&
        i.flightNumber == i.flightNumber
      );
    });
    if (checkRow) {
      checkRow = checkRow[0];
      if (checkRow) {
        checkRow.checked = true;
        rows[checkRow.number - 1] = checkRow;

        this.setSelection(checkRow, checkRow.checked);
        this.changeWindowScroll();
        this.setState({
          allrows: rows,
        });
      }
    }
  };

  FullScreenClose = async () => {
    this.setState({
      openFullScreen: false,
    });
  };
  FullScreenOpen = async () => {
    this.setState({
      openFullScreen: true,
    });
  };

  countHouses = (array) => {
    let x = array.filter((x) => x.hawbNumber != '');
    return x.length;
  };

  filters = () => {
    let rows = this.state.allrows;
    let filterType = [];
    let filterStatus = [];

    if (this.state.simple) {
      filterType.push('Simple');
    }
    if (this.state.consolidation) {
      filterType.push('Consolidation');
    }
    if (this.state.master) {
      filterType.push('Master');
    }
    if (this.state.send) {
      filterStatus.push('SENT');
    }
    if (this.state.draft) {
      filterStatus.push('DRAFT');
      // rows = rows.filter(x => x.filterStatus != 'Send');
    }
    if (this.state.canceled) {
      filterStatus.push('CANCELLED');
      // rows = rows.filter(x => x.filterStatus != 'Canceled');
    }
    if (this.state.rejected) {
      filterStatus.push('REJECTED');
      // rows = rows.filter(x => x.filterStatus != 'Rejected');
    }
    if (this.state.accepted) {
      filterStatus.push('ACCEPTED');
      // rows = rows.filter(x => x.filterStatus != 'Accepted');
    }
    if (this.state.hasFSN) {
      // filterFile.push('FSN');
      rows = rows.filter((x) => x.notification.length > 0);
    }
    if (this.state.hasFDM) {
      // filterFile.push('FDM');
      rows = rows.filter((x) => x.FDM && x.FDM !== null);
    }
    if (this.state.has1C) {
      // filterFile.push('FDM');
      rows = rows.filter((x) => x['1C'] !== 0);
    }
    if (this.state.hasAmsHold) {
      // filterFile.push('FDM');
      rows = rows.filter((x) => x.AMSHold !== 0 || x.wasHold);
    }
    if (filterType.length > 0) {
      rows = rows.filter(function (row) {
        if (filterType.includes(row.type)) return true;
        return false;
      });
    }
    if (filterStatus.length > 0) {
      rows = rows.filter(function (row) {
        if (filterStatus.includes(row.status)) return true;
        return false;
      });
    }

    if (this.hawb.value) {
      let prefix = null,
        serialNumber = null;
      if (this.state.hawbs.length) {
        for (const hawb of this.state.hawbs) {
          if (hawb.hawbNumber === this.hawb.value) {
            prefix = hawb.prefix;
            serialNumber = hawb.serialNumber;
            break;
          }
        }
      }
      rows = rows.filter(
        (item) => item.prefix === prefix && item.serialNumber === serialNumber
      );
    }

    if (this.selectedClient && this.selectedClient.value) {
      rows = rows.filter((item) => item.company === this.selectedClient.value);
    }

    //aqui meter CLients info totals
    this.setState({
      rows: rows,
    });
    this.setState({ loading: false });
    this.onSelection([]);

    //Fill total of status
    this.getTotalOfStatus();
  };

  openDetails = async (
    details,
    prefix,
    sn,
    id,
    status,
    userCreated,
    userSubmission,
    manifestCreated,
    lastRequest,
    lastResponse,
    row,
    getManifests,
    tabSelected
  ) => {
    this.setState({
      details: details,
      prefix: prefix,
      sn: sn,
      _id: id,
      status: status,
      userCreated: userCreated,
      userSubmission: userSubmission,
      manifestCreated: manifestCreated,
      lastRequest: lastRequest,
      lastResponse: lastResponse,
      rowEdit: row,
      getManifests: getManifests,
      tabSelected: tabSelected,
    });
  };

  changeWindowScroll = () => {
    window.scrollTo(0, 0);
  };
  changeTab = (event, value) => {
    this.setState({ ...this.state, tabSelected: value });
  };
  setRow = (row) => {
    this.setState({ ...this.state, row: row });
  };

  handleSetRowsStatus = async (data) => {
    if (this._isMounted) {
      console.log('data: ', data.serialNumber);
      let rows = this.state.allrows;

      let filter = {
        prefix: data.prefix,
        serialNumber: data.serialNumber,
        flightNumber: data.flightNumber,
        type: data.type,
      };

      if (!data.flightNumber) {
        delete filter.flightNumber;
      }

      let rowToUpdate = await _.find([...rows], filter);

      if (rowToUpdate && rowToUpdate.type == 'Consolidation') {
        rowToUpdate.code = data.code;
        rowToUpdate.docType = data.docType;
        rowToUpdate.hawb = data.hawb;
        rowToUpdate.message = data.message;
        rowToUpdate.message2 = data.message2;
        rowToUpdate.stats = data.stats;
        rowToUpdate.status = data.status;

        rows[rowToUpdate.number - 1] = await rowToUpdate;

        await this.setState({
          rows: [],
        });

        this.setState({
          allrows: rows,
        });

        this.setState({
          rows: rows,
        });
      } else if (rowToUpdate) {
        //console.log('rowToUpdate: ', rowToUpdate);
        rowToUpdate.code = data.code;
        rowToUpdate.message = data.message;
        rowToUpdate.message2 = data.message2;
        rowToUpdate.docType = data.docType;
        rowToUpdate.status = data.status;
        rowToUpdate.lastId = data.lastId;
        rows[rowToUpdate.number - 1] = await rowToUpdate;

        await this.setState({
          rows: [],
        });

        this.setState({
          allrows: rows,
        });

        this.setState({
          rows: rows,
        });
      }
    }
  };

  onSelection = async (selection) => {
    let rows = this.state.allrows;
    let data = [];
    let row = this.state.rows;

    rows = rows.map((row) => {
      delete row.checked;
      return row;
    });

    selection.map((index) => {
      let rowSelected = row.length ? row[index] : rows[index];

      let found = rows.find((it) => it.number === rowSelected.number);
      found.sendAs = this.state.selectAll;
      found.checked = true;
      data.push({
        prefix: found.prefix,
        serialNumber: found.serialNumber,
        type: found.type,
        arrivalRef: found.arrivalRef || null,
        action: found.sendAs,
        status: found.status,
        _id: found._id,
        total: (found && found.stats && found.stats.total) || 1,
        requestDate: found.requestDate || null,
      });
    });

    let rowDraft = data.filter((x) => x.status == 'DRAFT').length;

    await this.setState({
      selection,
      allrows: rows,
      selectedRows: data,
      activeDeleteDrafts: rowDraft == data.length && rowDraft > 0,
    });
  };

  setAllRowsAction = async (action) => {
    let rows = [];
    for (let row of this.state.allrows) {
      row.sendAs = action;
      rows.push(row);
    }

    let data = [];
    for (const x of this.state.selectedRows) {
      x.action = action;
      data.push(x);
    }

    this.setState({
      selectedRows: data,
      allrows: rows,
      selectAll: action,
    });
  };

  render() {
    if (this.state.loading) return <div>Loading...</div>;

    return (
      <Fragment>
        <SentModal {...this.state.progModal}></SentModal>
        <Drawer
          open={this.state.details}
          onClose={this.handleCloseDetails}
          anchor={'right'}
        >
          <div style={{ width: window.innerWidth * 0.9 }}>
            <AppBar
              position="fixed"
              style={{
                width: window.innerWidth * 0.9,
                backgroundColor: '#599dde',
              }}
            >
              <Toolbar>
                <Tabs
                  value={this.state.tabSelected}
                  onChange={this.changeTab}
                  indicatorColor="primary"
                  aria-label="simple tabs example"
                  style={{ backgroundColor: '#599dde' }}
                >
                  <Tab label="Manifest" wrapped />
                  <Tab label="Response" wrapped />
                  <Tab label="Event Notification" wrapped />
                </Tabs>
                <IconButton
                  edge="start"
                  style={{
                    marginRight: '10px',
                    position: 'absolute',
                    right: 10,
                  }}
                  onClick={this.handleCloseDetails}
                  color="inherit"
                  aria-label="menu"
                >
                  <Close />
                </IconButton>
              </Toolbar>
            </AppBar>
            <TabPanel value={this.state.tabSelected} index={0}>
              <ManifestDetails
                match={{
                  path: `/awb-history/:awb`,
                  url: `/awb-history/${this.state._id}`,
                  params: {
                    _id: this.state._id,
                    prefix: this.state.prefix,
                    sn: this.state.sn,
                    status: this.state.status,
                    userCreated: this.state.userCreated,
                    userSubmission: this.state.userSubmission,
                    manifestCreated: this.state.manifestCreated,
                    lastRequest: this.state.lastRequest,
                    lastResponse: this.state.lastResponse,
                    rowEdit: this.state.rowEdit,
                  },
                }}
                reload={this.props.reload}
                history={this.props.history}
                setLogged={this.props.setLogged}
                onClose={this.handleCloseDetails}
                onRowEdited={this.handleRowEDIted}
                onGetManifest={this.getManifest}
                getManifests={this.state.getManifests}
                pristine={(v) =>
                  this.setState({ isManifestDetailsPristine: v })
                }
              ></ManifestDetails>
            </TabPanel>
            <TabPanel value={this.state.tabSelected} index={1}>
              <Documents {...this.state.row} style={{ marginBottom: '50px' }} />
            </TabPanel>
            <TabPanel value={this.state.tabSelected} index={2}>
              <EventNotifications {...this.state.row}></EventNotifications>
            </TabPanel>
          </div>
        </Drawer>
        <GridMUI container>
          <GridMUI item style={{ width: '100%' }}>
            <Notifications />
          </GridMUI>
          <GridMUI item style={{ width: '70%' }}>
            <GridMUI container>
              <GridMUI
                item
                style={{
                  marginTop: '15px',
                  marginRight: '5px',
                }}
              ></GridMUI>
              <GridMUI
                item
                style={{
                  marginRight: '5px',
                  marginTop: '15px',
                  color: 'grey',
                  fontWeight: '400',
                }}
              >
                <DatePickerRange
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  dates={this.setDates}
                />
              </GridMUI>
              {this.state.rol.role === 'support' && (
                <GridMUI>
                  <Autocomplete
                    id="clientAuto"
                    label="CLIENT"
                    data={this.state.clientsAutoComplete}
                    dataLabel={'label'}
                    dataValue={'value'}
                    filter={null}
                    findInlineSuggestion={Autocomplete.findIgnoreCase}
                    value={this.selectedClient.select}
                    onChange={(e) => {
                      if (e.length > 2) {
                        this.getClients(e);
                      }
                      if (e.length === 0) {
                        this.selectedClient.value = e;
                        localStorage.setItem('client', e);
                        console.log('resetSelectedClient');
                        this.filters();
                      }
                    }}
                    onAutocomplete={(v) => {
                      console.log('v', v);
                      localStorage.setItem('client', v);
                      this.selectedClient.value = v;
                      this.filters();
                    }}
                  />
                </GridMUI>
              )}

              <GridMUI item style={{ marginRight: '5px' }}>
                <FormLabel component="legend"> Type </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.master}
                        onClick={async (e) => {
                          await this.setState({ master: !this.state.master });
                          this.filters();
                        }}
                        value="Master"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="Master"
                    style={{ marginRight: '10px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.consolidation}
                        onClick={async (e) => {
                          await this.setState({
                            consolidation: !this.state.consolidation,
                          });
                          this.filters();
                        }}
                        value="Master"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="Consolidation"
                    style={{ marginRight: '10px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.simple}
                        onClick={async (e) => {
                          await this.setState({ simple: !this.state.simple });
                          this.filters();
                        }}
                        value="Simple"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="Simple"
                    style={{ marginRight: '10px' }}
                  />
                </FormGroup>
              </GridMUI>
              <GridMUI item style={{ marginRight: '5px' }}>
                <FormLabel component="legend"> Status </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.draft}
                        onClick={async (e) => {
                          await this.setState({ draft: !this.state.draft });
                          this.filters();
                        }}
                        value="Simple"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="Draft"
                    style={{ marginRight: '10px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.send}
                        onClick={async (e) => {
                          await this.setState({ send: !this.state.send });
                          this.filters();
                        }}
                        value="Simple"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="Sent"
                    style={{ marginRight: '10px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.accepted}
                        onClick={async (e) => {
                          await this.setState({
                            accepted: !this.state.accepted,
                          });
                          this.filters();
                        }}
                        value="Simple"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="Accepted"
                    style={{ marginRight: '10px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.rejected}
                        onClick={async (e) => {
                          await this.setState({
                            rejected: !this.state.rejected,
                          });
                          this.filters();
                        }}
                        value="Simple"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="Rejected"
                    style={{ marginRight: '10px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.canceled}
                        onClick={async (e) => {
                          await this.setState({
                            canceled: !this.state.canceled,
                          });
                          this.filters();
                        }}
                        value="Simple"
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="Cancelled"
                    style={{ marginRight: '10px' }}
                  />
                </FormGroup>
              </GridMUI>
              <GridMUI item style={{ marginRight: '5px' }}>
                <FormLabel component="legend"> File </FormLabel>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hasFSN}
                        onClick={async (e) => {
                          await this.setState({ hasFSN: !this.state.hasFSN });
                          this.filters();
                        }}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="FSN"
                    style={{ marginRight: '10px' }}
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hasFDM}
                        onClick={async (e) => {
                          await this.setState({ hasFDM: !this.state.hasFDM });
                          this.filters();
                        }}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="FDM"
                    style={{ marginRight: '10px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.has1C}
                        onClick={async (e) => {
                          await this.setState({ has1C: !this.state.has1C });
                          this.filters();
                        }}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="1C"
                    style={{ marginRight: '10px' }}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={this.state.hasAmsHold}
                        onClick={async (e) => {
                          await this.setState({
                            hasAmsHold: !this.state.hasAmsHold,
                          });
                          this.filters();
                        }}
                        inputProps={{
                          'aria-label': 'primary checkbox',
                        }}
                      />
                    }
                    label="AMS Hold"
                    style={{ marginRight: '10px' }}
                  />
                </FormGroup>
              </GridMUI>
              <GridMUI
                item
                style={{
                  width: '250px',
                  marginLeft: '5px',
                  marginTop: '-10px',
                }}
              >
                <Autocomplete
                  id="hawbAuto"
                  label="HAWB"
                  data={this.state.hawbs || []}
                  dataLabel={'label'}
                  dataValue={'value'}
                  filter={Autocomplete.fuzzyFilter}
                  value={this.hawb.select}
                  onChange={(e) => {
                    this.hawb.value = e;
                    if (e.length >= 4) {
                      this.searchHouse(e);
                    }
                  }}
                  onAutocomplete={(v) => {
                    this.hawb.value = v;
                    // console.log(v);
                    this.filters();
                  }}
                  onBlur={() => {
                    this.filters();
                  }}
                />
              </GridMUI>
            </GridMUI>
          </GridMUI>
          <GridMUI item style={{ width: '30%' }}>
            <GridMUI item>
              <SelectField
                style={{ marginTop: '10px', marginLeft: '20px' }}
                id="select-field"
                disabled={this.state.selectedRows.length == 0}
                value={this.state.selectAll}
                menuItems={['Add', 'Change', 'Cancel']}
                onChange={(e) => {
                  let rows = [];
                  for (let row of this.state.allrows) {
                    if (row.checked) row.sendAs = e;
                    rows.push(row);
                  }

                  let data = [];
                  for (const x of this.state.selectedRows) {
                    x.action = e;
                    data.push(x);
                  }

                  this.setState({
                    selectedRows: data,
                    allrows: rows,
                    selectAll: e,
                  });

                  // TODO check why this function reset the grid data
                  // this.filters();
                }}
              />
              <Button
                disabled={
                  this.state.profile.role === 'shipper' ||
                  this.state.selectedRows.length == 0
                }
                style={{ marginTop: '15px', marginLeft: '15px' }}
                variant="outlined"
                color="primary"
                onClick={async (e) => {
                  if (await this.checkToken()) {
                    this.SendMMM(this.state.selectedRows);
                    this.onSelection([]);
                  }
                }}
              >
                Send Manifest
              </Button>
              <Button
                disabled={!this.state.activeDeleteDrafts}
                style={{ marginTop: '15px', marginLeft: '15px' }}
                variant="outlined"
                color="primary"
                onClick={async (e) => {
                  this.setState({ ...this.state, openDeleteDrafts: true });
                }}
              >
                Delete Manifest
              </Button>
            </GridMUI>
            {this.state.rol.role === 'support' && (
              <GridMUI item>
                <TotalStatus totals={this.state.totalStatus}></TotalStatus>
              </GridMUI>
            )}
          </GridMUI>
        </GridMUI>
        <DialogForm
          // title={this.state.title}
          open={this.state.openDeleteDrafts}
          onClose={this.handleCloseDeleteDrafts}
        >
          <DeleteManifestDraftsView
            close={this.handleCloseDeleteDrafts}
            deletes={this.deleteManifestDrafts}
            manifests={this.state.selectedRows}
          ></DeleteManifestDraftsView>
        </DialogForm>
        <Houses
          company={
            this.state.profile.role === 'support' &&
            this.selectedClient &&
            this.selectedClient.value &&
            this.selectedClient.value !== ''
              ? this.selectedClient.value
              : null
          }
          history={this.props.history}
          row={this.rowHouse}
          FullScreenClose={this.FullScreenClose}
          awb={`${this.rowHouse.prefix}-${this.rowHouse.serialNumber}`}
          open2={this.state.openFullScreen}
          openDetails={this.openDetails}
          setRow={this.setRow}
          handleSetRowsStatus={this.handleSetRowsStatus}
        ></Houses>
        <DialogForm
          title={this.state.title}
          open={this.state.open}
          onClose={this.handleClose}
        >
          {this.type == 'FSQ' ? (
            <FSQ
              {...this.data}
              company={
                this.state.profile.role === 'support' &&
                this.selectedClient &&
                this.selectedClient.value &&
                this.selectedClient.value !== ''
                  ? this.selectedClient.value
                  : null
              }
            ></FSQ>
          ) : this.type == 'FSN' ? (
            <FSN
              {...this.data}
              company={
                this.state.profile.role === 'support' &&
                this.selectedClient &&
                this.selectedClient.value &&
                this.selectedClient.value !== ''
                  ? this.selectedClient.value
                  : null
              }
            ></FSN>
          ) : this.type == 'FRX' ? (
            <FRX {...this.data}></FRX>
          ) : this.type == 'Delete' ? (
            <DeleteManifestSupport {...this.data}></DeleteManifestSupport>
          ) : (
            <FDM
              {...this.data}
              company={
                this.state.profile.role === 'support' &&
                this.selectedClient &&
                this.selectedClient.value &&
                this.selectedClient.value !== ''
                  ? this.selectedClient.value
                  : null
              }
            ></FDM>
          )}
        </DialogForm>
        <Grid
          rows={this.state.rows}
          columns={[
            {
              name: 'number',
              title: '#',
              getCellValue: (row) => {
                return row.number;
              },
            },
            {
              name: 'awb',
              title: 'AWB',
              getCellValue: (row) => {
                return `${row.prefix}-${row.serialNumber}${
                  row.arrivalRef && row.type !== 'Consolidation'
                    ? '-' + row.arrivalRef
                    : ''
                }`;
              },
              setSelection: this.setSelection,
              openDetails: this.openDetails,
              setRow: this.setRow,
              state: this.state,
            },
            {
              name: 'username',
              title: 'Username',
              getCellValue: (row) => {
                return row.userCreated;
              },
            },
            {
              name: 'type',
              title: 'Type',
              getCellValue: (row) => {
                if (row.type == 'Simple') row.send = true;
                return row.type;
              },
            },
            {
              name: 'numberHouses',
              title: '# of HAWB´s',
              getCellValue: (row) => {
                if (!row.stats || row.type !== 'Consolidation') {
                  return 'N/A';
                }
                return row.stats.total;
              },
              rowHouse: this.rowHouse,
              awb: this.awb,
              FullScreenOpen: this.FullScreenOpen,
            },
            {
              name: 'statusSummary',
              title: 'Summary',
              getCellValue: (row) => (
                <StatsSummary
                  key={`${row.serialNumber}-${row.flightNumber}-${row.type}`}
                  row={row}
                ></StatsSummary>
              ),
            },
            {
              name: 'status',
              title: 'Status',
              getCellValue: (row) => {
                let wbl =
                  row.message2 &&
                  row.message2.WBL &&
                  row.status !== 'CANCELLED' &&
                  row.status !== 'SENT'
                    ? `WBL: ${row.message2.WBL.airportOrigin}/${row.message2.WBL.cargoDesc}/${row.message2.WBL.numPieces}/${row.message2.WBL.shipmentDesc}/${row.message2.WBL.weight}/${row.message2.WBL.weightCode}`
                    : null;
                let arr =
                  row.message2 &&
                  row.message2.ARR &&
                  row.status !== 'CANCELLED' &&
                  row.status !== 'SENT'
                    ? `ARR: 
                    ${row.message2.ARR.flightNumber}${row.message2.ARR.impCarrier}/${row.message2.ARR.arrivalDate}
                    `
                    : null;
                let err = null;
                if (
                  row.message2 &&
                  row.message2.ERR &&
                  row.status !== 'CANCELLED' &&
                  row.status !== 'SENT'
                ) {
                  err = '';
                  if (row.message2) {
                    for (const errObj of row.message2.ERR) {
                      err += `${errObj.errorCode} - ${errObj.errorText} `;
                    }
                  }
                }
                return `
                ${row.status || 'SENT'}
                ${
                  row.code &&
                  row.status !== 'CANCELLED' &&
                  row.status !== 'SENT'
                    ? row.code
                    : ''
                }
                ${
                  row.message &&
                  row.status !== 'CANCELLED' &&
                  row.status !== 'SENT'
                    ? row.message
                    : ''
                }
                ${wbl ? wbl : ''}
                ${arr ? arr : ''}
                ${err ? err : ''}
              `;
              },
              setSelection: this.setSelection,
              openDetails: this.openDetails,
              setRow: this.setRow,
              state: this.state,
              setStatus: setStatus,
            },
            {
              name: 'marksAndNumbers',
              title: 'Marks and Numbers',
              getCellValue: (row) => {
                if (row.type !== 'Consolidation') {
                  return row.marksAndNumbers || '';
                }
                return '';
              },
            },
            {
              name: 'fsn',
              title: 'Notification',
              getCellValue: (row) => {
                if (
                  row.notification.length &&
                  row.notification[0].docTranslated.CSN
                ) {
                  if (
                    row.notification[0].docTranslated.CSN.actionCode === '1C'
                  ) {
                    return `${FSNMessage(
                      row.notification[0].docTranslated.CSN.actionCode,
                      true
                    )}${
                      row.notification[0].docTranslated.CSN.entryType
                        ? ' Entry Type: ' +
                          entryType(
                            row.notification[0].docTranslated.CSN.entryType
                          )
                        : ''
                    }${
                      row.notification[0].docTranslated.CSN.entryNumber
                        ? ' Entry #: ' +
                          row.notification[0].docTranslated.CSN.entryNumber
                        : ''
                    }`;
                  } else {
                    return `${FSNMessage(
                      row.notification[0].docTranslated.CSN.actionCode,
                      true
                    )}`;
                  }
                } else {
                  return '';
                }
              },
            },
            {
              name: '1c',
              title: '1C',
              getCellValue: (row) => {
                return row.type === 'Consolidation' ? row['1C'] : 'N/A';
              },
            },
            {
              name: 'hold',
              title: 'AMS Hold',
              getCellValue: (row) => {
                return row.type === 'Consolidation' || row.AMSHold > 0
                  ? row.AMSHold
                  : 'N/A';
              },
            },
            {
              name: 'dateCreated',
              title: 'Created',
              getCellValue: (row) => {
                return parseInt(row.manifestCreated) !== 0
                  ? moment(parseInt(row.manifestCreated)).format(
                      'MM/DD/YYYY HH:mm:ss'
                    )
                  : '';
              },
            },
            {
              name: 'dateModified',
              title: 'Modified',
              getCellValue: (row) => {
                return parseInt(row.manifestEdited) !== 0
                  ? moment(parseInt(row.manifestEdited)).format(
                      'MM/DD/YYYY HH:mm:ss'
                    )
                  : '';
              },
            },
            {
              name: 'requestDate',
              title: 'Submitted',
              getCellValue: (row) => {
                return parseInt(row.requestDate) !== 0 && row.status !== 'DRAFT'
                  ? moment(parseInt(row.requestDate)).format(
                      'MM/DD/YYYY HH:mm:ss'
                    )
                  : '';
              },
            },
            {
              name: 'responseDate',
              title: 'Response Date',
              getCellValue: (row) => {
                return parseInt(row.responseDate) !== 0
                  ? moment(parseInt(row.responseDate)).format(
                      'MM/DD/YYYY HH:mm:ss'
                    )
                  : '';
              },
            },
            {
              name: 'options',
              title: 'Options',
              getCellValue: (row) => {
                let menuItemsArray =
                  this.state.rol.role === 'airline'
                    ? [
                        <ListItem
                          key={0}
                          primaryText="Clone Manifest"
                          disabled={row.type == 'Consolidation' ? true : false}
                          onClick={async (e) => {
                            if (await this.checkToken()) {
                              this.props.history.push(
                                `create-manifest?id=${row.lastId}&clone=true&status=${row.status}`
                              );
                            }
                          }}
                        />,
                        <ListItem
                          key={1}
                          primaryText="Create FSQ"
                          disabled={
                            row.status == 'SENDING' || row.status == 'DRAFT'
                              ? true
                              : false
                          }
                          onClick={async (e) => {
                            if (await this.checkToken()) {
                              this.type = 'FSQ';
                              await this.handleClickListItem(
                                'Create Freight Status Query(FSQ)',
                                row,
                                this.handleClose,
                                []
                              );
                            }
                          }}
                        />,
                        <ListItem
                          key={2}
                          primaryText="Create FSN"
                          disabled={
                            row.status == 'SENDING' || row.status == 'DRAFT'
                              ? true
                              : false
                          }
                          onClick={async (e) => {
                            if (await this.checkToken()) {
                              this.type = 'FSN';
                              await this.handleClickListItem(
                                'Create Freight Status Notification(FSN)',
                                row,
                                this.handleClose,
                                []
                              );
                            }
                          }}
                        />,
                        <ListItem
                          key={3}
                          primaryText="Create FDM"
                          disabled={
                            row.status == 'SENDING' ||
                            row.status == 'DRAFT' ||
                            row.type == 'Consolidation'
                              ? true
                              : false
                          }
                          onClick={async (e) => {
                            if (await this.checkToken()) {
                              this.type = 'FDM';
                              await this.handleClickListItem(
                                'Create Flight Departure Message(FDM)',
                                row,
                                this.handleClose,
                                []
                              );
                            }
                          }}
                        />,
                      ]
                    : [
                        <ListItem
                          key={0}
                          primaryText="Clone Manifest"
                          disabled={
                            (this.state.profile.role === 'shipper' &&
                              row.status !== 'DRAFT') ||
                            row.type == 'Consolidation'
                              ? true
                              : false
                          }
                          onClick={async (e) => {
                            if (await this.checkToken()) {
                              this.props.history.push(
                                `create-manifest?id=${row.lastId}&clone=true&status=${row.status}`
                              );
                            }
                          }}
                        />,
                        <ListItem
                          key={1}
                          primaryText="Create FSQ"
                          disabled={
                            this.state.profile.role === 'shipper' ||
                            row.status == 'SENDING' ||
                            row.status == 'DRAFT'
                              ? true
                              : false
                          }
                          onClick={async (e) => {
                            this.type = 'FSQ';
                            await this.handleClickListItem(
                              'Create Freight Status Query(FSQ)',
                              row,
                              this.handleClose,
                              []
                            );
                          }}
                        />,
                        <ListItem
                          key={2}
                          primaryText="Create ASN"
                          disabled={
                            this.state.profile.role === 'shipper' ||
                            row.status == 'SENDING' ||
                            row.status == 'DRAFT'
                              ? true
                              : false
                          }
                          onClick={async (e) => {
                            this.type = 'FSN';
                            await this.handleClickListItem(
                              'Create Arrival Status Notification (ASN)',
                              row,
                              this.handleClose,
                              []
                            );
                          }}
                        />,
                        <ListItem
                          key={3}
                          primaryText="Create FDM"
                          disabled={
                            this.state.profile.role === 'shipper' ||
                            row.status == 'SENDING' ||
                            row.status == 'DRAFT' ||
                            row.type == 'Consolidation'
                              ? true
                              : false
                          }
                          onClick={async (e) => {
                            this.type = 'FDM';
                            await this.handleClickListItem(
                              'Create Flight Departure Message(FDM)',
                              row,
                              this.handleClose,
                              []
                            );
                          }}
                        />,
                      ];

                if (this.state.rol.role === 'support') {
                  menuItemsArray.push(
                    <ListItem
                      key={4}
                      primaryText="Delete Manifest"
                      onClick={async (e) => {
                        this.type = 'Delete';
                        await this.handleClickListItem(
                          'Delete Manifest',
                          row,
                          this.handleClose,
                          []
                        );
                      }}
                    />
                  );
                }

                return (
                  <div>
                    <div className="fix-menu">
                      <MenuButton
                        key={row.prefix}
                        id="menu-button-1"
                        raised
                        style={{
                          backgroundColor: '#019ee1',
                          color: '#fff',
                        }}
                        menuItems={menuItemsArray}
                      >
                        Options
                      </MenuButton>
                    </div>
                  </div>
                );
              },
            },
            {
              name: 'actions',
              title: 'Actions',
              getCellValue: (row) => {
                return (
                  <SelectField
                    id="select-field"
                    value={row.sendAs}
                    disabled={row.status == 'SENT'}
                    menuItems={['Add', 'Change', 'Cancel']}
                    onChange={(e) => {
                      row.sendAs = e;
                      let data = [];
                      for (const x of this.state.selectedRows) {
                        if (
                          x.prefix === row.prefix &&
                          x.serialNumber === row.serialNumber &&
                          x.type === row.type
                        ) {
                          x.action = e;
                        }
                        data.push(x);
                      }
                      this.setState({ selectedRows: data });
                    }}
                  />
                );
              },
            },
          ]}
        >
          <RowDetailState />
          <SortingState
            defaultSorting={[{ columnName: 'product', direction: 'asc' }]}
          />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={[
              { columnName: 'status', filteringEnabled: true },
            ]}
            onFiltersChange={(v) => this.onSelection([])}
          />
          <IntegratedFiltering />
          <SelectionState
            selection={this.state.selection}
            onSelectionChange={this.onSelection}
          />
          <IntegratedSelection />
          <PagingState
            defaultCurrentPage={0}
            pageSize={100}
            onCurrentPageChange={(e) => {
              this.changeWindowScroll();
            }}
          />
          <IntegratedPaging />
          <Table
            columnExtensions={[
              { columnName: 'status', wordWrapEnabled: true },
              { columnName: 'fsn', wordWrapEnabled: true },
              { columnName: 'awb', width: '180' },
              { columnName: 'type', width: '100' },
              { columnName: 'marksAndNumbers', width: '160' },
              { columnName: '1c', width: '80' },
              { columnName: 'number', width: '55' },
            ]}
            containerComponent={ContainerTable}
            cellComponent={Cell}
          />

          <TableSelection showSelectAll cellComponent={selectCellComponent} />

          <TableColumnVisibility
            defaultHiddenColumnNames={[
              JSON.parse(localStorage.profile).role == 'support'
                ? 'username'
                : 'username',
            ]}
          />
          <TableFilterRow />
          <TableHeaderRow showSortingControls />
          <PagingPanel containerComponent={PagingContainer} />
        </Grid>
      </Fragment>
    );
  }
}
const ToggleCellComponent = ({ row, ...restProps }) => {
  const countHouses = (array) => {
    let x = array.filter((x) => x.hawbNumber != '');
    return x.length;
  };
  if (row.type === 'Consolidation')
    return (
      <TableRowDetail.ToggleCell
        style={{
          pointerEvents: 'none',
          opacity: 0.5,
        }}
      />
    );
  return <TableRowDetail.ToggleCell row={row} {...restProps} />;
};

const setStatus = (row) => {
  let status = row.status || 'SENT';
  let docType = row.docType || 'FRI';
  let code = row.code || null;
  let message = row.message || null;
  let message2 = row.message2 || null;
  let ARR = message2 && message2.ARR ? message2.ARR : null;
  let WBL = message2 && message2.WBL ? message2.WBL : null;
  let TXT = message2 && message2.TXT ? message2.TXT : null;
  let ERR = message2 && message2.ERR ? message2.ERR : null;
  let statusCodeDescription = [
    'RECORD NOT ON FILE',
    'RECORD IN DELETED STATUS',
    'BILL IS SPLIT - SEE LIST BELOW.',
    'NOT AUTHORIZED TO RECEIVE INFORMATION',
    'BILL INCOMPLETE - NOTIFICATIONS NOT SENT',
    'NO NOTIFICATIONS SENT TO REQUESTER',
    'NOTIFICATIONS RESENT',
    'BILL STATUS INFORMATION FOLLOWS',
    'ROUTING INFORMATION FOLLOWS',
    'NOMINATED AGENT',
    'CURRENT AIR WAYBILL INFORMATION ON FILE FOLLOWS',
  ];
  let icon =
    status == 'ACCEPTED' || status == 'SENT' || status == 'DRAFT' ? (
      <CheckCircleRounded
        style={{
          color:
            status == 'ACCEPTED'
              ? row.notification.length > 0 &&
                row.notification[0].docTranslated.CSN
                ? 'green'
                : '#FFE333'
              : status == 'DRAFT'
              ? 'gray'
              : 'red',
        }}
      />
    ) : (
      <CancelRounded style={{ color: 'red' }} />
    );
  let description =
    status == 'ACCEPTED' ? (
      TXT && Array.isArray(TXT) ? (
        TXT.map((value, key) => {
          return (
            <Fragment key={key}>
              {code} - {value.information}
              <br />
            </Fragment>
          );
        })
      ) : TXT && !Array.isArray(TXT) ? (
        <Fragment>
          {code} - {TXT.information}
          <br />
        </Fragment>
      ) : WBL || ARR ? (
        <Fragment>
          {code} -{' '}
          {WBL
            ? `WBL: 
          ${WBL.airportOrigin}/
            ${WBL.cargoDesc}/
            ${WBL.numPieces}/
            ${WBL.shipmentDesc}/
            ${WBL.weight}/
            ${WBL.weightCode}`
            : ``}
          <br />
          {ARR
            ? `ARR: 
            ${ARR.arrivalDate}/
            ${ARR.flightNumber}/
            ${ARR.impCarrier}`
            : ``}
        </Fragment>
      ) : (
        <div className="status-text-style">
          {code} - {message ? message : statusCodeDescription[parseInt(code)]}
        </div>
      )
    ) : ERR ? (
      ERR.map((value, key) => {
        return (
          <Fragment key={key}>
            {value.errorCode} - {value.errorText}
            <br />
          </Fragment>
        );
      })
    ) : (
      ''
    );
  return (
    <div>
      {icon}{' '}
      <div className="status-text-style">
        {status} ({docType})
      </div>
      <br />
      {status == 'SENT' ? '' : description}
    </div>
  );
};

export default Main;
