import React, { useState, useEffect } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import feathers from '../../../../services/feathers';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import GridMUI from '@material-ui/core/Grid';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Axios from 'axios';
import Mustache from 'mustache';
import html2pdf from 'html2pdf.js';
import { consolidationReport } from '../../../../constants/HTMLTemplates/PDFTemplates';
const env = process.env;
const useStyles = makeStyles({
  cardReport: {
    width: '100%',
    borderRadius: '5px',
    border: '1px solid #aaa',
    padding: '10px',
    color: 'rgb(8 5 5 / 54%) !important',
  },
  cardHeader: {
    fontWeight: 'bold',
    fontSize: '15px',
    borderBottom: '1px solid #aaa',
    marginBottom: 30,
  },
  cardBody: {},
});
const Report = ({ row, open, close }) => {
  const classes = useStyles();
  const [flightNumber, setFlightNumber] = useState('');
  const [airportOrigin, setAirportOrigin] = useState('');
  const [arrivalAirport, setArrivalAirport] = useState('');
  const [arrivalDate, setArrivalDate] = useState('');
  const [impCarrier, setImpCarrier] = useState('');
  const [housesHold, setHousesHold] = useState([]);
  const [consignee, setConsignee] = useState({});
  const [shipper, setShipper] = useState({});
  const [client, setClient] = useState('');
  const [data, setData] = useState({});
  async function start() {
    let result = null;
    let res = await Axios.get(
      env['REACT_APP_CLIENT'] + '/client2/' + row.user.company
    );
    if (res && res.data && res.data.name) {
      setClient(res.data.name);
    }
    try {
      result = await feathers.service('manifests').get({ _id: row.lastId });
    } catch (error) {
      console.log('error :>> ', error);
    }
    if (!result) {
      result = await feathers.service('docs-pending').get({ _id: row.lastId });
    }
    setFlightNumber(
      result.docOrigin.ARR && result.docOrigin.ARR.flightNumber
        ? result.docOrigin.ARR.flightNumber
        : ''
    );
    setConsignee(result.docOrigin.CNE || {});
    setShipper(result.docOrigin.SHP || {});
    setArrivalDate(
      result.docOrigin.ARR && result.docOrigin.ARR.arrivalDate
        ? result.docOrigin.ARR.arrivalDate
        : ''
    );
    setImpCarrier(
      result.docOrigin.ARR && result.docOrigin.ARR.impCarrier
        ? result.docOrigin.ARR.impCarrier
        : ''
    );
    setAirportOrigin(
      result.docOrigin.WBL && result.docOrigin.WBL.airportOrigin
        ? result.docOrigin.WBL.airportOrigin
        : ''
    );
    setArrivalAirport(
      result.docOrigin.CCL && result.docOrigin.CCL.arrivalAirport
        ? result.docOrigin.CCL.arrivalAirport
        : ''
    );

    let holds = await getHousesHold(
      (result.docOrigin.CNE && result.docOrigin.CNE.name) || '',
      (result.docOrigin.SHP && result.docOrigin.SHP.name) || ''
    );
    setData({
      ...data,
      prefix: row.prefix || '',
      serialNumber: row.serialNumber || '',
      total: row.stats.total || 0,
      submitted: row.stats.total - row.stats.draft || 0,
      rejected: row.stats.rejected || 0,
      released: row['1C'] || 0,
      AMSHold: row.AMSHold || 0,

      flightNumber:
        result.docOrigin.ARR && result.docOrigin.ARR.flightNumber
          ? result.docOrigin.ARR.flightNumber
          : '',
      arrivalAirport:
        result.docOrigin.CCL && result.docOrigin.CCL.arrivalAirport
          ? result.docOrigin.CCL.arrivalAirport
          : '',
      airportOrigin:
        result.docOrigin.WBL && result.docOrigin.WBL.airportOrigin
          ? result.docOrigin.WBL.airportOrigin
          : '',
      carrier:
        result.docOrigin.ARR && result.docOrigin.ARR.impCarrier
          ? result.docOrigin.ARR.impCarrier
          : '',
      arrivalDate:
        result.docOrigin.ARR && result.docOrigin.ARR.arrivalDate
          ? result.docOrigin.ARR.arrivalDate
          : '',
      client: (res && res.data && res.data.name) || '',
      Holds: holds,
    });
  }
  const getHousesHold = async (consignee, shipper) => {
    let result2 = await feathers.service('house-status').find({
      query: {
        serialNumber: row.serialNumber,
        prefix: row.prefix,
        AMSHold: { $nin: [0, undefined] },
        $sort: {
          lastRequestDate: -1,
        },
      },
    });
    let holds = [];
    for (const hold of result2) {
      holds.push({
        hawbNumber: hold.hawbNumber,
        submissionDate: moment(parseInt(hold.lastRequestDate)).format(
          'MM/DD/YYYY HH:mm:ss'
        ),
        responseDate: moment(parseInt(hold.lastResponseDate)).format(
          'MM/DD/YYYY HH:mm:ss'
        ),
        marksAndNumbers: hold.marksAndNumbers,
        arrivalDate: hold.arrivalDate,
        shipper: shipper,
        consignee: consignee,
      });
    }
    setHousesHold(result2);
    console.log('holds :>> ', holds);
    return holds;
  };
  const createReport = (template, information) =>
    (template && information && Mustache.render(template, information)) || '';
  const Print = async () => {
    const report = createReport(`${consolidationReport}`, data);
    html2pdf()
      .from(`<div style="padding: 5%; height: 100%;">${report}</div>`)
      .to('pdf')
      .save(`${data.prefix}-${data.serialNumber}`);
  };
  useEffect(() => {
    if (open) {
      start(true);
    }
  }, [open]);
  return (
    <Dialog
      onBackdropClick={close}
      onEscapeKeyDown={close}
      open={open}
      onClose={close}
      maxWidth={'lg'}
      fullWidth={true}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="max-width-dialog-title">
        MAWB: {data.prefix}-{data.serialNumber}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div className={classes.cardReport}>
            <div className={classes.cardHeader}>Customs Status</div>
            <div className={classes.cardBody}>
              <GridMUI container>
                <GridMUI item xs="2"></GridMUI>
                <GridMUI item xs="2">
                  MAWB
                </GridMUI>
                <GridMUI item xs="2">
                  Total HAWB Count
                </GridMUI>
                <GridMUI item xs="2">
                  HAWB Submitted
                </GridMUI>
                <GridMUI item xs="2">
                  HAWB Rejected
                </GridMUI>
                <GridMUI item xs="1">
                  1C
                </GridMUI>
                <GridMUI item xs="1">
                  AMS Hold
                </GridMUI>
              </GridMUI>
              <hr />
              <GridMUI container>
                <GridMUI item xs="2">
                  AMS Status:
                </GridMUI>
                <GridMUI item xs="2">
                  {data.prefix}-{data.serialNumber}
                </GridMUI>
                <GridMUI item xs="2">
                  {data.total}
                </GridMUI>
                <GridMUI item xs="2">
                  {data.submitted}
                </GridMUI>
                <GridMUI item xs="2">
                  {data.rejected}
                </GridMUI>
                <GridMUI item xs="1">
                  {data.released}
                </GridMUI>
                <GridMUI item xs="1">
                  {data.AMSHold}
                </GridMUI>
              </GridMUI>
            </div>
          </div>
          <br />
          <>
            <div className={classes.cardReport}>
              <div className={classes.cardHeader}>HAWB's Hold</div>
              <div className={classes.cardBody}>
                <GridMUI container>
                  <GridMUI item xs="2">
                    HAWB
                  </GridMUI>
                  <GridMUI item xs="2">
                    Submission Date
                  </GridMUI>
                  <GridMUI item xs="2">
                    Response Date
                  </GridMUI>
                  <GridMUI item xs="2">
                    Marks and Numbers
                  </GridMUI>
                  <GridMUI item xs="1">
                    Arrival Date
                  </GridMUI>
                  <GridMUI item xs="1">
                    Shipper
                  </GridMUI>
                  <GridMUI item xs="2">
                    Consignee
                  </GridMUI>
                </GridMUI>
                <hr />
                {data &&
                  data.Holds &&
                  data.Holds.map((hold) => (
                    <GridMUI
                      container
                      style={{
                        borderBottom: '1px solid #9a9a9a',
                      }}
                    >
                      <GridMUI item xs="2">
                        {hold.hawbNumber}
                      </GridMUI>
                      <GridMUI item xs="2">
                        {hold.submissionDate}
                      </GridMUI>
                      <GridMUI item xs="2">
                        {hold.responseDate}
                      </GridMUI>
                      <GridMUI item xs="2">
                        {hold.marksAndNumbers}
                      </GridMUI>
                      <GridMUI item xs="1">
                        {hold.arrivalDate}
                      </GridMUI>
                      <GridMUI item xs="1">
                        {hold.shipper}
                      </GridMUI>
                      <GridMUI item xs="2">
                        {hold.consignee}
                      </GridMUI>
                    </GridMUI>
                  ))}
              </div>
            </div>
            <br />
          </>

          <div className={classes.cardReport}>
            <div className={classes.cardHeader}>MAWB Information</div>
            <div className={classes.cardBody}>
              <GridMUI container>
                <GridMUI item xs="3">
                  Client
                </GridMUI>
                <GridMUI item xs="2">
                  Airport of Origin
                </GridMUI>
                <GridMUI item xs="2">
                  Airport of Arrival
                </GridMUI>
                <GridMUI item xs="2">
                  Carrier
                </GridMUI>
                <GridMUI item xs="1">
                  Flight No.
                </GridMUI>
                <GridMUI item xs="2">
                  Arrival Date
                </GridMUI>
              </GridMUI>
              <hr />
              <GridMUI container>
                <GridMUI item xs="3">
                  {data.client}
                </GridMUI>
                <GridMUI item xs="2">
                  {data.airportOrigin}
                </GridMUI>
                <GridMUI item xs="2">
                  {data.arrivalAirport}
                </GridMUI>
                <GridMUI item xs="2">
                  {data.carrier}
                </GridMUI>
                <GridMUI item xs="1">
                  {data.flightNumber}
                </GridMUI>
                <GridMUI item xs="2">
                  {data.arrivalDate}
                </GridMUI>
              </GridMUI>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={async (e) => {
            await Print();
          }}
          color="primary"
          variant="outlined"
        >
          Print
        </Button>
        <Button onClick={close} variant="outlined" color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default (props) => {
  let isUpdate = null;
  let [row, setRow] = useState({ ...props.row, progress: 0 });
  let [open, setOpen] = useState(false);
  const close = () => {
    setOpen(false);
  };
  // useEffect(() => {
  //   if (row.type === 'Consolidation') {
  //     if (row.stats) {
  //       let progressValue =
  //         (row.stats.rejected +
  //           row.stats.sent +
  //           row.stats.accepted +
  //           row.stats.canceled) /
  //         row.stats.total;

  //       setRow({
  //         ...row,
  //         progress: progressValue * 100,
  //       });
  //     }
  //   }
  // }, []);

  // feathers.service('manifests').on('cached', data => {
  //   isUpdate = data;
  // });

  // setInterval(() => {
  //   if (isUpdate) {
  //     let data = isUpdate;

  //     if (data.type === 'Consolidation' && row.type === 'Consolidation') {
  //       if (
  //         data.prefix === row.prefix &&
  //         data.serialNumber == row.serialNumber
  //       ) {
  //         if (data.body) {
  //           let progressValue = 1;
  //           if (row.stats) {
  //             progressValue =
  //               (row.stats.rejected +
  //                 row.stats.sent +
  //                 row.stats.accepted +
  //                 row.stats.canceled) /
  //               row.stats.total;
  //           } else {
  //             progressValue =
  //               (data.body.rejected +
  //                 data.body.sent +
  //                 data.body.accepted +
  //                 data.body.canceled) /
  //               data.body.total;
  //           }
  //           setRow({
  //             ...row,
  //             type: data.type,
  //             stats: data.body,
  //             progress: progressValue * 100
  //           });
  //         }
  //       }
  //     }

  //     isUpdate = null;
  //   }
  // }, 1000);

  if (row.type === 'Consolidation') {
    if (row.stats) {
      return (
        <>
          <Report row={row} open={open} close={close} />
          <a
            style={{ fontSize: '10px', cursor: 'pointer' }}
            onClick={async (e) => {
              setOpen(true);
            }}
          >
            Rejected: {row.stats.rejected} <br />
            Sent: {row.stats.sent} <br />
            Accepted: {row.stats.accepted} <br />
            Cancelled: {row.stats.canceled} <br />
            Draft:{row.stats.draft}
          </a>
          {row.stats.draft ? (
            <LinearProgress variant="determinate" value={row.progress} />
          ) : null}
        </>
      );
    }
  }
  return '';
};
