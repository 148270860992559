const inclution = (val = '', fil = '') =>
  val && val.toLowerCase().includes(fil.toLowerCase());

export const externalFilters = (data = [], filters = {}) => {
  if (filters) {
    let res = JSON.parse(JSON.stringify(data));
    const { split, airline, usPortOfArrival: port, flightNumber } = filters;

    if (airline) {
      res = res.filter((it) => {
        const { flightInfo } = it;
        const Airline = flightInfo ? flightInfo['Airline'] : '';
        return inclution(Airline, airline) && it;
      });
    }

    if (flightNumber) {
      const filterByFlight = (array) =>
        array.filter(({ arrivedFlight: AF }) => AF && AF.includes(flightNumber))
          .length;

      res = res.filter((it) => {
        const { arrivalStatus: arrival, transferStatus: transfer } = it;
        const match = filterByFlight(arrival) || filterByFlight(transfer);
        return match && it;
      });
    }

    if (port) {
      res = res.filter((it) => {
        const { flightInfo } = it;
        var USPort = flightInfo ? flightInfo['1st US Port of Arrival'] : '';
        return inclution(USPort, port) && it;
      });
    }

    if (split) res = res.filter((it) => it.isSplit);

    return res;
  }
  return data;
};

const getMax = (array = []) => Math.max.apply(null, array) || 0;

export const getHeight = ({
  arrivalStatus,
  notificationSummary,
  flightInfo,
  additionalInfo,
  statusNotification,
  errorSummary,
}) => {
  const qtys = [];
  let h = 25;
  if (arrivalStatus) qtys.push(arrivalStatus.length || 0);

  if (notificationSummary) qtys.push(notificationSummary.length || 0);

  if (flightInfo) {
    const keys = [];
    for (const key in flightInfo) {
      if (Object.hasOwnProperty.call(flightInfo, key)) {
        const value = flightInfo[key];
        if (key === 'FSC10' && value) keys.push(key);

        const valid = [
          'Airline',
          '1st US Port of Arrival',
          'Flight #',
          'Arrival Date',
          'Transfer To',
        ];
        if (valid.includes(key)) keys.push(key);
      }
    }
    qtys.push(keys.length || 0);
  }

  if (Array.isArray(errorSummary)) {
    errorSummary.forEach(({ errorCodes }) => {
      if (Array.isArray(errorCodes)) qtys.push(errorCodes.length + 1);
    });
  }

  if (additionalInfo) qtys.push(additionalInfo.length || 0);

  if (statusNotification) qtys.push(statusNotification.length || 0);

  const max = getMax(qtys.filter((num) => num > 0));

  if (max > 1) return h * max;

  return 2 * h;
};
