import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  AWBHistory,
  CreateExpress,
  CreateManifest,
  CreateNotification,
  CreateQuery,
  Dashboard,
  ManifestDetails,
  RequestQuery,
  RequestFSN,
  ResponseQuery,
  ResponseFSN,
  SendDocManifest,
  Transactions,
  UploadDoc2,
  UploadDocFlatFile,
  UploadDocFlatFile2,
  IncomingFiles,
  Suscription,
  TransactionsSummary,
  Report1C,
  FSNHistory,
} from './views';
import { Profile, User, UserList } from './views/user';
import { Client, ClientList } from './views/client';
import { Partnership, PartnershipList } from './views/partnership';
import {
  SystemNotification,
  SystemNotificationsList,
} from './views/systemNotifications';

export default (props) => {
  const { setLogged, reload } = props;
  const profile = localStorage.profile ? JSON.parse(localStorage.profile) : {};
  return (
    <Switch>
      <Route path="/login" exact render={() => <Redirect to="/" />} />
      {profile.role === 'fsn' ? (
        <Route
          path="/"
          exact
          render={(props) => (
            <Transactions {...props} setLogged={setLogged} reload={reload} />
          )}
        />
      ) : (
        <Route
          path="/"
          exact
          render={(props) => (
            <Dashboard {...props} setLogged={setLogged} reload={reload} />
          )}
        />
      )}
      {profile.role !== 'fsn' ? (
        <Route
          path="/awb-history"
          exact
          render={(props) => (
            <AWBHistory {...props} setLogged={setLogged} reload={reload} />
          )}
        />
      ) : null}
      {/* {profile.role !== 'fsn' ? ( */}
      <Route
        path="/fsn-history"
        exact
        render={(props) => (
          <FSNHistory {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      {/* ) : null*/}
      <Route
        path="/manifest-details/:_id/:status"
        exact
        render={(props) => (
          <ManifestDetails {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/profile"
        exact
        render={(props) => (
          <Profile {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/reports/transactions"
        exact
        render={(props) => (
          <Transactions {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/reports/report1c"
        exact
        render={(props) => (
          <Report1C {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/upload2"
        exact
        render={(props) => (
          <UploadDoc2 {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/upload-flatfile"
        exact
        render={(props) => (
          <UploadDocFlatFile {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/upload-flatfile2"
        exact
        render={(props) => (
          <UploadDocFlatFile2
            {...props}
            setLogged={setLogged}
            reload={reload}
          />
        )}
      />
      <Route
        path="/create-manifest"
        exact
        render={(props) => (
          <CreateManifest {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/create-express"
        exact
        render={(props) => (
          <CreateExpress {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/send-manifest"
        exact
        render={(props) => (
          <SendDocManifest {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/create-query"
        exact
        render={(props) => (
          <CreateQuery {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/request-query"
        exact
        render={(props) => (
          <RequestQuery {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/response-query"
        exact
        render={(props) => (
          <ResponseQuery {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/create-notification/:id?"
        exact
        render={(props) => (
          <CreateNotification
            {...props}
            setLogged={setLogged}
            reload={reload}
          />
        )}
      />
      <Route
        path="/request-notification"
        exact
        render={(props) => (
          <RequestFSN {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/response-notification"
        exact
        render={(props) => (
          <ResponseFSN {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      <Route
        path="/incoming-files"
        exact
        render={(props) => (
          <IncomingFiles {...props} setLogged={setLogged} reload={reload} />
        )}
      />
      {profile.role === 'admin' || profile.role === 'support' ? (
        <Route
          path="/users/list"
          exact
          render={(props) => (
            <UserList {...props} setLogged={setLogged} reload={reload} />
          )}
        />
      ) : null}
      {profile.role === 'admin' || profile.role === 'support' ? (
        <Route
          path="/user/:id?/:company?"
          exact
          render={(props) => (
            <User {...props} setLogged={setLogged} reload={reload} />
          )}
        />
      ) : null}
      {profile.role === 'admin' || profile.role === 'support' ? (
        <Route
          path="/client/:id?"
          exact
          render={(props) => (
            <Client {...props} setLogged={setLogged} reload={reload} />
          )}
        />
      ) : null}
      {profile.role === 'admin' || profile.role === 'support' ? (
        <Route
          path="/suscription/:company/:id?"
          exact
          render={(props) => (
            <Suscription {...props} setLogged={setLogged} reload={reload} />
          )}
        />
      ) : null}
      {profile.role === 'support' ? (
        <Fragment>
          <Route
            path="/clients/list"
            exact
            render={(props) => (
              <ClientList {...props} setLogged={setLogged} reload={reload} />
            )}
          />
          <Route
            path="/partnership/:id?"
            exact
            render={(props) => (
              <Partnership {...props} setLogged={setLogged} reload={reload} />
            )}
          />
          <Route
            path="/partnerships/list"
            exact
            render={(props) => (
              <PartnershipList
                {...props}
                setLogged={setLogged}
                reload={reload}
              />
            )}
          />
          <Route
            path="/notification/:id?"
            exact
            render={(props) => (
              <SystemNotification
                {...props}
                setLogged={setLogged}
                reload={reload}
              />
            )}
          />
          <Route
            path="/notifications/list"
            exact
            render={(props) => (
              <SystemNotificationsList
                {...props}
                setLogged={setLogged}
                reload={reload}
              />
            )}
          />
          <Route
            path="/reports/transactions-summary"
            exact
            render={(props) => (
              <TransactionsSummary
                {...props}
                setLogged={setLogged}
                reload={reload}
              />
            )}
          />
        </Fragment>
      ) : null}
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};
