import {
  Card,
  CardText,
  Cell,
  Grid,
  Switch,
  Button,
  CardTitle,
  List,
  ListItem,
  DialogContainer,
  Subheader,
} from 'react-md';
import {
  CargoDetails,
  Consignee,
  FlightDetails,
  PermitProceed,
  Shipper,
  SplitIndicator,
  TransferDetails,
} from '../../components/documents/manifest';
import { Button as BTN } from '@material-ui/core';
import React, { useState, Fragment, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import '../style.css';
import feathers from '../../services/feathers';
import ShowDetailsManifest from './ShowDetailsManifest';
import Axios from 'axios';
import qs from 'query-string';
const env = process.env;

export default (props) => {
  let form;
  let impC = React.createRef();
  let tOp = React.createRef();
  let airO = React.createRef();
  let arrAirport = React.createRef();
  let prefixRef = React.createRef();
  let sNRef = React.createRef();
  let shpEmail = React.createRef();
  let shpPhoneNumber = React.createRef();
  let shpPostalCode = React.createRef();
  let cneEmail = React.createRef();
  let cnePhoneNumber = React.createRef();
  let cnePostalCode = React.createRef();
  let hawbRef = React.createRef();
  let cneCountryRef = React.createRef();
  let shpCountryRef = React.createRef();
  let cargoCountryRef = React.createRef();
  let cargoCurrencyRef = React.createRef();
  let flightNumberRef = React.createRef();
  let destinationAirportRef = React.createRef();
  let arrivalRefRef = React.createRef();
  let weightCodeRef = React.createRef();
  let transferstatusIdRef = React.createRef();
  let destinationAirportRefT = React.createRef();
  let bondedCarrierIdRef = React.createRef();
  let premisesIdRef = React.createRef();
  let onwardCarrierRef = React.createRef();
  let inbondControlNumRef = React.createRef();
  let pkTrackingIdRef = React.createRef();
  let weightCodeCargoRef = React.createRef();
  let email = JSON.parse(localStorage.profile).email;
  const [profile] = useState(JSON.parse(localStorage.profile));
  // Autocompletes
  const [autocompletes, setAutocompletes] = useState({
    originGoods: '',
    shpCountry: '',
    cneCountry: '',
  });
  const [autocompletesCurr, setAutocompletesCurr] = useState({});
  // Terminal Operator
  const [autocompleteTerminal, setAutocompleteTerminal] = useState({});
  // Importing Carrier
  const [autocompleteImpCarrier, setAutocompleteImpCarrier] = useState({});
  // Airline Transfer
  const [
    autocompleteAirlineTransfer,
    setAutocompleteAirlineTransfer,
  ] = useState({});
  // Airport Origin
  const [autocompleteAirportOrigin, setAutocompleteAirportOrigin] = useState(
    {}
  );
  // Destination Airport
  const [
    autocompleteDestinationAirport,
    setAutocompleteDestinationAirport,
  ] = useState({});
  // Destination Airport Transfer
  const [
    autocompleteDestinationAirportTransfer,
    setAutocompleteDestinationAirportTransfer,
  ] = useState({});
  // Arrival Airport
  const [autocompleteArrivalAirport, setAutocompleteArrivalAirport] = useState(
    {}
  );
  // Onward Carrier
  const [autocompletesAirArr, setAutocompletesAirArr] = useState({});
  const [autocompleteTransferAirOC, setAutocompleteTransferAirOC] = useState(
    {}
  );
  const [autocompletesTransferFIRMS, setAutocompletesTransferFIRMS] = useState(
    {}
  );
  const [bondedPremisesType, setBondedPremisesType] = useState('Airline');

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      await checkToken();
      let params = qs.parse(props.location.search);
      if (params && params.id && params.clone) {
        let params = qs.parse(props.location.search);
        let manifest = null;
        if (params.status == 'DRAFT') {
          manifest = await feathers.service('docs-pending').get(params.id);
        } else {
          manifest = await feathers.service('manifests').get(params.id);
        }
        let type = 'Simple';
        setSimple(true);
        setHouse(false);
        if (
          (manifest.docOrigin.AWB.hawbNumber &&
            manifest.docOrigin.AWB.hawbNumber != '' &&
            manifest.docOrigin.AWB.consolidationId &&
            manifest.docOrigin.AWB.consolidationId == '') ||
          (manifest.docOrigin.AWB.hawbNumber &&
            manifest.docOrigin.AWB.hawbNumber != '' &&
            !manifest.docOrigin.AWB.consolidationId)
        ) {
          type = 'House';
          setAwb({
            ...awb,
            prefix: manifest.docOrigin.AWB.prefix,
            serialNumber: manifest.docOrigin.AWB.serialNumber,
          });
          setSimple(false);
          setHouse(true);
        }
        if (
          manifest.docOrigin.AWB.consolidationId &&
          manifest.docOrigin.AWB.consolidationId == 'M'
        ) {
          type = 'Master';
          setSimple(false);
          setHouse(false);
        }
        setAws(type);
        if (manifest.docOrigin.CCL) {
          setCcl(manifest.docOrigin.CCL);
          searchAirports(manifest.docOrigin.CCL.arrivalAirport, 'arrival');
          searchAirlines(manifest.docOrigin.CCL.terminalOp, 'Terminal');
        }
        if (manifest.docOrigin.marksAndNumbers) {
          setMarksAndNumbers(manifest.docOrigin.marksAndNumbers);
        }
        if (manifest.docOrigin.ARR) {
          setArr(manifest.docOrigin.ARR);
          searchAirlines(manifest.docOrigin.ARR.impCarrier, 'impCarrier');

          if (
            manifest.docOrigin.ARR.arrivalRef &&
            manifest.docOrigin.ARR.arrivalRef != ''
          ) {
            setSplit(true);
          }
        }
        if (manifest.docOrigin.WBL) {
          setWbl(manifest.docOrigin.WBL);
          searchAirports(manifest.docOrigin.WBL.airportOrigin, 'origin');
          if (
            manifest.docOrigin.WBL.destinationAirport &&
            manifest.docOrigin.WBL.destinationAirport != ''
          ) {
            setPermit(true);
            searchAirports(
              manifest.docOrigin.WBL.destinationAirport,
              'destination'
            );
          }
        }
        if (manifest.docOrigin.AGT) {
          setAgt(manifest.docOrigin.AGT);
        }
        await fillParty(manifest, 'SHP');
        await fillParty(manifest, 'CNE');
        if (manifest.docOrigin.TRN) {
          setTrn(manifest.docOrigin.TRN);
          searchAirports(
            manifest.docOrigin.TRN.destinationAirport,
            'destinationTransfer'
          );
          searchAirlines(manifest.docOrigin.CCL.terminalOp, 'Terminal');
          if (
            manifest.docOrigin.TRN.destinationAirport &&
            manifest.docOrigin.TRN.destinationAirport != ''
          ) {
            setTransfer(true);
          }
          if (manifest.docOrigin.TRN.premisesId) {
            searchAirlines(
              manifest.docOrigin.TRN.premisesId,
              'airlineTransfer'
            );
            searchTransferFIRMS(manifest.docOrigin.TRN.premisesId);
          }

          if (
            manifest.docOrigin.TRN.onwardCarrier ||
            manifest.docOrigin.TRN.bondedCarrierId
          ) {
            setTransferType('outAirport');
            searchAirlines(
              manifest.docOrigin.TRN.onwardCarrier,
              'onwardCarrier'
            );
          }
        }
        if (manifest.docOrigin.CSD) {
          setCsd(manifest.docOrigin.CSD);
          getCountries(manifest.docOrigin.CSD.originGoods, 'CSD');
          currencies(manifest.docOrigin.CSD.currencyCode);
        }
        if (manifest.docOrigin.FDA) {
          setFda(manifest.docOrigin.FDA);
        }
      }
    }
    tttt();
  }, []);

  const fillParty = async ({ docOrigin }, segment) => {
    if (docOrigin && ['SHP', 'CNE'].includes(segment) && docOrigin[segment]) {
      const newData = { ...docOrigin[segment] };
      getCountries(newData.countryCode, segment);

      const res = await feathers.service('party').find({
        query: {
          search: newData.name,
          type: segment === 'SHP' ? 'shipper' : 'consignee',
        },
      });

      if (res && res.length) {
        newData.email = res[0].email;
      }

      if (segment === 'SHP') setShp(newData);
      else if (segment === 'CNE') setCne(newData);
    }
  };

  const getCountries = async (s, segment) => {
    let countries2 = await feathers.service('countries').find({
      query: {
        code: s,
      },
    });
    let countries = countries2.map((value) => ({
      ...value,
      find: value.code + ' - ' + value.name,
    }));
    if (countries.length) {
      if (segment === 'CSD') {
        setAutocompletes({ ...autocompletes, originGoods: countries[0].find });
        setCountries(countries);
      } else if (segment === 'SHP') {
        if (s) {
          setAutocompletesShp({
            ...autocompletesShp,
            shpCountry: countries[0].find,
          });
          setCountriesShp(countries);
        }
      } else if (segment === 'CNE') {
        if (s) {
          setAutocompletesCne({
            ...autocompletesCne,
            cneCountry: countries[0].find,
          });
          setCountriesCne(countries);
        }
      }
    }
  };

  const currencies = async (query) => {
    let currency = await feathers.service('currencies').find({
      query: {
        $curr: query,
      },
    });
    if (currency.length > 0) {
      let curr = [];

      curr = currency.length
        ? currency.map((value) => ({
            ...value.currency,
            find: value.code + ' - ' + value.name,
          }))
        : null;
      if (curr.length) {
        setAutocompletesCurr({
          ...autocompletesCurr,
          currencyCode: curr[0].find,
        });
        setCurrency(curr);
      }
    }
  };

  const searchAirlines = async (query, type) => {
    let response = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });
    if (response.length > 0) {
      let airline = response.map((value) => ({
        ...value,
        find: `${value.iata} - ${value.name} - ${value.icao} - ${value.country}`,
        txt: value.iata === '' ? value.icao : value.iata,
      }));
      if (type == 'Terminal') {
        setAutocompleteTerminal({
          ...autocompleteTerminal,
          terminalOp: airline[0].find,
        });
      }
      if (type == 'impCarrier') {
        setAutocompleteImpCarrier({
          ...autocompleteImpCarrier,
          impCarrier: airline[0].find,
        });
      }
      if (type == 'airlineTransfer') {
        setAutocompleteAirlineTransfer({
          ...autocompleteAirlineTransfer,
          premisesId: airline[0].find,
        });
      }
      if (type == 'onwardCarrier') {
        setAutocompleteTransferAirOC({
          ...autocompleteTransferAirOC,
          onwardCarrier: airline[0].find,
        });
      }
    } else {
      if (type == 'Terminal') {
        setAutocompleteTerminal({ ...autocompleteTerminal, terminalOp: query });
      }
      if (type == 'impCarrier') {
        setAutocompleteImpCarrier({
          ...autocompleteImpCarrier,
          impCarrier: query,
        });
      }
      if (type == 'airlineTransfer') {
        setAutocompleteAirlineTransfer({
          ...autocompleteAirlineTransfer,
          premisesId: query,
        });
      }
      if (type == 'onwardCarrier') {
        setAutocompleteTransferAirOC({
          ...autocompleteTransferAirOC,
          onwardCarrier: query,
        });
      }
    }
  };

  const searchAirports = async (query, type) => {
    let response = [];
    if (
      type == 'origin' ||
      type == 'destination' ||
      type == 'destinationTransfer'
    ) {
      response = await feathers.service('airports').find({
        query: {
          $agregation: {},
          $params: query,
        },
      });
    }
    if (type == 'arrival') {
      response = await feathers.service('airports').find({
        query: {
          $agregation: {},
          $ccl: query,
        },
      });
    }

    if (response.length > 0) {
      let airport = response.map((value) => ({
        ...value,
        find: `${value.iata} - ${value.name} - ${value.icao} - ${value.country} - ${value.city}`,
        txt: value.iata === 'N' ? value.icao : value.iata,
      }));

      if (type == 'origin') {
        setAutocompleteAirportOrigin({
          ...autocompleteAirportOrigin,
          airportOrigin: airport[0].find,
        });
      }
      if (type == 'destination') {
        setAutocompleteDestinationAirport({
          ...autocompleteDestinationAirport,
          destinationAirport: airport[0].find,
        });
      }
      if (type == 'destinationTransfer') {
        setAutocompleteDestinationAirportTransfer({
          ...autocompleteDestinationAirportTransfer,
          destinationAirport: airport[0].find,
        });
      }
      if (type == 'arrival') {
        setAutocompleteArrivalAirport({
          ...autocompleteArrivalAirport,
          arrivalAirport: airport[0].find,
        });
      }
    } else {
      if (type == 'origin') {
        setAutocompleteAirportOrigin({
          ...autocompleteAirportOrigin,
          airportOrigin: query,
        });
      }
      if (type == 'destination') {
        setAutocompleteDestinationAirport({
          ...autocompleteDestinationAirport,
          destinationAirport: query,
        });
      }
      if (type == 'destinationTransfer') {
        setAutocompleteDestinationAirportTransfer({
          ...autocompleteDestinationAirportTransfer,
          destinationAirport: query,
        });
      }
      if (type == 'arrival') {
        setAutocompleteArrivalAirport({
          ...autocompleteArrivalAirport,
          arrivalAirport: query,
        });
      }
    }
  };
  const searchTransferFIRMS = async (query) => {
    let cbpcodes = await feathers.service('firms').find({
      query: {
        $agregation: {},
        $firms: query,
      },
    });

    if (cbpcodes.length > 0) {
      let firms2 = [];

      firms2 = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.codes.name +
              ' - ' +
              value.codes.code +
              ' - ' +
              value.codes.city,
            code: value.codes.code,
          }))
        : null;

      setAutocompletesTransferFIRMS({
        ...autocompletesTransferFIRMS,
        premisesId: firms2[0].find,
      });
    } else {
      setAutocompletesTransferFIRMS({
        ...autocompletesTransferFIRMS,
        premisesId: query,
      });
    }
  };

  const searchCCL = async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $ccl: query,
      },
    });

    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt: value.iata === 'N' ? value.icao : value.iata,
          }))
        : null;
      setAutocompletesAirArr({
        ...autocompletesAirArr,
        arrivalAirport: ccl[0].find,
      });
    } else {
      setAutocompletesAirArr({
        ...autocompletesAirArr,
        arrivalAirport: query,
      });
    }
  };
  const [cleanAutocomplete, setCleanAutocomplete] = useState(false);
  const [allCodes, setAllCodes] = useState([]);
  const [usCodes, setUsCodes] = useState([]);
  const [shippersList, setShippers] = useState([]);
  const [consigneeList, setConsignees] = useState([]);
  const [airlines, setAirlines] = useState([]);
  const [terminal, setTerminal] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesShp, setCountriesShp] = useState([]);
  const [countriesCne, setCountriesCne] = useState([]);
  const [hts, setHts] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [loading, setLoading] = useState(false);
  const [, , fetchDoc] = useApi('ccapi', 'documents2', undefined, 'post');
  const [, , fetchDeleteManifest] = useApi(
    'ccapi',
    'documents/deletemanifest',
    {},
    'post'
  );
  const [, , fetchClient] = useApi('client', 'client', null, 'get');
  const [permit, setPermit] = useState(false);
  const [split, setSplit] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [simple, setSimple] = useState(false);
  const [house, setHouse] = useState(false);
  const [ccl, setCcl] = useState({ arrivalAirport: '', terminalOp: '' });
  const [awb, setAwb] = useState({
    consolidationId: '',
    prefix: '',
    serialNumber: '',
    hawbNumber: '',
    pkTrackingId: '',
  });

  const [autocompletesShp, setAutocompletesShp] = useState({ shpCountry: '' });
  const [autocompletesCne, setAutocompletesCne] = useState({ cneCountry: '' });
  const [wbl, setWbl] = useState({
    componentId: 'WBL',
    airportOrigin: '',
    numPieces: '',
    weightCode: '',
    weight: '',
    cargoDesc: '',
    destinationAirport: '',
    arrivalDatePTPAirport: '',
  });
  const [arr, setArr] = useState({
    componentId: 'ARR',
    impCarrier: '',
    flightNumber: '',
    arrivalDate: '',
    brdPieceCnt: '',
    weightCode: '',
    weight: '',
    arrivalRef: '',
  });
  const [agt, setAgt] = useState({ participantCode: '' });
  const [shp, setShp] = useState({
    componentId: 'SHP',
    name: '',
    address: '',
    city: '',
    state: '',
    countryCode: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
  });
  const [cne, setCne] = useState({
    componentId: 'CNE',
    name: '',
    address: '',
    city: '',
    state: '',
    countryCode: '',
    postalCode: '',
    phoneNumber: '',
    email: '',
  });
  const [trn, setTrn] = useState({
    destinationAirport: '',
    transferstatusId: '',
    bondedCarrierId: '',
    onwardCarrier: '',
    premisesId: '',
    inbondControlNum: '',
  });
  const [csd, setCsd] = useState({
    originGoods: '',
    declaredValue: '',
    currencyCode: '',
    hazmatCode: '',
  });
  const [fda, setFda] = useState({ lineId: '' });
  const [aws, setAws] = useState('Master');
  const [marksAndNumbers, setMarksAndNumbers] = useState('');
  const [transferType, setTransferType] = useState('inAirport');

  useEffect(() => {
    setLoading(false);
  }, [loading]);

  useEffect(() => {
    getDefaultData();
  }, []);

  const getDefaultData = async () => {
    let company = JSON.parse(localStorage.profile).company;
    let [res, err] = await fetchClient({ params: [company] });
    if (res) {
      if (res.awbPrefix) {
        setAwb({ ...awb, prefix: res.awbPrefix });
        awb.prefix = res.awbPrefix;
      }
      if (res.agentCode) setAgt({ ...agt, participantCode: res.agentCode });
    }
  };

  const validateInputs = async () => {
    if (
      prefixRef.current.value.length >= 3 &&
      prefixRef.current.value.match(/^[a-zA-Z0-9]+$/)
    ) {
      prefixRef.current.setCustomValidity('');
    } else {
      prefixRef.current.setCustomValidity(
        'Must be 3 alphanumeric characters long'
      );
    }

    if (
      sNRef.current.value.length >= 8 &&
      sNRef.current.value > 0 &&
      sNRef.current.value.match(/^[0-9]+$/)
    ) {
      sNRef.current.setCustomValidity('');
    } else {
      sNRef.current.setCustomValidity('Must be 8 positive integer numbers');
    }

    if (house) {
      if (
        hawbRef.current.value.length >= 1 &&
        hawbRef.current.value.match(/^[a-zA-Z0-9]+$/)
      ) {
        hawbRef.current.setCustomValidity('');
      } else {
        hawbRef.current.setCustomValidity(
          'Must be 1 - 12 alphanumeric characters long'
        );
      }
    }

    if (impC.current.value.length <= 3) {
      impC.current.setCustomValidity('');
    } else {
      impC.current.setCustomValidity('Invalid data');
    }
    if (flightNumberRef.current !== null) {
      if (flightNumberRef.current.value.length > 0) {
        if (
          flightNumberRef.current.value.length > 2 &&
          flightNumberRef.current.value > 0 &&
          flightNumberRef.current.value.match(/^[0-9]+$/)
        ) {
          flightNumberRef.current.setCustomValidity('');
        } else {
          flightNumberRef.current.setCustomValidity('Invalid data');
        }
      }
    }
    if (tOp.current.value.length <= 3) {
      tOp.current.setCustomValidity('');
    } else {
      tOp.current.setCustomValidity('Invalid data');
    }

    if (airO.current.value.length <= 3) {
      airO.current.setCustomValidity('');
    } else {
      airO.current.setCustomValidity('Invalid data');
    }

    if (arrAirport.current.value.length <= 3) {
      arrAirport.current.setCustomValidity('');
    } else {
      arrAirport.current.setCustomValidity('Invalid data');
    }

    if (
      shpEmail.current.value.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      ) ||
      shpEmail.current.value.length === 0
    ) {
      shpEmail.current.setCustomValidity('');
    } else {
      shpEmail.current.setCustomValidity('Invalid email');
    }
    if (
      shpPhoneNumber.current.value.match(/^[a-zA-Z0-9]+$/) ||
      shpPhoneNumber.current.value.length === 0
    ) {
      shpPhoneNumber.current.setCustomValidity('');
    } else {
      shpPhoneNumber.current.setCustomValidity(
        'Must be 1 - 14 alphanumeric characters long'
      );
    }
    if (
      shpPostalCode.current.value.match(/^[a-zA-Z0-9]+$/) ||
      shpPostalCode.current.value.length === 0
    ) {
      shpPostalCode.current.setCustomValidity('');
    } else {
      shpPostalCode.current.setCustomValidity(
        'Must be 1 - 9 alphanumeric characters long'
      );
    }

    if (shpCountryRef.current.value.length <= 2) {
      shpCountryRef.current.setCustomValidity('');
    } else {
      shpCountryRef.current.setCustomValidity('Invalid Country Code');
    }

    if (
      cneEmail.current.value.match(
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      ) ||
      cneEmail.current.value.length === 0
    ) {
      cneEmail.current.setCustomValidity('');
    } else {
      cneEmail.current.setCustomValidity('Invalid email');
    }

    if (
      cnePhoneNumber.current.value.match(/^[a-zA-Z0-9]+$/) ||
      cnePhoneNumber.current.value.length === 0
    ) {
      cnePhoneNumber.current.setCustomValidity('');
    } else {
      cnePhoneNumber.current.setCustomValidity(
        'Must be 1 - 14 alphanumeric characters long'
      );
    }

    if (
      cnePostalCode.current.value.match(/^[a-zA-Z0-9]+$/) ||
      cnePostalCode.current.value.length === 0
    ) {
      cnePostalCode.current.setCustomValidity('');
    } else {
      cnePostalCode.current.setCustomValidity(
        'Must be 1 - 9 alphanumeric characters long'
      );
    }

    if (cneCountryRef.current.value.length <= 2) {
      cneCountryRef.current.setCustomValidity('');
    } else {
      cneCountryRef.current.setCustomValidity('Invalid Country Code');
    }
    if (weightCodeCargoRef.current !== null) {
      if (weightCodeCargoRef.current.value.length >= 1) {
        weightCodeCargoRef.current.setCustomValidity('');
      } else {
        weightCodeCargoRef.current.setCustomValidity('Choose one');
      }
    }

    if (cargoCountryRef.current && cargoCountryRef.current.value !== null) {
      if (cargoCountryRef.current.value.length <= 2) {
        cargoCountryRef.current.setCustomValidity('');
      } else {
        cargoCountryRef.current.setCustomValidity('Invalid Country Code');
      }
    }

    if (cargoCurrencyRef.current && cargoCurrencyRef.current.value !== null) {
      if (cargoCurrencyRef.current.value.length <= 3) {
        cargoCurrencyRef.current.setCustomValidity('');
      } else {
        cargoCurrencyRef.current.setCustomValidity('Invalid Currency Code');
      }
    }

    if (destinationAirportRef.current !== null) {
      if (destinationAirportRef.current.value.length >= 3) {
        destinationAirportRef.current.setCustomValidity('');
      } else {
        destinationAirportRef.current.setCustomValidity('Invalid data');
      }
    }
    if (arrivalRefRef.current !== null) {
      if (arrivalRefRef.current.value.length >= 1) {
        arrivalRefRef.current.setCustomValidity('');
      } else {
        arrivalRefRef.current.setCustomValidity('Choose one');
      }
    }
    if (weightCodeRef.current !== null) {
      if (weightCodeRef.current.value.length >= 1) {
        weightCodeRef.current.setCustomValidity('');
      } else {
        weightCodeRef.current.setCustomValidity('Choose one');
      }
    }
    if (transferstatusIdRef.current !== null) {
      if (transferstatusIdRef.current.value.length >= 1) {
        transferstatusIdRef.current.setCustomValidity('');
      } else {
        transferstatusIdRef.current.setCustomValidity('Choose one');
      }
    }
    if (destinationAirportRefT.current !== null) {
      if (destinationAirportRefT.current.value.length <= 3) {
        destinationAirportRefT.current.setCustomValidity('');
      } else {
        destinationAirportRefT.current.setCustomValidity('Invalid data');
      }
    }
    if (bondedCarrierIdRef.current !== null) {
      console.log('bondedCarrierIdRef.current', bondedCarrierIdRef.current);
      if (
        bondedCarrierIdRef.current.value.match(/^\d{2}[\-]{1}\d{7}[A-Z]{2}/) ||
        bondedCarrierIdRef.current.value.match(/^\d{2}[\-]{1}\d{9}/) ||
        bondedCarrierIdRef.current.value.match(
          /^\d{3}[\-]{1}\d{2}[\-]{1}\d{4}/
        ) ||
        bondedCarrierIdRef.current.value.match(/^\d{6}[\-]{1}\d{5}/) ||
        bondedCarrierIdRef.current.value.length === 0
      ) {
        bondedCarrierIdRef.current.setCustomValidity('');
      } else {
        bondedCarrierIdRef.current.setCustomValidity(
          'Format invalid, Formats Accepted : NN-NNNNNNNAA, NN-NNNNNNNNN, NNN-NN-NNNN, NNNNNN-NNNNN'
        );
      }
    }
    if (premisesIdRef.current !== null) {
      if (
        (premisesIdRef.current.value.length <= 4 &&
          premisesIdRef.current.value.length >= 2) ||
        premisesIdRef.current.value.length === 0
      ) {
        premisesIdRef.current.setCustomValidity('');
      } else {
        premisesIdRef.current.setCustomValidity('Invalid data');
      }
    }
    if (onwardCarrierRef.current !== null) {
      if (
        (onwardCarrierRef.current.value.length <= 3 &&
          onwardCarrierRef.current.value.length >= 2) ||
        onwardCarrierRef.current.value.length === 0
      ) {
        onwardCarrierRef.current.setCustomValidity('');
      } else {
        onwardCarrierRef.current.setCustomValidity('Invalid data');
      }
    }
    if (inbondControlNumRef.current !== null) {
      if (
        inbondControlNumRef.current.value.length === 9 ||
        inbondControlNumRef.current.value.length === 0
      ) {
        inbondControlNumRef.current.setCustomValidity('');
      } else {
        inbondControlNumRef.current.setCustomValidity('Invalid data');
      }
    }
    if (pkTrackingIdRef.current !== null) {
      if (pkTrackingIdRef.current.value.length > 0) {
        if (
          pkTrackingIdRef.current.value.match(
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
          ) ||
          pkTrackingIdRef.current.value.length >= 5
        ) {
          pkTrackingIdRef.current.setCustomValidity('');
        } else {
          pkTrackingIdRef.current.setCustomValidity('Invalid Tracking #');
        }
      }
    }
  };

  const send = async (add) => {
    validateInputs();
    if (form.reportValidity()) {
      //Save Shipper
      let bodyShp;
      if (Object.values(shp).some((v) => v !== '')) {
        bodyShp = {
          name: shp.name,
          address: shp.address,
          city: shp.city,
          state: shp.state,
          countrycode: shp.countryCode,
          type: 'shipper',
          phone: shp.phoneNumber,
          zip: shp.postalCode,
          email:
            shp.email && shp.email.length > 0 ? shp.email.toLowerCase() : '',
        };

        try {
          let shipperUpdated = await feathers
            .service('party')
            .update({}, bodyShp);
        } catch (e) {
          console.log('error:', e);
        }
      }
      let bodyCne;
      //Save Consignee
      if (Object.values(cne).some((v) => v !== '')) {
        bodyCne = {
          name: cne.name,
          address: cne.address,
          city: cne.city,
          state: cne.state,
          countrycode: cne.countryCode,
          type: 'consignee',
          phone: cne.phoneNumber,
          zip: cne.postalCode,
          email:
            cne.email && cne.email.length > 0 ? cne.email.toLowerCase() : '',
        };

        try {
          await feathers.service('party').update({}, bodyCne);
        } catch (e) {
          console.log('error :', e);
        }
      }

      if (!house && !simple) {
        awb.consolidationId = 'M';
      }
      let body = { SMI: { componentId: 'FRI' } };
      if (Object.values(ccl).some((v) => v !== '')) body.CCL = ccl;
      if (Object.values(awb).some((v) => v !== '')) body.AWB = awb;
      if (Object.values(wbl).some((v) => v !== '')) body.WBL = wbl;
      if (Object.values(arr).some((v) => v !== '') && arr.impCarrier) {
        body.ARR = arr;
        if (arr.hasOwnProperty('brdPieceCnt') && arr.brdPieceCnt != '') {
          body.ARR.boardedQuantityId = 'B';
        }

        if (split == false) {
          delete body.ARR.arrivalRef;
          delete body.ARR.brdPieceCnt;
          delete body.ARR.weight;
          delete body.ARR.weightCode;
        }
      }
      if (Object.values(agt).some((v) => v !== '')) {
        body.AGT = { componentId: 'AGT', ...agt };
      }
      if (Object.values(shp).some((v) => v !== '')) body.SHP = shp;
      if (Object.values(cne).some((v) => v !== '')) body.CNE = cne;
      if (Object.values(trn).some((v) => v !== '')) {
        body.TRN = { componentId: 'TRN', ...trn };
      }
      if (Object.values(csd).some((v) => v !== '')) {
        body.CSD = { componentId: 'CSD', ...csd };
      }
      if (Object.values(fda).some((v) => v !== '')) body.FDA = fda;

      delete body.SHP.email;
      delete body.CNE.email;

      let { impCarrier, flightNumber, arrivalDate } = arr;
      let { arrivalAirport, terminalOp } = ccl;
      let { serialNumber, prefix } = awb;
      let airportOrigin = wbl.airportOrigin;
      body.marksAndNumbers = marksAndNumbers;
      if (fda.lineId === 'FDA') body.FDA = fda;

      let [res, err] = await fetchDoc({ body });
      let ret = false;
      if (err.length) {
        toast.error(err[0].doc.meta.errors[0].message.replace('\\', ''));
      } else if (res.errors !== undefined) {
        toast.error(res.message);
        for (let i = 0; i < res.errors.length; i++) {
          toast.error(res.errors[i].message);
        }
        ret = false;
      } else {
        toast.info('Document Saved');
        body.SHP.email = bodyShp.email;
        body.CNE.email = bodyCne.email;
        ret = true;
      }
      if (add) {
        setCountries([]);
        setCountriesShp([]);
        setCountriesCne([]);
        setAutocompletesCne({ cneCountry: '' });
        setAutocompletesShp({ shpCountry: '' });
        let houses = documents;

        houses.push({
          id: res.message._id,
          doc: res.message.docOrigin.AWB.hawbNumber,
          prefix: res.message.docOrigin.AWB.prefix,
          serialNumber: res.message.docOrigin.AWB.serialNumber,
          flightNumber: res.message.docOrigin.ARR
            ? res.message.docOrigin.ARR.flightNumber || null
            : null,
          hawbNumber: res.message.docOrigin.AWB.hawbNumber,
          status: res.message.meta.status,
        });
        setDocuments(houses);
        setAwb({
          ...awb,
          serialNumber,
          prefix,
          hawbNumber: '',
          pkTrackingId: '',
        });
        setArr({
          ...arr,
          impCarrier,
          flightNumber,
          arrivalDate,
          brdPieceCnt: '',
          weightCode: '',
          weight: '',
          arrivalRef: '',
        });
        setWbl({
          ...wbl,
          airportOrigin,
          numPieces: '',
          weightCode: '',
          weight: '',
          cargoDesc: '',
          destinationAirport: '',
          arrivalDatePTPAirport: '',
        });
        setCcl({ ...ccl, arrivalAirport, terminalOp });
        setAgt({ ...agt, participantCode: '' });
        setShp({
          ...shp,
          name: '',
          phoneNumber: '',
          address: '',
          city: '',
          state: '',
          postalCode: '',
          countryCode: '',
        });
        setCne({
          ...cne,
          name: '',
          phoneNumber: '',
          address: '',
          city: '',
          state: '',
          postalCode: '',
          countryCode: '',
        });
        setTrn({
          ...trn,
          destinationAirport: '',
          transferstatusId: '',
          bondedCarrierId: '',
          onwardCarrier: '',
          premisesId: '',
          inbondControlNum: '',
        });
        setMarksAndNumbers('');
        setCsd({
          ...csd,
          originGoods: '',
          declaredValue: '',
          currencyCode: '',
          hazmatCode: '',
        });
        setFda({ ...fda, lineId: '' });
      }
      setLoading(true);
      window.scrollTo(0, 0);
      return ret;
    }
  };

  const saveGoBack = async (add) => {
    if (await send(add)) {
      setTimeout(() => {
        props.history.push('awb-history');
      }, 2000);
    }
  };

  const saveCreateNew = async (add) => {
    if (await send(add)) {
      let params = qs.parse(props.location.search);
      if (params && params.id && params.clone) {
        props.history.push('create-manifest');
        props.history.go();
      } else {
        props.history.go();
      }
    }
  };

  const saveClone = async (add) => {
    if (await send(add)) {
      setAwb({
        serialNumber: '',
        prefix: '',
        hawbNumber: '',
        pkTrackingId: '',
      });
      setDocuments([]);
    }
  };

  return (
    <Fragment>
      <form
        ref={(ref) => {
          form = ref;
        }}
      >
        <Grid size={12}>
          <Cell size={12}>
            <span style={{ fontSize: 35, color: 'gray' }}>
              ACE Air eManifest
            </span>

            <Subheader
              className="md-grid"
              primary
              primaryText="* Denotes  Required Field"
              style={{ fontWeight: 'bold', fontSize: '14px', color: 'red' }}
            />
          </Cell>
        </Grid>

        <Grid size={12}>
          {/* Manifest details */}
          <Cell size={12}>
            <Card raise tableCard>
              <CardTitle style={{ backgroundColor: '#599dde' }}>
                <h2
                  style={{ color: 'white' }}
                  class="md-card-title--title md-card-title--large md-text"
                >
                  Manifest Details
                </h2>
              </CardTitle>
              <CardText>
                <ShowDetailsManifest
                  awb={awb}
                  date={Date.now()}
                  aws={aws}
                  status={'DRAFT'}
                  email={email}
                  userCreated={email}
                  userSubmission={''}
                  manifestCreated={Date.now()}
                />
              </CardText>
            </Card>
          </Cell>
        </Grid>

        <Grid size={12}>
          <Cell size={12}>
            <Card raise tableCard>
              <CardTitle style={{ backgroundColor: '#599dde' }}>
                <h2
                  style={{ color: 'white' }}
                  class="md-card-title--title md-card-title--large md-text"
                >
                  Flight Details
                </h2>
              </CardTitle>
              <CardText>
                <FlightDetails
                  role={profile.role}
                  create={true}
                  expr={false}
                  aws={aws}
                  setAws={setAws}
                  house={house}
                  setHouse={setHouse}
                  simple={simple}
                  setSimple={setSimple}
                  agt={agt}
                  setAgt={setAgt}
                  awb={awb}
                  setAwb={setAwb}
                  arr={arr}
                  setArr={setArr}
                  wbl={wbl}
                  setWbl={setWbl}
                  ccl={ccl}
                  setCcl={setCcl}
                  setAutocompletesT={setAutocompleteTerminal}
                  autocompletesT={autocompleteTerminal}
                  setAutocompletesA={setAutocompleteImpCarrier}
                  autocompletesA={autocompleteImpCarrier}
                  setAutocompletesAirArr={setAutocompleteArrivalAirport}
                  autocompletesAirArr={autocompleteArrivalAirport}
                  setAutocompletesAir={setAutocompleteAirportOrigin}
                  autocompletesAir={autocompleteAirportOrigin}
                  setAllCodes={setAllCodes}
                  allCodes={allCodes}
                  setUsCodes={setUsCodes}
                  usCodes={usCodes}
                  setAirlines={setAirlines}
                  airlines={airlines}
                  setTerminal={setTerminal}
                  terminal={terminal}
                  setCleanAutocomplete={setCleanAutocomplete}
                  cleanAutocomplete={cleanAutocomplete}
                  status={'DRAFT'}
                  impC={impC}
                  tOp={tOp}
                  airO={airO}
                  arrAirport={arrAirport}
                  prefixRef={prefixRef}
                  sNRef={sNRef}
                  hawbRef={hawbRef}
                  flightNumberRef={flightNumberRef}
                />
              </CardText>
            </Card>
          </Cell>
        </Grid>

        <Grid size={12}>
          <Cell size={6}>
            <Card raise tableCard>
              <CardTitle style={{ backgroundColor: '#599dde' }}>
                <h2
                  style={{ color: 'white' }}
                  class="md-card-title--title md-card-title--large md-text"
                >
                  Shipper
                </h2>
              </CardTitle>
              <CardText>
                <Shipper
                  role={profile.role}
                  shp={shp}
                  setShp={setShp}
                  setShippers={setShippers}
                  shippersList={shippersList}
                  status={'DRAFT'}
                  setCountriesShp={setCountriesShp}
                  countriesShp={countriesShp}
                  setAutocompletesShp={setAutocompletesShp}
                  autocompletesShp={autocompletesShp}
                  shpEmail={shpEmail}
                  shpPhoneNumber={shpPhoneNumber}
                  shpPostalCode={shpPostalCode}
                  shpCountryRef={shpCountryRef}
                />
              </CardText>
            </Card>
          </Cell>
          <Cell size={6}>
            <Card raise tableCard>
              <CardTitle style={{ backgroundColor: '#599dde' }}>
                <h2
                  style={{ color: 'white' }}
                  class="md-card-title--title md-card-title--large md-text"
                >
                  Consignee
                </h2>
              </CardTitle>
              <CardText>
                <Consignee
                  role={profile.role}
                  cne={cne}
                  setCne={setCne}
                  setConsignees={setConsignees}
                  consigneeList={consigneeList}
                  status={'DRAFT'}
                  setCountriesCne={setCountriesCne}
                  countriesCne={countriesCne}
                  setAutocompletesCne={setAutocompletesCne}
                  autocompletesCne={autocompletesCne}
                  cneEmail={cneEmail}
                  cnePhoneNumber={cnePhoneNumber}
                  cnePostalCode={cnePostalCode}
                  cneCountryRef={cneCountryRef}
                />
              </CardText>
            </Card>
          </Cell>
        </Grid>

        {/* Cargo details */}
        <Grid>
          <Cell size={12}>
            <Card raise tableCard>
              <CardTitle style={{ backgroundColor: '#599dde' }}>
                <h2
                  style={{ color: 'white' }}
                  class="md-card-title--title md-card-title--large md-text"
                >
                  Cargo Details
                </h2>
              </CardTitle>
              <CardText>
                <CargoDetails
                  role={profile.role}
                  awb={awb}
                  setAwb={setAwb}
                  marksAndNumbers={marksAndNumbers}
                  setMarksAndNumbers={setMarksAndNumbers}
                  csd={csd}
                  setCsd={setCsd}
                  setHts={setHts}
                  hts={hts}
                  wbl={wbl}
                  setWbl={setWbl}
                  fda={fda}
                  setFda={setFda}
                  setCountries={setCountries}
                  countries={countries}
                  setCurrency={setCurrency}
                  currency={currency}
                  autocompletes={autocompletes}
                  setAutocompletes={setAutocompletes}
                  setAutocompletesCurr={setAutocompletesCurr}
                  autocompletesCurr={autocompletesCurr}
                  status={'DRAFT'}
                  cargoCountryRef={cargoCountryRef}
                  cargoCurrencyRef={cargoCurrencyRef}
                  pkTrackingIdRef={pkTrackingIdRef}
                  aws={aws}
                  setAws={setAws}
                  weightCodeCargoRef={weightCodeCargoRef}
                />
              </CardText>
            </Card>
          </Cell>
        </Grid>

        <Grid size={12}>
          <Cell
            size={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
            }}
          >
            <Switch
              id="switch-permit"
              type="switch"
              label="Flight is moving under PTP"
              name="permit"
              checked={permit}
              onChange={(e) => setPermit(!permit)}
              style={{ marginRight: 16 }}
            />
            <Switch
              id="switch-split"
              type="switch"
              label="Split Shipment?"
              name="permit"
              checked={split}
              onChange={(e) => setSplit(!split)}
              style={{ marginRight: 16 }}
            />
            <Switch
              id="switch-transfer"
              type="switch"
              label="In bond shipment or local transfer to a bonded facility?"
              name="permit"
              checked={transfer}
              onChange={(e) => {
                setTransfer(!transfer);
              }}
            />
          </Cell>
        </Grid>

        {permit ? (
          <Grid size={12}>
            <Cell size={12}>
              <Card raise tableCard>
                <CardTitle style={{ backgroundColor: '#599dde' }}>
                  <h2
                    style={{ color: 'white' }}
                    class="md-card-title--title md-card-title--large md-text"
                  >
                    Permit to Proceed
                  </h2>
                </CardTitle>
                <CardText>
                  <PermitProceed
                    role={profile.role}
                    wbl={wbl}
                    setWbl={setWbl}
                    status={'DRAFT'}
                    destRef={destinationAirportRef}
                    setAutocompletesAirPermit={
                      setAutocompleteDestinationAirport
                    }
                    autocompletesAirPermit={autocompleteDestinationAirport}
                  />
                </CardText>
              </Card>
            </Cell>
          </Grid>
        ) : null}

        {split ? (
          <Grid size={12}>
            <Cell size={12}>
              <Card raise tableCard>
                <CardTitle style={{ backgroundColor: '#599dde' }}>
                  <h2
                    style={{ color: 'white' }}
                    class="md-card-title--title md-card-title--large md-text"
                  >
                    Split Shipment Indicator
                  </h2>
                </CardTitle>
                <CardText>
                  <SplitIndicator
                    role={profile.role}
                    arr={arr}
                    setArr={setArr}
                    status={'DRAFT'}
                    arrivalRefRef={arrivalRefRef}
                    weightCodeRef={weightCodeRef}
                  />
                </CardText>
              </Card>
            </Cell>
          </Grid>
        ) : null}

        {transfer ? (
          <Grid size={12}>
            <Cell size={12}>
              <Card raise tableCard>
                <CardTitle style={{ backgroundColor: '#599dde' }}>
                  <h2
                    style={{ color: 'white' }}
                    class="md-card-title--title md-card-title--large md-text"
                  >
                    Transfer Details (in-bond or local transfer)
                  </h2>
                </CardTitle>
                <CardText>
                  <TransferDetails
                    role={profile.role}
                    trn={trn}
                    setTrn={setTrn}
                    bondedPremisesType={bondedPremisesType}
                    setBondedPremisesType={setBondedPremisesType}
                    status={'DRAFT'}
                    autocompletesTransfer={
                      autocompleteDestinationAirportTransfer
                    }
                    setAutocompletesTransfer={
                      setAutocompleteDestinationAirportTransfer
                    }
                    autocompletesTransferFIRMS={autocompletesTransferFIRMS}
                    setAutocompletesTransferFIRMS={
                      setAutocompletesTransferFIRMS
                    }
                    autocompletesTransferAir={autocompleteAirlineTransfer}
                    setAutocompletesTransferAir={setAutocompleteAirlineTransfer}
                    bondedCarrierIdRef={bondedCarrierIdRef}
                    premisesIdRef={premisesIdRef}
                    inbondControlNumRef={inbondControlNumRef}
                    onwardCarrierRef={onwardCarrierRef}
                    autocompleteTransferAirOC={autocompleteTransferAirOC}
                    setAutocompleteTransferAirOC={setAutocompleteTransferAirOC}
                    transferType={transferType}
                    setTransferType={setTransferType}
                  />
                </CardText>
              </Card>
            </Cell>
          </Grid>
        ) : null}

        <Grid size={12} style={{ marginBottom: 40 }}>
          <Cell size={12} className="md-text-center">
            {house ? (
              <BTN
                variant="outlined"
                color="primary"
                style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={(e) => send(true)}
              >
                Add House
              </BTN>
            ) : null}

            <Fragment>
              <BTN
                variant="outlined"
                color="primary"
                style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={() => {
                  saveGoBack(false);
                }}
              >
                Save and Go Back
              </BTN>
              <BTN
                variant="outlined"
                color="primary"
                style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={() => {
                  saveCreateNew(false);
                }}
              >
                Save and Create New
              </BTN>
              <BTN
                variant="outlined"
                color="primary"
                style={{ marginLeft: '10px', marginRight: '10px' }}
                onClick={() => {
                  saveClone(false);
                }}
              >
                Save and Clone
              </BTN>
            </Fragment>
          </Cell>
        </Grid>

        {documents.length && house ? (
          <Grid size={12}>
            <Cell
              size={4}
              tabletSize={12}
              phoneSize={12}
              style={{ marginTop: 25 }}
            >
              <Card raise tableCard>
                <CardTitle style={{ backgroundColor: '#599dde' }}>
                  <h2
                    style={{ color: 'white' }}
                    class="md-card-title--title md-card-title--large md-text"
                  >
                    Related House Bills
                  </h2>
                  <h3
                    style={{ color: 'white' }}
                    class="md-card-title--title md-text--secondary"
                  >
                    documents.length
                  </h3>
                </CardTitle>
                <CardText>
                  <List>
                    {documents.map((d, i) => {
                      return (
                        <ListItem
                          key={i}
                          primaryText={d.doc}
                          secondaryText={d.status}
                          renderChildrenOutside
                        >
                          <Button
                            icon
                            onClick={() => {
                              setDocument(d);
                              setShowModal(true);
                            }}
                          >
                            delete
                          </Button>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardText>
              </Card>
            </Cell>
          </Grid>
        ) : null}
      </form>

      <DialogContainer
        id="speed-boost"
        visible={showModal}
        title="Do you want to delete document?"
        actions={[
          {
            secondary: true,
            children: 'Delete',
            onClick: async () => {
              let Body = {
                prefix: document.prefix,
                serialNumber: document.serialNumber,
                hawbNumber: document.hawbNumber,
                flightNumber: document.flightNumber,
                type: 'House',
              };
              await fetchDeleteManifest({ body: Body });
              let docs = [];
              for (const doc of documents) {
                if (doc != document) {
                  docs.push(doc);
                }
              }
              setDocuments(docs);
              setShowModal(false);
              toast.success('Document has been Deleted.');
            },
          },
          {
            primary: true,
            children: 'Cancel',
            onClick: () => {
              setShowModal(false);
            },
          },
        ]}
        onHide={() => {}}
      ></DialogContainer>
    </Fragment>
  );
};
