const FSNMessage = (code, union) => {
  let FSNStatus = {
    '1A': 'Entry processed: CBP intensive examination required.',
    '1B': 'CBP intensive examination complete.',
    '1C': 'Entry processed: CBP general examination.',
    '1D': 'In-bond movement authorized by CBP.',
    '1E': 'In-bond transfer not authorized, hold at port of arrival per CBP.',
    '1F': 'CBP local transfer authorized.',
    '1G': 'CBP local transfer not authorized.',
    '1J': 'CBP permit to proceed movement not authorized.',
    '1H': 'CBP hold at port of arrival',
    '2H': 'CBPA hold at port of arrival.',
    '3H': 'Other agency hold at port of arrival.',
    '1H': 'CBP enforcement hold.',
    '1H': 'CBP enforcement screening hold.',
    '5H': 'CBP entry documents processing hold',
    '1I': 'CBP hold removed.',
    '2I': 'CBPA hold removed.',
    '3I': 'Other agency hold removed.',
    '1I': 'CBP enforcement hold removed.',
    '1I': 'Enforcement screening hold removed.',
    '5I': 'CBP entry processing hold removed',
    '1L': 'CBP permit to proceed movement authorized.',
    '1M': 'CBP express consignment status denied',
    '1T': 'Seized by CBP.',
    '2T': 'Seized by CBPA',
    '3T': 'Seized by other agency.',
    '1R': 'CBP pending general order eligibility.',
    '1S': 'CBP Eligible for General Order.',
    '1U': 'Sent to CBP general order.',
    '4A': 'CBP entry override to intensive examination.',
    '4C': 'CBP entry override to general examination.',
    '4E': 'CBP entry cancelled.',
    85: 'BTA FDA Prior Notice data satisfied by FDA.',
    87: 'BTA FDA Prior Notice data not satisfied by FDA.',
    88: 'BTA FDA Prior Notice data not on file.',
    BC:
      'Good not authorized for zone. This message will inform the trade partners that the admission of the goods identified is prohibited for Foreign Trade Zone (FTZ).',
    BD:
      'Goods Accepted/No Qty Verification. This message informs the carrier the goods were acceptable to the Zone Operator and the goods will be processed by the zone (FTZ).',
    BE:
      'Goods arrived. This message informs the trade partners that the goods have arrived at the zone (FTZ).',
    BG:
      'Admission Advisory. This message informs the trade partners that a CBPF214 has been authorized for FTZ.',
    BH:
      'Admission is Deleted. This message informs the carriers that an admission to a FTZ has been deleted.',
    G5:
      'Fish and Wildlife Service (FWS) PGA Hold for Inspection at Port of Unlading',
    G6:
      'Fish and Wildlife Service (FWS) PGA Hold for Inspection at In-Bond Destination Port',
    G7:
      'Remove Fish and Wildlife Service (FWS) PGA Hold for Inspection at Port of Unlading.',
    G8:
      'Remove Fish and Wildlife Service (FWS) PGA Hold for Inspection at In-Bond Destination Port',
    I3: 'CBP PGA Hold for Inspection',
    I5: 'Remove CBP PGA Hold for Inspection',
    LZ:
      'Fish and Wildlife Service (FWS) PGA Hold for Documentation at Port of Unlading',
    M0:
      'Fish and Wildlife Service (FWS) PGA Hold for Documentation at In-Bond Destination Port',
    M1:
      'Remove Fish and Wildlife Service (FWS) PGA Hold for Documentation at Port of Unlading',
    M2:
      'Remove Fish and Wildlife Service (FWS) PGA Hold for Documentation at InBond Destination Port',
    11: 'In-bond Arrival at Destination Received',
    12: 'Arrival of in-bond - bill of lading',
    50: 'Export of In-bond -Complete movement',
    51: 'Export of In-bond -Bill of Lading',
    63: 'Delete Arrival of In-bond-Complete Movement',
    64: 'Delete Arrival of In-bond-Bill of Lading',
    66: 'Delete Export of In-bond -Complete Movement',
    67: 'Delete Export of In-bond -Bill of Lading',
    83: 'Local Transfer Cancelled',
    95: 'In-bond Move Authorization Cancelled',
    P3: 'Local Transfer Arrived',
  };
  if (union) {
    return `${code} - ${FSNStatus[code]}`;
  }
  return {
    code: code,
    message: `${FSNStatus[code]}`,
  };
};
const entryType = (code) => {
  let types = {
    '01': '01 Consumption',
    '02': '02 Consumption - Quota/Visa (Q/V)',
    '03': '03 Consumption - Antidumping (AD)/Countervailing Duty (CVD)',
    '04': '04 Appraisement',
    '05': '05 Vessel – Repair',
    '06': '06 Consumption - Foreign Trade Zone (FTZ)',
    '07': '07 Consumption - (AD), (CVD), (Q/V)',
    11: '11 Informal - Dutiable Commercial Merchandise ($2500 or less)',
    12: '12 Informal - Quota',
    21: '21 Warehouse',
    22: '22 Re-Warehouse',
    23: '23 Temporary Importation Bonded (TIB)',
    24: '24 Trade Fair',
    25: '25 Permanent Exhibition',
    26: '26 Warehouse - Foreign Trade Zone (FTZ) (Admission)',
    30: '30 Blanket Application (Warehouse Withdrawal)',
    31: '31 Warehouse Withdrawal - Consumption',
    32: '32 Warehouse Withdrawal - Quota',
    34: '34 Warehouse Withdrawal - (AD)/(CVD)',
    38: '38 Warehouse Withdrawal - (AD)/(CVD) and (Q/V)',
    41: '41 Manufacturing Drawback',
    42: '42 Same Condition Drawback',
    43: '43 Rejected Importation Drawback',
    51: '51 DCASR',
    52: '52 Government - Dutiable',
    53: '53 Government - Free Entry (FE)',
    61: '61 Immediate Transportation (IT)',
    62: '62 Transportation and Exportation (T&E)',
    63: '63 Immediate Exportation (IE)',
    80: '80 CF-3461, Entry/Immediate Delivery',
    81: '81 CF-214, Appl for (FTZ) Admission and/or Status Designation',
    82: '82 CF-6043, Delivery Ticket',
    83: '83 CF-7523, Entry & Manifest Duty Free, Carrier’s Cert-Release',
    84: '84 CF-3311, Declaration for (FE) of Returned American Goods',
    85: '85 CF-3299, Declaration for (FE) of Unaccompanied Articles',
    86: '86 Sec-321',
    87: '87 Intangibles, Headnotes, Harmonized Tariff Schedule of the U.S.',
    88: '88 Monthly Manifest',
    89: '89 Other',
    90: '90 Transfer - Code of Federal Regulation (CFR) 19, Section 42',
    91: '91 Gifts',
    92: '92 U.S. Goods Returned',
    93: '93 Gifts, U.S. Possessions',
    94: '94 Personal Shipment',
    99: '99 Special Entry Processing',
  };
  return types[code];
};
const FSCMessage = (code, union) => {
  let Message = [
    'RECORD NOT ON FILE',
    'RECORD IN DELETED STATUS',
    'BILL IS SPLIT - SEE LIST BELOW.',
    'NOT AUTHORIZED TO RECEIVE INFORMATION',
    'BILL INCOMPLETE - NOTIFICATIONS NOT SENT',
    'NO NOTIFICATIONS SENT TO REQUESTER',
    'NOTIFICATIONS RESENT',
    'BILL STATUS INFORMATION FOLLOWS',
    'ROUTING INFORMATION FOLLOWS',
    'NOMINATED AGENT',
    'MASTER AIRWAY BILL ON FILE', // CURRENT AIR WAYBILL , CURRENT AIR WAYBILL INFORMATION ON FILE FOLLOWS
  ];
  if (union) {
    return `${code} - ${Message[parseInt(code)]}`;
  }
  return { code: code, message: Message[parseInt(code)] };
};
const docType = (code) => {
  let type = {
    FRI: 'Freight Report Inbound (FRI)',
    PRI: 'Preliminary Report Inbound (PRI)',
    PER: 'Preliminary Error Report (PER)',
    PSN: 'Preliminary Status Notification (PSN)',
    FRC: 'Freight Report Change (FRC)',
    FRX: 'Freight Report Cancel (FRX)',
    FSN: 'Freight Status Notification (FSN)',
    FSI: 'Freight Status Information (FSI)',
    FXI: 'Freight Express Inbound (FXI)',
    FXC: 'Freight Express Change (FXC)',
    FXX: 'Freight Express Cancelation (FXX)',
    FSQ: 'Freight Status Query (FSQ)',
    FDM: 'Flight Departure Message (FDM)',
    FSC: 'Freight Status Condition (FSC)',
    FER: 'Freight Error Report (FER)',
  };
  return `${type[code]}`;
};
const FSNReasonCode = (code, union) => {
  let FSNReason = {
    3: 'In-bond arrived at destination',
    4: 'Local transfer of consolidated cargo',
    7: 'In-bond exported at destination',
  };
  if (union) {
    return `${code} - ${FSNReason[code]}`;
  }
  return { code: code, message: FSNReason[code] };
};
const FSQRequestCode = (code, union) => {
  let FSQRequest = {
    '01': 'Request for routing information',
    '02': 'Request for current record status',
    '03': 'Request for nominated agent',
    '04': 'Request for retransmission of FSN messages',
    '05':
      'Request for: routing information, current record status, nominated agent, current AWB information on file returned in FSC/10 message',
    '06': 'Request for HAWB information for supplied MAWB.',
  };
  if (union) {
    return `${code} - ${FSQRequest[code]}`;
  }
  return { code: code, message: FSQRequest[code] };
};

export {
  FSNMessage,
  FSCMessage,
  docType,
  FSNReasonCode,
  FSQRequestCode,
  entryType,
};
