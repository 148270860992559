import { useState } from 'react';
const headers = new Headers();
headers.append('content-type', 'application/json');

const env = process.env;

let def = {
  query: undefined,
  body: undefined,
  params: []
};

const useApi = (api, resource, initVal, method = 'get', auth = '') => {
  const [data, setData] = useState(initVal);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const setQuery = query =>
    Object.entries(query)
      .map(([key, val]) => `${key}=${val}`)
      .join('&');
  const doFetch = async ({ query, body, params } = def) => {
    headers.set('Authorization', localStorage.token || auth);

    let error = false;

    query = query ? `?${setQuery(query)}` : '';
    body = JSON.stringify(body);
    params = params ? params.join('/') : '';

    // console.log('Query:', query);
    // console.log('Body:', body);
    // console.log('Params:', params);

    return fetch(
      `${env['REACT_APP_' + api.toUpperCase()]}/${resource}/${params}${query}`,
      { method, body, headers }
    )
      .then(response => {
        // console.log('response', response);
        if (!response.ok) {
          error = true;
          // throw new Error('Failed to fetch.');
        }
        return response.json();
      })
      .then(d => {
        if (error) {
          setIsError(d);
        } else {
          setData(d);
        }

        setIsLoading(true);
        return [d, error];
      })
      .catch(err => {
        // console.log(err)
        setIsError(err);
        setIsLoading(true);
        // setData({ error: 'Error de conexion' })
      });
  };

  return [isLoading, data, doFetch, isError];
};

export { useApi };
