export default (props) =>
  `${props.title}
  <ul style="text-align:left;">
  ${
    props.hasOwnProperty('enum')
      ? `<li>Only support ${props.enum}</li>`
      : (props.hasOwnProperty('minLength') && props.hasOwnProperty('maxLength')
          ? props.minLength === props.maxLength
            ? `<li>Must be ${props.minLength} ${
                props.type.includes('number')
                  ? props.minLength === 1
                    ? 'number'
                    : 'numbers'
                  : 'letters'
              } </li>`
            : `<li>Must be greater than ${props.minLength} ${
                props.type.includes('number')
                  ? props.minLength === 1
                    ? 'number'
                    : 'numbers'
                  : 'letters'
              } </li>
            <li>Must be less than ${props.maxLength} ${
                props.type.includes('number') ? 'numbers' : 'letters'
              }</li>`
          : props.hasOwnProperty('minLength')
          ? `<li>Must be ${props.minLength} ${
              props.type.includes('number') ? 'numbers' : 'letters'
            }</li>`
          : `<li>${props.description}</li>`) + `<li>Type: ${props.type}</li>`
  }
  </ul>`;
