import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const Modal = (props) => {
  const {
    handleClose,
    open,
    title,
    children,
    showOptionalButton = false,
    fullWidth = false,
    maxWidth = 'sm',
    handleOptionalButton = null,
    textOptionalButton = '',
  } = props;
  return (
    <Fragment>
      <Dialog
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        onBackdropClick={handleClose}
        onEscapeKeyDown={handleClose}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          {showOptionalButton && (
            <Button
              onClick={handleOptionalButton}
              variant="outlined"
              color="primary"
            >
              {textOptionalButton}
            </Button>
          )}
          <Button onClick={handleClose} variant="outlined" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default Modal;
