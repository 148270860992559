import React, { useState, useEffect, Fragment } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import TableDetails from '../../components/tables/TableDetails';
import {
  Grid,
  Cell,
  TextField,
  Button,
  Card,
  CardActions,
  Toolbar,
  SelectField,
  MenuButton,
  ListItem,
  FontIcon,
  DialogContainer,
  Switch,
} from 'react-md';
import { SubscriptionsList } from '../../views/';
import Axios from 'axios';
const env = process.env;
export default (props) => {
  const [partnershipData, setPartnershipData] = useState([]);
  const [channels, setChannels] = useState([]);
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };
  const partnership = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_CCAPI'] + '/partnership/');
    return response;
  };

  const getChannels = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_CLIENT'] + '/channels');
    let channels = response.data.map((channel) => {
      return {
        label: `${channel.name}`,
        value: `${channel._id}`,
      };
    });
    setChannels(channels);
  };

  useEffect(() => {
    async function tttt() {
      await checkToken();
    }
    tttt();
  }, []);
  const [, data, doFetch] = useApi('client', 'client', null, 'post');
  const [sendACAS, setSendACAS] = useState(false);
  const [client, setClient] = useState({
    companyCode: '',
    channel: '',
    sendACAS: false,
    // usScacCode: '',
    // canadianCarrierCode: '',
    // resellerName: '',
    // warehouseSublocationCode: '',
    contact: {
      name: '',
      lastName: '',
      email: '',
      phone: '',
    },
    shippingAddress: {
      street: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
    billingAddress: {
      street: '',
      city: '',
      state: '',
      country: '',
      zip: '',
    },
  });
  const [, clientData, getclient] = useApi('client', 'client', client);
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState();
  const [, , fetchDelete] = useApi('auth', 'user', {}, 'delete');

  useEffect(() => {
    let data = JSON.parse(JSON.stringify(clientData));
    delete data._id;
    delete data.name;
    delete data.Users;
    setClient(data);
  }, [clientData]);

  useEffect(() => {
    // console.log('props.match.params :', props.match.params);
    async function partner() {
      await getPartnership();
      await getChannels();
    }
    if (props.match.params.id) {
      getclient({ params: [props.match.params.id] });
    }
    partner();
  }, [data]);
  const getPartnership = async () => {
    //get clients
    let partner = await partnership();
    let CompanyItemArray = [];
    for (const element of partner.data) {
      CompanyItemArray.push({
        label: `${element.sourceName}`,
        value: `${element._id}`,
      });
    }
    await setPartnershipData(CompanyItemArray);
  };

  let columnsUsers = [
    {
      name: 'name',
      title: 'Name',
      getCellValue: (s) => {
        return `${s.name ? s.name : ''} ${s.lastName ? s.lastName : ''}`;
        // <Link to={`../user/${s._id}`}>
        //   {s.name ? s.name : ''}
        //   {s.lastName ? ' ' + s.lastName : ''}
        // </Link>
      },
    },
    {
      name: 'email',
      title: 'Email',
      getCellValue: (s) => (s.email ? s.email : ''),
    },
    {
      name: 'phone',
      title: 'Phone',
      getCellValue: (s) => (s.phone ? s.phone : ''),
    },
    {
      name: 'menu',
      title: ' ',
      getCellValue: (s) => {
        return (
          // <div>
          //   <div className="fix-menu">
          <div>
            <MenuButton
              key={`menu1`}
              id="menu-button-2"
              icon
              menuItems={[
                <ListItem
                  key={1}
                  primaryText="Edit"
                  rightIcon={<FontIcon>edit</FontIcon>}
                  component={Link}
                  to={`/user/${s._id}`}
                  // onClick={() => {
                  //   props.history.push(
                  //     `/suscription/${company}/${s._id}`
                  //   );
                  //   //editSuscription(s._id);
                  // }}
                />,
                <ListItem
                  key={2}
                  primaryText="Delete"
                  rightIcon={<FontIcon>delete</FontIcon>}
                  onClick={() => {
                    setUserIdToDelete(s._id);
                    setShowModal(true);
                  }}
                />,
              ]}
            >
              more_vert
            </MenuButton>
          </div>
          // </div>
        );
      },
    },
  ];

  let tableColumnExtentionUser = [
    { columnName: 'menu', width: 55, align: 'right' },
  ];

  return (
    <Fragment>
      <Grid>
        <Cell
          size={props.match.params.id ? 5 : 6}
          offset={props.match.params.id ? 0 : 3}
        >
          <Card>
            <form>
              <Toolbar colored title="Client" />
              <Grid>
                <Cell size={6}>
                  <SelectField
                    className="md-cell md-cell--6"
                    label="Partnership"
                    placeholder="Partnership"
                    menuItems={partnershipData}
                    value={client.companyCode || ''}
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        companyCode: v,
                      });
                    }}
                    disabled={
                      JSON.parse(localStorage.profile).role === 'support'
                        ? false
                        : true
                    }
                  />
                </Cell>
                <Cell size={6}>
                  <SelectField
                    className="md-cell md-cell--6"
                    label="Channel"
                    placeholder="Channel"
                    menuItems={channels}
                    value={client.channel || ''}
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        channel: v,
                      });
                    }}
                    disabled={
                      JSON.parse(localStorage.profile).role === 'support'
                        ? false
                        : true
                    }
                  />
                </Cell>
                {/* <Cell size={6}>
                  <TextField
                    id="usScacCode"
                    autoComplete={'off'}
                    required
                    label="US SCAC Code"
                    value={client.usScacCode || ''}
                    onChange={async v => {
                      await setClient({
                        ...client,
                        usScacCode: v
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="canadianCarrierCode"
                    autoComplete={'off'}
                    required
                    label="Canadian Carrier Code"
                    value={client.canadianCarrierCode || ''}
                    onChange={async v => {
                      await setClient({
                        ...client,
                        canadianCarrierCode: v
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="resellerName"
                    autoComplete={'off'}
                    required
                    label="Reseller Name"
                    value={client.resellerName || ''}
                    onChange={async v => {
                      await setClient({
                        ...client,
                        resellerName: v
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="warehouseSublocationCode"
                    autoComplete={'off'}
                    required
                    label="Warehouse Sublocation Code"
                    value={client.warehouseSublocationCode || ''}
                    onChange={async v => {
                      await setClient({
                        ...client,
                        warehouseSublocationCode: v
                      });
                    }}
                  />
                </Cell> */}
                <Cell size={6}>
                  <Switch
                    style={{ marginTop: 20 }}
                    id="checkbox-read-material-design-spec"
                    name="simple-checkboxes[]"
                    label="Send ACAS"
                    value="material-design"
                    checked={client.sendACAS}
                    onChange={(e) => {
                      setSendACAS(e);
                      setClient({
                        ...client,
                        sendACAS: e,
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="iataCode"
                    autoComplete={'off'}
                    label="IATA Code"
                    value={client.iataCode || ''}
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        iataCode: v,
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="icaoCode"
                    autoComplete={'off'}
                    label="ICAO Code"
                    value={client.icaoCode || ''}
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        icaoCode: v,
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="firmsCode"
                    autoComplete={'off'}
                    label="Firms Code"
                    value={client.firmsCode || ''}
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        firmsCode: v,
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="awbPrefix"
                    autoComplete={'off'}
                    label="AWB Prefix"
                    value={client.awbPrefix || ''}
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        awbPrefix: v,
                      });
                    }}
                  />
                </Cell>
                {/* <Cell size={6}>
                  <TextField
                    id="importerBond"
                    autoComplete={'off'}
                    label="Importer Bond"
                    value={client.importerBond || ''}
                    onChange={async v => {
                      await setClient({
                        ...client,
                        importerBond: v
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="custodialBond"
                    autoComplete={'off'}
                    label="Custodial Bond"
                    value={client.custodialBond || ''}
                    onChange={async v => {
                      await setClient({
                        ...client,
                        custodialBond: v
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="internationalCarrierBond"
                    autoComplete={'off'}
                    label="International Carrier Bond"
                    value={client.internationalCarrierBond || ''}
                    onChange={async v => {
                      await setClient({
                        ...client,
                        internationalCarrierBond: v
                      });
                    }}
                  />
                </Cell> */}
                <Cell size={6}>
                  <TextField
                    id="nominatedAgenCode"
                    autoComplete={'off'}
                    label="Nominated Agent Code"
                    value={client.agentCode || ''}
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        agentCode: v,
                      });
                    }}
                  />
                </Cell>
                {/* <Cell size={4} /> */}
                <Cell size={4}>
                  <hr />
                </Cell>
                <Cell size={4} style={{ textAlign: 'center' }}>
                  Contact
                </Cell>
                <Cell size={4}>
                  <hr />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="contactName"
                    autoComplete={'off'}
                    required
                    label="Name"
                    value={
                      client.contact && client.contact.name
                        ? client.contact.name
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        contact: {
                          ...client.contact,
                          name: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="contactLastName"
                    autoComplete={'off'}
                    required
                    label="Last Name"
                    value={
                      client.contact && client.contact.lastName
                        ? client.contact.lastName
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        contact: {
                          ...client.contact,
                          lastName: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="contactEmail"
                    autoComplete={'off'}
                    required
                    label="Email"
                    value={
                      client.contact && client.contact.email
                        ? client.contact.email
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        contact: {
                          ...client.contact,
                          email: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="contactPhone"
                    autoComplete={'off'}
                    required
                    label="Phone"
                    value={
                      client.contact && client.contact.phone
                        ? client.contact.phone
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        contact: {
                          ...client.contact,
                          phone: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={4}>
                  <hr />
                </Cell>
                <Cell size={4} style={{ textAlign: 'center' }}>
                  Shipping Address
                </Cell>
                <Cell size={4}>
                  <hr />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="shippingStreet"
                    autoComplete={'off'}
                    required
                    label="Street"
                    value={
                      client.shippingAddress && client.shippingAddress.street
                        ? client.shippingAddress.street
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        shippingAddress: {
                          ...client.shippingAddress,
                          street: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="shippingCity"
                    autoComplete={'off'}
                    required
                    label="City"
                    value={
                      client.shippingAddress && client.shippingAddress.city
                        ? client.shippingAddress.city
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        shippingAddress: {
                          ...client.shippingAddress,
                          city: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="shippingState"
                    autoComplete={'off'}
                    required
                    label="State"
                    value={
                      client.shippingAddress && client.shippingAddress.state
                        ? client.shippingAddress.state
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        shippingAddress: {
                          ...client.shippingAddress,
                          state: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="shippingCountry"
                    autoComplete={'off'}
                    required
                    label="Country"
                    value={
                      client.shippingAddress && client.shippingAddress.country
                        ? client.shippingAddress.country
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        shippingAddress: {
                          ...client.shippingAddress,
                          country: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="shippingZip"
                    autoComplete={'off'}
                    required
                    label="Zip"
                    value={
                      client.shippingAddress && client.shippingAddress.zip
                        ? client.shippingAddress.zip
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        shippingAddress: {
                          ...client.shippingAddress,
                          zip: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}></Cell>
                <Cell size={4}>
                  <hr />
                </Cell>
                <Cell size={4} style={{ textAlign: 'center' }}>
                  Billing Address
                </Cell>
                <Cell size={4}>
                  <hr />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="billingStreet"
                    autoComplete={'off'}
                    required
                    label="Street"
                    value={
                      client.billingAddress && client.billingAddress.street
                        ? client.billingAddress.street
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        billingAddress: {
                          ...client.billingAddress,
                          street: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="billingCity"
                    autoComplete={'off'}
                    required
                    label="City"
                    value={
                      client.billingAddress && client.billingAddress.city
                        ? client.billingAddress.city
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        billingAddress: {
                          ...client.billingAddress,
                          city: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="billingState"
                    autoComplete={'off'}
                    required
                    label="State"
                    value={
                      client.billingAddress && client.billingAddress.state
                        ? client.billingAddress.state
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        billingAddress: {
                          ...client.billingAddress,
                          state: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="billingCountry"
                    autoComplete={'off'}
                    required
                    label="Country"
                    value={
                      client.billingAddress && client.billingAddress.country
                        ? client.billingAddress.country
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        billingAddress: {
                          ...client.billingAddress,
                          country: v,
                        },
                      });
                    }}
                  />
                </Cell>
                <Cell size={6}>
                  <TextField
                    id="billingZip"
                    autoComplete={'off'}
                    required
                    label="Zip"
                    value={
                      client.billingAddress && client.billingAddress.zip
                        ? client.billingAddress.zip
                        : ''
                    }
                    onChange={async (v) => {
                      await setClient({
                        ...client,
                        billingAddress: {
                          ...client.billingAddress,
                          zip: v,
                        },
                      });
                    }}
                  />
                </Cell>
              </Grid>
              <CardActions centered>
                <Button
                  onClick={async (e) => {
                    let [result, error] = await doFetch({
                      body: client,
                      params: [props.match.params.id] || [],
                    });

                    if (!error) {
                      let role = JSON.parse(localStorage.profile).role;

                      if (role === 'support') {
                        props.history.push('/clients/list');
                      }

                      toast.success(
                        props.match.params.id
                          ? 'Client successfully Updated.'
                          : 'Client successfully Created.'
                      );
                    } else {
                      //console.log('error: ', error);
                      if (result.error.includes('companyCode')) {
                        toast.error('Partnership is not allowed to be empty');
                      } else {
                        toast.error(result.error);
                      }
                      //toast.error('Please check data before save Client.');
                    }
                  }}
                  flat
                >
                  Save
                </Button>
                <Button
                  secondary
                  flat
                  onClick={() => {
                    let role = JSON.parse(localStorage.profile).role;

                    if (role === 'support') {
                      props.history.push('/clients/list');
                    } else {
                      props.history.push('/');
                    }
                  }}
                >
                  Cancel
                </Button>
              </CardActions>
            </form>
          </Card>
        </Cell>
        {/* <Cell size={4}></Cell> */}
        <Cell size={7}>
          {props.match.params.id ? (
            <Fragment>
              <SubscriptionsList
                company={props.match.params.id}
              ></SubscriptionsList>
              <br />
              <Card>
                <Toolbar
                  colored
                  title="Users"
                  actions={
                    <Button
                      component={Link}
                      to={`/user/${null}/${props.match.params.id}`}
                      tooltipLabel="Add User"
                      style={{ marginTop: '30px' }}
                      secondary
                      floating
                    >
                      add
                    </Button>
                  }
                />
                <TableDetails
                  rows={clientData && clientData.Users ? clientData.Users : []}
                  columns={columnsUsers}
                  showFilter={false}
                  showSortingControls={false}
                  tableColumnExtention={tableColumnExtentionUser}
                />
              </Card>
            </Fragment>
          ) : (
            ''
          )}
        </Cell>
      </Grid>

      <DialogContainer
        id="speed-boost"
        visible={showModal}
        title="Do you want to permanently delete this user?"
        actions={[
          {
            secondary: true,
            children: 'Delete',
            onClick: async () => {
              await fetchDelete({ params: [userIdToDelete] });
              await getclient({ params: [props.match.params.id] });
              setShowModal(false);
              toast.success('User has been deleted.');
            },
          },
          {
            primary: true,
            children: 'Cancel',
            onClick: () => {
              setShowModal(false);
            },
          },
        ]}
        onHide={() => {}}
      ></DialogContainer>
    </Fragment>
  );
};
