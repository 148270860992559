import {
  Cell,
  TextField,
  Subheader,
  SelectField,
  Grid,
  Autocomplete,
  // DatePicker,
  Button,
} from 'react-md';
import React, { Fragment, useEffect, useState } from 'react';
import { throttle } from 'lodash/function';
import feathers from '../../../services/feathers';
import Button2 from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { toast } from 'react-toastify';

export default (props) => {
  const { role, status, docType, create } = props;
  let user = localStorage.profile ? JSON.parse(localStorage.profile) : {};
  const [startDate, setStartDate] = useState(null);
  const [findc, setFind] = useState('iata');
  useEffect(() => {
    dateInput(startDate);
  }, [props.cleanAutocomplete]);

  const dateInput = (e) => {
    if (e) {
      let date = moment(e).format('DDMMM').toUpperCase();
      props.setArr({ ...props.arr, arrivalDate: date });
    }
  };
  const CustomInput = ({ value, onClick }) => (
    <TextField
      onClick={onClick}
      disabled={
        (status !== 'DRAFT' && role === 'shipper') ||
        status === 'SENT' ||
        props.house ||
        props.aws === 'House'
      }
      id="floating-center-title-nominated-agent"
      value={value ? value : props.arr.arrivalDate}
      label="Arrival Date"
      lineDirection="center"
      onChange={(e) => {}}
    />
  );

  const consolidation = (e) => {
    let cons = e.consolidationId;
    if (e.consolidationId === 'Master') {
      props.setSimple(false);
      props.setHouse(false);
      props.setAwb({ ...props.awb, consolidationId: 'M', hawbNumber: '' });
    } else if (e.consolidationId === 'House') {
      props.setHouse(true);
      props.setSimple(false);
      props.setAwb({ ...props.awb, consolidationId: '', hawbNumber: '' });
    } else {
      props.setHouse(false);
      props.setSimple(true);
      props.setAwb({ ...props.awb, consolidationId: '', hawbNumber: '' });
    }
    props.setAws(cons);
  };
  const searchAIR = throttle(async (query) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });
    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt:
              value.iata === ''
                ? value.iata + '-' + value.icao
                : value.iata + '-' + value.icao,
            // ,
            // txt: value.iata === undefined ? value.icao : 'cccc'
          }))
        : null;

      props.setAirlines(air);
    }
  }, 250);
  const searchTerminal = throttle(async (query) => {
    let cbpcodes = await feathers.service('cbpcodes').find({
      query: {
        $agregation: {},
        $air: query,
      },
    });

    if (cbpcodes.length > 0) {
      let air = [];

      air = cbpcodes.length
        ? cbpcodes.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country,
            txt:
              value.iata === ''
                ? value.iata + '-' + value.icao
                : value.iata + '-' + value.icao,
            // ,
            // txt: value.iata === undefined ? value.icao : 'cccc'
          }))
        : null;
      props.setTerminal(air);
    }
  }, 250);
  const search = throttle(async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $params: query,
      },
    });
    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt:
              value.iata === 'N'
                ? value.icao + '-' + value.iata
                : value.icao + '-' + value.iata,
          }))
        : null;
      props.setAllCodes(ccl);
    }
    // if (airports.length) {
    //   let air = [];
    //   for (const ports of airports) {
    //     air.push(ports.iataCodes);
    //   }
    //   props.setAllCodes(air);
    // }
  }, 250);
  const searchCCL = throttle(async (query) => {
    let airports = await feathers.service('airports').find({
      query: {
        $agregation: {},
        $ccl: query,
      },
    });
    if (airports.length > 0) {
      let ccl = [];

      ccl = airports.length
        ? airports.map((value) => ({
            ...value,
            find:
              value.iata +
              ' - ' +
              value.name +
              ' - ' +
              value.icao +
              ' - ' +
              value.country +
              ' - ' +
              value.city,
            txt:
              value.iata === 'N'
                ? value.icao + '-' + value.iata
                : value.icao + '-' + value.iata,
          }))
        : null;
      props.setUsCodes(ccl);
    }
    // if (airports.length) {
    //   let air = [];
    //   for (const ports of airports) {
    //     air.push(ports.iataCodes);
    //   }
    //   props.setUsCodes(air);
    // }
  }, 250);
  const handleSearch = (value, seg) => {
    if (value.length) {
      if (seg == 'CCL') {
        searchCCL(value);
      }
      if (seg == 'AIR') {
        searchAIR(value);
      } else {
        search(value);
      }
    }
  };

  return (
    <Fragment>
      <Grid>
        <SelectField
          id="select-field-ams"
          className="md-cell md-cell--1"
          value={props.aws}
          onChange={(e) => {
            if (props.setChange) {
              props.setChange(false);
            }
            consolidation({ consolidationId: e });
          }}
          label="Airway Bill Type"
          menuItems={
            // user.role === 'airline'
            // ? ['Master']
            // : user.role === 'admin'
            // ?
            ['Master', 'Simple', 'House']
            // : ['Simple', 'House']
          }
          position={SelectField.Positions.BOTTOM_RIGHT}
          required
          disabled={
            (status !== 'DRAFT' && role === 'shipper') ||
            (status === 'DRAFT' && docType !== 'FRC'
              ? false
              : status === 'ACCEPTED'
              ? false
              : true) ||
            create === false
          }
        />
        <Cell size={2}>
          <TextField
            id="floating-center-title-awb"
            value={props.awb.prefix ? props.awb.prefix : ''}
            onChange={async (e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 3) {
                props.setAwb({ ...props.awb, prefix: e.toUpperCase() });
              }
            }}
            label="AWB Prefix"
            lineDirection="center"
            required
            disabled={
              (status !== 'DRAFT' && role === 'shipper') ||
              (status === 'DRAFT' && docType !== 'FRC' ? false : true)
            }
            error={
              props.awb.prefix && props.awb.prefix.length < 3 ? true : false
            }
            errorText="length must be 3 alphanumeric characters long"
          />
          <input
            ref={props.prefixRef}
            type="text"
            value={props.awb.prefix}
            required
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
        <Cell size={3}>
          <TextField
            id="floating-center-title-awb#"
            value={props.awb.serialNumber ? props.awb.serialNumber : ''}
            onChange={async (e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              if (e.length <= 8) {
                props.setAwb({
                  ...props.awb,
                  serialNumber: e.toUpperCase(),
                });
              }
            }}
            onBlur={async () => {
              let serial = props.awb.serialNumber;
              let prefix = props.awb.prefix;
              let existSerialNumber = [];
              let arrivalRef;
              let master;
              let house;

              existSerialNumber = await feathers.service('manifests').find({
                query: {
                  'docOrigin.AWB.prefix': prefix,
                  'docOrigin.AWB.serialNumber': serial,
                },
              });
              if (existSerialNumber.length > 0) {
                arrivalRef =
                  existSerialNumber[0].docOrigin.ARR &&
                  existSerialNumber[0].docOrigin.ARR.arrivalRef !== undefined &&
                  existSerialNumber[0].docOrigin.ARR.arrivalRef !== ''
                    ? existSerialNumber[0].docOrigin.ARR.arrivalRef
                    : null;
                master =
                  existSerialNumber[0].docOrigin.AWB &&
                  existSerialNumber[0].docOrigin.AWB.consolidationId !==
                    undefined &&
                  existSerialNumber[0].docOrigin.AWB.consolidationId !== ''
                    ? existSerialNumber[0].docOrigin.AWB.consolidationId
                    : null;
                house =
                  existSerialNumber[0].docOrigin.AWB &&
                  existSerialNumber[0].docOrigin.AWB.hawbNumber !== undefined &&
                  existSerialNumber[0].docOrigin.AWB.hawbNumber !== ''
                    ? existSerialNumber[0].docOrigin.AWB.hawbNumber
                    : null;
              } else {
                existSerialNumber = await feathers
                  .service('docs-pending')
                  .find({
                    query: {
                      'docOrigin.AWB.prefix': prefix,
                      'docOrigin.AWB.serialNumber': serial,
                    },
                  });
                if (existSerialNumber.total > 0) {
                  arrivalRef =
                    existSerialNumber.data[0].docOrigin.ARR &&
                    existSerialNumber.data[0].docOrigin.ARR.arrivalRef !==
                      undefined &&
                    existSerialNumber.data[0].docOrigin.ARR.arrivalRef !== ''
                      ? existSerialNumber.data[0].docOrigin.ARR.arrivalRef
                      : null;

                  master =
                    existSerialNumber.data[0].docOrigin.AWB &&
                    existSerialNumber.data[0].docOrigin.AWB.consolidationId !==
                      undefined &&
                    existSerialNumber.data[0].docOrigin.AWB.consolidationId !==
                      ''
                      ? existSerialNumber.data[0].docOrigin.AWB.consolidationId
                      : null;
                  house =
                    existSerialNumber.data[0].docOrigin.AWB &&
                    existSerialNumber.data[0].docOrigin.AWB.hawbNumber !==
                      undefined &&
                    existSerialNumber.data[0].docOrigin.AWB.hawbNumber !== ''
                      ? existSerialNumber.data[0].docOrigin.AWB.hawbNumber
                      : null;
                }
              }

              if (arrivalRef === null) {
                if (props.aws === 'Master' || props.aws === 'Simple') {
                  if (master !== null) {
                    toast.error('AWB already exists!');
                    props.setAwb({ ...props.awb, serialNumber: '' });
                  }
                }
                if (master === null) {
                  if (house === null) {
                    toast.error('AWB already exists!');
                    props.setAwb({ ...props.awb, serialNumber: '' });
                  }
                }

                if (props.aws === 'Simple') {
                  if (master == null && house !== null) {
                    toast.error('AWB already exists!');
                    props.setAwb({ ...props.awb, serialNumber: '' });
                  }
                }
              }
            }}
            error={
              props.awb.serialNumber && props.awb.serialNumber.length < 8
                ? true
                : false
            }
            errorText="length must be at 8 characters long"
            label="AWB #"
            lineDirection="center"
            type="number"
            required
            disabled={
              (status !== 'DRAFT' && role === 'shipper') ||
              (status === 'DRAFT' && docType !== 'FRC' ? false : true)
            }
          />
          <input
            ref={props.sNRef}
            type="text"
            value={props.awb.serialNumber}
            required
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
        {props.house || props.aws === 'House' ? (
          <Cell size={2}>
            <TextField
              id="floating-center-title-house#"
              required
              value={props.awb.hawbNumber ? props.awb.hawbNumber : ''}
              onChange={async (e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                if (e.length <= 12) {
                  props.setAwb({ ...props.awb, hawbNumber: e.toUpperCase() });
                }
              }}
              onBlur={async () => {
                let serial = props.awb.serialNumber;
                let prefix = props.awb.prefix;
                let hawbNumber = props.awb.hawbNumber;

                let existHawbNumber = [];
                let arrivalRef;
                existHawbNumber = await feathers.service('manifests').find({
                  query: {
                    'docOrigin.AWB.hawbNumber': hawbNumber,
                    'docOrigin.AWB.serialNumber': serial,
                    'docOrigin.AWB.prefix': prefix,
                  },
                });
                if (existHawbNumber.length > 0) {
                  arrivalRef =
                    existHawbNumber[0].docOrigin.ARR &&
                    existHawbNumber[0].docOrigin.ARR.arrivalRef !== undefined &&
                    existHawbNumber[0].docOrigin.ARR.arrivalRef !== ''
                      ? existHawbNumber[0].docOrigin.ARR.arrivalRef
                      : null;
                } else {
                  existHawbNumber = await feathers
                    .service('docs-pending')
                    .find({
                      query: {
                        'docOrigin.AWB.hawbNumber': hawbNumber,
                        'docOrigin.AWB.serialNumber': serial,
                        'docOrigin.AWB.prefix': prefix,
                      },
                    });
                  if (existHawbNumber.total > 0) {
                    arrivalRef =
                      existHawbNumber.data[0].docOrigin.ARR &&
                      existHawbNumber.data[0].docOrigin.ARR.arrivalRef !==
                        undefined &&
                      existHawbNumber.data[0].docOrigin.ARR.arrivalRef !== ''
                        ? existHawbNumber.data[0].docOrigin.ARR.arrivalRef
                        : null;
                  }
                }

                if (arrivalRef === null && hawbNumber.length > 0) {
                  toast.error('AWB/HAWB already exists!');
                  props.setAwb({ ...props.awb, hawbNumber: '' });
                }
              }}
              label="House AWB #"
              lineDirection="center"
              error={
                props.awb.hawbNumber && props.awb.hawbNumber.length < 1
                  ? true
                  : false
              }
              errorText="length must be greater or equal than 1 characters and less than 12 characters long"
              disabled={
                (status !== 'DRAFT' && role === 'shipper') ||
                (status === 'DRAFT' && docType !== 'FRC' ? false : true)
              }
            />
            <input
              ref={props.hawbRef}
              type="text"
              value={props.awb.hawbNumber ? props.awb.hawbNumber : ''}
              required
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        ) : null}
        {!props.house && props.aws !== 'House' ? (
          <Cell size={2}>
            <TextField
              id="floating-center-title-nominated-agent"
              value={props.agt.participantCode ? props.agt.participantCode : ''}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                if (e.length <= 7) {
                  props.setAgt({
                    ...props.agt,
                    participantCode: e.toUpperCase(),
                  });
                }
              }}
              label="Nominated Agent"
              lineDirection="center"
              disabled={
                (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
              }
              error={
                props.agt.participantCode &&
                props.agt.participantCode.length < 2
                  ? true
                  : false
              }
              errorText="length must be greater or equal than 2 characters and less than 7 characters long"
            />
          </Cell>
        ) : null}
        <Cell size={4}>
          {props.allCodes !== undefined ? (
            <Autocomplete
              id="all-codes-origin"
              required
              label="Airport Origin"
              data={props.allCodes.slice(0, 30)}
              value={
                props.autocompletesAir
                  ? props.autocompletesAir.airportOrigin
                  : props.allCodes.name
              }
              dataLabel={'find'}
              dataValue={'txt'}
              filter={null}
              findInlineSuggestion={Autocomplete.findIgnoreCase}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }

                handleSearch(e);
                props.setWbl({
                  ...props.wbl,
                  airportOrigin: e.toUpperCase(),
                });
                if (props.autocompletesAir) {
                  props.setAutocompletesAir({
                    ...props.autocompletesAir,
                    airportOrigin: e.toUpperCase(),
                  });
                }
              }}
              onAutocomplete={(v) => {
                let find = v.split('-');
                let c = props.allCodes;
                props.setWbl({
                  ...props.wbl,
                  airportOrigin: v === 'N' ? c[0].icao : find[1],
                });
                if (props.autocompletesAir) {
                  let f = props.allCodes.filter((x) => x.txt === v)[0];
                  props.setAutocompletesAir({
                    ...props.autocompletesAir,
                    airportOrigin: f.find,
                  });
                }
              }}
              disabled={
                (status !== 'DRAFT' && role === 'shipper') || status === 'SENT'
              }
            />
          ) : null}
          <input
            ref={props.airO}
            type="text"
            value={props.wbl.airportOrigin}
            required
            onChange={() => {}}
            style={{
              marginTop: '-23px',
              position: 'absolute',
              zIndex: '-1',
            }}
          ></input>
        </Cell>
      </Grid>
      <Grid>
        {props.aws !== 'House' ? (
          <Cell size={4}>
            {props.airlines !== undefined ? (
              <Autocomplete
                id="codes-airline"
                required
                label="Airline"
                data={props.airlines.slice(0, 30)}
                dataLabel={'find'}
                dataValue={'txt'}
                filter={null}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                value={
                  props.autocompletesA
                    ? props.autocompletesA.impCarrier
                    : props.airlines.name
                }
                onChange={(e) => {
                  if (props.setChange) {
                    props.setChange(false);
                  }

                  handleSearch(e, 'AIR');

                  props.setArr({ ...props.arr, impCarrier: e.toUpperCase() });

                  if (props.autocompletesA) {
                    props.setAutocompletesA({
                      ...props.autocompletesA,
                      impCarrier: e.toUpperCase(),
                    });
                  }
                }}
                onAutocomplete={(v) => {
                  let find = v.split('-');
                  let c = props.airlines;
                  props.setArr({
                    ...props.arr,
                    impCarrier: v === '' ? c[0].icao : find[0] || find[1],
                  });
                  if (props.autocompletesA) {
                    let f = props.airlines.filter((x) => x.txt === v)[0];
                    props.setAutocompletesA({
                      ...props.autocompletesA,
                      impCarrier: f.find,
                    });
                  }
                }}
                disabled={
                  (status !== 'DRAFT' && role === 'shipper') ||
                  status === 'SENT'
                  // status === 'DRAFT' ||
                  // status === 'ACCEPTED' ||
                  // status === 'REJECTED' ||
                  // status === 'SENDING'
                  //   ? false
                  //   : true
                }
              />
            ) : null}
            <input
              ref={props.impC}
              type="text"
              value={props.arr.impCarrier}
              required
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        ) : null}
        {props.aws === 'House' ? (
          <Cell size={3}>
            <TextField
              id="floating-center-title-flight#"
              value={`${props.arr.impCarrier || ''}${
                props.arr.flightNumber || ''
              }`}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                if (e.length <= 5) {
                  props.setArr({ ...props.arr, flightNumber: e.toUpperCase() });
                }
              }}
              label="Flight #"
              lineDirection="center"
              disabled={
                (status !== 'DRAFT' && role === 'shipper') ||
                ((status === 'DRAFT' && docType !== 'FRC') ||
                status === 'REJECTED'
                  ? false
                  : status === 'SENT' || status === 'CANCELLED'
                  ? true
                  : false) ||
                props.house ||
                props.aws === 'House'
              }
              error={
                props.arr.flightNumber && props.arr.flightNumber.length < 3
                  ? true
                  : false
              }
              errorText="length must be greater or equal than 3 characters and less than 5 characters long"
            />
            <input
              ref={props.flightNumberRef}
              type="text"
              value={props.arr.flightNumber}
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        ) : (
          <Cell size={3}>
            <TextField
              id="floating-center-title-flight#"
              value={props.arr.flightNumber ? props.arr.flightNumber : ''}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                if (e.length <= 5) {
                  props.setArr({ ...props.arr, flightNumber: e.toUpperCase() });
                }
              }}
              label="Flight #"
              lineDirection="center"
              type="number"
              required
              disabled={
                (status !== 'DRAFT' && role === 'shipper') ||
                ((status === 'DRAFT' && docType !== 'FRC') ||
                status === 'REJECTED'
                  ? false
                  : status === 'SENT'
                  ? true
                  : status === 'ACCEPTED'
                  ? false
                  : false)
              }
              error={
                props.arr.flightNumber && props.arr.flightNumber.length < 3
                  ? true
                  : false
              }
              errorText="length must be greater or equal than 3 characters and less than 5 characters long"
            />
            <input
              ref={props.flightNumberRef}
              type="text"
              value={props.arr.flightNumber}
              required
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        )}
        {props.aws === 'House' ? (
          <Cell size={5}>
            {props.terminal !== undefined ? (
              <Autocomplete
                id="codes-terminal-operator"
                label="Terminal Operator"
                data={props.terminal.slice(0, 30)}
                dataLabel={'find'}
                dataValue={'txt'}
                filter={null}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                value={
                  props.autocompletesT
                    ? props.autocompletesT.terminalOp
                    : props.terminal.name
                }
                onChange={(e) => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  if (e.length >= 1) {
                    searchTerminal(e);
                  }
                  props.setCcl({ ...props.ccl, terminalOp: e.toUpperCase() });
                  if (props.autocompletesT) {
                    props.setAutocompletesT({
                      ...props.autocompletesT,
                      terminalOp: e.toUpperCase(),
                    });
                  }
                }}
                onAutocomplete={(v) => {
                  let find = v.split('-');
                  let c = props.terminal;
                  props.setCcl({
                    ...props.ccl,
                    terminalOp: v === '' ? c[0].icao : find[0] || find[1],
                  });
                  if (props.autocompletesT) {
                    let f = props.terminal.filter((x) => x.txt === v)[0];
                    props.setAutocompletesT({
                      ...props.autocompletesT,
                      terminalOp: f.find,
                    });
                  }
                }}
                disabled={
                  (status !== 'DRAFT' && role === 'shipper') ||
                  status === 'SENT' ||
                  props.house ||
                  props.aws === 'House'
                }
              />
            ) : null}
            <input
              ref={props.tOp}
              type="text"
              value={props.ccl.terminalOp}
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        ) : (
          <Cell size={5}>
            {props.terminal !== undefined ? (
              <Autocomplete
                id="codes-terminal-operator"
                required
                label="Terminal Operator"
                data={props.terminal.slice(0, 30)}
                dataLabel={'find'}
                dataValue={'txt'}
                filter={null}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                value={
                  props.autocompletesT
                    ? props.autocompletesT.terminalOp
                    : props.terminal.name
                }
                onChange={(e) => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  if (e.length >= 1) {
                    searchTerminal(e);
                  }
                  props.setCcl({ ...props.ccl, terminalOp: e.toUpperCase() });
                  if (props.autocompletesT) {
                    props.setAutocompletesT({
                      ...props.autocompletesT,
                      terminalOp: e.toUpperCase(),
                    });
                  }
                }}
                onAutocomplete={(v) => {
                  let find = v.split('-');
                  let c = props.terminal;
                  props.setCcl({
                    ...props.ccl,
                    terminalOp: v === '' ? c[0].icao : find[0] || find[1],
                  });
                  if (props.autocompletesT) {
                    let f = props.terminal.filter((x) => x.txt === v)[0];
                    props.setAutocompletesT({
                      ...props.autocompletesT,
                      terminalOp: f.find,
                    });
                  }
                }}
                disabled={
                  (status !== 'DRAFT' && role === 'shipper') ||
                  status === 'SENT'
                }
              />
            ) : null}
            <input
              ref={props.tOp}
              type="text"
              value={props.ccl.terminalOp}
              required
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        )}

        {props.aws === 'House' ? (
          <Cell size={3}>
            {props.usCodes !== undefined ? (
              <Autocomplete
                id="all-codes-arrival"
                label="1st US Airport of Arrival"
                value={
                  props.autocompletesAirArr
                    ? props.autocompletesAirArr.arrivalAirport
                    : props.usCodes.name
                }
                data={props.usCodes.slice(0, 30)}
                dataLabel={'find'}
                dataValue={'txt'}
                filter={null}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                onChange={(e) => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  setFind(e.length >= 4 ? 'name' : 'iata');
                  handleSearch(e, 'CCL');
                  props.setCcl({
                    ...props.ccl,
                    arrivalAirport: e.toUpperCase(),
                  });
                  if (props.autocompletesAirArr) {
                    props.setAutocompletesAirArr({
                      ...props.autocompletesAirArr,
                      arrivalAirport: e.toUpperCase(),
                    });
                  }
                }}
                onAutocomplete={(v) => {
                  let find = v.split('-');

                  let c = props.usCodes;
                  props.setCcl({
                    ...props.ccl,
                    arrivalAirport: v === 'N' ? c[0].icao : find[1],
                  });
                  if (props.autocompletesAirArr) {
                    let f = props.usCodes.filter((x) => x.txt === v)[0];
                    props.setAutocompletesAirArr({
                      ...props.autocompletesAirArr,
                      arrivalAirport: f.find,
                    });
                  }
                }}
                disabled={
                  (status !== 'DRAFT' && role === 'shipper') ||
                  status === 'SENT' ||
                  props.house ||
                  props.aws === 'House'
                }
              />
            ) : null}
            <input
              ref={props.arrAirport}
              type="text"
              value={props.ccl.arrivalAirport}
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        ) : (
          <Cell size={3}>
            {props.usCodes !== undefined ? (
              <Autocomplete
                id="all-codes-arrival"
                required
                label="1st US Airport of Arrival"
                value={
                  props.autocompletesAirArr
                    ? props.autocompletesAirArr.arrivalAirport
                    : props.usCodes.name
                }
                data={props.usCodes.slice(0, 30)}
                dataLabel={'find'}
                dataValue={'txt'}
                filter={null}
                findInlineSuggestion={Autocomplete.findIgnoreCase}
                onChange={(e) => {
                  if (props.setChange) {
                    props.setChange(false);
                  }
                  setFind(e.length >= 4 ? 'name' : 'iata');
                  handleSearch(e, 'CCL');
                  props.setCcl({
                    ...props.ccl,
                    arrivalAirport: e.toUpperCase(),
                  });
                  if (props.autocompletesAirArr) {
                    props.setAutocompletesAirArr({
                      ...props.autocompletesAirArr,
                      arrivalAirport: e.toUpperCase(),
                    });
                  }
                }}
                onAutocomplete={(v) => {
                  let find = v.split('-');

                  let c = props.usCodes;
                  props.setCcl({
                    ...props.ccl,
                    arrivalAirport: v === 'N' ? c[0].icao : find[1],
                  });
                  if (props.autocompletesAirArr) {
                    let f = props.usCodes.filter((x) => x.txt === v)[0];
                    props.setAutocompletesAirArr({
                      ...props.autocompletesAirArr,
                      arrivalAirport: f.find,
                    });
                  }
                }}
                disabled={
                  (status !== 'DRAFT' && role === 'shipper') ||
                  status === 'SENT'
                }
              />
            ) : null}
            <input
              ref={props.arrAirport}
              type="text"
              value={props.ccl.arrivalAirport}
              required
              onChange={() => {}}
              style={{
                marginTop: '-23px',
                position: 'absolute',
                zIndex: '-1',
              }}
            ></input>
          </Cell>
        )}
        <Cell size={2} fontSize={14}>
          <DatePicker
            dateFormat="ddMMM"
            customInput={<CustomInput />}
            placeholderText="Select A Date"
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              setStartDate(e);
              dateInput(e);
            }}
            withPortal
            disabled={
              (status !== 'DRAFT' && role === 'shipper') ||
              status === 'SENT' ||
              props.house ||
              props.aws === 'House'
            }
          />
        </Cell>
      </Grid>
      <Grid></Grid>
      <Grid></Grid>
      {props.expr ? (
        <Grid>
          <Cell size={4}>
            <TextField
              id="floating-center-title-entry-type"
              value={props.ced.entryType}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                props.setCed({ ...props.ced, entryType: e.toUpperCase() });
              }}
              label="Entry Type"
              lineDirection="center"
            />
          </Cell>
          <Cell size={4}>
            <TextField
              id="floating-center-title-consolidated"
              value={props.ced.entryNumber}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                props.setCed({ ...props.ced, entryNumber: e.toUpperCase() });
              }}
              label="Consolidated Entry Number"
              lineDirection="center"
            />
          </Cell>
        </Grid>
      ) : null}
      {props.replace !== undefined || props.delete !== undefined ? (
        <Grid>
          <SelectField
            id="floating-center-title-amm-code"
            value={props.rfa.amendCode}
            className="md-cell"
            onChange={(e) => {
              if (props.setChange) {
                props.setChange(false);
              }
              props.setRfa({
                ...props.rfa,
                amendCode: e.match(/[0-9]{2}/g)[0],
              });
            }}
            label="Amendment Code"
            menuItems={amendmentDescription}
          />
          <Cell size={8}>
            <TextField
              id="floating-center-title-reason"
              value={props.rfa.amendExp}
              onChange={(e) => {
                if (props.setChange) {
                  props.setChange(false);
                }
                props.setRfa({ ...props.rfa, amendExp: e.toUpperCase() });
              }}
              label="Reason"
              lineDirection="center"
            />
          </Cell>
        </Grid>
      ) : null}
    </Fragment>
  );
};

const amendmentDescription = [
  '01 Not laden aboard per evidence from foreign shipper or amended bill of lading.',
  '02 Error in manifesting.  Not laden on this flight.  Laden on subsequent flight for transportation to the U.S.',
  '03 Clerical error in manifesting per air waybill in files.',
  '04 Pilfered or prematurely landed prior to arrival in the U.S. per signed statement of master/ his agent or log extract in the carrier/deconsolidator/freight forwarder files.',
  '05 Erroneously duplicated by another air waybill on same flight.',
  '06 Prematurely unladen or over carried to another U.S. port where proper disposition was made per evidence in our files.',
  '07 Inadvertently retained onboard and taken foreign per statement of master/his agent.  Amended air waybill or landing certificate in our files.',
  '08 Container stripped under CBP Supervision.  Foreign seals affixed abroad were intact per evidence in our files.',
  '09 Merchandise apparently pilfered on dock while in custody of carrier.',
  '10 Inadvertently delivered without CBP authorization.  Goods will be redelivered intact or carrier will pay duty and taxes.',
  '11 Overage; omitted from manifest through clerical error.',
  '12 Overage; manifested for discharge at another port and inadvertently discharged at this port.',
  '13 Proper entry filed or placed in G.O. per entry or G.O. number.',
  '14 Merchandise inadvertently delivered to consignee without CBP authorization.  Merchandise will be redelivered intact or liquidated damages paid.',
  '15 Merchandise cannot be located and has apparently been lost.  Liquidated damages will be paid.',
  '16 Error in quantity manifested at airport of origin.',
  '17 Restuffing a container.',
  '18 Transmission delayed by electronic communications failure.',
  '19 Amendment to non-critical fields or provision of additional data to record.  Quantity manifested is not changed and original report was timely.',
  '20 Provision of detail data to air waybill record by a Deconsolidator.',
  '21 Provision of detail data to an express consignment record or a change in optional data previously submitted for an express consignment record.',
  '22 Record established by onward (in-bond) air carrier when importing air carrier is not an AMS air carrier Onward air carrier code required for AMS validation of transfer (TRN) detail.',
  '23 Original transmission of record by AMS Deconsolidator.  Importing carrier is not an AMS air carrier at U.S. airport where consolidation is unladen from importing flight.',
];
