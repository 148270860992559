import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import { FSN3 } from '../../../components/';
import { useApi } from '../../../services/api';
import Axios from 'axios';
import feathers from '../../../services/feathers';

const env = process.env;

const CreateFSN = ({ isOpen, setIsOpen, filteredRows, selection, company }) => {
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');
  const [, , fetchDoc] = useApi(
    'ccapi',
    `documents${company ? '?company=' + company : ''}`,
    undefined,
    'post'
  );
  const [startDate, setStartDate] = useState(new Date());
  const [asn, setAsn] = useState({ statusCode: '' });
  const [ccl, setCcl] = useState({ arrivalAirport: '', terminalOp: '' });
  const [arr, setArr] = useState({
    impCarrier: '',
    flightNumber: '',
    arrivalDate: '',
    arrivalRef: '',
  });
  const [disabledArrivalRef, setDisabledArrivalRef] = useState(true);
  const disabled =
    (arr.impCarrier && arr.impCarrier.length < 2) ||
    arr.flightNumber === '' ||
    (ccl.arrivalAirport && ccl.arrivalAirport.length < 2) ||
    (ccl.terminalOp && ccl.terminalOp.length < 2) ||
    asn.statusCode === '';
  const [airlines, setAirlines] = useState([]);
  const [usCodes, setUsCodes] = useState([]);
  const [terminal, setTerminal] = useState([]);
  const [checkedRows, setCheckedRows] = useState([]);

  useEffect(() => {
    start();
  }, [isOpen]);

  const start = async () => {
    if (isOpen) {
      let checked = [];
      for (const select of selection) {
        checked.push(filteredRows[select]);
      }
      setCheckedRows(checked);
      setDisabledArrivalRef(checked.length !== 1);
      let data = checked[0];
      let query = {
        'docOrigin.AWB.prefix': data.prefix,
        'docOrigin.AWB.serialNumber': data.serialNumber,
        'docOrigin.AWB.hawbNumber': data.hawbNumber,
      };
      if (data.flightNumber) {
        query['docOrigin.ARR.flightNumber'] = data.flightNumber;
      }
      let other = await feathers.service('manifests').find({
        query: query,
      });
      if (
        other &&
        other.length &&
        other[0].docOrigin &&
        other[0].docOrigin.ARR
      ) {
        setArr({
          ...arr,
          impCarrier: other[0].docOrigin.ARR.impCarrier,
          flightNumber: other[0].docOrigin.ARR.flightNumber,
          arrivalDate: other[0].docOrigin.ARR.arrivalDate,
          arrivalRef: '',
        });
        if (checked.length === 1) {
          setArr({
            ...arr,
            impCarrier: other[0].docOrigin.ARR.impCarrier,
            flightNumber: other[0].docOrigin.ARR.flightNumber,
            arrivalDate: other[0].docOrigin.ARR.arrivalDate,
            arrivalRef: other[0].docOrigin.ARR.arrivalRef || '',
          });
        }
      }
      if (
        other &&
        other.length &&
        other[0].docOrigin &&
        other[0].docOrigin.CCL
      ) {
        setCcl(other[0].docOrigin && other[0].docOrigin.CCL);
      }
    }
  };

  const handleClose = () => setIsOpen(false);

  const send = async (id) => {
    let [response, err] = await doSend({ body: id });
    if (err) return;
    toast.success(`${id.length} ${response.message}`);
  };

  const handleSubmit = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;

    const response2 = await Axios.get(
      env['REACT_APP_AUTH'] + '/validPartnership'
    );

    if (response2.data.success) {
      asn.componentId = 'ASN';
      arr.componentId = 'ARR';
      const promises = [];
      if (Array.isArray(checkedRows)) {
        checkedRows.forEach(
          async ({ prefix, serialNumber, hawbNumber, checked }) => {
            if (checked) {
              const AWB = {
                prefix: prefix || '',
                serialNumber: serialNumber || '',
                hawbNumber: hawbNumber || '',
                pkTrackingId: '',
              };
              let ARR = JSON.parse(JSON.stringify(arr));
              if (checkedRows.filter((x) => x.checked).length > 1) {
                let houses = await feathers.service('manifests').find({
                  query: {
                    'docOrigin.AWB.prefix': prefix || '',
                    'docOrigin.AWB.serialNumber': serialNumber || '',
                    'docOrigin.AWB.hawbNumber': hawbNumber || '',
                    'docOrigin.ARR.flightNumber': arr.flightNumber,
                  },
                });
                if (
                  houses.length &&
                  houses[0].docOrigin &&
                  houses[0].docOrigin.ARR &&
                  houses[0].docOrigin.ARR.arrivalRef
                ) {
                  ARR.arrivalRef = houses[0].docOrigin.ARR.arrivalRef;
                }
              }

              promises.push(
                fetchDoc({
                  body: {
                    SMI: { componentId: 'FSN' },
                    CCL: ccl,
                    AWB,
                    ARR: ARR,
                    ASN: asn,
                  },
                })
              );
            }
          }
        );
      }

      try {
        let msg = '';
        const msgs = [];
        const succ = [];
        const toSend = [];
        await Promise.all(promises)
          .then((responses) => {
            responses.forEach(async ([res, err]) => {
              if (err) return;
              if (res) {
                const { errors, message, doc } = res;
                if (errors) {
                  const {
                    AWB: { prefix, serialNumber },
                  } = doc;
                  errors.forEach(({ message: msg }) => {
                    msgs.push({ msg, doc: `${prefix}-${serialNumber}` });
                  });
                  msg = message;
                } else {
                  const {
                    docOrigin: {
                      AWB: { prefix, serialNumber },
                    },
                    _id,
                  } = message;
                  succ.push(`${prefix}-${serialNumber}`);
                  toSend.push(_id);
                }
              }
            });
          })
          .catch((e) => {
            console.log({ e });
          });
        if (succ.length) {
          let detail = '';
          succ.forEach((s) => {
            detail +=
              detail === ''
                ? `${succ.length} FSN successfully created for: ${s}`
                : `, ${s}`;
          });
          toast.success(detail);
        }
        if (msgs.length) {
          let detail = '';
          msgs.forEach(({ msg, doc }) => {
            detail +=
              detail === ''
                ? `${msg} at: ${doc}`
                : detail.includes(msg)
                ? `, ${doc}`
                : `, ${msg} at: ${doc}`;
          });
          toast.warn(`${msg}, ${detail}`);
        }
        if (toSend.length) {
          await send(toSend);
          setIsOpen && setIsOpen(false);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.warn(response2.data.message);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth={true}
      aria-labelledby="form-dialog-title"
    >
      <Form noValidate autoComplete="off">
        <DialogContent>
          <FSN3
            arr={arr}
            disabledArrivalRef={disabledArrivalRef}
            setDisabledArrivalRef={setDisabledArrivalRef}
            setArr={setArr}
            asn={asn}
            setAsn={setAsn}
            ccl={ccl}
            setCcl={setCcl}
            startDate={startDate}
            setStartDate={setStartDate}
            airlines={airlines}
            setAirlines={setAirlines}
            usCodes={usCodes}
            setUsCodes={setUsCodes}
            terminal={terminal}
            setTerminal={setTerminal}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            style={{
              color: '#ffffff',
              backgroundColor: disabled ? '#8f9096' : '#3f51b5',
            }}
            variant="contained"
            color="primary"
            disabled={disabled}
          >
            Create & Send
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  );
};

const Form = styled.form``;

export default CreateFSN;
