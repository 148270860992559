import React, { Fragment, useState, useEffect } from 'react';
import { DialogContainer } from 'react-md';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import feathers from '../../services/feathers';

export default props => {
  const {
    visible,
    setDelete,
    doc,
    history,
    getPendings,
    manifestStatus,
    onClose,
    onGetManifest
  } = props;

  const [, , fetchDelete] = useApi('ccapi', 'documents', {}, 'delete');

  const del = async () => {
    let message = null;
    if (doc._id !== null) {
      let [res, err] = await fetchDelete({ params: [doc._id] });
      if (err) return;
      if (res.message) {
        message = res.message;
      }
    }
    setDelete(false);
    if (getPendings) {
      getPendings();
    }
    if (manifestStatus) {
      await feathers
        .service('awb-status')
        .patch(manifestStatus._id, { deleted: true });
      onClose(false);
      onGetManifest();
    }
    toast.success(message);
    if (history) {
      // history.push({ pathname: '/send-manifest' });
    }
  };
  // console.log('a')
  return (
    <Fragment>
      {Object.keys(doc).length ? (
        <DialogContainer
          id="speed-boost"
          visible={visible}
          title="Do you want to delete document?"
          actions={[
            {
              secondary: true,
              children: 'Delete',
              onClick: del
            },
            {
              primary: true,
              children: 'Close',
              onClick: () => {
                setDelete(false);
              }
            }
          ]}
          onHide={() => setDelete(false)}
        ></DialogContainer>
      ) : null}
    </Fragment>
  );
};
