import React, { Fragment, useState, useEffect } from 'react';
import {
  Grid,
  Cell,
  Card,
  CardTitle,
  CardActions,
  Button,
  CardText,
} from 'react-md';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import feathers from '../../services/feathers';

export default (props) => {
  const { close, deletes, manifests } = props;

  return (
    <Fragment>
      <Grid>
        <Cell size={8} tabletSize={8} phoneSize={12}>
          <Card>
            <CardTitle title={`Do you want to delete this Manifests?`} />
            <CardText>
              <Grid>
                <Cell size={2}>
                  <Button
                    raised
                    secondary
                    iconBefore={false}
                    onClick={async (e) => {
                      deletes(manifests);
                      close();
                    }}
                  >
                    Delete
                  </Button>
                </Cell>
                <Cell size={2}>
                  <Button
                    raised
                    secondary
                    iconBefore={false}
                    onClick={(e) => {
                      close();
                    }}
                  >
                    Close
                  </Button>
                </Cell>
              </Grid>
            </CardText>
          </Card>
        </Cell>
      </Grid>
    </Fragment>
  );
};
