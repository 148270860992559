import React, { useState, useEffect, Fragment } from 'react';
import { CheckCircleRounded, CancelRounded } from '@material-ui/icons';
import feathers from '../../../../services/feathers';

export default ({ row }) => {
  const [status2, setStatus2] = useState({ ...row });
  // feathers.service('manifests').on('status', data => {
  //   if (row.hasOwnProperty('hawbNumber')) {
  //     if (
  //       row.prefix == data.prefix &&
  //       row.serialNumber == data.serialNumber &&
  //       row.flightNumber == data.flightNumber &&
  //       data.hawbNumber &&
  //       row.hawbNumber == data.hawbNumber
  //     ) {
  //       setStatus2({
  //         ...status2,
  //         status: data.status,
  //         docType: data.docType,
  //         code: data.code,
  //         message: data.message,
  //         message2: data.message2
  //       });
  //     }
  //   } else {
  //     if (
  //       row.prefix == data.prefix &&
  //       row.serialNumber == data.serialNumber &&
  //       row.flightNumber == data.flightNumber &&
  //       !data.hawbNumber &&
  //       row.hawbNumber == data.hawbNumber &&
  //       row.type == data.type
  //     ) {
  //       setStatus2({
  //         ...status2,
  //         status: data.status,
  //         docType: data.docType,
  //         code: data.code,
  //         message: data.message,
  //         message2: data.message2
  //       });
  //     }
  //   }
  // });
  let total1C = row['1C'];
  let holds = row['AMSHold'];
  let totalAccepted = row.stats ? row.stats.accepted : null;
  let status = status2.status || 'DRAFT';
  let docType = status2.docType || 'FRI';
  let code = status2.code || null;
  let message = status2.message || null;
  let message2 = status2.message2 || null;
  let ARR = message2 && message2.ARR ? message2.ARR : null;
  let WBL = message2 && message2.WBL ? message2.WBL : null;
  let TXT = message2 && message2.TXT ? message2.TXT : null;
  let ERR = message2 && message2.ERR ? message2.ERR : null;
  let arrayFragment = [];
  let statusCodeDescription = [
    'RECORD NOT ON FILE',
    'RECORD IN DELETED STATUS',
    'BILL IS SPLIT - SEE LIST BELOW.',
    'NOT AUTHORIZED TO RECEIVE INFORMATION',
    'BILL INCOMPLETE - NOTIFICATIONS NOT SENT',
    'NO NOTIFICATIONS SENT TO REQUESTER',
    'NOTIFICATIONS RESENT',
    'BILL STATUS INFORMATION FOLLOWS',
    'ROUTING INFORMATION FOLLOWS',
    'NOMINATED AGENT',
    'CURRENT AIR WAYBILL INFORMATION ON FILE FOLLOWS',
  ];
  let icon =
    status == 'ACCEPTED' ||
    status == 'SENT' ||
    status == 'SENDING' ||
    status == 'DRAFT' ? (
      <CheckCircleRounded
        style={{
          color:
            status == 'ACCEPTED' && total1C == totalAccepted && holds === 0
              ? 'green'
              : status == 'ACCEPTED'
              ? '#FFE333'
              : status == 'DRAFT'
              ? 'gray'
              : 'red',
        }}
      />
    ) : (
      <CancelRounded style={{ color: 'red' }} />
    );
  // icon =
  //   status == 'ACCEPTED' ? (
  //     <CheckCircleRounded
  //       style={{
  //         color:
  //           status == 'ACCEPTED' && total1C == totalAccepted
  //             ? 'green'
  //             : '#FFE333'
  //       }}
  //     />
  //   ) : (
  //     ''
  //   );
  let description =
    status == 'ACCEPTED' || (status == 'REJECTED' && docType == 'FSC') ? (
      TXT && Array.isArray(TXT) ? (
        TXT.map((value, key) => {
          // if (
          //   code == '07' &&
          //   row.FDM == null &&
          //   value.information != 'MASTER AWB'
          // ) {
          //   return (
          //     <Fragment key={key}>
          //       {code} - {value.information}
          //       {/* <br />
          //       {'04'} - {'FLIGHT NOT DEPARTED FOREIGN'}
          //       <br /> */}
          //     </Fragment>
          //   );
          // }
          // if (code == '04') {
          //   return (
          //     <Fragment key={key}>
          //       {code} - {value.information}
          //       {/* <br />
          //       {'07'} - {'NO ENTRY FILED'}
          //       <br /> */}
          //     </Fragment>
          //   );
          // }
          return (
            <Fragment key={key}>
              {code} - {value.information}
              <br />
            </Fragment>
          );
        })
      ) : TXT && !Array.isArray(TXT) ? (
        <Fragment>
          {code} - {TXT.information}
          <br />
        </Fragment>
      ) : WBL || ARR ? (
        <Fragment>
          {code} -{' '}
          {WBL
            ? `WBL: 
          ${WBL.airportOrigin}/
            ${WBL.cargoDesc}/
            ${WBL.numPieces}/
            ${WBL.shipmentDesc}/
            ${WBL.weight}/
            ${WBL.weightCode}`
            : ``}
          <br />
          {ARR
            ? `ARR: ${ARR.impCarrier}${ARR.flightNumber}/${ARR.arrivalDate}`
            : ``}
        </Fragment>
      ) : (
        <div className="status-text-style">
          {code} - {message ? message : statusCodeDescription[parseInt(code)]}
        </div>
      )
    ) : ERR ? (
      ERR.map((value, key) => {
        return (
          <Fragment key={key}>
            {value.errorCode} - {value.errorText}
            <br />
          </Fragment>
        );
      })
    ) : status == 'SENDING' && status2.errorList ? (
      status2.errorList.map((value, key) => {
        if (value.active == true) {
          arrayFragment.push(
            <Fragment key={key + row.serialNumber}>
              {value.errorDetail}
              <br />
            </Fragment>
          );
        }
        //console.log('key', key - 1, status2.errorList.length);
        if (key == status2.errorList.length - 1) {
          if (arrayFragment.length > 0) {
            arrayFragment.splice(
              0,
              0,
              <Fragment key={key + row.serialNumber}>
                {'File not sent, errors found:'}
                <br />
              </Fragment>
            );
          }
          return arrayFragment;
        }
      })
    ) : (
      ''
    );
  return (
    <div>
      {icon}{' '}
      <div className="status-text-style">
        {status} ({docType})
      </div>
      <br />
      {status == 'SENT' || status == 'SENDING' ? '' : description}
    </div>
  );
};
