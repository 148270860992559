import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Button from '@material-ui/core/Button';

const ButtonFSN = ({ row, value, ...restProps }) => {
  return (
    <Table.Cell {...restProps}>
      {row.docTranslated.CSN && row.docTranslated.CSN.actionCode === '1F' ? (
        <Button
          key={value}
          color="primary"
          variant="outlined"
          href={`/create-notification/${value}`}
        >
          Create ASN
        </Button>
      ) : (
        'N/A'
      )}
    </Table.Cell>
  );
};

export default ButtonFSN;
