import React from 'react';
import styled from 'styled-components';

export default props => {
  return (
    <IndicatorContainer>{props.message || 'Loading...'}</IndicatorContainer>
  );
};

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200;
`;
