import React, { useEffect, useState } from 'react';
import GridContainer from '../../components/Ag-Grid/GridContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import {
  sideBar,
  FSNOptions as options,
} from '../../constants/Ag-Grid/constants';
import {
  valueGetter,
  cellRendererFramework,
  valueFormatter,
} from '../../functions/Ag-Grid/functions';
import MyDropdown from '../../components/Ag-Grid/CellRenderer/Dropdown';
import GridButton from '../../components/Ag-Grid/CellRenderer/GridButton';

const Table = ({ data, gridActions: functions, setGridApi }) => {
  const filter = false;
  const fixed = false;

  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    functions,
    cellClass: 'cell-wrap-text',
    suppressMenu: true,
    valueGetter,
    cellRendererFramework,
    valueFormatter,
    rowGroup: false,
  };

  const columnDefs = [
    { field: 'number', headerName: '#', width: 80, fixed },
    { field: 'AWB', headerName: 'AWB #', fixed, width: 150 },
    { field: 'isHouse', headerName: 'HAWB', fixed, width: 150 },
    {
      field: 'totalHouse',
      headerName: "# of HAWB's",
      fixed,
      minWidth: 135,
      width: 135,
    },
    {
      field: 'flightInfo',
      headerName: 'Flight Arrival Info',
      width: 600,
    },
    { field: 'isSplit', headerName: 'Split Shipment' },
    { field: 'transferStatus', headerName: 'Transfer Status', width: 350 },
    { field: 'arrivalStatus', headerName: 'Arrival Status', width: 350 },
    {
      field: 'notificationSummary',
      headerName: 'Notification Summary',
      width: 350,
    },
    { field: 'errorSummary', headerName: 'Error Summary', width: 350 },
    { field: 'createDate', headerName: 'Created', width: 180 },
    { field: 'submittedDate', headerName: 'Submittted', width: 180 },
    { field: 'responseDate', headerName: 'Response', width: 180 },
    { field: 'checkIn', headerName: 'Check In' },
    { field: 'checkOut', headerName: 'Check Out' },
    { field: 'options', headerName: 'Options', filter, options },
  ];

  const frameworkComponents = {
    optionsRenderer: MyDropdown,
    GridButton: GridButton,
  };

  const getRowHeight = ({ data }) => (data && data.height) || 50;

  return (
    <GridContainer className="ag-theme-material" style={{ height: '68vh' }}>
      <AgGridReact
        onGridReady={({ api }) => {
          setGridApi(api);
        }}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        frameworkComponents={frameworkComponents}
        getRowHeight={getRowHeight}
        modules={AllModules}
        rowData={data}
        sideBar={sideBar}
        pagination={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
      />
    </GridContainer>
  );
};

export default Table;
