import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import React, { useState, useEffect, Fragment } from 'react';
import {
  Grid,
  Cell,
  Card,
  Toolbar,
  ListItem,
  MenuButton,
  FontIcon,
  Button,
  DialogContainer
} from 'react-md';
import {
  Grid as GridDX,
  Table as TableDX,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow
} from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering
} from '@devexpress/dx-react-grid';
import feathers from '../../services/feathers';
import Axios from 'axios';
const env = process.env;
export default props => {
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState({});
  const [showModal, setShowModal] = useState(false);
  const profile = JSON.parse(localStorage.profile);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      if (await checkToken()) {
        await getNotifications();
      }
    }
    tttt();
  }, []);
  const getNotifications = async () => {
    let response = await feathers.service('system-notifications').find({
      query: {
        $sort: {
          createdAt: -1
        }
      }
    });
    setNotifications(response);
  };

  const ContainerTable = ({ ...restProps }) => {
    return (
      <TableDX.Container
        style={{
          overflow: 'visible'
        }}
        {...restProps}
      />
    );
  };
  const CellDX = props => {
    const { column, value } = props;
    if (column && column.name == 'options') {
      return (
        <TableDX.Cell
          style={{
            overflow: 'visible'
          }}
        >
          {value}
        </TableDX.Cell>
      );
    }
    return <TableDX.Cell {...props} />;
  };
  let columns = [
    {
      name: 'CSMS',
      title: 'CSMS #',
      getCellValue: row => {
        return `${row.CSMSNumber}`;
      }
    },
    {
      name: 'title',
      title: 'Title',
      getCellValue: row => {
        return `${row.title}`;
      }
    },
    {
      name: 'account',
      title: 'Account',
      getCellValue: row => {
        return `${row.account || ''}`;
      }
    },
    {
      name: 'language',
      title: 'language',
      getCellValue: row => {
        return `${row.language || ''}`;
      }
    },
    {
      name: 'active',
      title: 'active',
      getCellValue: row => {
        return `${row.active ? true : false}`;
      }
    },
    {
      name: 'options',
      title: 'Options',
      getCellValue: row => {
        return (
          <MenuButton
            id="menu"
            icon
            id="menu-button-1"
            menuItems={
              profile.role == 'support' || profile.role == 'admin'
                ? [
                    <ListItem
                      key={1}
                      primaryText="Edit"
                      rightIcon={<FontIcon>edit</FontIcon>}
                      onClick={() => {
                        props.history.push(`/notification/${row._id}`);
                      }}
                    />,

                    <ListItem
                      key={2}
                      primaryText="Delete"
                      rightIcon={<FontIcon>delete</FontIcon>}
                      onClick={() => {
                        setNotification(row);
                        setShowModal(true);
                      }}
                    />
                  ]
                : [
                    <ListItem
                      key={1}
                      primaryText="Edit"
                      rightIcon={<FontIcon>edit</FontIcon>}
                      onClick={() => {
                        props.history.push(`/notification/${row._id}`);
                      }}
                    />
                  ]
            }
            centered
          >
            more_vert
          </MenuButton>
        );
      }
    }
  ];

  return (
    <Fragment>
      <Grid className="grid-example" style={{ marginBottom: 50 }}>
        <Cell desktopOffset={1} size={10}>
          <Card>
            <Toolbar
              colored
              title="Portal Announcements"
              actions={
                <Button
                  component={Link}
                  to="/notification"
                  tooltipLabel="Add Announcement"
                  style={{ marginTop: '30px' }}
                  secondary
                  floating
                >
                  add
                </Button>
              }
            ></Toolbar>
            <GridDX rows={notifications} columns={columns}>
              <SortingState
                defaultSorting={[]}
                columnExtensions={[
                  { columnName: 'options', sortingEnabled: false }
                ]}
              />
              <IntegratedSorting />
              <FilteringState
                columnExtensions={[
                  { columnName: 'options', filteringEnabled: false }
                ]}
              />
              <IntegratedFiltering />
              <PagingState defaultCurrentPage={0} defaultPageSize={10} />
              <IntegratedPaging />
              <TableDX
                columnExtensions={[
                  { columnName: 'title', wordWrapEnabled: true }
                ]}
                containerComponent={ContainerTable}
                cellComponent={CellDX}
              />
              <TableFilterRow />
              <TableHeaderRow showSortingControls />
              <PagingPanel pageSizes={[10, 25, 50, 0]} />
            </GridDX>
          </Card>
        </Cell>
      </Grid>
      <DialogContainer
        id="speed-boost"
        visible={showModal}
        title={`Do you want to delete announcement CSMS #${notification.CSMSNumber} permanently?`}
        actions={[
          {
            secondary: true,
            children: 'Delete',
            onClick: async () => {
              let response = await feathers
                .service('system-notifications')
                .remove(notification._id);
              await getNotifications();
              setShowModal(false);
              //   if (!error) {
              //     toast.success(response.message);
              //   } else {
              //     toast.success(response.message);
              //   }
            }
          },
          {
            primary: true,
            children: 'Cancel',
            onClick: () => {
              setShowModal(false);
            }
          }
        ]}
        onHide={() => {}}
      ></DialogContainer>
    </Fragment>
  );
};
