import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, FontIcon, ListItem, Collapse } from 'react-md';
import './style.css';
import { Fragment } from 'react';

export default (props) => {
  const { visible, setVisible } = props;
  let [queryCollapse, toggleQuery] = useState(true);
  let [notifCollapse, toggleNotif] = useState(true);
  let [reportCollapse, toggleReport] = useState(true);
  let profile = localStorage.profile ? JSON.parse(localStorage.profile) : {};

  return (
    <div>
      <Drawer
        className="drawer-margin"
        type={Drawer.DrawerTypes.TEMPORARY}
        visible={visible}
        style={{ zIndex: '1000' }}
        position="left"
        onVisibilityChange={(e) => setVisible(false)}
        onMediaTypeChange={() => {
          return (
            Drawer.DrawerTypes.TEMPORARY,
            {
              mobile: true,
              tablet: false,
              desktop: false,
            }
          );
        }}
      >
        <List key="mainList">
          {/* Dashboard */}
          {!['fsn'].includes(profile.role) ? (
            <ListItem
              key="ListItem1"
              component={Link}
              primaryText="Dashboard"
              to="/"
              leftIcon={<FontIcon iconClassName="fas fa-tachometer-alt" />}
              onClick={(e) => setVisible(false)}
            />
          ) : null}
          {/* ACE Air eManifest History */}
          {!['fsn'].includes(profile.role) ? (
            <ListItem
              key="ListItem2"
              component={Link}
              primaryText="ACE Air eManifest History"
              to="/awb-history"
              leftIcon={<FontIcon iconClassName="fas fa-folder-open" />}
              onClick={(e) => setVisible(false)}
            />
          ) : null}
          {/* FSQ/FSN History */}
          {!['shipper'].includes(profile.role) ? (
            <Fragment key="FragmentH">
              <ListItem
                key="ListItemH"
                component={Link}
                primaryText="FSQ/FSN History"
                to="/fsn-history"
                leftIcon={<FontIcon iconClassName="fas fa-folder-open" />}
                onClick={(e) => setVisible(false)}
              />
            </Fragment>
          ) : null}
          {/* Freight Status Query */}
          {!['shipper'].includes(profile.role) ? (
            <Fragment key="Fragment1">
              <ListItem
                key="ListItem3"
                primaryText="Freight Status Query"
                secondaryText="FSQ"
                leftIcon={<FontIcon iconClassName="fas fa-mail-bulk" />}
                rightIcon={<FontIcon iconClassName="fas fa-angle-down" />}
                onClick={(e) => {
                  console.log('queryCollapse :>> ', queryCollapse);
                  toggleQuery(!queryCollapse);
                }}
              />
              <Collapse collapsed={queryCollapse} animate={true} minHeight={0}>
                <List key="ListFSQ">
                  <ListItem
                    key="ListItem4"
                    component={Link}
                    primaryText="Create"
                    to="/create-query"
                    onClick={(e) => setVisible(false)}
                  />
                  <ListItem
                    key="ListItem5"
                    component={Link}
                    primaryText="Request History"
                    to="/request-query"
                    onClick={(e) => setVisible(false)}
                  />
                  <ListItem
                    key="ListItem6"
                    component={Link}
                    primaryText="Response"
                    to="/response-query"
                    onClick={(e) => setVisible(false)}
                  />
                </List>
              </Collapse>
            </Fragment>
          ) : null}
          {/* Freight Status Notification */}
          {!['shipper'].includes(profile.role) ? (
            <Fragment key="Fragment2">
              <ListItem
                key="ListItem7"
                primaryText="Freight Status Notification"
                secondaryText="FSN"
                leftIcon={<FontIcon iconClassName="fas fa-mail-bulk" />}
                rightIcon={<FontIcon iconClassName="fas fa-angle-down" />}
                onClick={(e) => toggleNotif(!notifCollapse)}
              />
              <Collapse collapsed={notifCollapse} animate={true} minHeight={0}>
                <List key="ListFSN">
                  <ListItem
                    key="ListItem8"
                    component={Link}
                    primaryText="Create"
                    to="/create-notification"
                    onClick={(e) => setVisible(false)}
                  />
                  <ListItem
                    key="ListItem9"
                    component={Link}
                    primaryText="Request History"
                    to="/request-notification"
                    onClick={(e) => setVisible(false)}
                  />
                  <ListItem
                    key="ListItem10"
                    component={Link}
                    primaryText="Response"
                    to="/response-notification"
                    onClick={(e) => setVisible(false)}
                  />
                </List>
              </Collapse>
            </Fragment>
          ) : null}
          {/* Reports */}
          {!['shipper'].includes(profile.role) ? (
            <Fragment key="FragmentReport">
              <ListItem
                key="ListItem11"
                component={Link}
                primaryText="Reports"
                //to="/reports"
                leftIcon={<FontIcon iconClassName="fas fa-file-import" />}
                rightIcon={<FontIcon iconClassName="fas fa-angle-down" />}
                onClick={(e) => toggleReport(!reportCollapse)}
                //onClick={e => setVisible(false)}
              />
              <Collapse collapsed={reportCollapse} animate={true} minHeight={0}>
                <List key="ListReport">
                  {!['shipper', 'fsn'].includes(profile.role) ? (
                    <ListItem
                      key="ListItem14"
                      component={Link}
                      primaryText="Transactions"
                      to="/reports/transactions"
                      onClick={(e) => setVisible(false)}
                    />
                  ) : null}
                  <ListItem
                    key="ListItem15"
                    component={Link}
                    primaryText="Responses ASN"
                    to="/reports/report1c"
                    onClick={(e) => setVisible(false)}
                  />
                </List>
              </Collapse>
            </Fragment>
          ) : null}
          {/* Submitted Transactions */}
          {['support'].includes(profile.role) ? (
            <ListItem
              key="ListItem12"
              component={Link}
              primaryText="Submitted Transactions"
              secondaryText="Report"
              to="/reports/transactions-summary"
              leftIcon={<FontIcon iconClassName="fas fa-file-import" />}
              onClick={(e) => setVisible(false)}
            />
          ) : null}
          {/* Incoming Files */}
          {[''].includes(profile.role) ? (
            <ListItem
              key="ListItem3"
              component={Link}
              primaryText="Incoming Files"
              to="/incoming-files"
              leftIcon={<FontIcon iconClassName="fas fa-file-download" />}
              onClick={(e) => setVisible(false)}
            />
          ) : null}
        </List>
      </Drawer>
    </div>
  );
};
