import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default (props) => {
  const { onClose, open, children, title } = props;

  return (
    <Dialog
      className={props.maxWidth ? '' : 'dialog-fix'}
      maxWidth={props.maxWidth ? props.maxWidth : 'md'}
      fullWidth={true}
      onBackdropClick={onClose}
      onEscapeKeyDown={onClose}
      open={open}
      aria-labelledby="responsive-dialog-title"
      scroll="body"
    >
      {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
