import { useApi } from '../../services/api';
import 'react-md/src/scss/_react-md.scss';
import { toast, ToastContainer } from 'react-toastify';
import React, { useState, useEffect, Fragment } from 'react';
import { TextField, Grid, Cell, Card, Button, Toolbar, Avatar } from 'react-md';
import logo from '../../assets/img/ccIcon.png';

export default (props) => {
  const [login, setLogin] = useState({ email: '', password: '' });
  const [, , doAuth, isErrorAuth] = useApi('auth', 'login', undefined, 'post');

  useEffect(() => {
    if (isErrorAuth) {
      toast.error(isErrorAuth.message);
    }
  }, [isErrorAuth]);

  const doLogin = async (e) => {
    let [aut, err] = await doAuth({ body: login });
    if (err) return;

    if (!aut.active) {
      localStorage.setItem('tokenChangePassword', aut.token);
      return props.history.push('/changepassword');
    }

    if (aut.active) {
      let token = aut.token;
      localStorage.setItem('token', token);
      delete aut.token;
      localStorage.setItem('profile', JSON.stringify(aut));
      props.setLogged(token);
      props.setProfile(aut);
      props.history.push('/');
    }
  };

  return (
    <Fragment>
      <Toolbar colored prominentTitle style={{ height: '200px' }} />
      <Grid>
        <Cell offset={4} size={4} style={{ marginTop: '-80px' }}>
          <Card>
            <div>
              <Grid>
                <Cell offset={4} size={4} position={'center'}>
                  <Avatar
                    src={logo}
                    style={{
                      width: '150px',
                      height: '150px',
                      background: '#0d112b',
                    }}
                  />
                </Cell>
              </Grid>
              <Grid>
                <Cell size={12}>
                  <TextField
                    id="input"
                    label={'User'}
                    value={login.email}
                    onChange={(v) => setLogin({ ...login, email: v })}
                  />
                </Cell>
              </Grid>
              <Grid>
                <Cell size={12}>
                  <TextField
                    id="input"
                    type="password"
                    label={'Password'}
                    value={login.password}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') return;
                      doLogin();
                    }}
                    onChange={(v) => setLogin({ ...login, password: v })}
                  />
                </Cell>
              </Grid>
            </div>
            <Grid>
              <Cell size={5}>
                <Button
                  secondary
                  fixedPosition={'tl'}
                  onClick={async (e) => {}}
                  flat
                >
                  Forgot password
                </Button>
              </Cell>
              <Cell offset={3} size={4}>
                <Button
                  className="md-cell--right md-cell--bottom"
                  disabled={!login.email && !login.password ? true : false}
                  onClick={doLogin}
                  flat
                >
                  Login
                </Button>
              </Cell>
            </Grid>
          </Card>
        </Cell>
        <ToastContainer autoClose={8000} />
      </Grid>
    </Fragment>
  );
};
