import React, { Fragment, useEffect, useState } from 'react';
import { Cell, DatePicker, Button, FontIcon, TextField } from 'react-md';
import { CircularProgress } from 'react-md';
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
} from '@devexpress/dx-react-grid';
import Grid2 from 'react-md/lib/Grids/Grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from '@devexpress/dx-react-grid-material-ui';
import Button2 from '@material-ui/core/Button';
import CargoImp from '../AWBHistory/Table/CargoImp';
import { useApi } from '../../services/api';
import 'chart.js';
import { FSCMessage } from '../../components/awb-history/Codes';
import moment from 'moment';
import Axios from 'axios';
import PagingContainer from '../../components/GridComponents/PagingPanel';
import AdditionalInfo from './Components/AdditionalInfo';
import StatusMessage from './Components/StatusMessage';

const env = process.env;

const Cell2 = (props) => {
  const { column } = props;

  if (column.name === 'date') {
    return <DateRequestCell {...props} />;
  }
  if (column.name === 'statusMessage') {
    return <StatusMessage {...props} />;
  }
  if (column.name === 'additionalInfo') {
    return <AdditionalInfo {...props} />;
  }
  return <Table.Cell {...props} />;
};

const DateRequestCell = ({ value, ...restProps }) => {
  let splitDate = value.toString().split(' ');
  let fullDate = '';
  let fullHour = '';

  if (splitDate.length > 0) {
    fullDate = splitDate[0];
    fullHour = splitDate[1];
  }
  return (
    <Table.Cell
      {...restProps}
      style={{
        overflow: 'visible',
      }}
    >
      <Fragment>
        {fullDate} <br /> {fullHour}
      </Fragment>
    </Table.Cell>
  );
};
export default (props) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [name, setName] = React.useState('');

  const [, , fetchDocuments] = useApi(
    'ccapi',
    'documents/search',
    undefined,
    'get'
  );
  const [range, setRange] = useState({
    from: new Date(),
    to: Date.now(),
  });
  const [awb, setAwb] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function start() {
      if (await checkToken()) {
        let day = new Date().getDate();
        let date = new Date();
        date.setDate(day);
        getDocuments({
          from: date.setHours(0, 0, 0, 0),
          to: Date.now(),
          types: ['FSC', 'FER'],
        });
      }
    }
    start();
  }, []);

  const getDocuments = async (query) => {
    setLoading(true);
    const [searchResult, err] = await fetchDocuments({ query: query });
    if (err) return;
    setLoading(false);
    setData(searchResult);
  };

  const applyFilter = () => {
    let query = {};
    let from = new Date(range.from);
    let to = new Date(range.to);
    to.setHours(23, 59, 59, 999);
    query.to = to.setDate(to.getDate());
    query.from = from.setDate(from.getDate());
    query.types = ['FSC', 'FER'];
    query.awb = awb;
    // Se añadió para no enviar manifiestos de en la opcion de busqueda, ya que de ahí se envían los documentos que están en DRAFT
    query.search = true;
    getDocuments(query);
  };

  const handleClickListItem = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <CargoImp
        open={open}
        name={name}
        onClose={handleClose}
        value={value}
      ></CargoImp>
      <Grid2 style={{ paddingTop: 0 }}>
        <Cell size={3} phoneSize={12} tabletSize={12}>
          <span style={{ fontSize: 35, color: 'gray' }}>Response FSC</span>
        </Cell>
        <DatePicker
          id="inline-date-picker-portait"
          label="from"
          value={moment(range.from).format('L')}
          defaultValue={moment(range.from).format('L')}
          maxDate={new Date(range.to)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={(e) => setRange({ ...range, from: e })}
        />
        <DatePicker
          id="inline-date-picker-portait"
          label="to"
          value={moment(range.to).format('L')}
          defaultValue={moment(range.to).format('L')}
          minDate={new Date(range.from)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={(e) => setRange({ ...range, to: e })}
        />

        <TextField
          id="floating-center-title"
          label="AirWay Bill"
          lineDirection="center"
          className="md-cell md-cell--2"
          onChange={(e) => setAwb(e)}
        />
        <Button
          className="md-cell"
          style={{ marginTop: 25 }}
          icon
          iconEl={
            <FontIcon
              secondary
              className="fas fa-search"
              onClick={applyFilter}
            />
          }
        />
        <Button2
          variant="outlined"
          color="primary"
          style={{ marginTop: 25 }}
          onClick={applyFilter}
        >
          Refresh
        </Button2>
      </Grid2>

      {loading ? (
        <div style={{ marginTop: '40px', marginBottom: '40px' }}>
          <CircularProgress
            id="loading"
            centered={true}
            className="loading-blue"
            scale={2}
          ></CircularProgress>
        </div>
      ) : (
        <Grid
          rows={data}
          style={{ backgroundColor: 'red' }}
          columns={[
            {
              name: 'awb',
              title: 'AWB',
              getCellValue: (row) => {
                return `${
                  row.docTranslated.AWB
                    ? row.docTranslated.AWB.prefix
                    : row.docTranslated.AWB2
                    ? row.docTranslated.AWB2.prefix
                    : ''
                }-${
                  row.docTranslated.AWB
                    ? row.docTranslated.AWB.serialNumber
                    : row.docTranslated.AWB2
                    ? row.docTranslated.AWB2.serialNumber
                    : ''
                }`;
              },
            },
            {
              name: 'houseBill',
              title: 'House Bill',
              getCellValue: (row) =>
                row.docTranslated.AWB
                  ? row.docTranslated.AWB.hawbNumber
                  : row.docTranslated.AWB2
                  ? row.docTranslated.AWB2.hawbNumber
                  : '',
            },
            {
              name: 'docType',
              title: 'Doc Type',
              getCellValue: (row) => {
                return row.docTranslated.name === 'FSC'
                  ? `Freight Status Condition (FSC)`
                  : `Freight Error Report (FER)`;
              },
            },
            {
              name: 'statusMessage',
              title: 'Status Message',
              getCellValue: (row) => {
                if (row.docTranslated.name === 'FSC') {
                  return row.docTranslated.FSC
                    ? FSCMessage(row.docTranslated.FSC.answerCode, true)
                    : '';
                } else {
                  let error = row.docTranslated.ERR
                    ? row.docTranslated.ERR.map(function (value) {
                        return `${value.errorCode} - ${value.errorText} `;
                      })
                    : '';
                  return error;
                }
              },
            },
            {
              name: 'additionalInfo',
              title: 'Additional Info',
              getCellValue: (row) => {
                if (row.docTranslated.FSC) {
                  if (row.docTranslated.FSC.answerCode === '10') {
                    let trn = row.docTranslated.TRN
                      ? row.docTranslated.TRN.map(function (value) {
                          return `${
                            value.destinationAirport
                              ? ` Destination Airport: ${value.destinationAirport}`
                              : ''
                          }
                        ${
                          value.transferstatusId
                            ? ` Transfer Status: ${value.transferstatusId}`
                            : ''
                        }
                        ${
                          value.bondedCarrierId
                            ? ` Bonded Carrier: ${value.bondedCarrierId}`
                            : ''
                        }
                        ${
                          value.onwardCarrier
                            ? ` Onward Carrier: ${value.onwardCarrier}`
                            : ''
                        }
                        ${
                          value.premisesId
                            ? ` Premises Identifier: ${value.premisesId}`
                            : ''
                        }
                        ${
                          value.inbondControlNum
                            ? ` Inbond Control #: ${value.inbondControlNum}`
                            : ''
                        }`;
                        })
                      : '';
                    return `${
                      row.docTranslated.ARR2 &&
                      row.docTranslated.ARR2.impCarrier
                        ? 'Airline ' + row.docTranslated.ARR2.impCarrier
                        : ''
                    }
                    ${
                      row.docTranslated.ARR2 &&
                      row.docTranslated.ARR2.flightNumber
                        ? ' Flight # ' + row.docTranslated.ARR2.flightNumber
                        : ''
                    }
                    ${
                      row.docTranslated.ARR2 &&
                      row.docTranslated.ARR2.arrivalDate
                        ? ' Arrival Date ' + row.docTranslated.ARR2.arrivalDate
                        : ''
                    }
                    ${trn}
                    `;
                  } else {
                    let additionalInfo = row.docTranslated.TXT
                      ? row.docTranslated.TXT.map(function (value) {
                          return `${value.information} `;
                        })
                      : '';
                    return additionalInfo;
                  }
                } else {
                  let additionalInfo = row.docTranslated.TXT
                    ? row.docTranslated.TXT.map(function (value) {
                        return `${value.information} `;
                      })
                    : '';
                  return additionalInfo;
                }
              },
            },
            {
              name: 'date',
              title: 'Date',
              getCellValue: (row) =>
                moment(row.meta.date).format('MM/DD/YYYY HH:mm:ss'),
            },
            {
              name: 'view',
              title: 'View EDI',
              getCellValue: (row) => {
                return (
                  <Button2
                    onClick={(c) => {
                      setValue(row.docOrigin);
                      setName(`${
                        row.docTranslated.AWB
                          ? row.docTranslated.AWB.prefix
                          : row.docTranslated.AWB2
                          ? row.docTranslated.AWB2.prefix
                          : ''
                      }
                      -
                      ${
                        row.docTranslated.AWB
                          ? row.docTranslated.AWB.serialNumber
                          : row.docTranslated.AWB2
                          ? row.docTranslated.AWB2.serialNumber
                          : ''
                      }`);
                      setTimeout(() => {
                        handleClickListItem();
                      }, 10);
                    }}
                    variant="outlined"
                    color="primary"
                  >
                    View EDI
                  </Button2>
                );
              },
            },
          ]}
        >
          <SortingState />
          <IntegratedSorting />
          <FilteringState
            defaultFilters={[]}
            columnExtensions={[{ columnName: 'view', filteringEnabled: false }]}
          />
          <IntegratedFiltering />
          <PagingState defaultCurrentPage={0} pageSize={50} />
          <IntegratedPaging />
          <Table
            columnExtensions={[
              { columnName: 'additionalInfo', wordWrapEnabled: true },
              { columnName: 'statusMessage', wordWrapEnabled: true },
              { columnName: 'awb', width: '120' },
              { columnName: 'statusMessage', width: '300' },
            ]}
            cellComponent={Cell2}
          />
          <TableFilterRow />
          <TableHeaderRow showSortingControls />
          <PagingPanel containerComponent={PagingContainer} />
        </Grid>
      )}
    </Fragment>
  );
};
