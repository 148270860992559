import { REGEX_GENERIC, REGEX_GENERIC_WITH_SPACES } from '../constants/Regex';
import generateTooltip from '../functions/generateTooltip';
import _ from 'lodash';

const fieldCompare = ['Name', 'Address', 'City', 'PostalCode'];

const required = (method, array, value) => {
  return {
    [method]: _.unionWith(array, value, _.isEqual),
  };
};

const validation = async (required, not, record) => {
  let out = {};
  required &&
    required.forEach((field) => {
      !record[field] && (out[field] = getError(`Required ${field}`, 'error'));
    });

  not &&
    not.forEach((field) => {
      record[field] &&
        (out[field] = getError(`${field} it must be empty`, 'error'));
    });

  return out;
};

export const getRegex = (key, field) => {
  let regex = REGEX_GENERIC;
  if (field.regex) {
    regex = field.regex;
  } else {
    if (field.enum) {
      regex =
        '^(' +
        (field.enum.includes(null) ? 'null' : '') +
        field.enum.toString().replace(/[,]/g, '|') +
        ')$';
    } else {
      switch (typeof field.type) {
        case 'string':
          if (field.type === 'number') {
            regex = `^[0-9]{${field.minLength},${field.maxLength}}$`;
          } else {
            if (fieldCompare.filter((field) => key.includes(field)).length)
              regex = REGEX_GENERIC_WITH_SPACES;

            regex = `${regex}{${field.minLength},${field.maxLength}}$`;
          }
          break;

        case 'object':
          if (field.type.includes('null')) {
            if (field.type.includes('string')) {
              if (fieldCompare.filter((field) => key.includes(field)).length)
                regex = REGEX_GENERIC_WITH_SPACES;

              regex = `${regex}{${field.minLength},${field.maxLength}}$`;
            }

            if (field.type.includes('number'))
              regex = `^[0-9]{${field.minLength},${field.maxLength}}$`;
          }
          break;
        default:
          break;
      }
    }
  }

  return {
    label: field.title || 'N/A',
    key: key,
    description: field.description || 'N/A',
    validators: [
      {
        validate: 'regex_matches',
        error: generateTooltip(field),
        regex: regex,
      },
    ],
    ...(field.isSelect && {
      type: 'select',
      options: field.isSelect,
    }),
  };
};

export const getAnyOf = (anyOf) => {
  if (!anyOf) return [];

  let newAnyOf = [];
  anyOf.map((any) => {
    Object.entries(any.properties).forEach(([key, item]) => {
      newAnyOf[key] = { ...newAnyOf[key], ...{} };
      item.enum &&
        item.enum.forEach((value) => {
          newAnyOf[key][value] = { ...newAnyOf[key][value], ...{} };
          newAnyOf[key][value] = {
            ...newAnyOf[key][value],
            ...{
              ...(any.required &&
                required(
                  'required',
                  newAnyOf[key][value].required,
                  any.required
                )),
              ...(any.not &&
                required('not', newAnyOf[key][value].not, any.not.required)),
            },
          };

          // any.disable && _.isEmpty(newAnyOf[key][value]) && delete newAnyOf[key][value];
        });

      !item.enum &&
        Object.entries(item).forEach(([name, value]) => {
          newAnyOf[key][name] = { ...newAnyOf[key][name], ...{} };
          newAnyOf[key][name] = {
            ...newAnyOf[key][name],
            ...{
              ...(any.required &&
                required(
                  'required',
                  newAnyOf[key][name].required,
                  any.required
                )),
              ...(any.not &&
                required('not', newAnyOf[key][name].not, any.not.required)),
              ...(value && {
                value: value,
              }),
            },
          };
        });
    });
  });

  return newAnyOf;
};

export const isDynamicRequired = async (index, text, record, anyOfMap) => {
  let out = {};

  if (anyOfMap[index][text]) {
    let { required, not } = anyOfMap[index][text];
    out = { ...out, ...(await validation(required, not, record)) };
  }

  let { minimum, minlength, maxinum, maxlength } = anyOfMap[index];
  if (minimum || minlength) {
    let { required, not, value } = minimum || minlength;
    text &&
      text.length >= parseInt(value) &&
      (out = { ...out, ...(await validation(required, not, record)) });
  }

  if (maxinum || maxlength) {
    let { required, not, value } = maxinum || maxlength;
    text &&
      text.length <= parseInt(value) &&
      (out = { ...out, ...(await validation(required, not, record)) });
  }

  return out;
};

export const getError = (message, level) => {
  return {
    info: [
      {
        message: message,
        level: level,
      },
    ],
  };
};

export default null;
