import React, { Fragment, useState } from 'react';
import DateRangePicker from 'react-daterange-picker';
import 'react-daterange-picker/dist/css/react-calendar.css';
import originalMoment from 'moment';
import { extendMoment } from 'moment-range';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, DialogActions } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

const moment = extendMoment(originalMoment);

export default (props) => {
  const { title, startDate, endDate, dates } = props;
  const [value, setValue] = useState(
    moment.range(moment(startDate).subtract(1, 'day'), moment(endDate))
  );
  const [open, setOpen] = useState(false);

  const handleCancel = () => setOpen(false);

  const onSelect = (value) => setValue(value);

  const stateDefinitions = { available: { color: null, label: 'Available' } };

  return (
    <Fragment>
      <Button
        variant="outlined"
        color="primary"
        style={{ marginRight: '10px' }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {moment(startDate).format('DDMMM')} / {moment(endDate).format('DDMMM')}
      </Button>
      <Dialog
        className="dialog-fix"
        open={open}
        maxWidth="md"
        onBackdropClick={handleCancel}
        onEscapeKeyDown={handleCancel}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar style={{ position: 'relative', backgroundColor: '#0d112b' }}>
          <Toolbar>
            <Typography
              variant="h6"
              style={{ flex: 1, color: 'white', textAlign: 'center' }}
            >
              {title ? title : 'Select Date Range'}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <DateRangePicker
            value={value}
            numberOfCalendars={2}
            onSelect={onSelect}
            singleDateRange={true}
            stateDefinitions={stateDefinitions}
            defaultState="available"
          />
          <div style={{ flex: 1, textAlign: 'center', marginTop: '20px' }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '0 5px' }}
              onClick={(e) => {
                setValue(moment.range(moment().startOf('day'), moment()));
              }}
            >
              Today
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '0 5px' }}
              onClick={(e) => {
                setValue(
                  moment.range(
                    moment().subtract(6, 'day').startOf('day'),
                    moment()
                  )
                );
              }}
            >
              Last 7 days
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '0 5px' }}
              onClick={(e) => {
                setValue(
                  moment.range(
                    moment().subtract(1, 'month').add(1, 'day').startOf('day'),
                    moment()
                  )
                );
              }}
            >
              Last month
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '0 5px' }}
              onClick={(e) => {
                setValue(
                  moment.range(
                    moment().subtract(3, 'month').add(1, 'day').startOf('day'),
                    moment()
                  )
                );
              }}
            >
              Last 3 months
            </Button>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '0 5px' }}
              onClick={(e) => {
                setValue(
                  moment.range(
                    moment().subtract(6, 'month').add(1, 'day').startOf('day'),
                    moment()
                  )
                );
              }}
            >
              Last 6 months
            </Button>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(false);
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={async () => {
              dates(moment(value.start), moment(value.end));
              if (moment(value.end).diff(moment(value.start), 'days') <= 15) {
                localStorage.setItem('startDate', moment(value.start));
                localStorage.setItem('endDate', moment(value.end));
              }
              setOpen(false);
            }}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
