import { useApi } from '../../services/api'
import { toast } from "react-toastify"
import React, { useState, useEffect } from "react";
import { TextField, Grid, Cell, Card, CardActions, Button, Toolbar } from "react-md";

export default (props) => {

    const [changePassword, setChangePassword] = useState({ password: '', newPassword: '', confirmPassword: '' })
    const [error, setError] = useState({ isError: false, errorText: '' })
    const tokenChange = localStorage.tokenChangePassword
    const [, , doChange, ] = useApi('auth', 'changePassword', changePassword, 'put', tokenChange)

    useEffect(() => {
        if (changePassword.newPassword.length && changePassword.newPassword.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[\d])(?=.*?[\W]).{10,128}$/) === null) {
            setError({ isError: true, errorText: 'Password must contain at least one uppercase character, one lowercase character, one number and one special character, example: Examplepass1!' })
        } else {
            setError({ isError: false, errorText: '' })
        }

    }, [changePassword])

    return (
        <Grid style={{ marginTop: '5%' }}>
            <Cell size={4} offset={4} position={'center'} >
                <Card animate>
                    <Toolbar
                        colored
                        prominentTitle
                        singleColor
                        title={'Change Password'} />
                    <Grid>
                        <Cell size={12}>
                            <TextField
                                id='input'
                                label={"Password"}
                                value={changePassword.password}
                                type='password'
                                helpText={'Existing password'}
                                onChange={(v) => setChangePassword({ ...changePassword, password: v })}
                            />
                        </Cell>
                    </Grid>
                    <Grid>
                        <Cell size={12}>
                            <TextField
                                id='input'
                                label={"New Password"}
                                type='password'
                                min={10}
                                maxLength={128}
                                value={changePassword.newPassword}
                                error={error.isError}
                                errorText={error.errorText}
                                helpText={'Example: Abcd12345!'}
                                onChange={(v) => setChangePassword({ ...changePassword, newPassword: v })}
                            />
                        </Cell>
                    </Grid>
                    <Grid>
                        <Cell size={12}>
                            <TextField
                                id='input'
                                label={"Confirm Password"}
                                type='password'
                                value={changePassword.confirmPassword}
                                onChange={(v) => setChangePassword({ ...changePassword, confirmPassword: v })}
                                error={changePassword.confirmPassword.length && (changePassword.newPassword !== changePassword.confirmPassword || changePassword.newPassword.length === 0 || changePassword.confirmPassword.length === 0)}
                                errorText="confirmation password is not equal to password"

                            />
                        </Cell>
                    </Grid>
                    <CardActions>
                        <Button
                            disabled={changePassword.newPassword !== changePassword.confirmPassword || changePassword.newPassword.length === 0 || changePassword.confirmPassword.length === 0}
                            onClick={async (e) => {
                                let [change, err] = await doChange({
                                    body: changePassword
                                })

                                if (!err) {
                                    toast.success(change.message)
                                    localStorage.clear()
                                    props.history.push('/login')
                                } else {
                                    toast.error('Error')
                                }

                            }} flat>Change password</Button>
                        <Button onClick={() => props.history.push('/login')} flat>Cancel</Button>
                    </CardActions>
                </Card>
            </Cell>
        </Grid >)
}