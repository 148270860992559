import 'chart.js';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardText,
  Cell,
  DataTable,
  DatePicker,
  EditDialogColumn,
  FontIcon,
  Grid,
  TableBody,
  TableCardHeader,
  TableColumn,
  TableHeader,
  TablePagination,
  TableRow,
  TextField
} from 'react-md';
import ActivityIndicator from '../../components/common/ActivityIndicator';
import { useApi } from '../../services/api';
import DocStatus from './DocStatusEnum';
import { Link } from 'react-router-dom';

export default props => {
  const [, , fetchDocuments] = useApi(
    'ccapi',
    'documents/search',
    undefined,
    'get'
  );
  const [range, setRange] = useState({
    from: moment()
      .subtract(1, 'day')
      .startOf('day'),
    to: moment().endOf('day')
  });
  const [awb, setAwb] = useState([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(50);
  const [filter, setFilter] = useState([]);
  const [value, setValue] = useState({
    serialNumber: '',
    hawbNumber: '',
    shipper: '',
    consignee: '',
    flight: '',
    eta: '',
    destination: '',
    origin: ''
  });

  useEffect(() => {
    console.log('Initial data request');
    getDocuments({
      from: moment()
        .subtract(1, 'day')
        .startOf('day')
        .format('x'),
      to: moment()
        .endOf('day')
        .format('x'),
      types: ['FRI', 'FXI', 'FRC', 'FXC', 'FRX', 'FXX'],
      awb: awb
    });
  }, []);

  // useEffect(() => {
  //   setLoading(false);
  // }, [loading]);

  const getDocuments = async query => {
    setLoading(true);

    // console.log(query);

    const [searchResult, err] = await fetchDocuments({ query: query });
    // console.log('searchResult:', searchResult, err);
    setLoading(false);

    if (err) return;
    setFilter(searchResult.slice(0, 50));
    setData(searchResult);
  };

  const applyFilter = () => {
    let query = {};
    let from = moment(range.from).startOf('day');
    let to = moment(range.to).endOf('day');
    query.to = to.format('x');
    query.from = from.format('x');
    query.types = ['FRI', 'FXI', 'FRC', 'FXC', 'FRX', 'FXX'];
    query.awb = awb;
    getDocuments(query);
  };

  const handlePagination = (start, rowsPerPage) => {
    setRows(rowsPerPage);
    setFilter(data.slice(start, start + rowsPerPage));
  };

  const handleEvent = (e, path, segment) => {
    setValue({ [path]: e });
    setFilter(
      data.filter(f => f.docOrigin[segment][path].toUpperCase().includes(e))
    );
  };

  return (
    <Fragment>
      <Grid style={{ paddingTop: 0 }}>
        <Cell size={3} phoneSize={12} tabletSize={12}>
          {/* <span style={{ fontSize: 35, color: 'gray' }}>Search Manifest</span> */}
        </Cell>
        <DatePicker
          id="inline-date-picker-portait"
          label="from"
          value={moment(range.from).format('L')}
          defaultValue={moment(range.from).format('L')}
          maxDate={new Date(range.to)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={e => setRange({ ...range, from: e })}
        />
        <DatePicker
          id="inline-date-picker-portait"
          label="to"
          value={moment(range.to).format('L')}
          defaultValue={moment(range.to).format('L')}
          minDate={new Date(range.from)}
          className="md-cell md-cell--2"
          locales="en-US"
          inline
          displayMode="portrait"
          fullWidth={false}
          autoOk
          onChange={e => setRange({ ...range, to: e })}
        />

        <TextField
          id="floating-center-title"
          label="AirWay Bill"
          lineDirection="center"
          className="md-cell md-cell--2"
          onChange={e => setAwb(e)}
        />
        <Button
          className="md-cell"
          style={{ marginTop: 25 }}
          icon
          iconEl={
            <FontIcon
              secondary
              className="fas fa-search"
              onClick={applyFilter}
            />
          }
        />
      </Grid>
      <Grid>
        {filter ? (
          <Cell size={12}>
            <Card raise tableCard>
              <CardText>
                <TableCardHeader title="Search Manifest" visible={false} />
                {loading ? (
                  <ActivityIndicator message="Loading data, please wait..." />
                ) : (
                  <DataTable
                    baseId="simple-pagination"
                    fixedDividers
                    selectableRows={false}
                  >
                    <TableHeader>
                      <TableRow>
                        <TableColumn style={{ width: 100 }}>
                          Doc Type
                        </TableColumn>

                        <EditDialogColumn
                          style={{ width: 100 }}
                          textFieldStyle={{ width: 100 }}
                          placeholder="AWB #"
                          onChange={e => handleEvent(e, 'serialNumber', 'AWB')}
                          value={value.serialNumber}
                          inline
                          leftIcon={<FontIcon>filter_list</FontIcon>}
                          inlineIcon={null}
                        />

                        {/* <EditDialogColumn
                          style={{ width: 100 }}
                          textFieldStyle={{ width: 100 }}
                          placeholder="HAWB #"
                          onChange={e => handleEvent(e, 'hawbNumber', 'AWB')}
                          value={value.hawbNumber}
                          inline
                          leftIcon={<FontIcon>filter_list</FontIcon>}
                          inlineIcon={null}
                        /> */}

                        <EditDialogColumn
                          style={{ width: 100 }}
                          textFieldStyle={{ width: 100 }}
                          placeholder="Origin"
                          onChange={e =>
                            handleEvent(e.toUpperCase(), 'airportOrigin', 'WBL')
                          }
                          value={value.origin}
                          inline
                          leftIcon={<FontIcon>filter_list</FontIcon>}
                          inlineIcon={null}
                        />

                        <EditDialogColumn
                          style={{ width: 100 }}
                          textFieldStyle={{ width: 100 }}
                          placeholder="Destination"
                          onChange={e =>
                            handleEvent(
                              e.toUpperCase(),
                              'arrivalAirport',
                              'CCL'
                            )
                          }
                          value={value.destination}
                          inline
                          leftIcon={<FontIcon>filter_list</FontIcon>}
                          inlineIcon={null}
                        />

                        <EditDialogColumn
                          style={{ width: 100 }}
                          textFieldStyle={{ width: 100 }}
                          placeholder="Shipper"
                          onChange={e =>
                            handleEvent(e.toUpperCase(), 'name', 'SHP')
                          }
                          value={value.shipper}
                          inline
                          leftIcon={<FontIcon>filter_list</FontIcon>}
                          inlineIcon={null}
                        />

                        <EditDialogColumn
                          style={{ width: 100 }}
                          textFieldStyle={{ width: 100 }}
                          placeholder="Consignee"
                          onChange={e =>
                            handleEvent(e.toUpperCase(), 'name', 'CNE')
                          }
                          value={value.consignee}
                          inline
                          leftIcon={<FontIcon>filter_list</FontIcon>}
                          inlineIcon={null}
                        />

                        <TableColumn style={{ width: 100 }}>
                          Piece Count
                        </TableColumn>

                        <TableColumn style={{ width: 100 }}>Weight</TableColumn>

                        <TableColumn style={{ width: 120 }}>Status</TableColumn>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {filter.map((content, i) => {
                        const { docOrigin } = content;

                        return (
                          <TableRow key={i}>
                            {
                              <Fragment>
                                <TableColumn className="column-style">
                                  {(docOrigin.SMI &&
                                    docOrigin.SMI.componentId) ||
                                    'N/A'}
                                </TableColumn>

                                <TableColumn className="column-style">
                                  <Link
                                    to={{
                                      pathname: '/detail',
                                      state: {
                                        id: content._id,
                                        status: 'DRAFT',
                                        from: 'search-manifest'
                                      }
                                    }}
                                  >
                                    {(docOrigin.AWB &&
                                      `${docOrigin.AWB.prefix}-${docOrigin.AWB.serialNumber}`) ||
                                      'N/A'}
                                  </Link>
                                </TableColumn>

                                {/* <TableColumn className="column-style">
                                  {(docOrigin.AWB &&
                                    docOrigin.AWB.hawbNumber) ||
                                    'N/A'}
                                </TableColumn> */}

                                <TableColumn className="column-style">
                                  {(docOrigin.WBL &&
                                    docOrigin.WBL.airportOrigin) ||
                                    'N/A'}
                                </TableColumn>

                                <TableColumn className="column-style">
                                  {(docOrigin.CCL &&
                                    docOrigin.CCL.arrivalAirport) ||
                                    'N/A'}
                                </TableColumn>

                                <TableColumn className="column-style">
                                  {(docOrigin.SHP &&
                                    docOrigin.SHP.name &&
                                    docOrigin.SHP.name.slice(0, 10)) ||
                                    'N/A'}
                                </TableColumn>

                                <TableColumn className="column-style">
                                  {(docOrigin.CNE &&
                                    docOrigin.CNE.name &&
                                    docOrigin.CNE.name.slice(0, 10)) ||
                                    'N/A'}
                                </TableColumn>

                                <TableColumn className="column-style">
                                  {(docOrigin.WBL && docOrigin.WBL.numPieces) ||
                                    'N/A'}
                                </TableColumn>

                                <TableColumn className="column-style">
                                  {(docOrigin.WBL &&
                                    `${docOrigin.WBL.weight}${docOrigin.WBL.weightCode}`) ||
                                    'N/A'}
                                </TableColumn>

                                <TableColumn className="column-style">
                                  {DocStatus.properties[DocStatus.DRAFT].label}
                                </TableColumn>
                              </Fragment>
                            }
                          </TableRow>
                        );
                      })}
                    </TableBody>
                    <TablePagination
                      rows={data.length}
                      rowsPerPageLabel="rows"
                      rowsPerPage={rows}
                      onPagination={handlePagination}
                    />
                  </DataTable>
                )}
              </CardText>
            </Card>
          </Cell>
        ) : null}
      </Grid>
    </Fragment>
  );
};
