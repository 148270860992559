import React, { useState, useEffect, Fragment } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { TextField, Grid, Cell, Card, Button, Toolbar, Avatar } from 'react-md';
import { useApi } from '../../services/api';
import Background from './images/ariplane4.jpg';
import Logo from '../../assets/img/ccNewLogoWhite.png';
import './css/login7-style.css';

const Login = (props) => {
  const [login, setLogin] = useState({ email: '', password: '' });
  const [, , doAuth, isErrorAuth] = useApi('auth', 'login', undefined, 'post');

  useEffect(() => {
    if (isErrorAuth) {
      toast.error(isErrorAuth.message);
    }
  }, [isErrorAuth]);

  const doLogin = async (e) => {
    let [aut, err] = await doAuth({ body: login });
    if (err) return;

    if (!aut.active) {
      localStorage.setItem('tokenChangePassword', aut.token);
      return props.history.push('/changepassword');
    }

    if (aut.active) {
      let token = aut.token;
      localStorage.setItem('token', token);
      delete aut.token;
      localStorage.setItem('profile', JSON.stringify(aut));
      props.setLogged(token);
      props.setProfile(aut);
      props.history.push('/');
    }
  };

  return (
    <>
      <div
        className="only-bootstrap"
        style={{
          background: `url(${Background})`,
          height: '100vh',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="authfy-container col-xs-12 col-sm-10 col-md-8 col-lg-6 col-sm-offset-1 col-md-offset-2 col-lg-offset-3">
              <div className="col-sm-5 authfy-panel-left">
                <div className="brand-col">
                  <div className="headline">
                    <div className="brand-logo">
                      <img src={Logo} width="200" alt="brand-logo" />
                    </div>

                    {/* <p>Login using social media to get quick access</p> */}

                    {/* <div className="row social-buttons"></div> */}
                  </div>
                </div>
              </div>
              <div className="col-sm-7 authfy-panel-right">
                <div className="authfy-login">
                  <div className="authfy-panel panel-login text-center active">
                    <div className="authfy-heading">
                      <h3 className="auth-title">Login to your account</h3>
                      <p>
                        Don’t have an account?
                        <a
                          className="lnk-toggler"
                          data-panel=".panel-signup"
                          href="https://www.customscity.com/ace-air-pricing/"
                        >
                          {' '}
                          Sign Up!
                        </a>
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12">
                        <form
                          name="loginForm"
                          className="loginForm"
                          action="#"
                          method="POST"
                        >
                          <div className="form-group wrap-input">
                            <TextField
                              id="input"
                              label={'User'}
                              value={login.email}
                              onChange={(v) => setLogin({ ...login, email: v })}
                            />
                            <span className="focus-input"></span>
                          </div>
                          <div className="form-group wrap-input">
                            <TextField
                              id="input"
                              type="password"
                              label={'Password'}
                              value={login.password}
                              onKeyDown={(e) => {
                                if (e.key !== 'Enter') return;
                                doLogin();
                              }}
                              onChange={(v) =>
                                setLogin({ ...login, password: v })
                              }
                            />
                            <span className="focus-input"></span>
                          </div>

                          <div className="row remember-row">
                            <div className="col-xs-6 col-sm-6"></div>
                            <div className="col-xs-6 col-sm-6">
                              {/* <p className="forgotPwd">
                                <a
                                  className="lnk-toggler"
                                  data-panel=".panel-forgot"
                                  href="#"
                                  onClick={async (e) => {}}
                                >
                                  Forgot password?
                                </a>
                              </p> */}
                            </div>
                          </div>

                          <div className="form-group">
                            <button
                              className="btn btn-lg btn-primary btn-block"
                              type="button"
                              disabled={
                                !login.email && !login.password ? true : false
                              }
                              onClick={doLogin}
                            >
                              Login
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <div className="authfy-panel panel-signup text-center">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12">
                        <div className="authfy-heading">
                          <h3 className="auth-title">Sign up for free!</h3>
                        </div>
                        <form
                          name="signupForm"
                          className="signupForm"
                          action="#"
                          method="POST"
                        >
                          <div className="form-group wrap-input">
                            <input
                              type="email"
                              className="form-control"
                              name="username"
                              placeholder="Email address"
                            />
                            <span className="focus-input"></span>
                          </div>
                          <div className="form-group wrap-input">
                            <input
                              type="text"
                              className="form-control"
                              name="fullname"
                              placeholder="Full name"
                            />
                            <span className="focus-input"></span>
                          </div>
                          <div className="form-group wrap-input">
                            <div className="pwdMask">
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                placeholder="Password"
                              />
                              <span className="focus-input"></span>
                              <span className="fa fa-eye-slash pwd-toggle"></span>
                            </div>
                          </div>
                          <div className="form-group">
                            <p className="term-policy text-muted small">
                              I agree to the
                              <a href="#">privacy policy</a>
                              and
                              <a href="#">terms of service</a>.
                            </p>
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-lg btn-primary btn-block"
                              type="submit"
                            >
                              Sign up with email
                            </button>
                          </div>
                        </form>
                        <a
                          className="lnk-toggler"
                          data-panel=".panel-login"
                          href="#"
                        >
                          Already have an account?
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="authfy-panel panel-forgot">
                    <div className="row">
                      <div className="col-xs-12 col-sm-12">
                        <div className="authfy-heading">
                          <h3 className="auth-title">Recover your password</h3>
                          <p>
                            Fill in your e-mail address below and we will send
                            you an email with further instructions.
                          </p>
                        </div>
                        <form
                          name="forgetForm"
                          className="forgetForm"
                          action="#"
                          method="POST"
                        >
                          <div className="form-group wrap-input">
                            <input
                              type="email"
                              className="form-control"
                              name="username"
                              placeholder="Email address"
                            />
                            <span className="focus-input"></span>
                          </div>
                          <div className="form-group">
                            <button
                              className="btn btn-lg btn-primary btn-block"
                              type="submit"
                            >
                              Recover your password
                            </button>
                          </div>
                          <div className="form-group">
                            <a
                              className="lnk-toggler"
                              data-panel=".panel-login"
                              href="#"
                            >
                              Already have an account?
                            </a>
                          </div>
                          <div className="form-group">
                            <a
                              className="lnk-toggler"
                              data-panel=".panel-signup"
                              href="#"
                            >
                              Don’t have an account?
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={8000} />
    </>
  );
};

export default Login;
