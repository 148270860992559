import { Card, CardTitle, CardText, CircularProgress } from 'react-md';
import { PieChart } from 'react-chartkick';
import React from 'react';

export default (props) => {
  let { value, title, id, loading } = props;
  let isEmpty =
    value.accepted === 0 && value.rejected === 0 && value.cancel === 0;
  let data = [
    ['accepted', parseFloat((value.accepted / value.total) * 100).toFixed(2)],
    ['rejected', parseFloat((value.rejected / value.total) * 100).toFixed(2)],
    ['cancelled', parseFloat((value.cancel / value.total) * 100).toFixed(2)],
    ['sent', parseFloat((value.sent / value.total) * 100).toFixed(2)],
  ];

  return (
    <Card
      style={{
        height: '100%',
      }}
    >
      <CardTitle title={title} />
      {!loading ? (
        <CardText>
          {!isEmpty ? (
            <PieChart
              suffix="%"
              height={200}
              colors={['#00FF7F', '#FF8C00', 'red', 'blue']}
              data={data}
              // legend={true}
              legend="bottom"
            />
          ) : (
            'No records found for selected date range.'
          )}
        </CardText>
      ) : (
        <CardText>
          <CircularProgress
            style={{ height: 170 }}
            scale={4}
            centered={true}
            id={id}
            key={id}
          />
        </CardText>
      )}
    </Card>
  );
};
