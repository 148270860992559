import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { CircularProgress } from 'react-md';
import { Table } from '@devexpress/dx-react-grid-material-ui';
// import feathers from '../../../../services/feathers';

export default (props) => {
  // let isUpdate = null;
  let [row, setRow] = useState({ ...props.row });
  let [value, setValue] = useState(props.value);
  let [restProps] = useState({ ...props.restProps });
  useEffect(() => {
    //console.log('props.row', props.row);
    setRow(props.row);
  }, []);

  // feathers.service('manifests').on('cached', data => {
  //   isUpdate = data;
  // });

  // setInterval(() => {
  //   if (isUpdate) {
  //     let data = isUpdate;

  //     if (data.type === 'Consolidation' && row.type === 'Consolidation') {
  //       if (
  //         data.prefix === row.prefix &&
  //         data.serialNumber == row.serialNumber
  //       ) {
  //         console.log('Imprimiendo ');

  //         console.log('data', data);
  //         if (data.body) {
  //           setRow({ ...row, type: data.type, stats: data.body });
  //           setValue(data.body.total);
  //         }
  //       }
  //     }
  //   }

  //   isUpdate = null;
  // }, 1000);

  // feathers.service('manifests').on('cached', data => {
  //   if (data.type === 'Consolidation' && row.type === 'Consolidation') {
  //     if (data.prefix === row.prefix && data.serialNumber == row.serialNumber) {
  //       if (data.body) {
  //         setRow({ ...row, type: data.type, stats: data.body });
  //         setValue(data.body.total);
  //       }
  //     }
  //   }
  // });

  if (row.type === 'Consolidation' && value === 'N/A') {
    return (
      <Table.Cell {...restProps}>
        <CircularProgress
          id="loading"
          centered={true}
          className="loading-blue"
        ></CircularProgress>
      </Table.Cell>
    );
  }
  if (value === 'N/A') return <Table.Cell {...restProps}>{value}</Table.Cell>;
  else
    return (
      <Table.Cell {...restProps}>
        <Button
          variant="outlined"
          color="primary"
          onClick={(e) => {
            restProps.column.awb = `${row.prefix}-${row.serialNumber}`;
            restProps.column.rowHouse.prefix = row.prefix;
            restProps.column.rowHouse.serialNumber = row.serialNumber;
            restProps.column.rowHouse.flightNumber = row.flightNumber;
            restProps.column.rowHouse.totalHouses = row.stats.total;
            restProps.column.rowHouse.rowNo = row;
            // restProps.column.rowHouse.data = row.consolidations;
            restProps.column.FullScreenOpen();
          }}
        >
          {value} HAWB
        </Button>
      </Table.Cell>
    );
};
