import React, { useEffect, useState, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function CargoImp(props) {
  const { onClose, value: valueProp, open, name } = props;
  const [value, setValue] = useState(valueProp);
  useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);
  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose();
  };
  const downloadTxtFile = () => {
    const element = document.createElement('a');
    const file = new Blob([value], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = name + '.txt';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  return (
    <div>
      <Dialog
        onBackdropClick={handleCancel}
        onEscapeKeyDown={handleCancel}
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Cargo Imp'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {value.split('\r\n').map((line, key) => {
              line = line.replace(/\n/g, '');
              if (line.trim() !== '') {
                return (
                  <Fragment key={key}>
                    {line}
                    <br />
                  </Fragment>
                );
              }
            })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={downloadTxtFile} color="primary">
            Download
          </Button>
          <Button onClick={handleCancel} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
