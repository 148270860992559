import { Grid, Cell, TextField, Button, SelectField } from 'react-md';
import Button2 from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useState, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import feathers from '../../services/feathers';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Axios from 'axios';
const env = process.env;

export default (props) => {
  const CustomInput = ({ value, onClick }) => (
    <Button2 variant="outlined" onClick={onClick}>
      {value}
    </Button2>
  );

  const [arrivalDate, setArrivalDate] = useState(new Date());
  const [liftDate, setLiftDate] = useState(new Date());
  const [splitData, setSplitData] = useState([]);
  const [flightNumbers, setFlightNumbers] = useState([]);
  const { close, AWB, getManifest, row } = props;
  const [awb, setAwb] = useState({
    prefix: props.prefix || '',
    serialNumber: props.serialNumber || '',
    hawbNumber: props.hawbNumber || '',
  });

  const [dep, setDep] = useState({
    componentId: 'DEP',
    impCarrier: '',
    flightNumber: '',
    arrivalDate: '',
    liftDate: '',
    liftTime: '',
    actualCarrier: '',
    actualFlightNum: '',
  });

  const getDataManifest = async () => {
    let resultFDM = await feathers.service('split-manifest').find({
      query: {
        prefix: AWB.split('-')[0],
        serialNumber: AWB.split('-')[1],
      },
    });
    setSplitData(resultFDM);
    let data = [];
    for (const item of resultFDM) {
      if (data.length === 0) {
        if (item.arrivalDate) {
          data.push(item.flightNumber);
          let month = parseInt(moment(item.arrivalDate).format('MM')) - 1;
          let day = parseInt(moment(item.arrivalDate).format('DD'));
          await setArrivalDate(new Date(new Date().getFullYear(), month, day));
        } else {
          await setArrivalDate(new Date());
        }
      } else {
        if (data.filter((x) => x === item.flightNumber).length === 0) {
          data.push(item.flightNumber);
        }
      }
    }
    await setFlightNumbers(data);

    await setDep({
      componentId: 'DEP',
      impCarrier: resultFDM[0].impCarrier,
      flightNumber: data.length === 1 ? data[0] : '',
      arrivalDate: '',
      liftDate: '',
      liftTime: '',
      actualCarrier: '',
      actualFlightNum: '',
    });
  };
  if (AWB) {
    useEffect(() => {
      setAwb({
        prefix: AWB.split('-')[0],
        serialNumber: AWB.split('-')[1],
        hawbNumber: '',
      });

      getDataManifest();
    }, [AWB]);
  }
  const [ready, setReady] = useState(true);
  // const [errorArrival, setErrorArrival] = useState('');
  // const [errorLift, setErrorLift] = useState('');
  // const [errorTime, setErrorTime] = useState('');
  const [, , fetchDoc] = useApi(
    'ccapi',
    `documents${props.company ? '?company=' + props.company : ''}`,
    undefined,
    'post'
  );
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');

  const handleInput = (e) => {
    setDep({ ...dep, ...e });
  };

  useEffect(() => {
    if (
      Object.keys(dep)
        .map((x) => dep[x] !== '')
        .includes(false)
    ) {
      setReady(true);
    } else {
      setReady(false);
    }
  }, [dep]);

  const send = async (id) => {
    let [response, err] = await doSend({ body: [id] });
    if (err) return;
    toast.success(response.message);
    await feathers.service('manifests').patch(row.lastId, {
      'meta.editDate': Date.now(),
    });
  };
  const create = async (willSend) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response2 = await Axios.get(
      env['REACT_APP_AUTH'] + '/validPartnership'
    );
    if (response2.data.success) {
      let Departure = {
        componentId: 'DEP',
        impCarrier: dep.impCarrier,
        flightNumber: dep.flightNumber,
        arrivalDate: moment(arrivalDate).format('DDMMM').toUpperCase(),
        liftDate: moment(liftDate).format('DDMMM').toUpperCase(),
        liftTime: moment(liftDate).format('HHmm'),
        actualCarrier: dep.actualCarrier,
        actualFlightNum: dep.actualFlightNum,
      };
      let [apifdm, err] = await fetchDoc({
        body: { SMI: { componentId: 'FDM' }, DEP: Departure, AWB3: awb },
      });
      if (err) return;
      if (apifdm) {
        if (apifdm.errors) {
          let detail = '';
          for (let i = 0; i < apifdm.errors.length; i++) {
            if (apifdm.errors.length - 1 === i)
              detail += ` ${apifdm.errors[i].message}`;
            else detail += ` ${apifdm.errors[i].message},`;
          }

          toast.warn(`${apifdm.message}, ${detail}`);
        } else {
          toast.success(
            `FDM for flight ${apifdm.message.docOrigin.DEP.impCarrier}-${apifdm.message.docOrigin.DEP.flightNumber} successfully created.`
          );
          if (willSend) {
            await send(apifdm.message._id);
          }
          let updateRow = await feathers.service('awb-status').patch(row._id, {
            manifestEdited: Date.now(),
          });
          if (updateRow) {
            await getManifest();
          }
        }
      } else {
        toast.error(`Error: something goes wrong, try again`);
      }
    } else {
      toast.warn(response2.data.message);
    }
  };

  return (
    <Fragment>
      <Grid>
        <Cell size={2} tabletSize={2} phoneSize={12}>
          <TextField
            id="impCarrier"
            autocomplete="off"
            value={dep.impCarrier || ''}
            onChange={(e) => {
              const re = /^[^><.|_º,!"@·#&/()=?'¿¡`+*´¨Çç{}–…:; -]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 3) {
                  setDep({ ...dep, impCarrier: e.toUpperCase() });
                }
              }
            }}
            label="Importing carrier"
            lineDirection="center"
          />
        </Cell>
        <Cell size={4} tabletSize={4} phoneSize={12}>
          {splitData.length <= 1 ? (
            <TextField
              id="flight"
              autocomplete="off"
              value={dep.flightNumber}
              onChange={(e) => {
                const re = /^[0-9\b]*$/;
                if (e === '' || re.test(e)) {
                  if (e.length <= 8) {
                    setDep({ ...dep, flightNumber: e });
                  }
                }
              }}
              label="Flight #"
              lineDirection="center"
            />
          ) : (
            <SelectField
              id="flight"
              label="Flight #"
              lineDirection="center"
              style={{ width: '200px' }}
              menuItems={flightNumbers}
              onChange={(e) => {
                setDep({ ...dep, flightNumber: e });
              }}
              simplifiedMenu={false}
              required
              errorText="This field is required"
            />
          )}
        </Cell>
        <Cell size={3} tabletSize={4} phoneSize={12}>
          <Typography>Date Of Scheduled Arrival</Typography>
          <br />
          <DatePicker
            selected={arrivalDate}
            dateFormat="ddMMM"
            customInput={<CustomInput id="arrivalDate" />}
            onChange={(e) => {
              setArrivalDate(e);
            }}
            required
            withPortal
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={2} tabletSize={4} phoneSize={12}>
          <Typography>LiftOff Date</Typography>
          <br />
          <DatePicker
            selected={liftDate}
            showDateSelect
            dateFormat="ddMMM"
            customInput={<CustomInput id="LiftOffDate" />}
            onChange={(e) => {
              setLiftDate(e);
            }}
            required
            withPortal
          />
        </Cell>
        <Cell size={2} tabletSize={4} phoneSize={6}>
          <Typography>LiftOff Time</Typography>
          <br />
          <DatePicker
            selected={liftDate}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat="HHmm"
            customInput={<CustomInput id="liftOffTime" />}
            onChange={(d) => {
              setLiftDate(d);
            }}
            required
            withPortal
          />
        </Cell>
        <Cell size={3} tabletSize={4} phoneSize={12}>
          <TextField
            id="floating-center-title"
            autocomplete="off"
            value={dep.actualCarrier}
            onChange={(e) => {
              const re = /^[^><.|_º,!"@·#&/()=?'¿¡`+*´¨Çç{}–…:; -]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 3) {
                  handleInput({ actualCarrier: e.toUpperCase() });
                }
              }
            }}
            label="Actual importing carrier"
            lineDirection="center"
          />
        </Cell>
        <Cell size={3} tabletSize={4} phoneSize={12}>
          <TextField
            id="floating-center-title"
            autocomplete="off"
            value={dep.actualFlightNum}
            onChange={(e) => {
              const re = /^[0-9\b]*$/;
              if (e === '' || re.test(e)) {
                if (e.length <= 8) {
                  handleInput({ actualFlightNum: e });
                }
              }
            }}
            label="Actual flight number"
            lineDirection="center"
          />
        </Cell>
      </Grid>
      <Grid>
        <Cell size={2}>
          <Button
            style={{ display: AWB ? 'none' : 'block' }}
            raised
            secondary
            iconBefore={false}
            onClick={async (e) => {
              await create(false);
            }}
            disabled={
              (dep.impCarrier && dep.impCarrier.length < 2) ||
              dep.flightNumber === ''
            }
            // iconEl={<FontIcon iconClassName="fas fa-check" />}
          >
            Create
          </Button>
          <Button
            style={{ display: AWB ? 'block' : 'none' }}
            raised
            secondary
            iconBefore={false}
            onClick={async (e) => {
              await create(true);
              close();
            }}
            disabled={
              (dep.impCarrier && dep.impCarrier.length < 2) ||
              dep.flightNumber === ''
            }
          >
            Create & Send
          </Button>
        </Cell>
        <Cell style={{ display: AWB ? 'block' : 'none' }} size={2}>
          <Button raised secondary iconBefore={false} onClick={close}>
            Cancel
          </Button>
        </Cell>
      </Grid>
    </Fragment>
  );
};
