import {
  Card,
  CardTitle,
  CardText,
  Grid,
  Cell,
  Button,
  DataTable,
  TableHeader,
  TableBody,
  TableRow,
  TableColumn,
  Chip,
  Avatar,
  FontIcon,
  TablePagination
} from 'react-md';
import React, { useState, useEffect, Fragment } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import Papa from 'papaparse';
import _ from 'lodash';
import '../style.css';

export default props => {
  const [, , fetchDoc, isError] = useApi(
    'ccapi',
    'cbp/air/upload',
    undefined,
    'post'
  );

  const docName = props.document.file.name.replace('.csv', '');
  const [isLoading, setLoading] = useState(true);
  const [dataSliced, setSliced] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [count, setCount] = useState({
    FRI: 0,
    FRC: 0,
    FRX: 0,
    FXI: 0,
    FXC: 0,
    FXX: 0
  });
  const [data, setData] = useState([]);
  const [rows, setRows] = useState(50);
  const cancelDoc = () => {
    props.setDocument(null);
  };

  const sendDoc = () => {
    Papa.parse(props.document.file, {
      delimiter: '', // auto-detect
      newline: '', // auto-detect
      quoteChar: '"',
      escapeChar: '"',
      header: true,
      skipEmptyLines: true,
      delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP],
      complete: results => {
        fetchDoc({ body: results.data }).then(response => {
          // if (response.error) {
          //   toast.error(`${docName} has failed`);
          // }
          if (
            response[0].docsWithErrors &&
            response[0].docsWithErrors.length > 0
          ) {
            console.log('errores', response[0].docsWithErrors);
            toast.error(`${docName} has failed`);
          } else {
            toast.success(`${docName} succesfully imported`);
            let files = [...props.files];
            files.splice(props.files.indexOf(props.document.file), 1);

            props.setFiles(files);
            props.setDocument(null);
          }
        });
      }
    });
  };

  useEffect(() => {
    setHeaders(props.document.data.splice(0, 1)[0]);
    props.document.data.splice(0, 2);
    setData(props.document.data);
    setRows(50);
  }, [props.document]);

  useEffect(() => {
    setCount({
      FRI: _.flattenDeep(data.map(x => x.filter(f => f === 'FRI'))).length,
      FRC: _.flattenDeep(data.map(x => x.filter(f => f === 'FRC'))).length,
      FRX: _.flattenDeep(data.map(x => x.filter(f => f === 'FRX'))).length,
      FXI: _.flattenDeep(data.map(x => x.filter(f => f === 'FXI'))).length,
      FXC: _.flattenDeep(data.map(x => x.filter(f => f === 'FXC'))).length,
      FXX: _.flattenDeep(data.map(x => x.filter(f => f === 'FXX'))).length
    });
    setSliced(data.slice(0, 50));
    setLoading(false);
  }, [data]);

  useEffect(() => {}, [isError]);

  useEffect(() => {}, [isLoading]);

  const handlePagination = (start, rowsPerPage) => {
    setSliced(data.slice(start, start + rowsPerPage));
  };

  return (
    <Fragment>
      {
        <Grid>
          <Cell size={12}>
            <Card tableCard={true}>
              <Button
                style={{ marginTop: '80px' }}
                floating
                secondary
                fixed
                onClick={sendDoc}
                fixedPosition="tr"
              >
                save_alt
              </Button>
              <CardTitle title={`${docName}`}>
                <div className="md-cell--center chips__list">
                  <Chip
                    className="custom-chip"
                    label={`Rows: ${data.length}`}
                    avatar={
                      <Avatar
                        suffix={'indigo'}
                        icon={<FontIcon iconClassName="fas fa-layer-group" />}
                      />
                    }
                  />
                  <Chip
                    className="custom-chip"
                    label={`Cols: ${headers.length}`}
                    avatar={
                      <Avatar
                        suffix={'indigo'}
                        icon={<FontIcon iconClassName="fas fa-columns" />}
                      />
                    }
                  />
                  <Chip
                    className="custom-chip"
                    label={`FRI: ${count.FRI}`}
                    avatar={
                      <Avatar
                        suffix={'indigo'}
                        icon={<FontIcon iconClassName="fas fa-file-alt" />}
                      />
                    }
                  />
                  <Chip
                    className="custom-chip"
                    label={`FRC: ${count.FRC}`}
                    avatar={
                      <Avatar
                        suffix={'indigo'}
                        icon={<FontIcon iconClassName="fas fa-file-alt" />}
                      />
                    }
                  />
                  <Chip
                    className="custom-chip"
                    label={`FRX: ${count.FRX}`}
                    avatar={
                      <Avatar
                        suffix={'indigo'}
                        icon={<FontIcon iconClassName="fas fa-file-alt" />}
                      />
                    }
                  />
                  <Chip
                    className="custom-chip"
                    label={`FXI: ${count.FXI}`}
                    avatar={
                      <Avatar
                        suffix={'indigo'}
                        icon={<FontIcon iconClassName="fas fa-file-alt" />}
                      />
                    }
                  />
                  <Chip
                    className="custom-chip"
                    label={`FXC: ${count.FXC}`}
                    avatar={
                      <Avatar
                        suffix={'indigo'}
                        icon={<FontIcon iconClassName="fas fa-file-alt" />}
                      />
                    }
                  />
                  <Chip
                    className="custom-chip"
                    label={`FXX: ${count.FXX}`}
                    avatar={
                      <Avatar
                        suffix={'indigo'}
                        icon={<FontIcon iconClassName="fas fa-file-alt" />}
                      />
                    }
                  />
                </div>
                <Button
                  style={{ marginRight: '20px' }}
                  className="md-cell--right"
                  icon
                  primary
                  onClick={cancelDoc}
                >
                  clear
                </Button>
              </CardTitle>
              {
                <CardText>
                  <DataTable baseId="simple-pagination">
                    <TableHeader>
                      <TableRow selectable={false}>
                        {headers.map((header, i) => (
                          <TableColumn key={i}>{header}</TableColumn>
                        ))}
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {dataSliced.map((dat, ix) => (
                        <TableRow key={ix} selectable={false}>
                          {dat.map((d, i) => (
                            <TableColumn
                              key={i}
                              style={{ textAlign: 'center' }}
                            >
                              {d}
                            </TableColumn>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                    <TablePagination
                      rows={data.length}
                      rowsPerPageLabel="rows"
                      rowsPerPage={rows}
                      onPagination={handlePagination}
                    />
                  </DataTable>
                </CardText>
              }
            </Card>
          </Cell>
        </Grid>
      }
    </Fragment>
  );
};
