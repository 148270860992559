import React from 'react';
import { Table } from '@devexpress/dx-react-grid-material-ui';
import Button from '@material-ui/core/Button';
import moment from 'moment';

const ButtonCargoOut = ({ value, otherName, column, row, ...restProps }) => {
  const { openDialogCargo, setRowDialog } = column;
  let date = null;
  let text = null;
  if (row.cargoOut !== '') {
    date = moment(row.cargoOut).format('MM/DD/YYYY HH:mm');
    text = row.commentOut;
  }
  return (
    <Table.Cell {...restProps}>
      {value !== 'N/A' ? (
        <Button
          key={otherName}
          color="primary"
          variant="outlined"
          onClick={(e) => {
            setRowDialog(row);
            openDialogCargo(true, otherName, date, row.commentOut);
          }}
        >
          {value ? date : otherName}
          {value ? <br /> : null}
          {value ? text : null}
        </Button>
      ) : (
        value
      )}
    </Table.Cell>
  );
};

export default ButtonCargoOut;
