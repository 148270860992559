import React from 'react';
import GridContainer from '../../../components/Ag-Grid/GridContainer';
import { AgGridReact } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-material.css';
import { sideBar } from '../../../constants/Ag-Grid/constants';
import {
  valueGetter,
  valueFormatter,
  cellRendererFramework
} from '../../../functions/Ag-Grid/functions';

const Table = ({ fsnData: rowData, gridActions: functions }) => {
  const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    functions,
    cellClass: 'cell-wrap-text',
    suppressMenu: true,
    valueGetter,
    valueFormatter,
    cellRendererFramework
  };

  const columnDefs = [
    { field: 'AWB#', headerName: 'AWB #' },
    { field: 'hawbNumber', headerName: 'HAWB #' },
    { field: 'split', headerName: 'Split Shipment' },
    { field: 'docType', headerName: 'Doc Type' },
    { field: 'statusNotification', headerName: 'Status Notification' },
    { field: 'additionalInfo', headerName: 'Additional Info' },
    { field: 'type', headerName: 'Type' },
    { field: 'date', headerName: 'Customs City Processing Date' },
    { field: 'cbpProcessingDate', headerName: 'CBP Processing Date' },
    { field: 'marksAndNumbers', headerName: 'Marks And Number' },
    { field: 'viewEDI', headerName: 'View EDI' },
    { field: 'print', headerName: 'Print' }
  ];

  const getRowHeight = ({ data }) => (data && data.height) || 50;

  return (
    <GridContainer className="ag-theme-material" style={{ height: '92vh' }}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        getRowHeight={getRowHeight}
        modules={AllModules}
        rowData={rowData}
        sideBar={sideBar}
        pagination={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
      />
    </GridContainer>
  );
};

export default Table;
