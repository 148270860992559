import { Table } from '@devexpress/dx-react-grid-material-ui';
import React, { Fragment } from 'react';
import 'chart.js';
import { FSNMessage, entryType } from '../../../components/awb-history/Codes';

const StatusNotificationFSN = ({ value, ...restProps }) => {
  const { row } = restProps;
  if (row.docTranslated.name === 'FSN') {
    return (
      <Table.Cell
        {...restProps}
        style={{
          overflow: 'visible',
        }}
      >
        <Fragment>
          {row.docTranslated.CSN
            ? FSNMessage(row.docTranslated.CSN.actionCode, true)
            : ''}
          {row.docTranslated.CSN && row.docTranslated.CSN.entryType && <br />}
          {row.docTranslated.CSN && row.docTranslated.CSN.entryType
            ? 'Entry Type: ' + entryType(row.docTranslated.CSN.entryType)
            : ''}
          {row.docTranslated.CSN && row.docTranslated.CSN.entryNumber && <br />}
          {row.docTranslated.CSN && row.docTranslated.CSN.entryNumber
            ? 'Entry #: ' + row.docTranslated.CSN.entryNumber
            : ''}
          {row.docTranslated.CSN && row.docTranslated.CSN.numPieces && <br />}
          {row.docTranslated.CSN && row.docTranslated.CSN.numPieces
            ? 'Quantity: ' + row.docTranslated.CSN.numPieces
            : ''}
          {row.docTranslated.ARR2 && row.docTranslated.ARR2.arrivalRef && (
            <br />
          )}
          {row.docTranslated.ARR2 && row.docTranslated.ARR2.arrivalRef
            ? 'Split Shipment Part: ' + row.docTranslated.ARR2.arrivalRef
            : ''}
          {row.docTranslated.CSN && row.docTranslated.CSN.remarks && <br />}
          {row.docTranslated.CSN && row.docTranslated.CSN.remarks
            ? 'Remarks: ' + row.docTranslated.CSN.remarks
            : ''}
        </Fragment>
      </Table.Cell>
    );
  } else {
    let error = row.docTranslated.ERR
      ? row.docTranslated.ERR.map(function (value) {
          return `${value.errorCode} - ${value.errorText} `;
        })
      : '';
    return (
      <Table.Cell
        {...restProps}
        style={{
          overflow: 'visible',
        }}
      >
        <div>
          {error.map((content, i) => (
            <Fragment>
              {content}
              <br />
            </Fragment>
          ))}
        </div>
      </Table.Cell>
    );
  }
};
export default StatusNotificationFSN;
