import Papa from 'papaparse';
import React, { Fragment, useState, useEffect } from 'react';
import ReactLoading from 'react-loading';
import {
  Avatar,
  Button as BUTTON,
  Card,
  CardText,
  CardTitle,
  Cell,
  DialogContainer,
  FontIcon,
  Grid,
  List,
  ListItem
} from 'react-md';
import { toast } from 'react-toastify';
import { Preview } from '..';
import { Dropzone } from '../../components';
import { useApi } from '../../services/api';
import { Redirect, Route, Switch } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TemplateCSV from '../../assets/AIR_AMS_COMPLEMENTARY_INFORMATION_TEMPLATE.csv';
import ExplanationDoc from '../../assets/AIR_AMS_COMPLEMENTARY_INFORMATION_GUIDE.xlsx';
import { Link } from '@material-ui/core';
import Lottie from 'react-lottie';
import feathers from '../../services/feathers';
import ProgressBar from './uploadDoc2Progress';

import LoadingAnimation from '../../assets/analyze.json';

import Axios from 'axios';
//import FlatFileImporterComponent from '../../components/flatfile/FlatFileImporterComponent';
import FlatFileImporterComponent from '../../components/flatfile/FlatFileImporterComponentComplementary';
import { airSchemasComp } from '../../constants/SchemaTypes';
import styled from 'styled-components';

const env = process.env;
let countRedirect = 0;

const downloadCsvFile = () => {
  const element = document.createElement('a');

  element.href = TemplateCSV;
  element.download = 'AIR AMS COMPLEMENTARY INFORMATION TEMPLATE V1.csv';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

const downloadExplanationDoc = () => {
  const element = document.createElement('a');
  element.href = ExplanationDoc;
  element.download = 'AIR AMS COMPLEMENTARY INFORMATION GUIDE V1.xlsx';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};
export default props => {
  // const profile = JSON.parse(localStorage.profile)
  const checkToken = async () => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    // const response = await Axios.get('http://localhost:3002/token');
    const response = await Axios.get(env['REACT_APP_AUTH'] + '/token');
    if (!response.data.valid) {
      localStorage.clear();
      props.setLogged(false);
      setTimeout(() => {}, 1000);
    }
    props.reload(response.data.version);
    return response.data.valid;
  };

  useEffect(() => {
    async function tttt() {
      await checkToken();
      getSchemas();
    }
    tttt();
  }, []);

  const [files, setFiles] = useState([]);
  const [document, setDocument] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const [, , fetchDoc, isError] = useApi(
    'ccapi',
    'cbp/air/upload2/csv',
    undefined,
    'post'
  );

  const [redirect, setRedirect] = useState(false);
  const [readingFile, setReadingFile] = React.useState(false);
  const [schemas, setSchemas] = useState([]);
  const [schemasLoaded, setSchemasLoaded] = useState([false]);
  const [isDisabled, setIsDisabled] = useState(false);

  //const docName = props.document.files.name.replace('.csv', '');

  const getSchemas = async () => {
    setSchemasLoaded(false);
    try {
      let response = [];
      for (const { type, title } of airSchemasComp) {
        let res = await Axios.get(
          env['REACT_APP_CCOCEAN_FEATHERS_URL'] + `/schemas/${type}`,
          {
            params: {
              dereference: true,
              $populate: 'includes'
            }
          }
        );

        if (res && res.data) {
          let mySchema = res.data;
          mySchema.title = title.toUpperCase();
          mySchema.type = type;
          response.push(mySchema);
        }
      }
      setSchemas(response);
      setSchemasLoaded(true);
    } catch (error) {
      console.error('error :>> ', error);
    }
  };

  return redirect == true ? (
    <Route render={() => <Redirect to="/awb-history" />} />
  ) : (
    <Fragment>
      {!document ? (
        <Grid>
          <Cell size={8} phoneSize={12} desktopOffset={2} phoneOffset={0}>
            <Card tableCard={true}>
              <CardTitle title="Upload CSV Document" />
              <CardText>
                {readingFile ? (
                  <>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: LoadingAnimation,
                        rendererSettings: {
                          preserveAspectRatio: 'xMidYMid slice'
                        }
                      }}
                      height={400}
                      width={400}
                      isStopped={false}
                      isPaused={false}
                    />
                    <div
                      style={{
                        textAlign: 'center',
                        marginBottom: 50,
                        color: 'gray'
                      }}
                    >
                      <ProgressBar></ProgressBar>
                    </div>
                  </>
                ) : (
                  <>
                    <Grid style={{ paddingTop: '0px' }}>
                      <Cell
                        size={12}
                        tabletSize={6}
                        phoneSize={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start'
                        }}
                      >
                        <UtilityButtonWrapper>
                          <Button
                            variant="contained"
                            onClick={downloadCsvFile}
                            color="primary"
                          >
                            Download template csv
                          </Button>
                        </UtilityButtonWrapper>

                        <UtilityButtonWrapper>
                          <Button
                            variant="contained"
                            onClick={downloadExplanationDoc}
                            color="primary"
                          >
                            Download guide
                          </Button>
                        </UtilityButtonWrapper>
                      </Cell>
                    </Grid>
                    {schemas && schemasLoaded == true ? (
                      <Grid>
                        <Cell style={{ textAlign: 'center', width: '100%' }}>
                          {schemas.map((schema, i) => (
                            <FlatFileImporterComponent
                              schema={schema}
                              key={schema.title + '1'}
                              history={props.history}
                              isDisabled={isDisabled}
                              setIsDisabled={setIsDisabled}
                            />
                          ))}
                        </Cell>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </CardText>
            </Card>
          </Cell>
        </Grid>
      ) : (
        <Fragment>
          {isLoading ? (
            <ReactLoading
              type="bubbles"
              color="#0d112b"
              height={'20%'}
              width={'20%'}
            />
          ) : (
            <Preview
              document={document}
              setDocument={setDocument}
              files={files}
              setFiles={setFiles}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const UtilityButtonWrapper = styled.div`
  margin-left: 16px;
`;
