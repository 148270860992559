import {
  Grid,
  Cell,
  TextField,
  Button,
  Subheader,
  SelectField,
  CardText,
  Card,
} from 'react-md';
import React, { Fragment, useState, useEffect } from 'react';
import { useApi } from '../../services/api';
import { toast } from 'react-toastify';
import Button2 from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import DatePicker from 'react-datepicker';
import feathers from '../../services/feathers';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import Axios from 'axios';
const env = process.env;

export default (props) => {
  const { close, AWB, HAWB, getManifest, row } = props;
  console.log({ props });
  const [, , doSend] = useApi('ccapi', 'documents/send', undefined, 'post');
  const [startDate, setStartDate] = useState(new Date());
  const [asn, setAsn] = useState({ statusCode: '' });
  const [ccl, setCcl] = useState({ arrivalAirport: '', terminalOp: '' });
  const [awb, setAwb] = useState({
    prefix: props.prefix || '',
    serialNumber: props.serialNumber || '',
    hawbNumber: props.hawbNumber || '',
    pkTrackingId: '',
  });
  const [arr, setArr] = useState({
    impCarrier: '',
    flightNumber: '',
    arrivalDate: '',
    arrivalRef: '',
  });
  const CustomInput = ({ value, onClick }) => (
    <Button2 variant="outlined" onClick={onClick}>
      {value}
    </Button2>
  );
  const getDataManifest = async () => {
    let userProfile = localStorage.profile
      ? JSON.parse(localStorage.profile)
      : {};

    let query = {
      'docOrigin.AWB.prefix': AWB.split('-')[0],
      'docOrigin.AWB.serialNumber': AWB.split('-')[1],
      'docOrigin.SMI.componentId': {
        $in: ['FRI', 'FRC', 'FXC', 'FXI'],
      },
      $sort: { 'meta.date': 1 },
    };

    if (userProfile.hasOwnProperty('company'))
      query = await { ...query, 'meta.company': userProfile.company };

    let resultFiles = await feathers.service('manifests').find({
      query: query,
    });

    let flight = '';

    let impCarrier = '';
    let month = -1;
    let day = 0;
    let destination = '';
    let terminalOperator = '';
    for await (const item of resultFiles) {
      if (item.docOrigin.hasOwnProperty('ARR')) {
        month =
          parseInt(moment(item.docOrigin.ARR.arrivalDate).format('MM')) - 1;
        day = parseInt(moment(item.docOrigin.ARR.arrivalDate).format('DD'));
        flight = item.docOrigin.ARR.flightNumber;
        impCarrier = item.docOrigin.ARR.impCarrier;
      }
      if (item.docOrigin.hasOwnProperty('CCL')) {
        destination = item.docOrigin.CCL.arrivalAirport;
        terminalOperator = item.docOrigin.CCL.terminalOp;
      }
    }

    if (month > 0 && flight !== '') {
      await setStartDate(new Date(new Date().getFullYear(), month, day));
      await setArr({
        ...arr,
        impCarrier: impCarrier,
        flightNumber: flight,
        arrivalDate: moment(new Date(new Date().getFullYear(), month, day))
          .format('DDMMM')
          .toUpperCase(),
      });
    }

    if (destination !== '') {
      await setCcl({
        ...ccl,
        arrivalAirport: destination,
        terminalOp: terminalOperator,
      });
    }
  };

  if (AWB) {
    useEffect(() => {
      setAwb({
        prefix: AWB.split('-')[0],
        serialNumber: AWB.split('-')[1],
        hawbNumber: HAWB || '',
      });

      getDataManifest();
    }, [AWB]);
  }

  const [, , fetchDoc] = useApi(
    'ccapi',
    `documents${props.company ? '?company=' + props.company : ''}`,
    undefined,
    'post'
  );

  const send = async (id) => {
    let [response, err] = await doSend({ body: [id] });
    if (err) return;
    toast.success(response.message);
    let manifestUpdate = await feathers.service('manifests').patch(row.lastId, {
      'meta.editDate': Date.now(),
    });
    console.log({ manifestUpdate });
  };

  const create = async (willSend) => {
    Axios.defaults.headers.common['Authorization'] = localStorage.token;
    const response2 = await Axios.get(
      env['REACT_APP_AUTH'] + '/validPartnership'
    );
    if (response2.data.success) {
      asn.componentId = 'ASN';
      arr.componentId = 'ARR';
      let [apifsn, err] = await fetchDoc({
        body: {
          SMI: { componentId: 'FSN' },
          CCL: ccl,
          AWB: awb,
          ARR: arr,
          ASN: asn,
        },
      });
      if (err) return;
      if (apifsn) {
        if (apifsn.errors) {
          let detail = '';
          for (let i = 0; i < apifsn.errors.length; i++) {
            if (apifsn.errors.length - 1 === i)
              detail += ` ${apifsn.errors[i].message}`;
            else detail += ` ${apifsn.errors[i].message},`;
          }

          toast.warn(`${apifsn.message}, ${detail}`);
        } else {
          toast.success(
            `FSN for ${apifsn.message.docOrigin.AWB.prefix}-${apifsn.message.docOrigin.AWB.serialNumber} successfully created.`
          );
          if (willSend) {
            await send(apifsn.message._id);
          }
          let updateRow = await feathers.service('awb-status').patch(row._id, {
            manifestEdited: Date.now(),
          });
          if (updateRow) {
            await getManifest();
          }
        }
      } else {
        toast.error(`Error`);
      }
    } else {
      toast.warn(response2.data.message);
    }
  };

  const dateInput = (e) => {
    let date = moment(e).format('DDMMM').toUpperCase();
    setArr({ ...arr, arrivalDate: date });
  };
  const setPrefix = (e) => setAwb({ ...awb, prefix: e });

  const setSerialNumber = (e) => setAwb({ ...awb, serialNumber: e });

  return (
    <Fragment>
      <Grid>
        <Cell size={8} desktopOffset={2} tabletSize={8} phoneSize={12}>
          <Card>
            <CardText>
              <Grid>
                <Cell size={3}>
                  <TextField
                    id="AWBPSN"
                    onChange={(e) => {
                      const re = /^[a-zA-Z0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 3) {
                          setPrefix(e.toUpperCase());
                        }
                      }
                    }}
                    label="AWB #"
                    value={awb.prefix}
                    lineDirection="center"
                    required
                  />
                </Cell>
                <Cell size={3}>
                  <TextField
                    id="AWBPSN"
                    onChange={(e) => {
                      const re = /^[0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 8) {
                          setSerialNumber(e);
                        }
                      }
                    }}
                    label="AWB #"
                    value={awb.serialNumber}
                    lineDirection="center"
                    required
                  />
                </Cell>
                <Cell size={3}>
                  <TextField
                    id="HAWB"
                    defaultValue={HAWB}
                    onChange={(e) => {
                      const re = /^[a-zA-Z0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 12) {
                          setAwb({ ...awb, hawbNumber: e.toUpperCase() });
                        }
                      }
                    }}
                    value={awb.hawbNumber}
                    label="House AWB #"
                    lineDirection="center"
                  />
                </Cell>
                <SelectField
                  id="floating-center-title"
                  className="md-cell md-cell--3"
                  onChange={(arrivalRef) => setArr({ ...arr, arrivalRef })}
                  label="PARN #"
                  style={{ maxHeight: '120px' }}
                  menuItems={PARNs}
                  lineDirection="center"
                />
              </Grid>
            </CardText>
          </Card>
        </Cell>
      </Grid>
      <Grid>
        <Cell size={8} desktopOffset={2} tabletSize={8} phoneSize={12}>
          <Card>
            <CardText>
              <Subheader
                className="md-grid"
                primary
                primaryText="Flight Details"
              />
              <Grid>
                <Cell size={3} tabletSize={2}>
                  <TextField
                    id="floating-center-title"
                    value={arr.impCarrier}
                    onChange={(e) => {
                      const re = /^[^><.|_º,!"@·#&/()=?'¿¡`+*´¨Çç{}–…:; -]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 3) {
                          setArr({ ...arr, impCarrier: e.toUpperCase() });
                        }
                      }
                    }}
                    label="Airline"
                    lineDirection="center"
                  />
                </Cell>
                <Cell size={3} tabletSize={3}>
                  <TextField
                    id="floating-center-title"
                    value={arr.flightNumber}
                    onChange={(e) => {
                      const re = /^[0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 8) {
                          setArr({ ...arr, flightNumber: e });
                        }
                      }
                    }}
                    label="Flight #"
                    lineDirection="center"
                  />
                </Cell>
                <Cell size={3} tabletSize={3}>
                  <TextField
                    id="floating-center-title"
                    value={ccl.arrivalAirport}
                    onChange={(e) => {
                      const re = /^[a-zA-Z\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 3) {
                          setCcl({ ...ccl, arrivalAirport: e.toUpperCase() });
                        }
                      }
                    }}
                    label="Arrival Airline"
                    lineDirection="center"
                  />
                </Cell>
                <Cell size={3} tabletSize={3}>
                  <TextField
                    id="floating-center-title"
                    value={ccl.terminalOp}
                    onChange={(e) => {
                      const re = /^[a-zA-Z0-9\b]*$/;
                      if (e === '' || re.test(e)) {
                        if (e.length <= 3) {
                          setCcl({ ...ccl, terminalOp: e.toUpperCase() });
                        }
                      }
                    }}
                    label="Terminal Operator (Airline)"
                    lineDirection="center"
                  />
                </Cell>
              </Grid>
              <Grid>
                <Cell size={3}>
                  <Typography>Select a Date</Typography>
                  <br></br>
                  <DatePicker
                    selected={startDate}
                    dateFormat="ddMMM"
                    customInput={<CustomInput />}
                    onChange={(e) => {
                      setStartDate(e);
                      dateInput(e);
                    }}
                    withPortal
                  />
                </Cell>
                <SelectField
                  id="floating-center-title"
                  className="md-cell md-cell--9"
                  onChange={(e) =>
                    setAsn({ ...asn, statusCode: e.match(/[0-9]{1}/g)[0] })
                  }
                  label="FSN Reason Code"
                  menuItems={code}
                  lineDirection="center"
                />
              </Grid>
              <Grid>
                <Cell size={3}>
                  <Button
                    style={{ display: AWB ? 'none' : 'block' }}
                    raised
                    secondary
                    iconBefore={false}
                    onClick={async (e) => {
                      await create(false);
                    }}
                  >
                    Create
                  </Button>
                  <Button
                    style={{ display: AWB ? 'block' : 'none' }}
                    raised
                    secondary
                    iconBefore={false}
                    onClick={async (e) => {
                      await create(true);
                      close();
                    }}
                    disabled={
                      awb.prefix.length < 3 ||
                      awb.serialNumber.length < 8 ||
                      arr.impCarrier.length < 2 ||
                      arr.flightNumber === '' ||
                      ccl.arrivalAirport.length < 2 ||
                      ccl.terminalOp.length < 2 ||
                      asn.statusCode === ''
                    }
                  >
                    Create & Send
                  </Button>
                </Cell>
                <Cell style={{ display: AWB ? 'block' : 'none' }} size={2}>
                  <Button raised secondary iconBefore={false} onClick={close}>
                    Cancel
                  </Button>
                </Cell>
              </Grid>
            </CardText>
          </Card>
        </Cell>
      </Grid>
    </Fragment>
  );
};

const code = [
  '3 In-bond arrived at destination',
  //Sent by the bonded custodian at the CBP Control Destination
  '4 Local transfer of consolidated cargo',
  //to a Deconsolidator has been accomplished
  '7 In-bond exported at destination',
  //Sent by the bonded custodian at the CBP Control Destination
];
const PARNs = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];
